import axios from "../../../../api/axios";

export const sendInviteUser = async (formData) => {
  const response = await axios.post(
    "userManagement/addUserManagement",
    formData
  );
  console.log("response", response);
  return response.data;
};

export const getUserManagement = (payload) =>
  axios.post("userManagement/getUserManagement", payload);

export const updateUser = async (formData) => {
  const response = await axios.post(
    "userManagement/updateUserManagement",
    formData
  );
  return response.data;
};

export const deleteUser = (payload) =>
  axios.delete("userManagement/deleteUserManagement", payload);
