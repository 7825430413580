import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Toastr } from "../../../components/Toastr/Toastr";
import Validate from "./components/Validate";
import otpApi from "./api/otp";

export default function Index() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpError, setOtpError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChangeOtp = (i, value) => {
    const newOtp = [...otp];
    newOtp[i] = value;
    setOtp(newOtp);
  };
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const handleOtp = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const enteredOtp = otp.join("");
      if (enteredOtp.length !== 6) {
        setLoading(false);
        setOtpError(t("otp.enterSixDigitsOtp"));
        return;
      }
      const otpDigits = parseInt(enteredOtp, 10);
      const response = await otpApi.validateOtp({ code: otpDigits });
      if (response.data.status === "ok") {
        setLoading(false);
        Toastr.success(t("otp.otpVerifiedSuccessfully"));
        navigate("/mySites");
      } else {
        setLoading(false);
        Toastr.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResendOtp = async (event) => {
    event.preventDefault();
    try {
      setResendLoading(true);
      const response = await otpApi.resendOtp();
      if (response.data.status === "ok") {
        setResendLoading(false);
        Toastr.success(t("otp.otpResendSuccessfully"));
      } else {
        setResendLoading(false);
        Toastr.error(response.data.error);
      }
    } catch (error) {
      setResendLoading(false);
    }
  };
  return (
    <Validate
      handleOtp={handleOtp}
      handleChangeOtp={handleChangeOtp}
      loading={loading}
      otp={otp}
      resendLoading={resendLoading}
      handleResendOtp={handleResendOtp}
      otpError={otpError}
      setOtpError={setOtpError}
    />
  );
}
