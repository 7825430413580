import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import { getPaymentHandler } from "./api/paymentHandler";
import successTransaction from "../../assets/icons/successTransaction.svg";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./assets/stylesheets/paymentHandler.scss";
import storage from "../../utils/storage";

function PaymentSuccess() {
  const [payment, setPayment] = useState(false);
  const orderCode = storage.getItem("order");
  const navigate = useNavigate();
  const currentPath = window.location.href;
  const url = new URL(currentPath);
  const tValue = url.searchParams.get("t");

  const paymentTransaction = async () => {
    try {
      const res = await getPaymentHandler(tValue, orderCode);
      console.log(res.data.status);
      if (res.data.status === 200) {
        setPayment(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function reDirect() {
    navigate("/settings/subscription");
  }

  useEffect(() => {
    paymentTransaction();
  }, []);

  useEffect(() => {
    if (payment)
      setTimeout(() => {
        reDirect();
      }, 10000);
  }, [payment]);

  return (
    <div>
      <AuthorizedHeader />
      <div className="flex-center successPage">
        {payment ? (
          <div className="background-box content">
            <div className="header"> Transaction Successful! </div>
            <img
              src={successTransaction}
              alt="success"
              height="250"
              width="250"
            />
            <div className="body">
              <div className="content-1">
                Congratulations! Your subscription is being activated.{" "}
              </div>
              <div>You will be redirected to previous page shortly.</div>
              <div>
                <a href="/settings/subscription">Go to Subscription page</a>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-center">
            <TailSpin
              height="150"
              width="150"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius=".25"
              wrapperStyle={{}}
              wrapperClass=""
              visible
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentSuccess;
