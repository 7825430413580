import { useState } from "react";
import PropTypes from "prop-types";
import WorldMap from "./componenets/WorldMap";
import PopUpWindow from "../../PopUpWindow";
import ChartHeader from "../../ChartHeader/ChartHeader";

export default function Index({
  data,
  labels,
  title,
  compareData,
  diffData,
  selectedFilters,
  updateFilters,
  dashboard = ""
}) {
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  return (
    <div className="bargraph-body background-box">
      <ChartHeader
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        title={title}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
        worldMap={1}
      />
      {labels && (
        <WorldMap
          data={data}
          compareData={compareData}
          diffData={diffData}
          labels={labels}
          updatePosition={selectedCountry}
          togglePopUp={togglePopUp}
          selectedFilters={selectedFilters}
          updateFilters={updateFilters}
          dashboard={dashboard}
        />
      )}

      {togglePopUp && (
        <PopUpWindow className="world-map">
          <div className="bargraph-body background-box">
            <ChartHeader
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              title={title}
              worldMap={1}
              togglePopUp={togglePopUp}
              setTogglePopUp={setTogglePopUp}
            />
            {labels && (
              <WorldMap
                data={data}
                compareData={compareData}
                diffData={diffData}
                labels={labels}
                togglePopUp={togglePopUp}
                updatePosition={selectedCountry}
                selectedFilters={selectedFilters}
                updateFilters={updateFilters}
                dashboard={dashboard}
              />
            )}
          </div>
        </PopUpWindow>
      )}
    </div>
  );
}

Index.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  compareData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  diffData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  updateFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.oneOfType([Object]).isRequired,
  dashboard: PropTypes.string
};
