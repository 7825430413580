const storagePrefix = "yodalytics_";

const storage = {
  getToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.sessionStorage.setItem(
      `${storagePrefix}token`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },
  setItem: (key, value) => {
    window.sessionStorage.setItem(
      `${storagePrefix}${key}`,
      JSON.stringify(value)
    );
  },
  getItem: (key) => {
    return JSON.parse(window.sessionStorage.getItem(storagePrefix + key));
  }
};

export default storage;
