import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../assets/subscription.scss";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";
import DisplayBillingInfo from "./DisplayBillingInfo";

export default function DisplayAllAddresses({
  addressList,
  selected,
  handleAddressSelect,
  handleChangeModal,
  handleCloseModal
}) {
  const [selectedAdd, setSelectedAdd] = useState(selected);
  const { t } = useTranslation();

  return (
    <div className="display-all-address address-list">
      {addressList.map((ele) => (
        <div>
          <DisplayBillingInfo
            id={ele.billingId}
            key={ele.billingId}
            value={ele}
            showInList
            selected={selectedAdd.billingId === ele.billingId}
            onClick={() => setSelectedAdd(ele)}
            handleChangeModal={handleChangeModal}
          />
          <hr />
        </div>
      ))}
      <div style={{ marginTop: "1em" }} className="flex-center">
        <ButtonLight
          buttonText={t("common.cancelButton")}
          onClick={() => handleCloseModal()}
        />
        <ButtonDark
          isSubmit
          buttonText={t("common.saveButton")}
          className="tagManagerButton"
          onClick={() => {
            handleAddressSelect(selectedAdd);
            handleCloseModal();
          }}
        />
      </div>
    </div>
  );
}

DisplayAllAddresses.propTypes = {
  addressList: PropTypes.oneOfType([PropTypes.array]),
  selected: PropTypes.number,
  handleAddressSelect: PropTypes.func,
  handleChangeModal: PropTypes.func,
  handleCloseModal: PropTypes.func
};
