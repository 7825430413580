/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function TermBarGraph({ termData, commonProps, t }) {
  return (
    <AddBarGraph
      title={t("personaDashboard.term")}
      labels={termData?.labels}
      numBars={termData?.labels?.length < 6 ? termData?.labels?.length : 6}
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: termData?.visitors
      }}
      columnData={[
        {
          name: t("analyticsPage.visitors"),
          diff: termData?.visitors,
          number: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

TermBarGraph.propTypes = {
  termData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    visitors: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired
};
