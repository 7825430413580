import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ChartHeader from "../../../../../components/ChartHeader/ChartHeader";
import "../assets/stylesheets/questions.scss";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import PopUpWindow from "../../../../../components/PopUpWindow/components/PopUpWindow";
import CustomAccordian from "../../../../../components/Accordion/CustomAccordian";

const ScrollableContainer = styled.div`
  margin-top: 10px;
`;

export default function QuestionsComponent({
  title,
  isCompare,
  questions,
  compareQuestions,
  pageCountOfQuestions,
  comparePageCountOfQuestions,
  loadingQuestions,
  handlePagination,
  pageNumber,
  comparePageNumber,
  handlePagination2
}) {
  const { t } = useTranslation();
  const { selectedISIFilters } = useSelector((state) => state?.generalData);
  const { multiBarChartData: multiBarChartData1, selectedRange } = useSelector(
    (state) => state?.isiChatData
  );
  const { multiBarChartData: multiBarChartData2 } = useSelector(
    (state) => state?.isiChatCompareData
  );
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [compareExpandedAccordion, setCompareExpandedAccordion] =
    useState(null);

  const handleAccordionToggle = (index) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? index : false);
  };

  const compreHandleAccordionToggle = (index) => (event, isExpanded) => {
    setCompareExpandedAccordion(isExpanded ? index : false);
  };

  const disableQuestions1 = () => {
    if (
      isCompare &&
      Object.keys(selectedISIFilters).length &&
      selectedISIFilters?.[t("isiChatDashboard.categories")]?.length
    ) {
      return selectedRange === t("common.range_b");
    }
    if (
      isCompare &&
      Object.keys(selectedISIFilters).length &&
      selectedISIFilters?.[t("dashboardMenu.personas")]
    ) {
      return false;
    }
    if (isCompare && Object.keys(selectedISIFilters).length) {
      return multiBarChartData2?.labels.includes(
        selectedISIFilters?.[t("common.date")][0]
      );
    }
    return false;
  };

  const disableQuestions2 = () => {
    if (
      isCompare &&
      Object.keys(selectedISIFilters).length &&
      selectedISIFilters?.[t("isiChatDashboard.categories")]?.length
    ) {
      return selectedRange === t("common.range_a");
    }
    if (
      isCompare &&
      Object.keys(selectedISIFilters).length &&
      selectedISIFilters?.[t("dashboardMenu.personas")]
    ) {
      return false;
    }
    if (isCompare && Object.keys(selectedISIFilters).length) {
      return multiBarChartData1?.labels.includes(
        selectedISIFilters?.[t("common.date")][0]
      );
    }
    return false;
  };

  const QuestionsContent = (
    <div className="bargraph-body background-box">
      <ChartHeader
        title={title}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
      />
      <div className="flex">
        <div className={disableQuestions1() ? "content disabled" : "content"}>
          {isCompare && (
            <span>{`${t("common.date")} ${t(
              "common.range"
            ).toLowerCase()} A`}</span>
          )}
          <ScrollableContainer>
            {loadingQuestions && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1
                }}
              >
                <CircularProgress
                  sx={{ color: themeColors.primaryColorShade2 }}
                />
              </Box>
            )}
            {questions?.map((question) => (
              // <div className="question" key={`${question}${i + 1}`}>
              //   <span className="question-number">
              //     {(pageNumber - 1) * 20 + i + 1}.&nbsp;&nbsp;
              //   </span>
              //   {question}
              // </div>
              <>
                <CustomAccordian
                  key={question?.questionNumber}
                  title={`${question?.questionNumber}. ${question?.user}`}
                  expanded={expandedAccordion === question?.questionNumber}
                  setClickExpand={handleAccordionToggle(
                    question?.questionNumber
                  )}
                  className="custom-accordion"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: themeColors.primaryText,
                    fontFamily: themeColors.fontQuickSand,
                    marginLeft: "10px"
                  }}
                >
                  <div
                    style={{
                      margin: "-15px 0px 20px 20px",
                      backgroundColor: themeColors.primaryColor2,
                      borderRadius: "5px"
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: themeColors.background,
                        fontFamily: themeColors.fontQuickSand,
                        padding: "10px"
                      }}
                    >
                      {question?.bot}
                    </p>
                  </div>
                </CustomAccordian>
                <hr />
              </>
            ))}
          </ScrollableContainer>
        </div>
        {isCompare && (
          <div className={disableQuestions2() ? "content disabled" : "content"}>
            <span>{`${t("common.date")} ${t(
              "common.range"
            ).toLowerCase()} B`}</span>
            <ScrollableContainer>
              {loadingQuestions && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1
                  }}
                >
                  <CircularProgress
                    sx={{ color: themeColors.primaryColorShade2 }}
                  />
                </Box>
              )}
              {compareQuestions?.map((item) => (
                // <div className="question" key={`${item}${i + 1}`}>
                //   <span className="question-number">
                //     {(comparePageNumber - 1) * 20 + i + 1}.&nbsp;&nbsp;
                //   </span>
                //   {item}
                // </div>
                <>
                  <CustomAccordian
                    key={item?.questionNumber}
                    title={`${item.questionNumber}. ${item?.user}`}
                    expanded={compareExpandedAccordion === item?.questionNumber}
                    setClickExpand={compreHandleAccordionToggle(
                      item?.questionNumber
                    )}
                    className="custom-accordion"
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: themeColors.primaryText,
                      fontFamily: themeColors.fontQuickSand,
                      marginLeft: "10px"
                    }}
                  >
                    <div
                      style={{
                        margin: "-15px 0px 20px 20px",
                        backgroundColor: themeColors.primaryColor2,
                        borderRadius: "5px"
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          color: themeColors.background,
                          fontFamily: themeColors.fontQuickSand,
                          padding: "10px"
                        }}
                      >
                        {item?.bot}
                      </p>
                    </div>
                  </CustomAccordian>
                  <hr />
                </>
              ))}
            </ScrollableContainer>
          </div>
        )}
      </div>
      <div className="flex">
        <div className="questionsContainer">
          <Stack spacing={2}>
            <Pagination
              count={pageCountOfQuestions}
              variant="outlined"
              shape="rounded"
              page={pageNumber}
              sx={{
                "& .MuiPaginationItem-root": {
                  backgroundColor: themeColors.background1,
                  color: "#181818",
                  fontSize: "14px",
                  borderColor: themeColors.tertiaryText,
                  "&.Mui-selected": {
                    backgroundColor: themeColors.primaryColor4,
                    color: "#181818"
                  }
                }
              }}
              onChange={(e, page) => {
                handlePagination(e, page);
                setExpandedAccordion(null);
              }}
            />
          </Stack>
        </div>
        {isCompare && (
          <div className="questionsContainer">
            <Stack spacing={2}>
              <Pagination
                count={comparePageCountOfQuestions}
                variant="outlined"
                shape="rounded"
                page={comparePageNumber}
                sx={{
                  "& .MuiPaginationItem-root": {
                    backgroundColor: themeColors.background1,
                    color: "#181818",
                    fontSize: "14px",
                    borderColor: themeColors.tertiaryText,
                    "&.Mui-selected": {
                      backgroundColor: themeColors.primaryColor4,
                      color: "#181818"
                    }
                  }
                }}
                onChange={(e, page) => {
                  handlePagination2(e, page);
                  setCompareExpandedAccordion(null);
                }}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );

  if (togglePopUp)
    return (
      <PopUpWindow className="stacked-chart">{QuestionsContent}</PopUpWindow>
    );
  return QuestionsContent;
}

QuestionsComponent.propTypes = {
  title: PropTypes.string,
  isCompare: PropTypes.bool,
  questions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  compareQuestions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  pageCountOfQuestions: PropTypes.number,
  comparePageCountOfQuestions: PropTypes.number,
  loadingQuestions: PropTypes.bool,
  handlePagination: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  handlePagination2: PropTypes.func.isRequired,
  comparePageNumber: PropTypes.number
};
