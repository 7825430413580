import { createSlice } from "@reduxjs/toolkit";
import { getEcommerceData } from "../apiSlice";

const initialState = {
  allData: [],
  filteredData: [],
  insights: {
    totalRevenue: 0,
    numberOfOrders: 0,
    avgOrderValue: 0,
    activeCustomers: 0,
    ordersAndCustomers: 0
  },
  multiBarChartData: {
    labels: [],
    requests: [],
    purchase: [],
    revenue: []
  },
  bestSellingProducts: {
    labels: [],
    productCount: []
  },
  revenuePerCategory: {
    labels: [],
    revenue: []
  },
  topVriants: {
    labels: [],
    dataset: []
  },
  orderStatus: {
    labels: [],
    dataset: []
  },
  uniqueVsReturningCustomers: {
    labels: [],
    dataset: []
  },
  spendingIntensity: {
    labels: [],
    dataset: []
  },
  worldData: {
    labels: [],
    dataset: []
  },
  averageTimeBetweenOrders: {
    labels: [],
    dataset: []
  },
  repeatPurchaseRate: {
    labels: [],
    dataset: []
  },
  discountsCodeUsage: {
    labels: [],
    values: []
  },
  customerAcquisition: {
    labels: [],
    dataset: []
  },
  customerPreferences: {
    labels: [],
    dataset: []
  },
  newAndRerutnCustomers: {},
  spendingIntensityRanges: {},
  repeatPurchaseCustomers: {},
  averageTimeBetweenOrdersCustomers: {},
  selectedEcommerceFilterType: "Daily",
  isLoading: false
};

const ecommerceSlice = createSlice({
  name: "ecommerce",
  initialState,
  reducers: {
    updateInsights: (state, action) => {
      return { ...state, insights: { ...action.payload } };
    },
    updateFilteredData: (state, action) => {
      return { ...state, filteredData: [...action.payload] };
    },
    updateMultiBarChartData: (state, actions) => {
      return {
        ...state,
        multiBarChartData: { ...state.multiBarChartData, ...actions.payload }
      };
    },
    updateBestSellingProducts: (state, action) => {
      return {
        ...state,
        bestSellingProducts: { ...action.payload }
      };
    },
    updateRevenuePerCategory: (state, action) => {
      return {
        ...state,
        revenuePerCategory: { ...action.payload }
      };
    },
    updateTopVriants: (state, action) => {
      return {
        ...state,
        topVriants: { ...action.payload }
      };
    },
    updateOrderStatus: (state, action) => {
      return {
        ...state,
        orderStatus: { ...action.payload }
      };
    },
    updateUniqueVsReturningCustomers: (state, action) => {
      return {
        ...state,
        uniqueVsReturningCustomers: { ...action.payload }
      };
    },
    updateSpendingIntensity: (state, action) => {
      return {
        ...state,
        spendingIntensity: { ...action.payload }
      };
    },
    updateWorldData: (state, action) => {
      return {
        ...state,
        worldData: { ...action.payload }
      };
    },
    updateAverageTimeBetweenOrders: (state, action) => {
      return {
        ...state,
        averageTimeBetweenOrders: { ...action.payload }
      };
    },
    updateRepeatPurchaseRate: (state, action) => {
      return {
        ...state,
        repeatPurchaseRate: { ...action.payload }
      };
    },

    updateDiscountsCodeUsage: (state, action) => {
      return {
        ...state,
        discountsCodeUsage: { ...action.payload }
      };
    },
    updateCustomerAcquisition: (state, action) => {
      return {
        ...state,
        customerAcquisition: { ...action.payload }
      };
    },
    updateCustomerPreferences: (state, action) => {
      return {
        ...state,
        customerPreferences: { ...action.payload }
      };
    },
    updateSelectedEcommerceFilterType: (state, action) => {
      return { ...state, selectedEcommerceFilterType: action.payload };
    },
    updateNewAndRerutnCustomers: (state, action) => {
      return { ...state, newAndRerutnCustomers: { ...action.payload } };
    },
    updateSpendingIntensityRanges: (state, action) => {
      return { ...state, spendingIntensityRanges: { ...action.payload } };
    },
    updateRepeatPurchaseCustomers: (state, action) => {
      return { ...state, repeatPurchaseCustomers: { ...action.payload } };
    },
    updateAverageTimeBetweenOrdersCustomers: (state, action) => {
      return {
        ...state,
        averageTimeBetweenOrdersCustomers: { ...action.payload }
      };
    }
  },
  extraReducers: {
    [getEcommerceData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getEcommerceData.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        allData: [...action.payload[0]]
      };
    },
    [getEcommerceData.rejected]: (state) => {
      return { ...state, isLoading: false };
    }
  }
});

export const {
  updateInsights,
  updateFilteredData,
  updateMultiBarChartData,
  updateBestSellingProducts,
  updateRevenuePerCategory,
  updateTopVriants,
  updateOrderStatus,
  updateUniqueVsReturningCustomers,
  updateSpendingIntensity,
  updateWorldData,
  updateAverageTimeBetweenOrders,
  updateRepeatPurchaseRate,
  updateDiscountsCodeUsage,
  updateCustomerAcquisition,
  updateCustomerPreferences,
  updateSelectedEcommerceFilterType,
  updateNewAndRerutnCustomers,
  updateSpendingIntensityRanges,
  updateRepeatPurchaseCustomers,
  updateAverageTimeBetweenOrdersCustomers
} = ecommerceSlice.actions;

export default { ecommerceSlice };
