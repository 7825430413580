import { collectTrackingData } from "../api/collectTrackingDataAPI";
import { resetTracking } from "../store/slices/trackingSlice";

export const handleAddToQueue = ({ payload, setTrackingQueue }) => {
  setTrackingQueue((prevQueue) => {
    const exists = prevQueue.some(
      (item) =>
        item.sessionId === payload.sessionId &&
        item.component === payload.component &&
        item.component_label === payload.component_label
    );
    return exists ? prevQueue : [...prevQueue, payload];
  });
};

export const handleCollectTrackingData = async ({
  dispatch,
  setIsSending,
  trackingQueue,
  setTrackingQueue
}) => {
  if (trackingQueue.length === 0) {
    return; // Exit early if the queue is empty
  }

  setIsSending(true); // Setting the flag to true before sending

  try {
    // Send the current queue and clear it afterwards
    const res = await collectTrackingData(trackingQueue);
    if (res?.status === 200) {
      dispatch(resetTracking());
      // Remove only the successfully sent entries from the queue
      setTrackingQueue((prevQueue) => {
        const sentIds = trackingQueue.map((item) => item.component_label); // Assuming component_label uniquely identifies the actions sent
        return prevQueue.filter(
          (item) => !sentIds.includes(item.component_label)
        );
      });
      // setTrackingQueue([]); // Clear the queue after successful API call
    }
  } catch (error) {
    console.error("Error sending tracking data:", error);
  } finally {
    setIsSending(false); // Reset the flag after the API call
  }
};
