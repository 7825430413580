import React from "react";
import { Route, Routes } from "react-router-dom";
import PaymentSuccess from "../../features/Handlers/PaymentSuccess";
import PaymentFailure from "../../features/Handlers/PaymentFailure";

function PageHandler() {
  return (
    <Routes>
      <Route path="/paymentSuccess" element={<PaymentSuccess />} />
      <Route path="/paymentFailure" element={<PaymentFailure />} />
    </Routes>
  );
}

export default PageHandler;
