/* eslint-disable */
import axios from "../../../../api/axios";

export const enablePrivacy = async (payload) => {
  const response = await axios.post("setup/enable-privacy", payload);
  console.log("response", response);
  return response.data;
};

export const getPrivacySettings = (websiteId) =>
  axios.get(`setup/get-privacy/${websiteId}`);
