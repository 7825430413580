import React, { useEffect, useState } from "react";
import "../assets/stylesheets/advertisementStyles.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { Toastr } from "../../../../components/Toastr/Toastr";
import AdvertisementColorComponent from "./AdvertisementColorComponent";
import AdvertisementFontComponent from "./AdvertisementFontComponent";
import {
  getAdvertisementstyles,
  updateAdvertisementStyles
} from "../api/advertisementApi";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";

function AdvertisementStyles() {
  const { t } = useTranslation();
  const { websiteID, createdBy } = useSelector((state) => state.generalData);
  const [loading, setLoading] = useState(false);
  const [advStyles, setAdvStyles] = useState({
    website_id: websiteID,
    customer_id: createdBy,
    titleFontColor: [],
    contentFontColor: [],
    buttonFontColor: [],
    buttonBackgroundColor: [],
    websiteBackgroundColor: [],
    titleFontFamily: [],
    contentFontFamily: [],
    buttonFontFamily: []
  });

  // Api call to get Advertisement styles
  const handleGetAdvertismentStyles = async () => {
    try {
      setLoading(true);
      const resp = await getAdvertisementstyles(`${websiteID}`);
      if (resp?.data?.result?.success) {
        console.log("resp", resp?.data?.result?.result);
        if (resp?.data?.result?.result !== undefined) {
          setAdvStyles(resp?.data?.result?.result);
        }
      } else {
        console.log("get data failed");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  // Function to update Advertisement styles settings
  const handleUpdateAdvertismentStyles = async () => {
    try {
      setLoading(true);
      const TFC = advStyles.titleFontColor.find((style) => style.isSelected);
      const CFC = advStyles.contentFontColor.find((style) => style.isSelected);
      const BFC = advStyles.buttonFontColor.find((style) => style.isSelected);
      const BBC = advStyles.buttonBackgroundColor.find(
        (style) => style.isSelected
      );
      const WBC = advStyles.websiteBackgroundColor.find(
        (style) => style.isSelected
      );
      const TFF = advStyles.titleFontFamily.find((style) => style.isSelected);
      const CFF = advStyles.contentFontFamily.find((style) => style.isSelected);
      const BFF = advStyles.buttonFontFamily.find((style) => style.isSelected);

      const payload = {
        website_id: websiteID,
        customer_id: createdBy,
        titleFontColor: TFC !== undefined ? TFC.color : "",
        contentFontColor: CFC !== undefined ? CFC.color : "",
        buttonFontColor: BFC !== undefined ? BFC.color : "",
        buttonBackgroundColor: BBC !== undefined ? BBC.color : "",
        websiteBackgroundColor: WBC !== undefined ? WBC.color : "",
        titleFontFamily: TFF !== undefined ? TFF.fontFamily : "",
        contentFontFamily: CFF !== undefined ? CFF.fontFamily : "",
        buttonFontFamily: BFF !== undefined ? BFF.fontFamily : ""
      };
      // console.log("payload", payload);
      const response = await updateAdvertisementStyles(payload);
      // console.log("response", response);
      if (response?.data?.success) {
        Toastr.success(t("advertisementStyles.stylesUpdatedSuccessfully"));
      } else {
        Toastr.error(t("advertisementStyles.failedToUpdateStyles"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toastr.error(error.response.data.error);
    }
  };

  // Function to updated select color or font
  const handleColorOrFontChange = (id, object) => {
    console.log("id,object", id, object);

    setAdvStyles((styles) => {
      const isPresent = styles[id]?.find((style) => style.id === object.id);
      let updatedStyle = [];

      if (isPresent !== undefined) {
        updatedStyle = styles[id]?.map((style) => {
          if (object.id !== style.id) {
            return { ...style, isSelected: 0 };
          }
          return object;
        });
      } else {
        const updateStatus = styles[id]?.map((style) => {
          return { ...style, isSelected: 0 };
        });
        updatedStyle = [...updateStatus, object];
      }

      console.log("updatedStyles", updatedStyle);
      if (updatedStyle !== undefined) {
        return {
          ...styles,
          [id]: updatedStyle
        };
      }

      return styles;
    });
  };

  useEffect(() => {
    handleGetAdvertismentStyles();
  }, []);

  console.log("advStyles", advStyles);
  return (
    <div className="advertisement-styles-container">
      <div className="modules-heading">{t("advertisementStyles.heading")}</div>
      <div className="advertisement-subtitle">
        {t("advertisementStyles.subHeading")}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="advertsiment-body-container">
            <div className="advertisement-style-tile">
              {t("advertisementStyles.colorStyles")}
            </div>

            <AdvertisementColorComponent
              key="websiteBackgroundColor"
              id="websiteBackgroundColor"
              title={t("advertisementStyles.websiteBackgroundColor")}
              options={advStyles.websiteBackgroundColor}
              handleColorOrFontChange={handleColorOrFontChange}
            />
            <AdvertisementColorComponent
              key="titleFontColor"
              id="titleFontColor"
              title={t("advertisementStyles.titleFontColor")}
              options={advStyles.titleFontColor}
              handleColorOrFontChange={handleColorOrFontChange}
            />
            <AdvertisementColorComponent
              key="contentFontColor"
              id="contentFontColor"
              title={t("advertisementStyles.contentFontColor")}
              options={advStyles.contentFontColor}
              handleColorOrFontChange={handleColorOrFontChange}
            />
            <AdvertisementColorComponent
              key="buttonFontColor"
              id="buttonFontColor"
              title={t("advertisementStyles.buttonFontColor")}
              options={advStyles.buttonFontColor}
              handleColorOrFontChange={handleColorOrFontChange}
            />
            <AdvertisementColorComponent
              key="buttonBackgroundColor"
              id="buttonBackgroundColor"
              title={t("advertisementStyles.buttonBackgroundColor")}
              options={advStyles.buttonBackgroundColor}
              handleColorOrFontChange={handleColorOrFontChange}
            />

            <div className="advertisement-style-tile">
              {t("advertisementStyles.fontStyles")}
            </div>

            <AdvertisementFontComponent
              key="titleFontFamily"
              id="titleFontFamily"
              title={t("advertisementStyles.titleFontFamily")}
              options={advStyles.titleFontFamily}
              handleColorOrFontChange={handleColorOrFontChange}
            />
            <AdvertisementFontComponent
              key="contentFontFamily"
              id="contentFontFamily"
              title={t("advertisementStyles.contentFontFamily")}
              options={advStyles.contentFontFamily}
              handleColorOrFontChange={handleColorOrFontChange}
            />
            <AdvertisementFontComponent
              key="buttonFontFamily"
              id="buttonFontFamily"
              title={t("advertisementStyles.buttonFontFamily")}
              options={advStyles.buttonFontFamily}
              handleColorOrFontChange={handleColorOrFontChange}
            />
          </div>
          {true ? (
            <div className="flex-center button-container">
              <ButtonLight
                buttonText={t(
                  "profileSettings.generalInformation.buttons.cancel"
                )}
                onClick={() => {
                  handleGetAdvertismentStyles();
                }}
              />
              <ButtonDark
                onClick={() => {
                  handleUpdateAdvertismentStyles();
                }}
                buttonText={t(
                  "profileSettings.generalInformation.buttons.save"
                )}
                className="button-spacing"
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default AdvertisementStyles;
