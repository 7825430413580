/* eslint-disable */
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import folder from "../../assets/icons/Folder.png";
import {
  ButtonDark,
  ButtonLight
} from "../../components/inputElements/buttons/MainButton";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/uploadImages.scss";
import "../stylesheets/addImageModel.scss";
import DragAndDropArea from "./DragAndDropArea";
import PreviewedImage from "./PreviewedImage";
import SelectedImage from "./SelectedImage";

export default function AddImageModel({
  loading,
  close,
  handleAddImagePopUpClose,
  uploadedImages,
  setUploadedImages,
  handleClick,
  handleDrop,
  handleDragOver,
  handleFileUpload,
  handleUploadImages
}) {
  const { t } = useTranslation();

  const [preViewedImage, setPreviewedImage] = useState(null);

  const text =
    uploadedImages?.length == 1
      ? t("uploadImages.singleFile")
      : t("uploadImages.multipleFiles");

  return (
    <div className="add-image-model">
      <div className="upload-images">
        <p>{t("uploadImages.uploadImages")}</p>
      </div>
      <div className="close-div">
        <img
          src={close}
          onClick={handleAddImagePopUpClose}
          className="close-div-img"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              handleAddImagePopUpClose();
            }
          }}
        />
      </div>

      {loading ? (
        <div
          style={{
            marginTop: "20%"
          }}
          className="flex-center"
        >
          <TailSpin
            height="50"
            width="50"
            color={themeColors.primaryColor}
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="spinner"
            visible
          />
        </div>
      ) : (
        <>
          {/* Drag and drop area if image is previewed */}
          {preViewedImage !== null && (
            <>
              <DragAndDropArea
                folder={folder}
                handleClick={handleClick}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleFileUpload={handleFileUpload}
                text={text}
                uploadedImages={uploadedImages}
                width="120px"
                height="90px"
              />
            </>
          )}
          {/* Drag and drop area if image is previewed ends */}

          {preViewedImage == null ? (
            <>
              {/* Drag and drop area */}
              <DragAndDropArea
                folder={folder}
                handleClick={handleClick}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleFileUpload={handleFileUpload}
                text={text}
                uploadedImages={uploadedImages}
                width="365px"
                height="320px"
              />
              {/* Drag and drop area ends */}
            </>
          ) : (
            <PreviewedImage
              preViewedImage={preViewedImage}
              uploadedImages={uploadedImages}
            />
          )}

          {uploadedImages?.length > 0 && (
            <div className="uploaded-images-name">
              <p>{t("tagManager.Name")}</p>
            </div>
          )}

          {/* Uploaded images preview */}
          <div
            style={{
              height: "15vh",
              overflowY: "auto"
            }}
          >
            <SelectedImage
              uploadedImages={uploadedImages}
              setUploadedImages={setUploadedImages}
              preViewedImage={preViewedImage}
              setPreviewedImage={setPreviewedImage}
            />
          </div>
          {/* Uploaded images preview ends */}
        </>
      )}

      <div
        className="flex-center"
        style={{
          paddingTop: loading && "50%"
        }}
      >
        <ButtonLight
          buttonText={t("common.cancelButton")}
          className="margin-right"
          onClick={handleAddImagePopUpClose}
        />
        <ButtonDark
          disabled={uploadedImages?.some((image) => image.formattedSize > 30)}
          buttonText={t("uploadImages.upload")}
          onClick={handleUploadImages}
        />
      </div>
    </div>
  );
}
