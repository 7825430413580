import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import FilterPill from "../../components/FilterPill/components/FilterPill";
import DateCalendar from "../../components/DateRange";
import { getPerformanceCampaignData } from "../../store/apiSlice";
import LocalStorage from "../../utils/LocalStorgae";
import "../stylesheets/persona.scss";
import {
  getAllCampaignTypeData,
  getAllSourcesData,
  getCampaignDetailsData,
  getInsights,
  groupByChannelAndSumSpend
} from "../../utils/campaignPerformanceUtil";
import {
  updateAllCampaignTypeData,
  updateAllSourcesData,
  updateCampaignData,
  updateCampaignTypeData,
  updateCampainDetailsData,
  updateChannelData,
  updateFilteredData,
  updateInsights
} from "../../store/slices/campaignPerformance";
import GeneralDashboard from "../../features/CampaignPerformance/GeneralDashboard";
import Loading from "../../features/Placeholders/Loading";

export default function CampaignPerformance() {
  const dashboard = "campaignPerformance";
  const { t } = useTranslation();
  const { websiteID, isCompare, dateRange, selectedCampPerfomanceFilters } =
    useSelector((state) => state?.generalData);
  const language = LocalStorage.getItem("selectedLanguage");
  const dispatch = useDispatch();

  const { allData, filteredData, isLoading } = useSelector(
    (state) => state?.campaignPerformance
  );

  const separateData = (data) => {
    dispatch(updateInsights(getInsights(data)));
    if (!selectedCampPerfomanceFilters?.[t("campaignPerformance.sources")]) {
      dispatch(updateChannelData(groupByChannelAndSumSpend(data, "channel")));
    }
    if (
      !selectedCampPerfomanceFilters?.[t("campaignPerformance.campaignType")]
    ) {
      dispatch(
        updateCampaignTypeData(groupByChannelAndSumSpend(data, "Add_type"))
      );
    }
    if (
      !selectedCampPerfomanceFilters?.[t("campaignPerformance.campaignUTM")]
    ) {
      dispatch(
        updateCampaignData(groupByChannelAndSumSpend(data, "campaign_name"))
      );
    }
    dispatch(updateAllSourcesData(getAllSourcesData(data)));
    dispatch(updateCampainDetailsData(getCampaignDetailsData(data)));
    dispatch(updateAllCampaignTypeData(getAllCampaignTypeData(data)));
  };

  useEffect(() => {
    dispatch(
      getPerformanceCampaignData({
        website_id: websiteID,
        dateRange: [
          { startDate: dateRange.startDate, endDate: dateRange.endDate }
        ]
      })
    );
  }, [dateRange, language, websiteID]);

  useEffect(() => {
    if (Object.keys(selectedCampPerfomanceFilters).length > 0) {
      separateData(filteredData);
    } else {
      separateData(allData);
    }
  }, [allData, filteredData, language]);

  const filterData = (data) => {
    let tempData = data;

    if (selectedCampPerfomanceFilters[t("campaignPerformance.sources")]) {
      tempData = tempData.filter((ele) =>
        selectedCampPerfomanceFilters[
          t("campaignPerformance.sources")
        ].includes(ele.channel)
      );
    }
    if (selectedCampPerfomanceFilters[t("campaignPerformance.campaignType")]) {
      tempData = tempData.filter((ele) =>
        selectedCampPerfomanceFilters[
          t("campaignPerformance.campaignType")
        ].includes(ele.Add_type)
      );
    }
    if (selectedCampPerfomanceFilters[t("campaignPerformance.campaignUTM")]) {
      tempData = tempData.filter((ele) =>
        selectedCampPerfomanceFilters[
          t("campaignPerformance.campaignUTM")
        ].includes(ele.campaign_name)
      );
    }
    return tempData;
  };

  useEffect(() => {
    dispatch(updateFilteredData(filterData(allData)));
  }, [selectedCampPerfomanceFilters, language]);

  const insightsTitle = {
    spend: t("campaignPerformance.spend"),
    revenue: t("campaignPerformance.revenue"),
    roi: t("campaignPerformance.roi"),
    impressions: t("campaignPerformance.impressions"),
    leads: t("campaignPerformance.leads"),
    sales: t("campaignPerformance.sales"),
    cpa: t("campaignPerformance.cpa"),
    revenuePerSales: t("campaignPerformance.revenuePerSales")
  };

  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container padding-bottom-campaign">
          <div className="flex-between filter-header">
            <FilterPill
              filterValues={selectedCampPerfomanceFilters}
              dashboard={dashboard}
            />
            <DateCalendar />
          </div>
          {isLoading && <Loading />}
          {!isLoading && (
            <GeneralDashboard
              insightsTitle={insightsTitle}
              dashboard={dashboard}
              isCompare={isCompare}
              selectedCampPerfomanceFilters={selectedCampPerfomanceFilters}
            />
          )}
        </div>
      </div>
    </div>
  );
}
