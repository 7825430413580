import axios from "../../../../api/axios";

const getPersonaGroups = (payload) =>
  axios.get(`/persona/getPersonaData/${payload}`);

const createPersonaGroup = (payload) =>
  axios.post(`/persona/addPersonaDetails`, payload);

const updatePersonaGroup = (payload) =>
  axios.put(`/persona/updatePersonaData`, payload);

const deletePersonaGroup = (payload) =>
  axios.post(`/persona/deletePersonaGroups`, payload);

export {
  getPersonaGroups,
  createPersonaGroup,
  updatePersonaGroup,
  deletePersonaGroup
};
