import PropTypes from "prop-types";
import StackedChart from "../../../../components/ChartComponents/StackedChart";

export default function StackedChartComponent({
  personasData,
  updateSelectedPersonasFilters,
  selectedPersonaFilters,
  t
}) {
  return (
    <StackedChart
      title={t("personaDashboard.personas")}
      views={personasData?.views}
      visitors={personasData?.visitors}
      labels={personasData?.labels}
      updatedComponentFilters={updateSelectedPersonasFilters}
      selectedFilters={selectedPersonaFilters}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

StackedChartComponent.propTypes = {
  personasData: PropTypes.shape({
    views: PropTypes.arrayOf(PropTypes.number),
    visitors: PropTypes.arrayOf(PropTypes.number),
    labels: PropTypes.arrayOf(PropTypes.string)
  }),
  updateSelectedPersonasFilters: PropTypes.func.isRequired,
  selectedPersonaFilters: PropTypes.oneOfType([PropTypes.shape({})]),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired
};
