/* eslint-disable */
import React from "react";
import LanguageDropdown from "../../../../components/layouts/LanguageDropdown";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import { useTranslation } from "react-i18next";

export default function MainForm({ login }) {
  const { t } = useTranslation();

  return (
    <div className="flex-between">
      <div className="bottom-details">
        <p>Yodalytics™- A Limendo GmbH Software product</p>
        <div className="flex policies">
          <p
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              cursor: "pointer",
              color: themeColors.primaryColorShade2,
              whiteSpace: "nowrap"
            }}
            onClick={() =>
              window.open(
                "https://www.yodalytics.com/service/nutzungsbedingungen",
                "_blank"
              )
            }
          >
            {t("landingPage.termsConditions")}
          </p>
          <p
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              cursor: "pointer",
              color: themeColors.primaryColorShade2,
              whiteSpace: "nowrap"
            }}
            onClick={() =>
              window.open(
                "https://www.yodalytics.com/service/datenschutzbestimmungen",
                "_blank"
              )
            }
          >
            {t("landingPage.privacyPolicy")}
            {"  "}
          </p>
        </div>
      </div>
      <div
        className={login ? "language-change-login" : "language-change-forgot"}
      >
        <LanguageDropdown showLabel />
      </div>
    </div>
  );
}
