/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/inputElements/Input/Input";
import forgetPasswordImage from "../../../../assets/images/auth2.svg";
import LanguageDropdown from "../../../../components/layouts/LanguageDropdown";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/forgotPassword.scss";
import MainForm from "../../LogIn/components/MainForm";

export default function ForgotPasswordForm({
  emailChangeHandler,
  email,
  loading,
  handleSubmit,
  emailError
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="forgot-password-section">
      <div
        style={isMobile ? { width: "1296px" } : { maxWidth: "1296px" }}
        className="container-2"
      >
        <div className="flex">
          <div className="forgot-password-image">
            <img
              style={isMobile ? { height: "72rem" } : { height: "57.25rem" }}
              src={forgetPasswordImage}
              alt="svg"
            />
          </div>
          <div
            style={!isMobile ? { height: "57.25rem" } : {}}
            className="forget-password-form"
          >
            <div className="flex-column">
              <div className="forget-password-top">
                <h2 className="modules-heading heading-bottom">
                  {t("forgotPassword.forgotPassword")}
                </h2>
                <p className="sub-text">{t("forgotPassword.subDetails")}</p>
                <form onSubmit={handleSubmit}>
                  <div className="email-input">
                    <Input
                      type="text"
                      label={t("login.email")}
                      placeholder="Brucewayne.batman@email.com"
                      value={email}
                      onChange={emailChangeHandler}
                      error={emailError}
                    />
                  </div>
                  <button type="submit" disabled={loading}>
                    {loading ? "Loading..." : t("ManageApi.editApi.continue")}
                  </button>
                </form>
                <div className="signup-redirect">
                  <p>
                    {t("login.dontHaveAnAccount")}{" "}
                    <span onClick={() => navigate("/signup")}>
                      {t("login.clickRegister")}{" "}
                    </span>
                  </p>
                </div>
              </div>

              <div className="forgot-password-bottom">
                {/*  */}
                <MainForm login={false} />
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  emailChangeHandler: PropTypes.func,
  email: PropTypes.string,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  emailError: PropTypes.string
};
