/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../assets/stylesheets/validate.scss";

export default function Validate({
  otp,
  handleOtp,
  loading,
  handleChangeOtp,
  resendLoading,
  handleResendOtp,
  otpError,
  setOtpError
}) {
  const inputRefs = useRef([]);

  const focusNextInput = (index) => {
    if (index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputChange = (index, value) => {
    handleChangeOtp(index, value);
    if (value === "") {
      // If the input is empty, move focus to the previous input
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else {
      // If the input has a value, move focus to the next input
      focusNextInput(index);
    }
    setOtpError("");
  };

  const { t } = useTranslation();

  return (
    <section className="validate">
      <h3>{t("otp.validateAccount")}</h3>
      <p className="heading-details">{t("otp.otpSentMessage")}</p>
      <form>
        <div className="otp-inputs">
          {otp.map((digit, index) => (
            <input
              type="text"
              key={`${index}-${digit}`}
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              maxLength={1}
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
          {otpError && <p className="error-message">{otpError}</p>}
        </div>
        <div className="flex buttons">
          <button
            type="submit"
            className="send-again"
            disabled={resendLoading}
            onClick={handleResendOtp}
          >
            {resendLoading ? t("common.loading") : t("otp.sendAgain")}
          </button>
          <button
            type="submit"
            className="confirm"
            disabled={loading}
            onClick={handleOtp}
          >
            {loading ? t("common.loading") : t("common.confirm")}
          </button>
        </div>
      </form>
      <div className="info">
        <p className="notice">{t("otp.noticeTitle")}</p>
        <p className="notice-details">{t("otp.noticeDetails")}</p>
      </div>
    </section>
  );
}

Validate.propTypes = {
  otp: PropTypes.arrayOf(PropTypes.string),
  handleOtp: PropTypes.func,
  loading: PropTypes.bool,
  handleChangeOtp: PropTypes.func,
  resendLoading: PropTypes.bool,
  handleResendOtp: PropTypes.func,
  otpError: PropTypes.string,
  setOtpError: PropTypes.func
};
