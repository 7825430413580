/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./confirmPassword.scss";

function ConfirmPasswordInput({
  label,
  value,
  onChange,
  error,
  placeholder,
  name,
  register
}) {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  if (register) {
    return (
      <div className="confirm-password-input-wrapper">
        {label && <label htmlFor={label}>{label}</label>}
        <div className="confirm-password-input">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id={label}
            value={value}
            name={name}
            {...register}
            placeholder={placeholder}
            className={`add-tag-input-label ${error && "error"}`}
          />
          {showConfirmPassword ? (
            <i className="ri-eye-off-line" onClick={togglePasswordVisibility} />
          ) : (
            <i className="ri-eye-line" onClick={togglePasswordVisibility} />
          )}
        </div>
        {error && <p className="error-message">{error}</p>}
      </div>
    );
  }
  return (
    <div className="confirm-password-input-wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <div className="confirm-password-input">
        <input
          type={showConfirmPassword ? "text" : "password"}
          id={label}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`add-tag-input-label ${error && "error"}`}
        />
        {showConfirmPassword ? (
          <i className="ri-eye-off-line" onClick={togglePasswordVisibility} />
        ) : (
          <i className="ri-eye-line" onClick={togglePasswordVisibility} />
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

ConfirmPasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string
};

export default ConfirmPasswordInput;
