/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import DonutChart from "../../../components/ChartComponents/DonutChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import Insights from "../../../components/Insights";
import MultiBarChart from "../../../components/ChartComponents/MultiBarChart";

export default function ComparePMSDashboard({
  isCompare,
  selectedFilters,
  insightsTitle,
  dashboard,
  updateSelectedFilter
}) {
  const {
    visitorsLineData,
    countriesData,
    roomsData,
    channelsData,
    daysOfStayData,
    adPartnerData,
    adMediumData,
    adCampaignData,
    monthlyRequestData,
    monthlyBookingData,
    ageData,
    spendData,
    genderData,
    typeData
  } = useSelector((state) => state.comparePMSData);
  const { allData: allData2, insights: insightsToCompare } = useSelector(
    (state) => state.PMSdataToCompare
  ); // compareData
  const { allData: allData1, insights } = useSelector((state) => state.PMSData);
  const { t } = useTranslation();

  const commonProps = {
    isCompare,
    dashboard,
    filterValues: selectedFilters
  };

  if (allData1.length > 0 && allData2.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
          insightsToCompare={insightsToCompare}
        />
        <MultiBarChart
          title={t("PMSPage.lineChartTitle")}
          filterTitle={t("common.date")}
          labels={visitorsLineData?.labels}
          compareLabels={visitorsLineData?.compareLabels}
          data1={{
            dataset: visitorsLineData?.requests,
            label: `${t("PMSPage.requests")} (${t("common.range")} A)`
          }}
          compareData1={{
            dataset: visitorsLineData?.secondRequests,
            label: `${t("PMSPage.requests")} (${t("common.range")} B)`
          }}
          data2={{
            dataset: visitorsLineData?.bookings,
            label: `${t("PMSPage.bookings")} (${t("common.range")} A)`
          }}
          compareData2={{
            dataset: visitorsLineData?.secondBookings,
            label: `${t("PMSPage.bookings")} (${t("common.range")} B)`
          }}
          data3={{
            dataset: visitorsLineData?.revenue,
            label: `${t("PMSPage.revenue")} (${t("common.range")} A)`
          }}
          compareData3={{
            dataset: visitorsLineData?.secondRevenue,
            label: `${t("PMSPage.revenue")} (${t("common.range")} B)`
          }}
          {...commonProps}
        />

        {/* PERSONA */}
        {/* <StackedChart
        title={t("PMSPage.personaTitle")}
        views={personaData?.views}
        visitors={personaData?.visitors}
        labels={personaData?.labels}
      /> */}
        {/* Persona description  */}

        <div
          className="grid half-column-grid"
          style={{ height: isCompare ? "30rem" : "22.5rem" }}
        >
          <AddBarGraph
            title={t("PMSPage.requestTypeTitle")}
            subtitle={t("PMSPage.type")}
            labels={typeData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: typeData?.requests1
            }}
            compareData={typeData?.requests2}
            numBars={
              typeData?.labels?.length < 6 ? typeData?.labels?.length : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: typeData?.requests,
                first: typeData?.requests1,
                second: typeData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.roomsTitle")}
            subtitle={t("PMSPage.roomType")}
            labels={roomsData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: roomsData?.requests1
            }}
            compareData={roomsData?.requests2}
            numBars={
              roomsData?.labels?.length < 6 ? roomsData?.labels?.length : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: roomsData?.requests,
                first: roomsData?.requests1,
                second: roomsData?.requests2,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomReq"),
                diff: roomsData?.roomReq,
                first: roomsData?.roomReq1,
                second: roomsData?.roomReq2,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <div
          className="grid half-column-grid"
          style={{ height: isCompare ? "30rem" : "22.5rem" }}
        >
          <AddBarGraph
            title={t("PMSPage.channelTitle")}
            subtitle={t("PMSPage.sources")}
            labels={channelsData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: channelsData?.requests1
            }}
            compareData={channelsData?.requests2}
            numBars={
              channelsData?.labels?.length < 6
                ? channelsData?.labels?.length
                : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: channelsData?.requests,
                first: channelsData?.requests1,
                second: channelsData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.daysOfStayTitle")}
            subtitle={t("PMSPage.days")}
            labels={daysOfStayData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: daysOfStayData?.requests1
            }}
            compareData={daysOfStayData?.requests2}
            numBars={
              daysOfStayData?.labels?.length < 6
                ? daysOfStayData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: daysOfStayData?.requests,
                first: daysOfStayData?.requests1,
                second: daysOfStayData?.requests2,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomReq"),
                diff: daysOfStayData?.roomReq,
                first: daysOfStayData?.roomReq1,
                second: daysOfStayData?.roomReq2,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>

        <div
          className="grid grid-template"
          style={{ height: isCompare ? "30rem" : "22.5rem" }}
        >
          <AddBarGraph
            title={t("PMSPage.adMediumTitle")}
            subtitle={t("PMSPage.sources")}
            labels={adMediumData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: adMediumData?.requests1
            }}
            compareData={adMediumData?.requests2}
            numBars={
              adMediumData?.labels?.length < 6
                ? adMediumData?.labels?.length
                : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: adMediumData?.requests,
                first: adMediumData?.requests1,
                second: adMediumData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.adCampaignTitle")}
            subtitle={t("PMSPage.sources")}
            labels={adCampaignData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: adCampaignData?.requests1
            }}
            compareData={adCampaignData?.requests2}
            numBars={
              adCampaignData?.labels?.length < 6
                ? adCampaignData?.labels?.length
                : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: adCampaignData?.requests,
                first: adCampaignData?.requests1,
                second: adCampaignData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.adPartnerTitle")}
            subtitle={t("PMSPage.sources")}
            labels={adPartnerData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: adPartnerData?.requests1
            }}
            compareData={adPartnerData?.requests2}
            numBars={
              adPartnerData?.labels?.length < 6
                ? adPartnerData?.labels?.length
                : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: adPartnerData?.requests,
                first: adPartnerData?.requests1,
                second: adPartnerData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <div
          className="grid half-column-grid"
          style={{ height: isCompare ? "30rem" : "22.5rem" }}
        >
          <AddBarGraph
            title={t("PMSPage.monthlyReqTitle")}
            subtitle={t("PMSPage.arrival")}
            labels={monthlyRequestData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: monthlyRequestData?.requests1
            }}
            compareData={monthlyRequestData?.requests2}
            numBars={
              monthlyRequestData?.labels?.length < 6
                ? monthlyRequestData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: monthlyRequestData?.requests,
                first: monthlyRequestData?.requests1,
                second: monthlyRequestData?.requests2,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomReq"),
                diff: monthlyRequestData?.roomReq,
                first: monthlyRequestData?.roomReq1,
                second: monthlyRequestData?.roomReq2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.monthBookingTitle")}
            subtitle={t("PMSPage.arrival")}
            labels={monthlyBookingData?.labels}
            data={{
              tooltip: t("PMSPage.bookings"),
              values: monthlyBookingData?.bookings1
            }}
            compareData={monthlyBookingData?.bookings2}
            numBars={
              monthlyBookingData?.labels?.length < 6
                ? monthlyBookingData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.bookings"),
                diff: monthlyBookingData?.bookings,
                first: monthlyBookingData?.bookings1,
                second: monthlyBookingData?.bookings2,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomBooking"),
                diff: monthlyBookingData?.roomBook,
                first: monthlyBookingData?.roomBook1,
                second: monthlyBookingData?.roomBook2,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <WorldMap
          data={countriesData?.requests1}
          compareData={countriesData?.requests2}
          diffData={countriesData?.requests}
          labels={countriesData.labels}
          title={t("PMSPage.worldTitle")}
          selectedFilters={selectedFilters}
          updateFilters={updateSelectedFilter}
        />
        <div className="grid grid-template">
          <AddBarGraph
            title={t("PMSPage.ageTitle")}
            subtitle={t("PMSPage.ageTitle")}
            labels={ageData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: ageData?.requests1
            }}
            compareData={ageData?.requests2}
            numBars={ageData?.labels?.length < 6 ? ageData?.labels?.length : 6}
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: ageData?.requests,
                first: ageData?.requests1,
                second: ageData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.spendTitle")}
            subtitle={t("PMSPage.euros")}
            labels={spendData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: spendData?.requests1
            }}
            compareData={spendData?.requests2}
            numBars={
              spendData?.labels?.length < 6 ? spendData?.labels?.length : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: spendData?.requests,
                first: spendData?.requests1,
                second: spendData?.requests2,
                number: true
              }
            ]}
            {...commonProps}
          />
          <DonutChart
            title={t("PMSPage.genderTitle")}
            labels={genderData?.labels}
            dataSet={genderData?.requests1}
            compareData={genderData?.requests2}
            selectedFilters={selectedFilters}
            updateFilters={updateSelectedFilter}
          />
        </div>
        <div className="grid grid-template">
          {/* <AddBarGraph
            title={t("PMSPage.avgPriceTitle")}
            subtitle={t("PMSPage.euros")}
            labels={avgPriceData?.labels}
            data={avgPriceData?.dataset}
            requests={avgPriceData?.dataset}
            numBars={
              avgPriceData?.labels?.length < 6
                ? avgPriceData?.labels?.length
                : 6
            }
          /> */}
        </div>
      </>
    );

  return <NoDataFound />;
}

ComparePMSDashboard.propTypes = {
  isCompare: PropTypes.bool,
  selectedFilters: PropTypes.oneOfType([Object]),
  insightsTitle: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  updateSelectedFilter: PropTypes.func
};
