/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import { t } from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { deleteBillingAddress } from "../api/subscriptionAPI";
import DeleteComponent from "../../../../components/DeletePopUp/DeleteComponent";
import EditButton from "../../../../assets/icons/edit-button.svg";
import DeleteButton from "../../../../assets/icons/bin.svg";

export default function DisplayBillingInfo({
  value,
  id,
  showInList,
  selected,
  onClick,
  handleChangeModal
}) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { createdBy: customerId } = useSelector((state) => state.generalData);
  const deleteAddress = async () => {
    try {
      await deleteBillingAddress({ customer_id: customerId, billing_id: id });
      handleChangeModal("changeAddress", { address: null });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div
      onClick={onClick}
      className={`flex-column bill-details ${showInList ? "list" : "display"} ${
        selected && "selected"
      }`}
    >
      <div className="flex">
        <div className="flex-center">
          {showInList && (
            <input
              type="radio"
              id={id}
              name="address"
              checked={selected}
              onChange={onClick}
            />
          )}
          <span className="name">
            {value.companyName !== ""
              ? `${value.companyName}`
              : `${value.firstName} ${value.lastName}`}
          </span>
        </div>
        <div>
          {showInList && (
            <img
              src={DeleteButton}
              alt="delete-button"
              onClick={() => setShowDeletePopup(true)}
            />
          )}
          <img
            src={EditButton}
            alt="edit-button"
            onClick={() => handleChangeModal("editAddress", { address: id })}
          />
        </div>
      </div>
      <div>
        <div className={`address ${showInList && "add-margin"}`}>
          <div>{`${value.address}, ${value.country}`}</div>
          <div>
            {`${value.email} `}
            {value.vatNumber && (
              <span className="vat">
                {t("subscription.vat")} :- {"  "}
                {value.vatNumber}
              </span>
            )}
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <DeleteComponent
          loading={false}
          onCancel={() => setShowDeletePopup(false)}
          onConfirmDelete={() => {
            setShowDeletePopup(false);
            deleteAddress();
          }}
        />
      )}
    </div>
  );
}

DisplayBillingInfo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.number,
  showInList: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  handleChangeModal: PropTypes.func
};
