import PropTypes from "prop-types";
import Input from "../../../../components/inputElements/Input/Input";

export default function ServiceField({
  label,
  value,
  onChange,
  isDisabled,
  type = "text"
}) {
  return (
    <div>
      <LabelInputSet>
        <label htmlFor={label} className="label-tag">
          {label}
        </label>
        <Input
          type={type}
          id={label}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          // error={emailError}
        />
      </LabelInputSet>
    </div>
  );
}

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

ServiceField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.string
};

LabelInputSet.propTypes = {
  children: PropTypes.element
};
