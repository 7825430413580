/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import FilterPill from "./components/FilterPill";

export default function Index(props) {
  return <FilterPill {...props} />;
}

Index.propTypes = {
  dashboard: PropTypes.string,
  filterValues: PropTypes.oneOfType([Object])
};
