import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import FilterPill from "../../components/FilterPill/components/FilterPill";
import DateCalendar from "../../components/DateRange";
import LocalStorage from "../../utils/LocalStorgae";
import Loading from "../../features/Placeholders/Loading";
import GeneralDashboard from "../../features/ECommerce/GeneralDashboard";
import { updateSelectedEcommerceFilters } from "../../store/slices/generalSlice";
import { getEcommerceData } from "../../store/apiSlice";
import {
  updateAverageTimeBetweenOrders,
  updateAverageTimeBetweenOrdersCustomers,
  updateBestSellingProducts,
  updateCustomerAcquisition,
  updateCustomerPreferences,
  updateDiscountsCodeUsage,
  updateFilteredData,
  updateInsights,
  updateMultiBarChartData,
  updateNewAndRerutnCustomers,
  updateOrderStatus,
  updateRepeatPurchaseCustomers,
  updateRepeatPurchaseRate,
  updateRevenuePerCategory,
  updateSpendingIntensity,
  updateSpendingIntensityRanges,
  updateTopVriants,
  updateUniqueVsReturningCustomers,
  updateWorldData
} from "../../store/slices/ecommerceSlice";
import {
  calculateAverageTimeBWOrders,
  getBestSellingProducts,
  getCustomerAcquisition,
  getCustomerPreferences,
  getDiscountCodeCounts,
  getInsights,
  getMultibarchartData,
  getMultibarchartDataByMonth,
  getMultibarchartDataByWeek,
  getMultibarchartDataByYear,
  getRepeatPurchaseRateData,
  getRevenuePerCategory,
  getSpendingIntensityData,
  getTopVariantsData,
  getUniqueVsReturningCustomers,
  groupByCountryAndCount,
  modifyData,
  orderStatusCounts
} from "../../utils/ecommerceUtil";

export default function ECommerce() {
  const dashboard = "ecommerce";
  const { t } = useTranslation();
  const {
    websiteID,
    isCompare,
    dateRange,
    compareDateRange,
    // createdBy,
    selectedEcommerceFilters
  } = useSelector((state) => state?.generalData);
  const language = LocalStorage.getItem("selectedLanguage");
  const dispatch = useDispatch();

  const {
    isLoading,
    allData,
    filteredData,
    selectedEcommerceFilterType,
    newAndRerutnCustomers,
    spendingIntensityRanges,
    repeatPurchaseCustomers,
    averageTimeBetweenOrdersCustomers
  } = useSelector((state) => state?.ecommerce);

  const insightsTitle = {
    totalRevenue: t("ecommerce.totalRevenue"),
    numberOfOrders: t("ecommerce.numberOfOrders"),
    avgOrderValue: t("ecommerce.avgOrderValue"),
    activeCustomers: t("ecommerce.activeCustomers"),
    ordersAndCustomers: t("ecommerce.ordersAndCustomers")
  };

  const separateData = (data) => {
    dispatch(updateInsights(getInsights(data)));
    if (!selectedEcommerceFilters?.[t("common.date")]) {
      if (selectedEcommerceFilterType === "Weekly") {
        dispatch(updateMultiBarChartData(getMultibarchartDataByWeek(data)));
      } else if (selectedEcommerceFilterType === "Monthly") {
        dispatch(updateMultiBarChartData(getMultibarchartDataByMonth(data)));
      } else if (selectedEcommerceFilterType === "Yearly") {
        dispatch(updateMultiBarChartData(getMultibarchartDataByYear(data)));
      } else {
        dispatch(updateMultiBarChartData(getMultibarchartData(data)));
      }
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.bestSellingProducts")]) {
      dispatch(updateBestSellingProducts(getBestSellingProducts(data)));
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.revenuePerCategory")]) {
      dispatch(updateRevenuePerCategory(getRevenuePerCategory(data)));
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.orderStatus")]) {
      dispatch(updateOrderStatus(orderStatusCounts(data)));
    }

    if (!selectedEcommerceFilters?.[t("ecommerce.topVariants")]) {
      dispatch(updateTopVriants(getTopVariantsData(data, "variant_name")));
    }
    if (
      !selectedEcommerceFilters?.[t("ecommerce.uniqueVsReturningCustomers")]
    ) {
      const result = getUniqueVsReturningCustomers(data);
      dispatch(updateNewAndRerutnCustomers(result?.newAndReturningCustomers));
      dispatch(
        updateUniqueVsReturningCustomers({
          labels: result?.labels,
          dataset: result?.dataset
        })
      );
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.spendingIntensity")]) {
      const spendingIntensityResult = getSpendingIntensityData(data);
      dispatch(
        updateSpendingIntensityRanges(
          spendingIntensityResult?.rangesByCustomers
        )
      );
      dispatch(
        updateSpendingIntensity({
          labels: spendingIntensityResult?.labels,
          dataset: spendingIntensityResult?.dataset
        })
      );
    }

    if (!selectedEcommerceFilters?.[t("ecommerce.customersLocations")]) {
      dispatch(
        updateWorldData(groupByCountryAndCount(data, "customer_country"))
      );
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.averageTimeBetweenOrders")]) {
      const timeBWOrders = calculateAverageTimeBWOrders(data);
      dispatch(
        updateAverageTimeBetweenOrdersCustomers(timeBWOrders?.customerIdsByBin)
      );
      dispatch(
        updateAverageTimeBetweenOrders({
          labels: timeBWOrders?.labels,
          dataset: timeBWOrders?.dataset
        })
      );
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.repeatPurchaseRate")]) {
      const repeatedCustomers = getRepeatPurchaseRateData(data);
      dispatch(
        updateRepeatPurchaseCustomers(repeatedCustomers.repeatPurchaseCustomers)
      );
      dispatch(
        updateRepeatPurchaseRate({
          labels: repeatedCustomers?.labels,
          dataset: repeatedCustomers?.dataset
        })
      );
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.discountsCodeUsage")]) {
      dispatch(updateDiscountsCodeUsage(getDiscountCodeCounts(data)));
    }

    if (!selectedEcommerceFilters?.[t("ecommerce.customerAcquisition")]) {
      dispatch(updateCustomerAcquisition(getCustomerAcquisition(data)));
    }
    if (!selectedEcommerceFilters?.[t("ecommerce.customerPreferences")]) {
      dispatch(updateCustomerPreferences(getCustomerPreferences(data)));
    }
  };

  useEffect(() => {
    dispatch(
      getEcommerceData({
        website_id: websiteID,
        dateRange: [
          { startDate: dateRange.startDate, endDate: dateRange.endDate }
        ]
      })
    );
  }, [dateRange, compareDateRange, isCompare, language, websiteID]);

  useEffect(() => {
    if (Object.keys(selectedEcommerceFilters).length > 0) {
      separateData(filteredData);
    } else {
      separateData(modifyData(allData));
    }
  }, [allData, filteredData, language]);

  const filterData = (data) => {
    if (selectedEcommerceFilters[t("ecommerce.bestSellingProducts")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.bestSellingProducts")].includes(
          ele.product_name
        )
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.revenuePerCategory")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.revenuePerCategory")].includes(
          ele.product_type
        )
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.orderStatus")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.orderStatus")].includes(
          ele.financial_status
        )
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.topVariants")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.topVariants")].includes(
          ele.variant_name
        )
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.uniqueVsReturningCustomers")]) {
      if (
        selectedEcommerceFilters[
          t("ecommerce.uniqueVsReturningCustomers")
        ].includes("New")
      ) {
        return data.filter((ele) =>
          newAndRerutnCustomers?.newCustomers?.includes(ele.customer_id)
        );
      }
      return data.filter((ele) =>
        newAndRerutnCustomers?.returningCustomers?.includes(ele.customer_id)
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.spendingIntensity")]) {
      if (
        selectedEcommerceFilters[t("ecommerce.spendingIntensity")].includes(
          "Above 500"
        )
      ) {
        return data.filter((ele) =>
          spendingIntensityRanges["Above 500"]?.includes(ele.customer_id)
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.spendingIntensity")].includes(
          "401-500"
        )
      ) {
        return data.filter((ele) =>
          spendingIntensityRanges["401-500"]?.includes(ele.customer_id)
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.spendingIntensity")].includes(
          "301-400"
        )
      ) {
        return data.filter((ele) =>
          spendingIntensityRanges["301-400"]?.includes(ele.customer_id)
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.spendingIntensity")].includes(
          "201-300"
        )
      ) {
        return data.filter((ele) =>
          spendingIntensityRanges["201-300"]?.includes(ele.customer_id)
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.spendingIntensity")].includes(
          "100-200"
        )
      ) {
        return data.filter((ele) =>
          spendingIntensityRanges["100-200"]?.includes(ele.customer_id)
        );
      }
      return data.filter((ele) =>
        spendingIntensityRanges["Below 100"]?.includes(ele.customer_id)
      );
    }

    if (selectedEcommerceFilters[t("analyticsPage.countryTitle")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters?.Country?.includes(ele.customer_country)
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.averageTimeBetweenOrders")]) {
      return data.filter((ele) =>
        averageTimeBetweenOrdersCustomers[
          selectedEcommerceFilters[t("ecommerce.averageTimeBetweenOrders")]
        ].includes(ele.customer_id)
      );
    }
    if (selectedEcommerceFilters[t("ecommerce.repeatPurchaseRate")]) {
      if (
        selectedEcommerceFilters[t("ecommerce.repeatPurchaseRate")].includes(
          "Repeat purchase 1"
        )
      ) {
        return data.filter((ele) =>
          repeatPurchaseCustomers["Repeat purchase 1"]?.includes(
            ele.customer_id
          )
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.repeatPurchaseRate")].includes(
          "Repeat purchase 2"
        )
      ) {
        return data.filter((ele) =>
          repeatPurchaseCustomers["Repeat purchase 2"]?.includes(
            ele.customer_id
          )
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.repeatPurchaseRate")].includes(
          "Repeat purchase 3"
        )
      ) {
        return data.filter((ele) =>
          repeatPurchaseCustomers["Repeat purchase 3"]?.includes(
            ele.customer_id
          )
        );
      }
      if (
        selectedEcommerceFilters[t("ecommerce.repeatPurchaseRate")].includes(
          "Repeat purchase 4"
        )
      ) {
        return data.filter((ele) =>
          repeatPurchaseCustomers["Repeat purchase 4"]?.includes(
            ele.customer_id
          )
        );
      }
      return data.filter((ele) =>
        repeatPurchaseCustomers["Repeat purchase 5+"].includes(ele.customer_id)
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.discountsCodeUsage")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.discountsCodeUsage")].includes(
          ele.discount_codes
        )
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.customerAcquisition")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.customerAcquisition")].includes(
          ele.customer_aquasation
        )
      );
    }

    if (selectedEcommerceFilters[t("ecommerce.customerPreferences")]) {
      return data.filter((ele) =>
        selectedEcommerceFilters[t("ecommerce.customerPreferences")].includes(
          ele.product_type
        )
      );
    }

    if (selectedEcommerceFilterType === "Weekly") {
      return data?.filter((ele) =>
        selectedEcommerceFilters?.[t("common.date")]?.includes(ele.weekName)
      );
    }
    if (selectedEcommerceFilterType === "Monthly") {
      return data?.filter((ele) =>
        selectedEcommerceFilters?.[t("common.date")]?.includes(ele.monthName)
      );
    }
    if (selectedEcommerceFilterType === "Yearly") {
      return data?.filter((ele) =>
        selectedEcommerceFilters?.[t("common.date")]?.includes(
          ele.year.toString()
        )
      );
    }
    if (selectedEcommerceFilterType === "Daily") {
      return data?.filter((ele) =>
        selectedEcommerceFilters?.[t("common.date")]?.includes(ele.dateName)
      );
    }
    return [];
  };

  useEffect(() => {
    dispatch(updateFilteredData(filterData(modifyData(allData))));
  }, [
    selectedEcommerceFilters,
    selectedEcommerceFilterType,
    websiteID,
    isCompare,
    dateRange,
    language
  ]);

  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          <div className="flex-between filter-header">
            <FilterPill
              filterValues={selectedEcommerceFilters}
              dashboard={dashboard}
            />
            <DateCalendar />
          </div>
          {isLoading && <Loading />}
          {!isLoading && (
            <GeneralDashboard
              insightsTitle={insightsTitle}
              dashboard={dashboard}
              isCompare={isCompare}
              selectedEcommerceFilters={selectedEcommerceFilters}
              updateSelectedFilter={updateSelectedEcommerceFilters}
            />
          )}
        </div>
      </div>
    </div>
  );
}
