import React from "react";
import { CircularProgress, Box } from "@mui/material";
import "./loader.scss";

export default function Loader() {
  return (
    <Box
      sx={{
        width: "3rem",
        height: "3rem",
        margin: "25% auto"
      }}
    >
      <CircularProgress />
    </Box>
  );
}
