import axios from "../../api/axios";

export const getAllImages = (websiteId) =>
  axios.get(`wiget/getUploadImage/${websiteId}`);

export const uploadImages = (payload) =>
  axios.post("wiget/uploadImage", payload);

export const deleteImages = (payload) =>
  axios.post("wiget/deleteUploadImage", payload);
