/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";
import LanguageDropdown from "../../../components/layouts/LanguageDropdown";
import HomeLogo from "./assets/icons/home.svg";
import NotificationLogo from "./assets/icons/notification.svg";
import LogoutLogo from "./assets/icons/logout.svg";
import "./assets/stylesheets/header.scss";

export default function Index() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // window.location.href = "/";
    navigate("/");
  };

  return (
    <header className="header-onboarding">
      <div className="onboarding-container flex-between">
        <div className="logo">
          <img src={HomeLogo} alt="home-logo" />
        </div>
        <nav>
          <ul className="flex nav-element">
            <li>
              <img src={NotificationLogo} alt="notification-logo" />
            </li>
            <li>
              <img
                src={LogoutLogo}
                onClick={handleLogout}
                onKeyDown={handleLogout}
                alt="logout-logo"
              />
            </li>
            <li>
              <LanguageDropdown />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
