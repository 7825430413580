/* eslint-disable */
import React from "react";
import "../stylesheets/uploadImages.scss";

export default function ({ src, onClick, alt }) {
  return (
    <div>
      <img src={src} alt={alt} onClick={onClick} className="image-button" />
    </div>
  );
}
