/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import close from "../../../../../assets/icons/close.png";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import { getAllImages } from "../../../../../pages/apis/uploadImagesAPI";
import "../assets/stylesheets/uploadImagesModel.scss";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import UploadImageComponent from "./UploadImageComponent";
import Loader from "./Loader";

export default function UploadImagesModel({
  setShowUploadImagesModel,
  handleNaviagetToEdit,
  selectedImage,
  setSelectedImage,
  getUploadImagesLoading
}) {
  const { t } = useTranslation();

  const { websiteID } = useSelector((state) => state.generalData);

  const [allImages, setAllImages] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [loader, setLoader] = useState(true);

  const getImages = async () => {
    setLoader(true);
    try {
      const res = await getAllImages(websiteID);
      console.log("res====>", res);
      const allImages =
        res?.data?.imageUrl?.length > 0 ? res?.data?.imageUrl : [];
      setAllImages(allImages);
      setLoader(false);
    } catch (error) {
      setLoader(true);
      console.error("Error fetching images:", error);
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    getImages();
  }, []);

  console.log("selectedImage", selectedImage);

  return (
    <div className="upload-images-model">
      <div className="flex-between">
        <div>
          <p className="upload-images-text">{t("uploadImages.uploadImages")}</p>
        </div>

        <div
          className="close-img-div"
          onClick={() => {
            setShowUploadImagesModel(false);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setShowUploadImagesModel(false);
            }
          }}
        >
          <img src={close} className="close-img" />
        </div>
      </div>

      <div>
        <p className="select-image">{t("uploadImages.selectImage")}</p>
      </div>

      <div>
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   paddingTop: "15%",
              paddingBottom: "15%"
            }}
          >
            <TailSpin
              height="50"
              width="50"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible
            />
          </div>
        ) : (
          <UploadImageComponent
            allImages={allImages}
            photos={photos}
            setPhotos={setPhotos}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            hoveredIndex={hoveredIndex}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        )}
      </div>

      {getUploadImagesLoading ? (
        <div
          style={{
            paddingTop: "5%",
            paddingBottom: "5%"
          }}
        >
          <Loader text={t("manageCampaign.generatingImages")} />
        </div>
      ) : (
        <div className="flex-center cancel-proceed-buttons">
          <div className="cancel-right">
            <ButtonLight
              buttonText={t("common.confirmation.cancel")}
              onClick={() => {
                setShowUploadImagesModel(false);
              }}
            />
          </div>
          <ButtonDark
            disabled={selectedImage.length === 0}
            buttonText={t("common.confirmation.next")}
            onClick={handleNaviagetToEdit}
          />
        </div>
      )}
    </div>
  );
}
