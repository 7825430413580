/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { IconButton, Dialog } from "@mui/material";
import Stack from "@mui/material/Stack";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import GreenIcon from "../../../assets/icons/status (3).svg";
// import YellowIcon from "../../../assets/icons/status (2).svg";
import sort from "../../../assets/icons/sort.svg";
import DeleteButton from "../../../assets/icons/delete.svg";
import EditButton from "../../../assets/icons/edit.svg";
import CloseIcon from "../../../assets/icons/closeModal.svg";
import addIcon from "../../../assets/icons/add.svg";
import DeletePersonagroupIcon from "../../../assets/icons/delete_persona_group.svg";
import { ButtonDark as ButtonDarkwithIcon } from "../../../components/inputElements/buttons/Buttons";
import DataTable from "../../../components/Table/DataTable";
import Input from "../../../components/inputElements/Input/Input";
import LocalStorage from "../../../utils/LocalStorgae";
import "./assets/stylesheets/personaManagement.scss";
import themeColors from "../../../assets/stylesheets/_var.scss";
import {
  ButtonLight,
  ButtonDark
} from "../../../components/inputElements/buttons/MainButton";
import {
  createPersonaGroup,
  deletePersonaGroup,
  getPersonaGroups,
  updatePersonaGroup
} from "./api/personaManagementApi";
import "moment/locale/en-gb";
import "moment/locale/it";
import "moment/locale/de";
import Loader from "../../../components/Loader/Loader";
import Toastr from "../../../components/Toastr/Toastr";

function CustomButton() {
  return (
    <IconButton>
      <img src={sort} alt="sort" />
    </IconButton>
  );
}

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function Index() {
  const { t } = useTranslation();
  const language = LocalStorage.getItem("selectedLanguage");
  const { websiteID, createdBy } = useSelector((state) => state.generalData);
  const [selectMultipleRowData, setSelectMultipleRowData] = useState([]);
  const [selectedPersonaGroup, setSelectedPersonaGroup] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setdescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allPersonaGroups, setAllPersonaGroups] = useState([]);
  const [multidelete, setMultidelete] = useState(false);
  const [validateStartDate, setValidateStartDate] = useState(false);
  const [validateEndDate, setValidateEndDate] = useState(false);
  const [validateDate, setValidateDate] = useState(false);
  const [validateTitle, setValidateTitle] = useState(false);
  moment.locale(language);

  const getAllPersonaGroups = async () => {
    try {
      setLoader(true);
      const resp = await getPersonaGroups(`${websiteID}/${createdBy}`);
      if (resp?.data?.result) {
        setAllPersonaGroups(resp.data.result);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllPersonaGroups();
  }, []);

  const createPersonaGroups = async () => {
    if (startDate === null || endDate === null || groupTitle === "") {
      if (startDate === null) setValidateStartDate(true);
      if (endDate === null) setValidateEndDate(true);
      if (groupTitle === "") setValidateTitle(true);
      return;
    } else if (startDate.isAfter(endDate)) {
      setValidateDate(true);
      return;
    } else {
      setLoading(true);
      try {
        const response = await createPersonaGroup({
          customer_id: createdBy,
          website_id: websiteID,
          title: groupTitle,
          description,
          start_date: moment(startDate).format("DD-MM-YYYY"),
          end_date: moment(endDate).format("DD-MM-YYYY")
        });
        if (response?.data?.success) {
          setLoading(false);
          setOpenModel(false);
          setGroupTitle("");
          setStartDate(null);
          setEndDate(null);
          setdescription("");
          setSelectedPersonaGroup({});
          getAllPersonaGroups();
          Toastr.success(t("personaManagement.createSuccess"));
        } else {
          setLoading(false);
          Toastr.error(response?.data?.message);
        }
      } catch (err) {
        console.log("err", err);
        setLoading(false);
      }
    }
  };

  const deletePersonaGroups = async () => {
    setLoading(true);
    try {
      const response = await deletePersonaGroup({
        customer_id: createdBy,
        website_id: websiteID,
        id: selectMultipleRowData
      });
      if (response?.data?.success) {
        setLoading(false);
        setOpenDeleteModel(false);
        setMultidelete(false);
        setSelectedPersonaGroup({});
        setSelectMultipleRowData([]);
        setGroupTitle("");
        setStartDate(null);
        setEndDate(null);
        setdescription("");
        getAllPersonaGroups();
        Toastr.success(t("personaManagement.deleteSuccess"));
      } else {
        setLoading(false);
        Toastr.error(response?.data?.message);
      }
    } catch (err) {
      console.log("errerrerr", err);
      setLoading(false);
    }
  };

  const updatePersonaGroups = async () => {
    if (startDate === null || endDate === null || groupTitle === "") {
      if (startDate === null) setValidateStartDate(true);
      if (endDate === null) setValidateEndDate(true);
      if (groupTitle === "") setValidateTitle(true);
      return;
    } else if (startDate.isAfter(endDate)) {
      setValidateDate(true);
      return;
    } else {
      setLoading(true);
      try {
        const response = await updatePersonaGroup({
          customer_id: createdBy,
          website_id: websiteID,
          id: selectedPersonaGroup?.id,
          title: groupTitle,
          description: description,
          start_date: moment(startDate).format("DD-MM-YYYY"),
          end_date: moment(endDate).format("DD-MM-YYYY")
        });
        if (response?.data?.success) {
          setLoading(false);
          setOpenModel(false);
          getAllPersonaGroups();
          setGroupTitle("");
          setStartDate(null);
          setEndDate(null);
          setdescription("");
          setSelectMultipleRowData([]);
          Toastr.success(t("personaManagement.updateSuccess"));
        } else {
          setLoading(false);
          Toastr.error(response?.data?.message);
        }
      } catch (err) {
        console.log("errerrerr", err);
        setLoading(false);
      }
    }
  };

  const hangleGroupTitle = (value) => {
    setGroupTitle(value);
    setValidateTitle(false);
  };

  const handleRowSelect = (rowData) => {
    setSelectMultipleRowData(rowData);
  };

  const handleRowClick = (rowData) => {
    setSelectedPersonaGroup(rowData);
    setSelectedRowData(rowData);
  };

  const handleEditModel = () => {
    setOpenModel(true);
  };

  const handleDeleteModel = () => {
    setOpenDeleteModel(true);
    setMultidelete(true);
  };

  const handleStartDate = (newValue) => {
    setStartDate(newValue);
    setValidateStartDate(false);
    setValidateDate(false);
  };

  const handleEndDate = (newValue) => {
    setEndDate(newValue);
    setValidateEndDate(false);
  };

  const handleSave = () => {
    createPersonaGroups();
  };

  const handleCancel = () => {
    setSelectedPersonaGroup({});
    setGroupTitle("");
    setStartDate(null);
    setEndDate(null);
    setdescription("");
    setValidateStartDate(false);
    setValidateEndDate(false);
    setValidateDate(false);
    setOpenModel(false);
    setOpenDeleteModel(false);
    setMultidelete(false);
    setValidateTitle(false);
  };

  useEffect(() => {
    if (Object.keys(selectedPersonaGroup).length) {
      setGroupTitle(selectedPersonaGroup?.title);
      setStartDate(moment(selectedPersonaGroup?.start_date, "DD/MM/YYYY"));
      setEndDate(moment(selectedPersonaGroup?.end_date, "DD/MM/YYYY"));
      setdescription(selectedPersonaGroup?.description);
    }
  }, [selectedPersonaGroup]);

  const columns = [
    {
      field: "title",
      headerName: "Group title",
      flex: 1
    },
    {
      field: "start_date",
      headerName: t("personaManagement.startDate"),
      flex: 1
    },
    {
      field: "end_date",
      headerName: t("personaManagement.endDate"),
      flex: 1
    },
    {
      field: "created_date",
      headerName: t("tagManager.Created Date"),
      flex: 1
    },
    // {
    //   field: "status",
    //   headerName: t("ManageApi.savedApis.dataTable.status"),
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div className="status-icon" style={{ alignItems: "center" }}>
    //       {params.value === 1 ? (
    //         <img src={GreenIcon} alt="running" />
    //       ) : (
    //         <img src={YellowIcon} alt="stopped" />
    //       )}
    //     </div>
    //   )
    // },
    {
      field: "actions",
      headerName: t("ManageApi.savedApis.dataTable.actions"),
      flex: 0,
      renderCell: () => (
        <div className="button-group">
          <button
            className="delete-button"
            type="button"
            onClick={() => setOpenDeleteModel(true)}
          >
            <img src={DeleteButton} alt="delete button" />
          </button>
          <button
            className="edit-button"
            type="button"
            onClick={() => handleEditModel()}
          >
            <img src={EditButton} alt="edit button" />
          </button>
        </div>
      )
    }
  ];

  return (
    <div className="persona-management">
      <div className="header-content flex-between">
        <div className="modules-heading">{t("settings.personaManagement")}</div>
        <div className="flex">
          {selectMultipleRowData.length > 1 && (
            <button
              className={`delete-multiple ${
                isMobile ? "backgroundSizeMobile" : "backgroundSize"
              }`}
              type="button"
              onClick={handleDeleteModel}
            />
          )}
          <ButtonDarkwithIcon
            icon={addIcon}
            buttonText={t("personaManagement.createGroup")}
            onClick={() => {
              setOpenModel(true);
            }}
          />
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="margin-top-10">
          <h4>{t("personaManagement.personaGroups")}</h4>
          <DataTable
            columns={columns}
            rows={allPersonaGroups}
            getRowId={(row) => row.id}
            onRowClick={handleRowClick}
            CustomButton={CustomButton}
            onRowsSelect={handleRowSelect}
            tableType={0}
          />
        </div>
      )}
      <Dialog
        open={openModel}
        onClose={() => {
          handleCancel();
        }}
        maxWidth="md"
        fullWidth
        className="persona-management"
      >
        <div>
          <div className="flex-end">
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={() => handleCancel()}
              className="close-icon"
            />
          </div>
          <div className="grid-gap">
            <div>
              <text className="header-text">
                {Object.keys(selectedPersonaGroup).length
                  ? t("personaManagement.updatePersonaGroups")
                  : t("personaManagement.createPersonaGroups")}
              </text>
            </div>

            <LabelInputSet>
              <label className="labels-text" htmlFor="groupTitle">
                {t("personaManagement.personaGroupTitle")}
              </label>
              <Input
                type="text"
                id="groupTitle"
                value={groupTitle}
                onChange={(e) => hangleGroupTitle(e.target.value)}
              />
              {validateTitle && (
                <span className="error-labels-text">
                  {t("personaManagement.pleaseEnterTitle")}
                </span>
              )}
            </LabelInputSet>
            <div className="half-column-grid">
              <div>
                <LabelInputSet>
                  <label
                    htmlFor="from-date"
                    className="labels-text"
                    style={{
                      color: themeColors.secondaryText
                    }}
                  >
                    {t("personaManagement.startDate")}
                  </label>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={language === "en" ? "en-gb" : language}
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        onChange={handleStartDate}
                        renderInput={(params) => (
                          <TextField
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...params}
                          />
                        )}
                        slotProps={{
                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: themeColors.primaryColor
                              },
                              "&.MuiPickersDay-root": {
                                "&:hover": {
                                  backgroundColor: themeColors.primaryColor4
                                }
                              }
                            }
                          }
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                  {validateStartDate && (
                    <span className="error-labels-text">
                      {t("dataExport.pleaseSelectStartDate")}
                    </span>
                  )}
                  {validateDate && (
                    <span className="error-labels-text">
                      {t("personaManagement.dateValidation")}
                    </span>
                  )}
                </LabelInputSet>
              </div>
              <div>
                <LabelInputSet>
                  <label
                    htmlFor="to-date"
                    className="labels-text"
                    style={{
                      color: themeColors.secondaryText
                    }}
                  >
                    {t("personaManagement.endDate")}
                  </label>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={language === "en" ? "en-gb" : language}
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        onChange={handleEndDate}
                        renderInput={(params) => (
                          <TextField
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...params}
                          />
                        )}
                        slotProps={{
                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: themeColors.primaryColor
                              },
                              "&.MuiPickersDay-root": {
                                "&:hover": {
                                  backgroundColor: themeColors.primaryColor4
                                }
                              }
                            }
                          }
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                  {validateEndDate && (
                    <span className="error-labels-text">
                      {t("dataExport.pleaseSelectEndDate")}
                    </span>
                  )}
                </LabelInputSet>
              </div>
            </div>
            <LabelInputSet>
              <label className="labels-text" htmlFor="groupDescription">
                {t("personaManagement.groupDescription")}
              </label>
              <textarea
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                id="groupDescription"
                className="common-input add-tag-input-label"
                placeholder="Enter description"
                rows={4}
              />
            </LabelInputSet>
          </div>
        </div>
        <div className="flex-center gap-20">
          <ButtonLight
            buttonText={t("common.cancelButton")}
            onClick={() => {
              handleCancel();
            }}
          />
          <ButtonDark
            buttonText={
              Object.keys(selectedPersonaGroup).length
                ? t("common.confirmation.update")
                : t("common.saveButton")
            }
            onClick={() =>
              Object.keys(selectedPersonaGroup).length
                ? updatePersonaGroups()
                : handleSave()
            }
            loader={loading}
            disabled={loading}
          />
        </div>
      </Dialog>
      <Dialog
        open={openDeleteModel}
        onClose={() => {
          setOpenDeleteModel(false);
        }}
        maxWidth="sm"
        fullWidth
        className="persona-management"
      >
        <div>
          <div className="flex-center">
            <img
              src={DeletePersonagroupIcon}
              alt="delete-persona-icon"
              className="delete-persona-group"
            />
          </div>
          <div className="flex-center">
            {multidelete && selectMultipleRowData?.length > 1 ? (
              <p className="delete-text">
                {t("personaManagement.multiDeleteConfirmText")}
              </p>
            ) : (
              <p className="delete-text">
                {t("personaManagement.deleteConfirmText")}
              </p>
            )}
          </div>
          {!multidelete && (
            <div className="flex-center">
              <p className="delete-persona-text">
                {selectedPersonaGroup?.title}
              </p>
            </div>
          )}
        </div>
        <div className="flex-center gap-20">
          <ButtonLight
            buttonText={t("common.cancelButton")}
            onClick={() => {
              handleCancel();
            }}
          />
          <ButtonDark
            buttonText={t("ManageApi.deleteApi.yes")}
            onClick={() => deletePersonaGroups()}
            loader={loading}
            disabled={loading}
          />
        </div>
      </Dialog>
    </div>
  );
}

LabelInputSet.propTypes = {
  children: PropTypes.element
};
