import PropTypes from "prop-types";
import "../assets/stylesheets/popup-window.scss";

export default function PopUpWindow({ className, children }) {
  return <section className={`popup-modal ${className}`}>{children}</section>;
}

PopUpWindow.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
};
