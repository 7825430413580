/* eslint-disable */
import React from "react";
import ReactJsxParser from "react-jsx-parser";

export default function WebAdvertisement({
  webImages,
  setOpenedImage,
  setSelectedImage,
  setSelectedName,
  setSelectedDimension,
  selectedCampaign,
  navigationFrom,
  orginalTemplate,
  orginalTemplateCreate,
  templateRefs
}) {
  console.log(
    "orginalTemplateCreate",
    orginalTemplateCreate,
    "orginalTemplate",
    orginalTemplate,
    "navigationFrom",
    navigationFrom,
    "webImages",
    webImages
  );

  // let firstTemplate = webImages?.website[0]?.template;
  // console.log("firstTemplate", firstTemplate);
  return (
    <div className="flex-justify-pointer">
      <div className="flex">
        <div
          style={{
            paddingBottom: "10%"
          }}
          onClick={() => {
            setOpenedImage(true);
            if (navigationFrom == "createFlow") {
              setSelectedImage(orginalTemplateCreate?.website[0]?.template);
              setSelectedName(orginalTemplateCreate?.website[0]?.name);
              setSelectedDimension(
                orginalTemplateCreate?.website[0]?.dimensions
              );
            } else {
              setSelectedImage(orginalTemplate[0]);
              setSelectedName(selectedCampaign?.advertisment_image[0]?.name);
              setSelectedDimension(
                selectedCampaign?.advertisment_image[0]?.dimensions
              );
            }
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setOpenedImage(true);
              if (navigationFrom == "createFlow") {
                setSelectedImage(orginalTemplateCreate?.website[0]?.template);
                setSelectedName(orginalTemplateCreate?.website[0]?.name);
                setSelectedDimension(
                  orginalTemplateCreate?.website[0]?.dimensions
                );
              } else {
                setSelectedImage(orginalTemplate[0]);
                setSelectedName(selectedCampaign?.advertisment_image[0]?.name);
                setSelectedDimension(
                  selectedCampaign?.advertisment_image[0]?.dimensions
                );
              }
            }
          }}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={webImages?.website?.[0]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[0] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={webImages?.[0]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>

        {/* <div
          style={{
            paddingBottom: "10%"
          }}
          onClick={() => {
            setOpenedImage(true);
            if (navigationFrom == "createFlow") {
              setSelectedImage(orginalTemplateCreate?.website[1]?.template);
              setSelectedName(orginalTemplateCreate?.website[1]?.name);
              setSelectedDimension(
                orginalTemplateCreate?.website[1]?.dimensions
              );
            } else {
              setSelectedImage(orginalTemplate[1]);
              setSelectedName(selectedCampaign?.advertisment_image[1]?.name);
              setSelectedDimension(
                selectedCampaign?.advertisment_image[1]?.dimensions
              );
            }
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setOpenedImage(true);
              if (navigationFrom == "createFlow") {
                setSelectedImage(orginalTemplateCreate?.website[1]?.template);
                setSelectedName(orginalTemplateCreate?.website[1]?.name);
                setSelectedDimension(
                  orginalTemplateCreate?.website[1]?.dimensions
                );
              } else {
                setSelectedImage(orginalTemplate[1]);
                setSelectedName(selectedCampaign?.advertisment_image[1]?.name);
                setSelectedDimension(
                  selectedCampaign?.advertisment_image[1]?.dimensions
                );
              }
            }
          }}
        >
          <ReactJsxParser
            components={{}}
            jsx={webImages?.website?.[1]?.template}
          />
        </div> */}
      </div>

      {/* <div
        style={{
          paddingLeft: "30%"
        }}
        onClick={() => {
          setOpenedImage(true);
          if (navigationFrom == "createFlow") {
            setSelectedImage(orginalTemplateCreate?.website[2]?.template);
            setSelectedName(orginalTemplateCreate?.website[2]?.name);
            setSelectedDimension(orginalTemplateCreate?.website[2]?.dimensions);
          } else {
            setSelectedImage(orginalTemplate[2]);
            setSelectedName(selectedCampaign?.advertisment_image[2]?.name);
            setSelectedDimension(
              selectedCampaign?.advertisment_image[2]?.dimensions
            );
          }
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          // Handling keyboard events
          if (e.key === "Enter" || e.key === " ") {
            setOpenedImage(true);
            if (navigationFrom == "createFlow") {
              setSelectedImage(orginalTemplateCreate?.website[2]?.template);
              setSelectedName(orginalTemplateCreate?.website[2]?.name);
              setSelectedDimension(
                orginalTemplateCreate?.website[2]?.dimensions
              );
            } else {
              setSelectedImage(orginalTemplate[2]);
              setSelectedName(selectedCampaign?.advertisment_image[2]?.name);
              setSelectedDimension(
                selectedCampaign?.advertisment_image[2]?.dimensions
              );
            }
          }
        }}
      >
        <ReactJsxParser
          components={{}}
          jsx={webImages?.website?.[2]?.template}
        />
      </div> */}
    </div>
  );
}
