import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./footer.scss";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div style={isMobile ? { paddingTop: "300px" } : { paddingTop: "100px" }}>
      <footer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px"
          }}
        >
          <div>
            <small>{t("footer.copyright")}</small>
            <text
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                color: themeColors.secondaryText
              }}
            >
              |
            </text>
          </div>
          <div className="footer-width">
            <nav>
              <ul className="flex-between">
                {t("footer.navigation", { returnObjects: true }).map(
                  (item, index) => (
                    <React.Fragment key={index.id}>
                      <li>
                        <a
                          href={
                            item.label === "Privacy"
                              ? "https://www.yodalytics.com/service/datenschutzbestimmungen"
                              : "https://www.yodalytics.com/service/nutzungsbedingungen"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            cursor: "pointer",
                            color: themeColors.primaryColorShade2,
                            textDecoration: "none",
                            marginRight: "10px"
                          }}
                        >
                          {t(item.label)}
                        </a>
                      </li>
                      {item.label === "Terms" && (
                        <li
                          style={{
                            paddingRight: "10px"
                          }}
                        >
                          |
                        </li>
                      )}
                      {item.label === "Termini" && (
                        <li
                          style={{
                            paddingRight: "10px"
                          }}
                        >
                          |
                        </li>
                      )}
                      {item.label === "AGB" && (
                        <li
                          style={{
                            paddingRight: "10px"
                          }}
                        >
                          |
                        </li>
                      )}
                    </React.Fragment>
                  )
                )}
              </ul>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  );
}
