/* eslint-disable */
import React from "react";

export default function PreviewedImage({ uploadedImages, preViewedImage }) {
  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          marginTop: "2%",
          paddingTop: "4%",
          paddingBottom: "4%",
          paddingLeft: "4%",
          backgroundColor: "rgba(249, 249, 249, 1)"
        }}
        className="background-box"
      >
        <div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center"
            }}
            className="flex center"
          >
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "200px",
                height: "200px",
                objectFit: "contain"
              }}
              src={uploadedImages[preViewedImage]?.url}
              alt={uploadedImages[preViewedImage]?.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
