/* eslint-disable no-console */
import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Toastr } from "../../../components/Toastr/Toastr";
import ResetPasswordForm from "./component/ResetPasswordForm";
import resetPassword from "./api/resetPassword";

export default function Index() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const otp = searchParams.get("otp");

  const passwordValidation = () => {
    if (!password) {
      return "*Password is required";
    }
    if (password.length < 8) {
      return "*Password must be at least 8 characters long.";
    }
    if (!/\d/.test(password)) {
      return "*Password must contain at least one digit.";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return "*Password must contain at least one special character (!@#$%^&*).";
    }
    if (!/[A-Z]/.test(password)) {
      return "*Password must contain at least one uppercase letter.";
    }
    return "";
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const passwordValidationError = passwordValidation();

    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    try {
      setLoading(true);

      if (password !== confirmPassword) {
        setLoading(false);
        setConfirmPasswordError("*Password and Confirm Password don't match");
        return;
      }

      const response = await resetPassword({ token: otp, password });
      if (response.data.status === "ok") {
        setLoading(false);
        Toastr.success("Password reset successful");
        navigate("/", { replace: false });
      } else {
        setLoading(false);
        Toastr.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ResetPasswordForm
      passwordChangeHandler={passwordChangeHandler}
      confirmPasswordChangeHandler={confirmPasswordChangeHandler}
      loading={loading}
      handleSubmit={handleSubmit}
      passwordError={passwordError}
      confirmPasswordError={confirmPasswordError}
    />
  );
}
