/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function CountriesBarGraph({ countriesData, commonProps, t }) {
  return (
    <AddBarGraph
      labels={countriesData.labels}
      title={t("personaDashboard.country")}
      numBars={
        countriesData?.labels?.length < 6 ? countriesData?.labels?.length : 6
      }
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: countriesData?.visitors
      }}
      columnData={[
        {
          name: t("analyticsPage.visitors"),
          diff: countriesData?.visitors,
          number: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

CountriesBarGraph.propTypes = {
  countriesData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    visitors: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired
};
