/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import deleteButton from "../../../../assets/icons/buttons.svg";
import closeButton from "../../../../assets/icons/Close.svg";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/addTagManager.scss";
import Input from "../../../../components/inputElements/Input/Input";
import info from "../assets/icons/info.svg";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";
// import axios from "../../api/axios";
import {
  addCustomTag,
  addWebsiteEventTag,
  deleteCustomTag,
  deleteWebsiteEventTag,
  getCustomTagsAPI,
  updateWebsiteEventTag
} from "../api/tagManagerApi";
import { Toastr } from "../../../../components/Toastr/Toastr";
import DeleteComponent from "../../../../components/DeletePopUp/DeleteComponent";
import { Button, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import DropdownComponent from "../../../../components/inputElements/selectInput/DropdownComponent";

export default function AddTagManager({
  fetchData,
  handleShowAddTag,
  initialData,
  rowClicked,
  options,
  showDeletePopup,
  setShowDeletePopup,
  showPricePopUp,
  setShowPricePopUp,
  inputValue,
  setInputValue,
  websiteEventRelationId,
  setWebsiteEventRelationId,
  isEditing,
  setIsEditing,
  selectedTag,
  setSelectedTag,
  selectedId,
  setSelectedId,
  addCustom,
  setAddCustom,
  customTag,
  setCustomTag,
  customTagList,
  setCustomTagList,
  priceSelector,
  locationSelector,
  showSelector
}) {
  const { t } = useTranslation();

  const { websiteID } = useSelector((state) => state.generalData);
  const websiteId = websiteID;

  const [loading, setLoading] = useState(false);
  const [disableIframe, setDisableIframe] = useState(false);
  const [tagType, setTagType] = useState("");
  const [triggerType, setTriggerType] = useState("");
  const selectOptions = [
    { value: "click", label: "Click" },
    { value: "hover", label: "Hover" }
  ];

  const selectTagTypeOptions = [
    { value: "presalesevent", label: t("analyticsPage.preSalesEvent") },
    { value: "salesevent", label: t("analyticsPage.salesEvent") }
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues // Add getValues from useForm to access form values
  } = useForm({
    defaultValues: {
      tagTitle: initialData.event_title ?? "",
      triggerType: initialData.keyword_type ?? "cssSelector",
      triggerTypeValue: initialData.keyword_tag ?? "",
      trigger: initialData.input_event_name ?? "",
      tagType: initialData.tag_type ?? "",
      locationType: initialData.price_location_type ?? "cssSelector",
      locationTypeValue: initialData.price_location_tag ?? ""
    }
  });

  console.log("getValues====>", getValues()); // This will log all defaultValues

  useEffect(() => {
    if (locationSelector) {
      setValue("triggerTypeValue", locationSelector);
      console.log("executed");
    }
  }, [locationSelector]);

  useEffect(() => {
    if (priceSelector) {
      setValue("locationTypeValue", priceSelector);
    }
  }, [priceSelector]);

  function handleInputChange(event) {
    const { value } = event.target;
    setInputValue(value);
  }

  const handleInputChanges = (event) => {
    const { value, name } = event.target;
    console.log("value set for", name, value);
    setTagType(event.target.value);
    setValue(name, value);
  };

  const handleInputChangesNew = (event) => {
    const { value, name } = event.target;
    setTriggerType(event.target.value);
    setValue(name, value);
  };

  function handleSelectChange(event) {
    const { value } = event.target;
    // const selectedOption = event.target.options[event.target.selectedIndex];
    // setSelectedId(Number(selectedOption.id));
    if (value === t("tagManager.addCustomLabel")) {
      setAddCustom(true);
      setSelectedTag(t("tagManager.addCustomLabel"));
    } else {
      setAddCustom(false);
      customTagList.map((ele) => {
        if (ele.sales_event === value) {
          setCustomTag(false);
        }
        return ele;
      });
      setSelectedTag(value);
    }
  }

  console.log("selectedId====>", selectedId);

  function deleteCustomTags() {
    setShowDeletePopup(true);
  }

  function handleDeleteTag() {
    setShowDeletePopup(true);
  }

  function handleDeleteOption() {
    setSelectedTag("");
    setInputValue("");
    setAddCustom(false);
  }

  const apiCall = async (data) => {
    if (!rowClicked) {
      try {
        const res = await addWebsiteEventTag({
          eventTitle: data.tagTitle,
          inputEventName: data.trigger,
          tagType: data.tagType,
          salesEventTag: selectedTag,
          websiteId: websiteId,
          sales_event_id: selectedId,
          keywordType: data.triggerType,
          keywordTag: data.triggerTypeValue,
          priceTag: showPricePopUp ? data.locationTypeValue : "",
          priceType: data.locationType
        });
        if (res.data.response.success === true) {
          handleShowAddTag();
          fetchData();
          Toastr.success(t("tagManager.successMessages.addedTag"));
        }
      } catch (error) {}
    } else {
      try {
        const res = await updateWebsiteEventTag({
          eventTitle: data.tagTitle,
          tagType: data.tagType,
          inputEventName: data.trigger,
          salesEventTag: selectedTag,
          websiteId: websiteId,
          sales_event_id: selectedId,
          website_event_relation_id: websiteEventRelationId,
          keywordType: data.triggerType,
          keywordTag: data.triggerTypeValue,
          priceTag: showPricePopUp ? data.locationTypeValue : "",
          priceType: data.locationType
        });
        if (res.data.success === true) {
          handleShowAddTag();
          fetchData();
          Toastr.success(t("tagManager.successMessages.updatedTag"));
        }
      } catch (error) {}

      reset();
    }
  };

  const confirmDelete = async () => {
    try {
      const res = await deleteWebsiteEventTag({
        data: [
          {
            website_event_relation_id: websiteEventRelationId
          }
        ]
      });
      if (res.data.success === true) {
        handleShowAddTag();
        fetchData();
        Toastr.success(t("tagManager.successMessages.deletedTag"));
      }
    } catch (error) {}
  };

  const confirmDeleteCustomTag = async () => {
    setLoading(true);
    const deleteTag = customTagList.filter(
      (ele) => ele.sales_event_id === selectedId
    );
    const { sales_event_id: tagId, website_id: webID } = deleteTag[0];
    try {
      await deleteCustomTag(tagId, webID);
      Toastr.success(t("tagManager.successMessages.deletedCustomTag"));
      setCustomTag(false);
      setSelectedTag("");
      setCustomTagList(
        customTagList.filter((ele) => ele.sales_event_id !== selectedId)
      );
      setLoading(false);
    } catch (error) {}
  };

  const getCustomTags = async () => {
    try {
      const res = await getCustomTagsAPI(websiteId);
      setCustomTagList(res.data.result);
    } catch (error) {}
  };

  const handleAddOption = async () => {
    setLoading(true);
    try {
      if (inputValue.trim() && !options.includes(inputValue)) {
        const res = await addCustomTag({
          sales_custom_tag: inputValue,
          website_id: websiteId
        });
        Toastr.success(t("tagManager.successMessages.addedCustomTag"));
        setCustomTagList((prevOptions) => [res.data.result, ...prevOptions]);
        setInputValue(""); // optionally clear the input
        setAddCustom(false);
        setSelectedTag(res.data.result.sales_event);
        setSelectedId(res.data.result.sales_event_id);
        setLoading(false);
        setValue("tag", res.data.result.sales_event);
        // await getCustomTags();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCustomTags();
    setShowPricePopUp(!!initialData.price_location_tag);
    setValue("tag", initialData.sales_event);
    if (initialData) {
      setSelectedTag(initialData.sales_event);
      setWebsiteEventRelationId(initialData.website_event_relation_id);
    }
    setTagType(initialData?.tag_type);
    setTriggerType(initialData?.input_event_name);
  }, [initialData]);

  console.log("rowClicked====>", rowClicked);

  return (
    <div className="add-tag-form-box">
      <div className="flex-between">
        <div>
          <div className="add-tag-title">
            {rowClicked ? t("tagManager.editTag") : t("tagManager.addTag")}
          </div>
        </div>
        <div className="flex">
          <button
            className="deleteButton"
            type="button"
            onClick={handleDeleteTag}
          >
            <img src={deleteButton} alt="delete" />
          </button>
          {showDeletePopup && (
            <DeleteComponent
              loading={false}
              onCancel={() => setShowDeletePopup(false)}
              onConfirmDelete={() => {
                setShowDeletePopup(false);
                confirmDelete();
              }}
            />
          )}
          <button
            className="close-button"
            onClick={handleShowAddTag}
            type="button"
          >
            <img src={closeButton} alt="close-button" />
          </button>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(
          (data) => apiCall(data),
          () => {}
        )}
      >
        <div className="grid add-tag-form-grid">
          <div className="flex-column">
            <label htmlFor="tag-title" className="label-tag">
              {t("tagManager.addTagForm.tagTitleLabel")}
            </label>
            <Input
              id="tag-title"
              type="text"
              name="tagTitle"
              register={register("tagTitle", {
                required: true
              })}
              error={
                errors.tagTitle && t("tagManager.addTagForm.requiredFieldError")
              }
            />
          </div>
          <div className="flex-column">
            <div className="tooltip flex">
              <label htmlFor="tag-type" className="label-tag">
                {t("tagManager.addTagForm.tagTypeLabel")}
              </label>
              <Tooltip
                title={
                  <p className="tool-tip">
                    {t("tagManager.tagManagerToolTips.tagTypeTool")}
                  </p>
                }
                placement="right"
              >
                <IconButton>
                  <img src={info} alt="info-icon" />
                </IconButton>
              </Tooltip>
            </div>
            {/* <SelectInput
              id="tag-type"
              name="tagType"
              register={register("tagType", {
                onChange: handleInputChanges,
                required: true
              })}
              options={selectTagTypeOptions}
              error={
                errors.tagType && t("tagManager.addTagForm.selectTagTypeError")
              }
            /> */}
            <DropdownComponent
              id="tag-type"
              value={
                tagType === undefined
                  ? t("tagManager.addTagForm.selectPlaceholder")
                  : tagType
              }
              name="tagType"
              register={register}
              options={selectTagTypeOptions}
              error={
                errors.tagType && t("tagManager.addTagForm.selectTagTypeError")
              }
              onChange={handleInputChanges}
              defaultValue={t("tagManager.addTagForm.selectPlaceholder")}
              isRequired={true}
            />
          </div>
          <div className="flex-column">
            <div className="tooltip flex">
              <label className="label-tag" htmlFor="demo-simple-select-label">
                {t("tagManager.addTagForm.tagsLabel")}
              </label>
              <Tooltip
                title={
                  <p className="tool-tip">
                    {t("tagManager.tagManagerToolTips.tagsTool")}{" "}
                  </p>
                }
                placement="right"
              >
                <IconButton>
                  {/* <Button>Arrow</Button> */}
                  <img src={info} alt="info-icon" />
                </IconButton>
              </Tooltip>
            </div>

            {/* Custom tag section */}
            {addCustom && !customTag && (
              <div className="common-select flex-start ">
                <div className="customTag">
                  <input
                    className="input-select"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                  />

                  <button
                    className="selectButton"
                    type="button"
                    onClick={handleAddOption}
                    disabled={loading}
                  />

                  <button
                    className="closeButton"
                    type="button"
                    onClick={handleDeleteOption}
                    disabled={loading}
                  />
                </div>
              </div>
            )}
            {/* Custom tag section ends */}

            {!addCustom && customTag && (
              <div className="common-select flex-start">
                {selectedTag}
                <button
                  type="button"
                  className="selectButton"
                  onClick={() => setCustomTag(false)}
                  disabled={loading}
                />

                <button
                  type="button"
                  className="closeButton"
                  onClick={deleteCustomTags}
                  disabled={loading}
                />

                {showDeletePopup && (
                  <DeleteComponent
                    loading={false}
                    onCancel={() => setShowDeletePopup(false)}
                    onConfirmDelete={() => {
                      setShowDeletePopup(false);
                      confirmDeleteCustomTag();
                    }}
                  />
                )}
              </div>
            )}

            {!addCustom && !customTag && (
              <FormControl
                variant="outlined"
                style={{
                  minWidth: "100%"
                }}
              >
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="common-select add-tag-input-label"
                  name="tag"
                  value={
                    selectedTag === undefined
                      ? t("tagManager.addTagForm.selectPlaceholder")
                      : selectedTag
                  }
                  onChange={handleSelectChange}
                  style={{
                    fontFamily: themeColors.fontQuickSand,
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "0.875rem"
                  }}
                  sx={{
                    height: "2.9rem",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: themeColors.tertiaryText
                      },
                      "& fieldset": {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: themeColors.tertiaryText
                      }
                    },
                    "& .MuiSelect-select": {
                      padding: "0px",
                      height: "auto"
                    }
                  }}
                  defaultValue={getValues("trigger")}
                >
                  <MenuItem
                    style={{
                      fontFamily: themeColors.fontQuickSand,
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "0.875rem"
                    }}
                    key={t("tagManager.addTagForm.selectPlaceholder")}
                    value={t("tagManager.addTagForm.selectPlaceholder")}
                    disabled
                  >
                    {`--${t("tagManager.addTagForm.selectPlaceholder")}--`}
                  </MenuItem>

                  {customTagList?.map((filter) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setSelectedId(filter?.sales_event_id);
                        }}
                        key={filter.sales_event_id}
                        id={filter.sales_event_id}
                        value={filter.sales_event}
                        style={{
                          fontFamily: themeColors.fontQuickSand,
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "0.875rem"
                        }}
                      >
                        {filter.sales_event}
                      </MenuItem>
                    );
                  })}

                  {options.map((option) => (
                    <MenuItem
                      style={{
                        fontFamily: themeColors.fontQuickSand,
                        fontStyle: "normal",
                        fontWeight:
                          option == t("tagManager.addCustomLabel")
                            ? "600"
                            : "500",
                        fontSize: "0.875rem"
                      }}
                      key={option}
                      id={option}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <div className="half-column-grid-trigger-selection">
            <div className="flex-column">
              <div className="tooltip flex">
                <label htmlFor="tag-title" className="label-tag">
                  {t("tagManager.addTagForm.triggersLabel")}
                </label>
                <Tooltip
                  title={
                    <p className="tool-tip">
                      {t("tagManager.tagManagerToolTips.triggersTool")}
                    </p>
                  }
                  placement="right"
                >
                  <IconButton>
                    {/* <Button>Arrow</Button> */}
                    <img src={info} alt="info-icon" />
                  </IconButton>
                </Tooltip>
              </div>

              <DropdownComponent
                id="tag-title"
                value={
                  triggerType === undefined
                    ? t("tagManager.addTagForm.selectPlaceholder")
                    : triggerType
                }
                name="trigger"
                register={register}
                options={selectOptions}
                error={
                  errors.trigger &&
                  t("tagManager.addTagForm.selectTriggerError")
                }
                onChange={handleInputChangesNew}
                defaultValue={t("tagManager.addTagForm.selectPlaceholder")}
                isRequired={true}
              />
            </div>
            <div className="flex-column">
              <div className="tooltip flex">
                <label htmlFor="tag-title" className="label-tag">
                  {t("tagManager.addTagForm.triggerLocation")}
                </label>
                <Tooltip
                  title={
                    <p className="tool-tip">
                      {t("tagManager.tagManagerToolTips.triggersLocationTool")}
                    </p>
                  }
                  placement="right"
                >
                  <IconButton>
                    {/* <Button>Arrow</Button> */}
                    <img src={info} alt="info-icon" />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="flex-column">
                <Input
                  id="html"
                  placeholder={t("tagManager.addTagForm.selectDiv")}
                  onClick={() =>
                    disableIframe ? "" : showSelector("location")
                  }
                  type="text"
                  name="triggerTypeValue"
                  register={register("triggerTypeValue", {
                    required: true
                  })}
                  error={
                    errors.triggerTypeValue &&
                    t("tagManager.addTagForm.selectTriggerTypeValueError")
                  }
                />
              </div>
            </div>
          </div>

          <div className="half-column-grid-trigger-selection">
            <div className="flex">
              <input
                type="checkbox"
                checked={showPricePopUp}
                className="custom-checkbox"
                id="customCheckbox"
                onChange={() => setShowPricePopUp(!showPricePopUp)}
              />
              <div className="tooltip flex">
                <label
                  className="custom-checkbox-label"
                  htmlFor="customCheckbox"
                >
                  {/* <div className="checkmark" /> */}
                  <input
                    type="checkbox"
                    checked={showPricePopUp}
                    // className="custom-checkbox"
                    id="customCheckbox"
                    onChange={() => setShowPricePopUp(!showPricePopUp)}
                  />
                  {t("tagManager.addTagForm.addPriceLocation")}
                </label>
                <Tooltip
                  title={
                    <p className="tool-tip">
                      {t("tagManager.tagManagerToolTips.addPriceTool")}
                    </p>
                  }
                  placement="right"
                >
                  <IconButton>
                    {/* <Button>Arrow</Button> */}
                    <img src={info} alt="info-icon" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                checked={disableIframe}
                className="custom-checkbox"
                id="customCheckboxFrame"
                onChange={() => setDisableIframe(!disableIframe)}
              />
              <div className="tooltip flex">
                <label
                  className="custom-checkbox-label"
                  htmlFor="customCheckboxFrame"
                >
                  <input
                    type="checkbox"
                    checked={disableIframe}
                    // className="custom-checkbox"
                    id="customCheckboxFrame"
                    onChange={() => setDisableIframe(!disableIframe)}
                  />
                  {/* <div className="checkmark" /> */}
                  {t("tagManager.addTagForm.disableIframe")}
                </label>
                <Tooltip
                  title={
                    <p className="tool-tip">
                      {t("tagManager.tagManagerToolTips.disableTool")}
                    </p>
                  }
                  placement="right"
                >
                  <IconButton>
                    {/* <Button>Arrow</Button> */}
                    <img src={info} alt="info-icon" />
                  </IconButton>
                </Tooltip>
              </div>
              {/* <label className="info-text-iframe">Info</label> */}
            </div>
          </div>
          <div className="flex-column">
            <div className="tooltip flex">
              <label htmlFor="tag-title" className="label-tag">
                {t("tagManager.addTagForm.priceTitle")}
              </label>
              <Tooltip
                title={
                  <p className="tool-tip">
                    {t("tagManager.tagManagerToolTips.priceLocationTool")}{" "}
                  </p>
                }
                placement="right"
              >
                <IconButton>
                  {/* <Button>Arrow</Button> */}
                  <img src={info} alt="info-icon" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="flex-column">
              <Input
                id="html"
                placeholder={t("tagManager.addTagForm.priceText")}
                onClick={() => (disableIframe ? "" : showSelector("price"))}
                disabled={!showPricePopUp}
                type="text"
                name="locationTypeValue"
                register={register("locationTypeValue", {
                  required: showPricePopUp
                })}
                error={
                  errors.locationTypeValue &&
                  t("tagManager.addTagForm.selectPriceError")
                }
              />
            </div>
            <div className="info-text-price-location">
              {t("tagManager.addTagForm.priceNote")}
            </div>
          </div>
          <div className="flex-end">
            <ButtonLight
              buttonText={t("common.cancelButton")}
              onClick={handleShowAddTag}
            />
            <ButtonDark
              isSubmit
              buttonText={t("common.saveButton")}
              className="tagManagerButton"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

AddTagManager.propTypes = {
  handleShowAddTag: PropTypes.func,
  fetchData: PropTypes.func,
  initialData: PropTypes.oneOfType([PropTypes.object]),
  rowClicked: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array]),
  showDeletePopup: PropTypes.bool,
  setShowDeletePopup: PropTypes.func,
  showPricePopUp: PropTypes.bool,
  setShowPricePopUp: PropTypes.func,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  websiteEventRelationId: PropTypes.number,
  setWebsiteEventRelationId: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  selectedTag: PropTypes.string,
  setSelectedTag: PropTypes.func,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
  addCustom: PropTypes.bool,
  setAddCustom: PropTypes.func,
  customTag: PropTypes.bool,
  setCustomTag: PropTypes.func,
  customTagList: PropTypes.oneOfType([PropTypes.array]),
  setCustomTagList: PropTypes.func,
  priceSelector: PropTypes.string,
  locationSelector: PropTypes.string,
  showSelector: PropTypes.func
};
