const terms = `1. ANWENDUNGSBEREICH

1.1 "YODALYTICS" sind ein Angebot (im Folgenden „DIENSTE“), das unter der Internet-Domain https://yodalytics.com (im Folgenden: "WEBSEITE") bzw. durch die Nutzung der DIENSTE unter der Internet-Domain https://portal.yodalytics.com, durch die Limendo GmbH (im Folgenden: "ANBIETER"), Umsatzsteuernummer IT02995110216, mit Sitz in 39100 Bozen (BZ), Italien, bereitgestellt wird.

1.2 Die Nutzung der DIENSTE unterliegt den Bestimmungen einer rechtsverbindlichen Vereinbarung zwischen Ihnen, (im Folgenden „NUTZER“, „Sie“, „SIE“, "Ihnen", "IHNEN", „IHRE“, „IHREN“, etc.) und dem ANBIETER.

1.3 Die BESTIMMUNGEN regeln das Verhältnis zwischen Ihnen und dem ANBIETER in Bezug auf die Nutzung der DIENSTE und sind Bestandteil aller zwischen Ihnen und dem ANBIETER in Bezug auf die Nutzung der DIENSTE geschlossenen Verträge. Ausnahmen und ergänzende Bestimmungen gelten nur bei ausdrücklicher Vereinbarung. Die BESTIMMUNGEN gelten für alle Nutzer der DIENSTE und alle Besucher der WEBSEITE.

1.4 Neben den BESTIMMUNGEN kann die Nutzung der DIENSTE weiteren gesetzlichen und vertraglichen Bestimmungen unterliegen, auch von Dritten, mit denen der ANBIETER zusammenarbeitet, wenn Sie deren Dienste nutzen.

1.5 Die BESTIMMUNGEN gelten in jedem Fall, es sei denn, ihre Geltung wurde vor Auftragserteilung ausdrücklich schriftlich ausgesetzt und ihre Aussetzung vom ANBIETER schriftlich bestätigt.

1.6 Sie erkennen an und stimmen zu, dass jedes Mitglied der Unternehmensgruppe, zu der der ANBIETER gehört, ein Drittbegünstigter der BESTIMMUNGEN ist und dass die betreffenden Unternehmen berechtigt sind, sich auf jede Bestimmung der BESTIMMUNGEN zu berufen, die Ihnen einen Vorteil (oder Rechte) gewährt, und diese direkt durchzusetzen.

‍

2. AKZEPTANZ DER BESTIMMUNGEN

2.1 Um die DIENSTE nutzen zu können, müssen Sie zunächst die BESTIMMUNGEN akzeptieren. Mit dem Besuch der WEBSEITE, der Einrichtung eines Kundenkontos für Sie durch den ANBIETER oder durch Sie selbst, und/oder der Nutzung der DIENSTE erklären Sie, dass Sie die zum Zeitpunkt der Nutzung gültigen BESTIMMUNGEN gelesen haben und diese akzeptieren. Sie sollten diese BESTIMMUNGEN für Ihre Unterlagen ausdrucken oder lokal speichern.

2.2 Sie dürfen die DIENSTE nicht nutzen oder die BESTIMMUNGEN akzeptieren, wenn (a) Sie noch nicht das gesetzliche Mindestalter erreicht haben, um einen verbindlichen Vertrag mit dem ANBIETER abzuschließen, oder (b) Ihnen der Empfang oder die Nutzung der DIENSTE nach den Gesetzen des Landes, in dem Sie Ihren Wohnsitz haben oder von dem aus Sie auf die DIENSTE zugreifen oder es nutzen, untersagt ist oder aus sonstigen Gründen gesetzlich verboten ist.

‍

3. SPRACHE DER BESTIMMUNGEN

3.1 Die Inhalte der DIENSTE werden zu Ihrem Nutzen in verschiedenen Sprachen angeboten. Für Ihr Vertragsverhältnis mit dem ANBIETER ist jedoch ausschließlich die deutsche Textfassung der BESTIMMUNGEN und deren Inhalt für die Nutzung der DIENSTE maßgeblich.

‍

4. ÄNDERUNG DER BESTIMMUNGEN UND KONDITIONEN

4.1 Der ANBIETER behält sich das Recht vor, die BESTIMMUNGEN jederzeit ganz oder teilweise nach eigenem Ermessen und ohne Angabe von Gründen zu ändern. In diesem Fall treten die Änderungen der BESTIMMUNGEN mit ihrer Veröffentlichung auf der WEBSEITE in Kraft. Durch die Nutzung der DIENSTE nach der Veröffentlichung der Änderungen akzeptiert der Nutzer die Änderungen der BESTIMMUNGEN und muss die BESTIMMUNGEN regelmäßig auf diese überprüfen.

‍

LEISTUNGEN UND VERTRAGSGEGENSTAND

5. LEISTUNG

5.1 Die DIENSTE enthalten Softwarelösungen für Unternehmer im Bereich Webtracking und Datenplattform. Unsere Plattform ist ein SaaS-Produkt (Software-as-a-Service), das als Webtracking- und Analyseplattform fungiert. Durch die Installation unseres Codes auf Ihrer Webseite können die Aktivitäten ihrer Webseitenbesucher verfolgt werden. Es besteht die Möglichkeit, in bestimmten Fällen Daten aus Drittsystemen zu integrieren. Vor der Integration werden diese Daten anonymisiert, um sicherzustellen, dass keine personenbezogenen oder identifizierbaren Informationen durch uns als ANBIETER gespeichert werden.

5.2 Die DIENSTE werden als Software-as-a-Service (SaaS) für Unternehmer angeboten. Wenn Sie DIENSTE als Unternehmer nutzen, erbringt der ANBIETER die vertraglichen Leistungen entsprechend als SaaS-Dienstleister. Gegenstand des Vertragsverhältnisses zwischen Ihnen und dem ANBIETER ist die zeitlich begrenzte, nicht ausschließliche, entgeltliche Überlassung von Software im Internet. Zu diesem Zweck speichert der ANBIETER die Software auf einem Server, auf den Sie über das Internet respektive dem Webbrowser zugreifen können. Die DIENSTE ermöglichen es Ihnen, die vom ANBIETER zur Verfügung gestellte Datenplattform zu nutzen, um Webtracking Ihrer Webseiten zu nutzen sowie wenn gewünscht und verfügbar Daten aus Drittsystemen zu integrieren und falls verfügbar mit Webtracking-Daten zu kombinieren.

5.3 Der ANBIETER behält sich das Recht vor, jederzeit und von Zeit zu Zeit einen Teil des DIENSTES mit oder ohne Ankündigung vorübergehend oder dauerhaft zu ändern oder einzustellen.

5.4 Die DIENSTE, einschließlich der Software und aller darin enthaltenen geistigen Eigentumsrechte, ist und bleibt ausschließliches Eigentum des ANBIETERS. Alle Rechte an der Software, die Ihnen in dieser Vereinbarung nicht ausdrücklich eingeräumt werden, werden vom ANBIETER und seinen Lizenzgebern ohne Einschränkungen bewahrt und innegehalten, einschließlich dem ausschließlichen Eigentumsrecht des ANBIETERS an der Software und Dokumentation. Ohne die Allgemeingültigkeit des Vorherigen einzuschränken, erklären Sie sich damit einverstanden, nicht (und Dritten nicht zu gestatten):

(a) den Dienst oder die Software außerhalb des in dieser Vereinbarung gewährten Lizenzumfangs zu unterlizenzieren, zu verbreiten oder zu nutzen;

(b) die Software zu kopieren, zu ändern, anzupassen, zu übersetzen, davon abgeleitete Werke zu erstellen, zu reverse-engineeren, zu zerlegen oder zu dekompilieren oder anderweitig versuchen, Quellcode oder Betriebsgeheimnisse im Zusammenhang mit dem Dienst oder der Dokumentation zu entdecken;

(c) Rechte an oder in der Software, Dokumentation oder dem Dienst zu vermieten, zu verpachten, zu verkaufen, zuzuweisen oder anderweitig zu übertragen;

(d) Geräte, Software oder Routinen zu verwenden, zu veröffentlichen, zu übertragen oder einzuführen, die den Betrieb des Dienstes, der Dokumentation oder der Software stören oder zu stören versuchen;

(e) Marken, Handelsnamen, Dienstleistungsmarken, Logos, Domainnamen und andere markante Markenmerkmale oder jegliche Urheberrechte oder andere Eigentumsrechte, die mit dem Dienst verbunden sind, ohne die ausdrückliche schriftliche Zustimmung des ANBIETERS zu verwenden;

(f) Marken, Handelsnamen, Dienstleistungsmarken, Logos, Domainnamen und andere markante Markenmerkmale, Urheberrechte oder andere Eigentumsrechte des ANBIETERS zu registrieren, zu versuchen zu registrieren oder anderen bei der Registrierung zu helfen, oder

(g) Hinweise auf Urheberrecht, Marken oder andere Eigentumsrechte zu entfernen, zu verbergen oder zu verändern, die in oder auf einem Element enthalten sind, das mit dem Dienst oder der Software geliefert wird.

5.4 Unternehmer im Sinne dieser Allgemeinen Geschäftsbedingungen ist jede natürliche oder juristische Person, die zu Zwecken handelt, die ihrer gewerblichen, geschäftlichen oder beruflichen Tätigkeit zugerechnet werden können. Endkunde im Sinne dieser AGB ist jede natürliche oder juristische Person, die zu Zwecken handelt, die nicht ihrer gewerblichen, geschäftlichen, handwerklichen oder beruflichen Tätigkeit zugerechnet werden können.

5.5 Alle Leistungsangebote des ANBIETERS sind freibleibend und unverbindlich. Dies gilt auch für Preisangebote. Der genaue Leistungsumfang und die Art der vertraglichen Leistungen für Unternehmer bzw. Endkunden ergibt sich aus den entsprechenden Leistungsbeschreibungen, welche auf der WEBSEITE dargestellt werden und mit diesen AGB einen integralen Bestandteil bilden. Zusätzliche Leistungen bedürfen einer gesonderten schriftlichen Vereinbarung mit dem ANBIETER. Der ANBIETER kann jederzeit nach eigenem Ermessen Änderungen an den DIENSTEN und/oder der WEBSEITE vornehmen und jederzeit aktualisierte Versionen der DIENSTE und/oder der WEBSEITE bereitstellen.

‍

6. DATEN

6.1 Die erfassten Daten über das Code-Schnipsel (auch Code-Snippet genannt), u.a. Clickstreams und Meta-Informationen des Seitenaufrufs, einer einzelnen Webseite verbleiben uneingeschränkt im Eigentum des jeweiligen NUTZERS. Dies gilt auch für über Dritt-Systeme integrierte Daten (z.B. ERP-Systeme, PMS-Systeme, Shop-Systeme, etc.). Wenn Daten aus Dritt-Systemen integriert werden, werden diese zuvor anonymisiert. Dies bedeutet, dass der NUTZER über unsere Systeme keine persönlichen Daten abrufen kann und diese über seine genutzten Dritt-Systeme abgerufen werden müssen.

Durch die Nutzung der DIENSTE gewährt der NUTZER dem ANBIETER eine weltweite, nicht-exklusive und gebührenfreie, unterlizenzierbare und übertragbare Lizenz, um die genannten Daten zu sammeln, speichern, analysieren, verarbeiten und nutzen zu dürfen, ausschließlich für den Zweck der Erbringung des DIENSTE sowie zur kontinuierlichen Verbesserung und Optimierung unserer DIENSTE. Die eingeräumten Lizenzen an den DATEN des NUTZERS sind unbefristet und unwiderruflich, lassen die Eigentumsrechte des NUTZERS im Übrigen aber unberührt. Der ANBIETER schließt jegliche Haftung in Zusammenhang mit den lizenzierten Daten des NUTZERS aus.

6.2 Es wird ausdrücklich festgehalten, dass der NUTZER keinerlei Rechte oder Eigentumsansprüche an den daraus abgeleiteten aggregierten Daten, statistischen Informationen oder erstellten Datenmodellen und Algorithmen besitzt, die sich aus der Analyse oder Verarbeitung seiner Daten ergeben. Diese abgeleiteten Daten, aggregierten Informationen oder Datenmodelle stehen ausschließlich dem ANBIETER zu, und der Nutzer hat kein Recht auf deren Nutzung, Verwertung oder Verbreitung.

6.3 Es wird weiterhin betont, dass jegliche Verwendung der vom Nutzer bereitgestellten Daten in Übereinstimmung mit geltenden Datenschutzgesetzen und unseren Datenschutzrichtlinien erfolgt, wodurch die Vertraulichkeit, Sicherheit und Integrität dieser Daten gewährleistet werden.

‍

7. AUFGABEN DES NUTZERS IN BEZUG AUF DIE INFORMATION DER NUTZER-ENDKUNDEN

7.1 Der NUTZER erklärt sich damit einverstanden, mit dem ANBIETER eine Vereinbarung zur Auftragsverarbeitung gemäß den geltenden Datenschutzgesetzen abzuschließen.

7.2 Der NUTZER ist verpflichtet, seinen eigenen Nutzern und Endkunden entsprechende Informationen zur Verfügung zu stellen und deren Zustimmung einzuholen.

7.3 Der NUTZER stellt den Anbieter von jeglichen Ansprüchen Dritter in Bezug auf Datenschutzverstöße frei. Dies umfasst die Haftung für direkte und indirekte Schäden, Kosten, Verbindlichkeiten und sonstige Ausgaben, die aus Datenschutzverletzungen, unrechtmäßiger Offenlegung oder Nutzung von personenbezogenen Daten resultieren, sofern diese Verstöße nicht durch grobe Fahrlässigkeit oder vorsätzliches Handeln des ANBIETERS verursacht wurden.

‍

8. SCHUTZ IHRES KONTOS

8.1 Sie sind dafür verantwortlich, die Sicherheit Ihres Kontos und Passworts aufrechtzuerhalten. Das Unternehmen kann und wird nicht für Verluste oder Schäden haftbar gemacht werden, die durch Ihre Nichtbefolgung dieser Sicherheitsverpflichtung entstehen.

8.2. Sie sind verantwortlich für sämtliche Inhalte, die unter Ihrem Konto veröffentlicht werden, sowie für sämtliche Aktivitäten, die unter Ihrem Konto stattfinden (auch wenn Inhalte von anderen Personen veröffentlicht werden, die über ihre eigenen Anmeldedaten unter Ihrem Konto verfügen).

8.3 Sie dürfen den Service nicht für illegale Zwecke nutzen oder gegen Gesetze in Ihrer Rechtsprechung verstoßen.

8.4 Sie müssen Ihren vollständigen rechtlichen Namen, eine gültige E-Mail-Adresse und alle anderen angeforderten Informationen bereitstellen, um den Anmeldeprozess abzuschließen.

8.5 Ihr Login darf nur von einer Person verwendet werden – ein einzelner Login, der von mehreren Personen gemeinsam genutzt wird, ist nicht gestattet.

8.6 Sie können gemäß Ihrem Serviceplan separate Logins für andere Personen erstellen. Jeder separate Login oder jede Person ist automatisch an diese Nutzungsbedingungen gebunden, und es liegt in Ihrer Verantwortung, Ihre Nutzer über diese Nutzungsbedingungen zu informieren.

8.7 Sie müssen ein Mensch sein. Konten, die von „Bots“ oder anderen automatisierten Methoden registriert werden, sind nicht gestattet."

‍

9. KOSTEN FÜR DIE NUTZUNG DER DIENSTE

9.1 Die Nutzung der DIENSTE ist für Unternehmer bis auf das "kostenlose" Paket (dieses enthält aktuell 1.000 kostenfreie Seitenaufrufe pro Monat) kostenpflichtig und basiert auf einem Abonnementvertrag. Mit der Auswahl eines kostenpflichten Pakets in den DIENSTEN, erklärt der Unternehmer die DIENSTE kostenpflichtig nutzen zu wollen und der Vertrag mit dem ANBIETER gilt als geschlossen. Alle Preise und sonstigen Kosten für die Nutzung der DIENSTE sind auf der WEBSITE bzw. in den DIENSTEN einsehbar und verstehen sich zzgl. Steuern und/oder sonstiger gesetzlich anfallender Abgaben.

9.2 Der Abonnementvertrag beginnt mit dem Tag des Vertragsschlusses und ist auf die Dauer von einem Monat bzw. einem Jahr befristet, je nach gewähltem Paket. Er verlängert sich automatisch um einen weiteren Monat bzw. Jahr, wenn er nicht von einer der Vertragsparteien in den DIENSTEN vor Ablauf des Abonnements gekündigt wird. Die Preise aller Dienstleistungen können sich nach unserer Benachrichtigung innerhalb von 30 Tagen ändern. Eine solche Benachrichtigung kann jederzeit erfolgen, indem die Änderungen in den DIENSTEN oder auf der WEBSEITE publiziert werden.

9.3 Die Kosten für die Nutzung der DIENSTE werden Ihnen monatlich bzw. jährlich in Rechnung gestellt. Ihnen werden die Kosten im Voraus in Rechnung gestellt. Eine Nutzung der DIENSTE ist nur dann möglich, wenn die Rechnung beglichen wurde. Die Rechnung ist auf Sicht fällig. Sie sind verpflichtet, den in der Rechnung ausgewiesenen Betrag ohne Abzug zu zahlen. Die Zahlung kann über eine mit dem Benutzerkonto verbundene Kreditkarte abgebucht werden.

9.4 Im Falle eines auch nur teilweisen Zahlungsverzuges ist der ANBIETER berechtigt, seine vertraglichen Leistungen zu unterbrechen und Ihren Zugang zu den DIENSTEN zu sperren und das bestehende Vertragsverhältnis gilt gemäß Art. 1456 ZGB von Rechts wegen als aufgelöst. Für den Fall, dass der ANBIETER von der oben genannten ausdrücklichen Kündigungsklausel keinen Gebrauch macht, führt der Zahlungsverzug zur Verpflichtung zur Zahlung von Verzugszinsen in Höhe des in Artikel 5 der Gesetzesverordnung Nr. 231 vom 9. Oktober 2002 vorgesehenen Zinssatzes, wobei die Zinsen automatisch und ohne Ankündigung am Tag nach Ablauf des entsprechenden Zahlungstermins anfallen. In jedem Fall behält sich der ANBIETER die Geltendmachung weiterer Ansprüche im Zusammenhang mit dem Zahlungsverzug vor.

‍

10. ALLGEMEINE BESTIMMUNGEN ZUR NUTZUNG DER DIENSTE

10.1 Der NUTZER darf die DIENSTE nur im Rahmen des vertraglich vereinbarten Nutzungsumfangs einsetzen. Weitergehende Rechte stehen dem Nutzer nicht zu. Jede weitere Verwendung bedarf der vorherigen schriftlichen Zustimmung des ANBIETERS.

10.2 Überschreitet der Nutzer die vertraglich zulässige Nutzung oder nutzt er die DIENSTE in sonstiger Weise rechtswidrig, kann der ANBIETER seine vertraglichen Leistungen unterbrechen und den Zugang zu den DIENSTEN sperren und/oder das Vertragsverhältnis fristlos kündigen. In diesem Fall haftet der Benutzer für alle daraus entstehenden Schäden und/oder Ansprüche Dritter und ist verpflichtet, den ANBIETER von allen Ansprüchen Dritter freizustellen.

10.3 Sie sind verpflichtet, alle notwendigen und/oder angemessenen Maßnahmen zu ergreifen, um eine rechtswidrige oder vertragswidrige Nutzung der DIENSTE zu vermeiden. Wenn Sie erkennen oder erkennen sollten, dass eine rechtswidrige oder nicht vertragsgemäße Nutzung bevorsteht, sind Sie verpflichtet, den ANBIETER unverzüglich zu informieren. Sie sind verpflichtet, die Ihnen zugewiesenen Zugangsberechtigungen und Identifikations- und Authentifizierungsinformationen vor dem Zugriff Dritter zu schützen und nicht an Unbefugte weiterzugeben.

10.4 Der ANBIETER ist berechtigt, jederzeit nach eigenem Ermessen technische oder sonstige Maßnahmen zum Schutz gegen eine nicht vertragsgemäße Nutzung der DIENSTE zu ergreifen und/oder weitergehende BESTIMMUNGEN einzuführen.

10.5 Der Nutzer darf im Rahmen der Nutzung der DIENSTE keine Inhalte, Informationen, Angebote und/oder Dienste bereitstellen oder anderweitig nutzen, die Rechte Dritter (insbesondere Datenschutz-, Wettbewerbs-, Patent- und/oder Markenrecht und/oder sonstige gewerbliche Schutzrechte) verletzen oder die gegen die Bestimmungen des italienischen Rechts (insbesondere die der öffentlichen Ordnung und des Strafrechts) verstoßen. Der NUTZER verpflichtet sich, den ANBIETER von allen Ansprüchen Dritter freizustellen und dem ANBIETER alle Kosten und Schäden zu ersetzen, die dem ANBIETER direkt oder indirekt durch damit verbundene Rechtsverletzungen entstehen.

‍

11. AUSSCHLUSS DER GEWÄHRLEISTUNG DES ANBIETERS

11.1 Die DIENSTE einschließlich der Inhalte und Dienste der WEBSEITE, sowie alle anderen Alias-Seiten des ANBIETERS werden "wie besehen" zur Verfügung gestellt und der ANBIETER gibt diesbezüglich keine Zusicherungen oder Garantien.

11.2 Insbesondere und ohne Einschränkung garantiert der ANBIETER nicht, dass:

(a) die Nutzung der DIENSTE nicht unterbrochen wird und/oder zeitgerecht, sicher und/oder frei von Fehlern oder Störungen ist,

(b) dass die Nutzung der DIENSTE Ihren Erwartungen entspricht;

(c) dass alle Informationen, die Sie durch die Nutzung der DIENSTE erhalten, richtig oder zuverlässig sind;

(d) dass Fehler im Betrieb oder in der Funktionalität von Software, die Ihnen im Rahmen der DIENSTE Verfügung gestellt wird, korrigiert werden.

(e) dass die DIENSTE einwandfrei und korrekt funktionieren und zu 100% genau arbeiten, z.B. aber nicht ausschließlich in der Generierung von Personas. Die DIENSTE basieren u.a. auf der Technologie des NLP, welche immer nur mit bestimmten Wahrscheinlichkeiten arbeiten, welche kleiner als 100% sind.

11.3 Andere Bestimmungen oder Garantien (einschließlich jeglicher Bestimmungen bezüglich zufriedenstellender Qualität, Zweittauglichkeit, oder Übereinstimmung mit Beschreibungen) sind auf die Leistungen des ANBIETERS ebenfalls nicht anwendbar, sofern in diesen AGB nicht ausdrücklich anders angegeben.

11.4 Der ANBIETER ist nicht verantwortlich und übernimmt keine Gewährleistung oder Garantie für die Inhalte, Informationen, Angebote und/oder Leistungen, die der Nutzer im Zusammenhang mit der Nutzung der DIENSTE Dritten zur Verfügung stellt. Der NUTZER verpflichtet sich, den ANBIETER von allen Ansprüchen Dritter freizustellen.

‍

12. AUSSCHLUSS/BESCHRÄNKUNG DER HAFTUNG DES ANBIETERS

12.1 Der ANBIETER haftet nicht für Schäden (einschließlich indirekter Schäden, Folgeschäden und entgangenem Gewinn), die sich direkt oder indirekt aus der Nutzung der DIENSTE ergeben sollten, außer bei Vorsatz oder grober Fahrlässigkeit.

12.2 Der ANBIETER übernimmt keine Haftung für dauerhafte oder vorübergehende Fehler, Auslassungen, Unterbrechungen, Löschungen, Defekte, Verzögerungen und/oder Aussetzungen im Betrieb, in der Übertragung und/oder Lieferung von Inhalten und Diensten der DIENSTE, Ausfälle von Kommunikationsleitungen, unbefugten Zugriff auf Daten oder Diebstahl. Der ANBIETER ist auch nicht verantwortlich für Probleme oder technische Störungen im Zusammenhang mit mobilem Internet, Telefonnetzen oder -leitungen, Online-Systemen, Servern oder Providern, Computerausstattungen, Software sowie Ausfälle von E-Mail- oder Service-Providern zur Abwicklung von elektronischen Zahlungen aufgrund von technischen Problemen oder Datenstaus im Internet oder einer Kombination dieser Faktoren. Der ANBIETER übernimmt keine Verantwortung für nicht zustande gekommene Verträge aufgrund eines oder einer Kombination der genannten Faktoren.

12.3 Der Nutzer ist für alle Inhalte, Informationen, Angebote und/oder Dienstleistungen, die im Zusammenhang mit der Nutzung der DIENSTE bereitgestellt oder sonst genutzt werden, allein verantwortlich. Der ANBIETER übernimmt diesbezüglich keine Haftung und Sie sind verpflichtet, den ANBIETER von allen Ansprüchen Dritter freizustellen und dem ANBIETER alle Kosten und Schäden zu ersetzen, die dem ANBIETER direkt oder indirekt durch damit zusammenhängende Rechtsverletzungen entstehen.

12.4 Der ANBIETER haftet nicht für Speicherfehler, Löschung, Verlust und/oder Beschädigung der von Ihnen im Rahmen der Nutzung der DIENSTE bereitgestellten, erstellten oder empfangenen Daten.

12.5 Der ANBIETER haftet nicht für die unbefugte Kenntnisnahme personenbezogener Daten von Kunden durch Dritte (z.B. durch unbefugten Zugriff auf die DIENSTE durch Hacker). Der ANBIETER kann auch nicht für den Missbrauch von Inhalten und Informationen verantwortlich gemacht werden, die der Nutzer selbst Dritten zur Verfügung gestellt hat.

12.6 Der ANBIETER ist nicht verantwortlich und übernimmt keine Gewährleistung oder Garantie für die Inhalte, Informationen, Angebote und/oder Leistungen, die der Nutzer im Zusammenhang mit der Nutzung der DIENSTE Dritten zur Verfügung stellt. Der NUTZER verpflichtet sich, den ANBIETER von allen Ansprüchen Dritter freizustellen.

12.7 Die in dieser Ziffer 12 enthaltenen Haftungsausschlüsse oder -beschränkungen gelten unabhängig davon, ob der NUTZER über die Möglichkeit des Eintritts solcher Ansprüche, Verluste und/oder Schäden informiert war und/oder von dieser Kenntnis hatte. Sie gelten auch im Hinblick auf die Haftung der gesetzlichen Vertreter und Erfüllungsgehilfen.

12.8 Die in dieser Ziffer 12 enthaltenen Haftungsausschlüsse oder -beschränkungen des ANBIETERS gelten nicht im Falle von Arglist oder grober Fahrlässigkeit.

‍

13. AUSDRÜCKLICHE WIDERRUFSKLAUSEL

13.1 Im Falle der Nichteinhaltung auch nur einer der Bestimmungen des Abschnitts 7, 8, 9 und 10 so stellt dies einen schwerwiegenden und erheblichen Verstoß gegen die Bestimmungen des Artikels 1455 des Codice Civile dar und führt ipso jure zur Beendigung des Vertragsverhältnisses zwischen Ihnen und dem ANBIETER gemäß Artikel 1456 des Codice Civile, unbeschadet der Geltendmachung anderer Ansprüche und des Ersatzes aller Schäden. Im Falle einer vorzeitigen Beendigung des Vertragsverhältnisses nach den Bestimmungen dieses Punktes sind Sie in jedem Fall verpflichtet, die bis zum Zeitpunkt der Beendigung in Anspruch genommenen Leistungen zu bezahlen.

‍

SCHLUSSBESTIMMUNGEN

14. REFERENZEN

14.1 Der NUTZER ist bereit dem ANBIETER, eine weltweite, nicht-exklusive und gebührenfreie, unterlizenzierbare und übertragbare Lizenz der seiner Marke (eingetragen oder nicht-eingetragene Marke, genutzt durch den NUTZER), zwecks zur Verfügungstellen der DIENSTE einschließlich der Vermarktung bzw. für das Marketing der Dienste des ANBIETERS einräumt. Die eingeräumten Lizenzen der Nutzung der Marke des NUTZERS sind unbefristet und unwiderruflich, lassen die Eigentumsrechte des NUTZERS im Übrigen aber unberührt. Der ANBIETER schließt jegliche Haftung in Zusammenhang mit der Nutzung der Marke des NUTZERS aus.

‍

15. ANWENDBARES RECHT

15.1 Alle Beziehungen zwischen den Parteien, einschließlich dieser BESTIMMUNGEN, unterliegen dem italienischen Recht unter Ausschluss der kollisionsrechtlichen Bestimmungen. Durch diese Rechtswahlklausel werden etwaige zwingende Verbraucherschutzvorschriften nicht berührt.

‍

16. GERICHTSSTAND

16.1 Ausschließlicher Gerichtsstand für alle Streitigkeiten aus und im Zusammenhang mit dem Vertragsverhältnis zwischen den Parteien, einschließlich dieser BESTIMMUNGEN, ist Bozen, Italien. Eventuell geltende zwingende Verbraucherschutzvorschriften werden durch diese Gerichtsstandsvereinbarung nicht berührt.

‍

17. ERFÜLLUNGSORT

17.1 Erfüllungsort für alle vertraglichen Leistungen ist der Sitz des ANBIETERS.

‍

18. SALVATORISCHE KLAUSEL

18.1 Sollten eine oder mehrere Paragraphen bzw. Bestimmungen dieser BESTIMMUNGEN ungültig und/oder unwirksam sein oder werden, so wird dadurch die Gültigkeit und Wirksamkeit der übrigen Bestimmungen nicht berührt.

‍

19. DULDUNG

19.1 Im Falle eines Verstoßes gegen die Bestimmungen dieser BESTIMMUNGEN bedeutet eine Duldung dieses Verstoßes durch den ANBIETER in keinem Fall einen Verzicht des ANBIETERS auf seine Rechte und sonstigen Wirkungen aus den betreffenden Bestimmungen sowie einen Verzicht auf das Recht zur vollständigen Erfüllung der Pflichten und Bestimmungen des Vertragsverhältnisses und/oder dieser BESTIMMUNGEN.

‍

ICH AKZEPTIERE

Unterschrift: _________________________________

Im Sinne und mit Wirkung der Artikel 1341 und 1342 des Bürgerlichen Gesetzbuches erklärt der Benutzer der WEBSITE bzw. der DINESTE die folgenden Klauseln und Bestimmungen der vorliegenden allgemeinen Vertragsbestimmungen gelesen zu haben und sie einzeln und ausdrücklich zu akzeptieren:

Punkt 1.6 (Drittbegünstigte der BESTIMMUNGEN),

Punkte 2.1 und 2.2 (Annahme der BESTIMMUNGEN),

Punkt 3 (Sprache der BESTIMMUNGEN),

Punkt 4.1 (Änderungen der BESTIMMUNGEN),

Punkt 5. (Leistung), insbesondere die Punkte 5.3 (Änderung oder Beendigung der Leistung) und 5.4 (Eigentumsrechte an der Leistung),

Punkt 6 (Daten), insbesondere 6.1 (Weltweite, nicht-exklusive und gebührenfreie, unterlizenzierbare und übertragbare Lizenz an den Daten) und 6.2 (Eigentumsrechte an aggregierten Datenmodellen),

Punkt 7 (Informationspflichten des NUTZERS), insbesondere Punkt 7.3 (Pflicht zur Freistellung des ANBIETERS von Ansprüchen Dritter),

Punkt 8 (Schutz des Kontos),

Punkt 9 (Kosten der DIENSTE),

Punkt 10 (Allgemeine Nutzungsbedingungen)Punkte 11.1, 11.2, 11.3 und 11.4 (Gewährleistungsausschluss),

Punkte 12.1, 12.2., 12.3, 12.4, 12.5, 12.6, 12.7, 12.8 (Haftungsausschluss bzw. -beschränkung),

Punkt 13.1 (Ausdrückliche Aufhebungsklausel),

Punkt 14.1 (Referenzen),

Punkt 15.1 (Anwendbares Recht),

Punkt 16.1 (Gerichtsstandsvereinbarung),

Punkt 17.1 (Erfüllungsort),

Punkt 19.1 (Duldung).

‍

ICH AKZEPTIERE

Unterschrift: _________________________________`;

export default terms;
