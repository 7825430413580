import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  insights: {
    visitors: 0,
    sessions: 0,
    questions: 0,
    tokens: 0,
    average_time: 0
  },
  multiBarChartData: {},
  personaData: {},
  categoriesData: {},
  isLoading: false
};

const compareISIChatSlice = createSlice({
  name: "compareISIChatData",
  initialState,
  reducers: {
    setCompareIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    setCompareInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    setCompareMultiBarChartData: (state, actions) => {
      return { ...state, multiBarChartData: { ...actions.payload } };
    },
    setComparePerosnaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    setCompareCategoriesData: (state, actions) => {
      return { ...state, categoriesData: { ...actions.payload } };
    }
  }
});

export const {
  setCompareIsLoading,
  setCompareInsights,
  setCompareMultiBarChartData,
  setComparePerosnaData,
  setCompareCategoriesData
} = compareISIChatSlice.actions;

export default compareISIChatSlice.reducer;
