/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import popUp from "../../assets/icons/pop-up.svg";
import refresh from "../../assets/icons/reorder.svg";
import settings from "../../assets/icons/settings.svg";
import backButton from "../../assets/icons/back-button.svg";
import {
  updateSelectedFilters,
  updateToggleState
} from "../../store/slices/generalSlice";
import { updateSelectedFilterType } from "../../store/slices/isiChatDataSlice";
import { updateSelectedPersonaFilterType } from "../../store/slices/personasSlice";
import Toggle from "../Toggle/Toggle";
import { useEffect } from "react";
import themeColors from "../../assets/stylesheets/_var.scss";
import DropdownComponent from "../inputElements/selectInput/DropdownComponent";
import { updateSelectedEcommerceFilterType } from "../../store/slices/ecommerceSlice";

export default function ChartHeader({
  heatmap,
  onReload,
  togglePopUp,
  setTogglePopUp,
  title,
  selectedCountry,
  setSelectedCountry,
  worldMap,
  showGear,
  filterGear,
  setFilterGear,
  handleButtonMouseDown,
  multiBarChart = false,
  bookingPercentage = false,
  isToggled,
  handleHideOtherData,
  otherDataIncluded,
  search = false,
  onSearch,
  limitedFilteredData,
  setLimitedFilteredData,
  pageUrlDataNew,
  dashboard
}) {
  const {
    countryISO,
    selectedFilters,
    selectedISIFilters,
    selectedPersonasFilters,
    selectedEcommerceFilters
  } = useSelector((state) => state.generalData);
  const { selectedFilterType } = useSelector((state) => state.isiChatData);
  const { selectedEcommerceFilterType } = useSelector(
    (state) => state.ecommerce
  );

  const { pageUrlData } = useSelector((state) => state.dashboardData);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(
    dashboard === "isichat" ? selectedFilterType : selectedEcommerceFilterType
  );
  const [transformedData, setTransformedData] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    dispatch(updateSelectedFilterType(event.target.value));
    dispatch(updateSelectedPersonaFilterType(event.target.value));
    dispatch(updateSelectedEcommerceFilterType(event.target.value));
  };

  const pagesContent = [
    {
      id: 1,
      name: t("analyticsPage.topPages")
    },
    {
      id: 2,
      name: t("analyticsPage.entryPages")
    },
    {
      id: 3,
      name: t("analyticsPage.exitPages")
    }
  ];

  const multibarChartOptions = [
    { value: "Daily", label: t("isiChatDashboard.daily") },
    { value: "Weekly", label: t("isiChatDashboard.weekly") },
    { value: "Monthly", label: t("isiChatDashboard.monthly") },
    { value: "Yearly", label: t("isiChatDashboard.yearly") }
  ];

  const [selectedPage, setSelPage] = useState(
    selectedFilters?.Filter
      ? pagesContent.filter((ele) => ele.name === selectedFilters?.Filter[0])
      : pagesContent[0]
  );

  const handleChangePage = (target) => {
    setSelPage(target);
    if (target?.id === 1) {
      const { Filter: remove, ...updatedFilters } = selectedFilters;
      dispatch(updateSelectedFilters(updatedFilters));
    } else
      dispatch(
        updateSelectedFilters({
          ...selectedFilters,
          Filter: [target.name]
        })
      );
  };

  useEffect(() => {
    const newData = Object.entries(countryISO)?.map(([countryCode, data]) => ({
      countryCode,
      name: data.name,
      label: data.name,
      value: countryCode,
      latitude: data.latitude,
      longitude: data.longitude
    }));
    setTransformedData(newData);
  }, [countryISO]);

  const worldMapContent = (
    <DropdownComponent
      value={selectedCountry || t("tagManager.addTagForm.selectPlaceholder")}
      options={transformedData}
      onChange={(e) => setSelectedCountry(e.target.value)}
      isRequired={false}
      marginTop="1px"
      minWidth={"250px"}
      defaultValue={t("tagManager.addTagForm.selectPlaceholder")}
    />
  );

  const heatmapContent = heatmap && (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: themeColors.primaryText,
        fontSize: "0.9rem"
      }}
    >
      {t("heatmap.bookingPercentage")}
      <LegendData color={heatmap[100]} value={`${t("heatmap.above")}  90%`} />
      <LegendData color={heatmap[89]} value="60% to 89%" />
      <LegendData color={heatmap[59]} value="30% to 59%" />
      <LegendData color={heatmap[29]} value={`${t("heatmap.below")}  30%`} />
      <LegendData color={heatmap[0]} value="0%" />
    </div>
  );

  if (togglePopUp)
    return (
      <div className="flex-between graph-header">
        <div className="flex-start">
          <button
            type="button"
            className="pop-up-button"
            onClick={() => {
              if (search) {
                setLimitedFilteredData(pageUrlDataNew);
              }
              dispatch(updateToggleState(false));
              setTogglePopUp(!togglePopUp);
            }}
          >
            <img src={backButton} alt="pop-up-icon" />
          </button>
          <h6>{title}</h6>
        </div>
        {worldMap && worldMapContent}
        {showGear && (
          <button
            type="button"
            className="settings-button"
            onClick={() => setFilterGear(!filterGear)}
          >
            <img src={settings} alt="settings" />
          </button>
        )}
      </div>
    );

  return (
    <div className="flex-between">
      <div>
        <h6>{title}</h6>
      </div>
      <div className="region-dropdown flex-end">
        {worldMap && worldMapContent}
        {title === t("analyticsPage.pagesTitle") && (
          <>
            {pagesContent.map((ele) => (
              <div
                key={ele.id}
                onClick={() => handleChangePage(ele)}
                className={`${
                  ele.id === selectedPage.id && "selected"
                } page-name`}
                style={{
                  fontSize: "14px"
                }}
              >
                <h6>{ele.name}</h6>
              </div>
            ))}
          </>
        )}
        {showGear && (
          <button
            type="button"
            className="settings-button"
            onClick={() => setFilterGear(!filterGear)}
            onMouseDown={handleButtonMouseDown}
          >
            <img src={settings} alt="settings" />
          </button>
        )}
        {multiBarChart && (
          <DropdownComponent
            value={selectedValue}
            options={multibarChartOptions}
            onChange={(e) => handleSelectChange(e)}
            isRequired={false}
            minWidth={120}
            disabled={
              (Object.keys(selectedISIFilters).length &&
                selectedISIFilters?.[t("common.date")]?.length > 0) ||
              Object.keys(selectedPersonasFilters).length > 0 ||
              (Object.keys(selectedEcommerceFilters).length &&
                selectedEcommerceFilters?.[t("common.date")]?.length > 0)
            }
          />
        )}
        {heatmap && (
          <>
            {bookingPercentage && heatmapContent}
            <button type="button" className="pop-up-button" onClick={onReload}>
              <img src={refresh} alt="refresh-icon" />
            </button>
          </>
        )}{" "}
        {otherDataIncluded && (
          <div>
            <Toggle
              isToggled={isToggled}
              setIsToggled={handleHideOtherData}
              label={t("common.withUndefinedPersons")}
            />
          </div>
        )}
        {!multiBarChart && !heatmap ? (
          <button
            type="button"
            className="pop-up-button"
            onClick={() => {
              if (search) {
                setLimitedFilteredData(pageUrlDataNew);
              }
              dispatch(updateToggleState(true));
              setTogglePopUp(!togglePopUp);
            }}
          >
            <img src={popUp} alt="pop-up-icon" />
          </button>
        ) : null}
      </div>
    </div>
  );
}

function LegendData({ color, value }) {
  return (
    <>
      <div
        style={{
          marginLeft: "10px",
          marginRight: "5px",
          backgroundColor: color,
          borderRadius: "4px",
          height: "19.75px",
          width: "19.75px"
        }}
      />
      <div>{value}</div>
    </>
  );
}

ChartHeader.propTypes = {
  heatmap: PropTypes.bool,
  onReload: PropTypes.func,
  togglePopUp: PropTypes.bool,
  setTogglePopUp: PropTypes.func,
  showGear: PropTypes.bool,
  filterGear: PropTypes.bool,
  setFilterGear: PropTypes.func,
  setLimitedFilteredData: PropTypes.func,
  pageUrlDataNew: PropTypes.array,
  title: PropTypes.string,
  selectedCountry: PropTypes.string,
  setSelectedCountry: PropTypes.func,
  worldMap: PropTypes.bool,
  handleButtonMouseDown: PropTypes.func,
  multiBarChart: PropTypes.bool,
  bookingPercentage: PropTypes.bool,
  isToggled: PropTypes.bool,
  handleHideOtherData: PropTypes.func,
  otherDataIncluded: PropTypes.bool,
  dashboard: PropTypes.string
};

LegendData.propTypes = {
  color: PropTypes.number,
  value: PropTypes.string
};
