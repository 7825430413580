import { createSlice } from "@reduxjs/toolkit";
import {
  getAnalyticsPages,
  getCompareAnalyticsPages,
  getCompareDataItems,
  getDataItems
} from "../apiSlice";
import { modifyData } from "../../utils/dataFilter";

const initialState = {
  // initial State
  shouldUpdateData: false,
  allData: [],
  filteredData: [],
  insights: {
    visitors: 0,
    actions: 0,
    views: 0,
    avgTime: 0,
    bounceRate: 0,
    tag_actions: 0,
    revenue: 0
  },
  devicesData: {},
  browsersData: {},
  countriesData: {},
  pageURLGraphData: { isLoading: true },
  graphSepData: { isLoading: true },
  pageUrlData: {},
  personaData: {},
  viewsVisitorsData: {},
  worldData: {},
  ageGroupData: {},
  sourceData: {},
  mediumData: {},
  campaignData: {},
  termData: {},
  contentData: {},
  isLoading: true,
  componentLoading: true,
  limitExceeded: false
};

const dataSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    updateShouldUpdateData: (state, action) => {
      return { ...state, shouldUpdateData: action.payload };
    },
    updateIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateFilteredData: (state, actions) => {
      return { ...state, filteredData: [...actions.payload] };
    },
    updateComponentLoading: (state) => {
      return { ...state, componentLoading: !state.componentLoading };
    },
    updateInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    updateDevicesData: (state, actions) => {
      return { ...state, devicesData: { ...actions.payload } };
    },
    updateBrowsersData: (state, actions) => {
      return { ...state, browsersData: { ...actions.payload } };
    },
    updateCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    updatePageUrlData: (state, actions) => {
      return { ...state, pageUrlData: { ...actions.payload } };
    },
    updatePersonaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    updateViewsVisitorsData: (state, actions) => {
      return { ...state, viewsVisitorsData: { ...actions.payload } };
    },
    updateWorldData: (state, actions) => {
      return { ...state, worldData: { ...actions.payload } };
    },
    updateAgeGroupData: (state, actions) => {
      return { ...state, ageGroupData: { ...actions.payload } };
    },
    updateSourceData: (state, actions) => {
      return { ...state, sourceData: { ...actions.payload } };
    },
    updateMediumData: (state, actions) => {
      return { ...state, mediumData: { ...actions.payload } };
    },
    updateCampaignData: (state, actions) => {
      return { ...state, campaignData: { ...actions.payload } };
    },
    updateTermData: (state, actions) => {
      return { ...state, termData: { ...actions.payload } };
    },
    updateContentData: (state, actions) => {
      return { ...state, contentData: { ...actions.payload } };
    }
  },
  extraReducers: {
    [getDataItems.pending]: (state) => {
      if (state.shouldUpdateData)
        return { ...state, graphSepData: { isLoading: true } };
      return { ...state, isLoading: true };
    },
    [getDataItems.fulfilled]: (state, action) => {
      if (state.shouldUpdateData)
        return {
          ...state,
          filteredData: [],
          graphSepData: {
            isLoading: false,
            data: modifyData([...action.payload[0]])
          },
          limitExceeded: action.payload[1]?.subscriptionLimit
        };

      return {
        ...initialState,
        shouldUpdateData: state.shouldUpdateData,
        pageURLGraphData: state.pageURLGraphData,
        pageUrlData: state.pageUrlData,
        isLoading: false,
        allData: modifyData([...action.payload[0]]),
        graphSepData: {
          isLoading: false,
          data: modifyData([...action.payload[0]])
        },
        limitExceeded: action.payload[1]?.subscriptionLimit
      };
    },
    [getDataItems.rejected]: (state) => {
      return { ...state, isLoading: false, graphSepData: { isLoading: false } };
    },
    [getCompareDataItems.pending]: (state) => {
      if (state.shouldUpdateData)
        return { ...state, graphSepData: { isLoading: true } };
      return { ...state, isLoading: true };
    },
    [getCompareDataItems.fulfilled]: (state, action) => {
      if (state.shouldUpdateData)
        return {
          ...state,
          filteredData: [],
          graphSepData: {
            isLoading: false,
            data: modifyData([...action.payload[0]])
          }
        };

      return {
        ...initialState,
        shouldUpdateData: state.shouldUpdateData,
        pageURLGraphData: state.pageURLGraphData,
        pageUrlData: state.pageUrlData,
        isLoading: false,
        allData: modifyData([...action.payload[0]]),
        graphSepData: {
          isLoading: false,
          data: modifyData([...action.payload[0]])
        },
        limitExceeded: action.payload[1]?.subscriptionLimit
      };
    },
    [getCompareDataItems.rejected]: (state) => {
      return { ...state, isLoading: false, graphSepData: { isLoading: false } };
    },
    [getAnalyticsPages.pending]: (state) => {
      return { ...state, pageURLGraphData: { isLoading: true } };
    },
    [getAnalyticsPages.fulfilled]: (state, action) => {
      return {
        ...state,
        pageURLGraphData: { isLoading: false, data: [...action.payload[0]] }
      };
    },
    [getAnalyticsPages.rejected]: (state) => {
      return { ...state, pageURLGraphData: { isLoading: false } };
    },
    [getCompareAnalyticsPages.pending]: (state) => {
      return { ...state, pageURLGraphData: { isLoading: true } };
    },
    [getCompareAnalyticsPages.fulfilled]: (state, action) => {
      return {
        ...state,
        pageURLGraphData: { isLoading: false, data: [...action.payload[0]] }
      };
    },
    [getCompareAnalyticsPages.rejected]: (state) => {
      return { ...state, pageURLGraphData: { isLoading: false } };
    }
  }
});

const dataToCompareSlice = createSlice({
  name: "dataToCompare",
  initialState,
  reducers: {
    updateCompareIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateCompareShouldUpdateData: (state, action) => {
      return { ...state, shouldUpdateData: action.payload };
    },
    updateCompareFilteredData: (state, actions) => {
      return { ...state, filteredData: [...actions.payload] };
    },
    updateCompareInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    updateCompareDevicesData: (state, actions) => {
      return { ...state, devicesData: { ...actions.payload } };
    },
    updateCompareBrowsersData: (state, actions) => {
      return { ...state, browsersData: { ...actions.payload } };
    },
    updateCompareCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    updateComparePageUrlData: (state, actions) => {
      return { ...state, pageUrlData: { ...actions.payload } };
    },
    updateComparePersonaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    updateCompareViewsVisitorsData: (state, actions) => {
      return { ...state, viewsVisitorsData: { ...actions.payload } };
    },
    updateCompareWorldData: (state, actions) => {
      return { ...state, worldData: { ...actions.payload } };
    },
    updateCompareAgeGroupData: (state, actions) => {
      return { ...state, ageGroupData: { ...actions.payload } };
    },
    updateCompareSourceData: (state, actions) => {
      return { ...state, sourceData: { ...actions.payload } };
    },
    updateCompareMediumData: (state, actions) => {
      return { ...state, mediumData: { ...actions.payload } };
    },
    updateCompareCampaignData: (state, actions) => {
      return { ...state, campaignData: { ...actions.payload } };
    },
    updateCompareTermData: (state, actions) => {
      return { ...state, termData: { ...actions.payload } };
    },
    updateCompareContentData: (state, actions) => {
      return { ...state, contentData: { ...actions.payload } };
    }
  },
  extraReducers: {
    [getCompareDataItems.pending]: (state) => {
      if (state.shouldUpdateData)
        return { ...state, graphSepData: { isLoading: true } };
      return { ...state, isLoading: true };
    },
    [getCompareDataItems.fulfilled]: (state, action) => {
      if (state.shouldUpdateData)
        return {
          ...state,
          filteredData: [],
          graphSepData: {
            isLoading: false,
            data: modifyData([...action.payload[1]])
          },
          limitExceeded: action.payload[2]?.subscriptionLimit
        };

      return {
        ...initialState,
        shouldUpdateData: state.shouldUpdateData,
        pageURLGraphData: state.pageURLGraphData,
        pageUrlData: state.pageUrlData,
        isLoading: false,
        allData: modifyData([...action.payload[1]]),
        graphSepData: {
          isLoading: false,
          data: modifyData([...action.payload[1]])
        },
        limitExceeded: action.payload[2]?.subscriptionLimit
      };
    },
    [getCompareDataItems.rejected]: (state) => {
      return { ...state, isLoading: false, graphSepData: { isLoading: false } };
    },
    [getCompareAnalyticsPages.pending]: (state) => {
      return { ...state, pageURLGraphData: { isLoading: true } };
    },
    [getCompareAnalyticsPages.fulfilled]: (state, action) => {
      return {
        ...state,
        pageURLGraphData: { isLoading: false, data: [...action.payload[1]] }
      };
    },
    [getCompareAnalyticsPages.rejected]: (state) => {
      return { ...state, pageURLGraphData: { isLoading: false } };
    }
  }
});

export const {
  updateShouldUpdateData,
  updateDevicesData,
  updateBrowsersData,
  updateCountriesData,
  updatePageUrlData,
  updatePersonaData,
  updateViewsVisitorsData,
  updateWorldData,
  updateAgeGroupData,
  updateFilteredData,
  updateComponentLoading,
  updateFiltersList,
  updateInsights,
  updateIsLoading,
  updateIsCompare,
  updateSourceData,
  updateMediumData,
  updateCampaignData,
  updateTermData,
  updateContentData
} = dataSlice.actions;

export const {
  updateCompareShouldUpdateData,
  updateCompareAgeGroupData,
  updateCompareBrowsersData,
  updateCompareCountriesData,
  updateCompareDateRange,
  updateCompareFilteredData,
  updateCompareDevicesData,
  updateCompareIsLoading,
  updateComparePageUrlData,
  updateComparePersonaData,
  updateCompareViewsVisitorsData,
  updateCompareWorldData,
  updateCompareInsights,
  updateCompareSourceData,
  updateCompareMediumData,
  updateCompareCampaignData,
  updateCompareTermData,
  updateCompareContentData
} = dataToCompareSlice.actions;

export default { dataSlice, dataToCompareSlice };
