import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import ComingSoon from "../../features/Placeholders/ComingSoon";

export default function WidgetPerformance() {
  return (
    <div>
      <AuthorizedHeader />
      <div className="container">
        <ComingSoon />
      </div>
    </div>
  );
}
