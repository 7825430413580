/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/inputElements/Input/Input";
import PasswordInput from "../../../../components/inputElements/Input/Password";
import LoginImage from "../../../../assets/images/auth2.svg";
import "../assets/login.scss";
import MainForm from "./MainForm";

export default function LoginForm({
  rememberMe,
  setRememberMe,
  setEmail,
  setPassword,
  handleSubmit,
  loading,
  emailError,
  passwordError,
  email,
  password
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="login-section">
      <div
        style={isMobile ? { width: "1296px" } : { maxWidth: "1296px" }}
        className="container-2"
      >
        <div className="flex">
          <div className="login-image">
            <img
              style={isMobile ? { height: "72rem" } : { height: "57.25rem" }}
              src={LoginImage}
              alt="svg"
            />
          </div>
          <div
            style={!isMobile ? { height: "57.25rem" } : {}}
            className="login-form"
          >
            <div className="flex-column">
              <div className="login-top">
                <h2 className="modules-heading heading-bottom">
                  {t("common.logInAccount")}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="email-input">
                    <Input
                      type="text"
                      label={t("common.emailField")}
                      placeholder="Brucewayne.batman@email.com"
                      value={email}
                      onChange={setEmail}
                      error={emailError}
                    />
                  </div>
                  <div className="password-input">
                    <PasswordInput
                      placeholder="**************"
                      label={t("common.password")}
                      value={password}
                      onChange={setPassword}
                      error={passwordError}
                    />
                  </div>
                  <div className="flex-between checkbox">
                    <div
                      className="flex inner-checkbox"
                      onClick={() => setRememberMe(!rememberMe)}
                    >
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        name="remember"
                        id="remember"
                      />
                      <p>{t("login.rememberMe")}</p>
                    </div>
                    <p onClick={() => navigate("/forgot-password")}>
                      {t("login.forgetYourPassword")}
                    </p>
                  </div>
                  <button
                    type="submit"
                    className={loading ? "login-loader" : ""}
                  >
                    {loading ? (
                      <div className="flex-center">
                        <div className="login-text-padding">
                          {t("common.loggingIn")}
                        </div>
                        <div className="flex-start">
                          <div className="dotted-progress-container-login">
                            <div className="dotted-progress-login" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      t("common.login")
                    )}
                  </button>
                </form>
                <div className="signup-redirect">
                  <p>
                    {t("login.dontHaveAnAccount")}{" "}
                    <span onClick={() => navigate("/signup")}>
                      {t("login.clickRegister")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="login-bottom">
                {/*  */}
                <MainForm login />
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  setRememberMe: PropTypes.func,
  rememberMe: PropTypes.bool,
  setEmail: PropTypes.func,
  setPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  emailError: PropTypes.string,
  passwordError: PropTypes.string,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired
};
