/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import campaignImage from "../assets/icons/campaignimage.png"; // Replace with your actual image path
import "../assets/stylesheets/campaignTile.scss";

export default function CampaignTile({ selectedCampaign }) {
  const { t } = useTranslation();

  const adTypes =
    selectedCampaign?.type?.length > 1
      ? selectedCampaign?.type?.join(", ")
      : selectedCampaign?.type[0];
  const personaTypes =
    selectedCampaign?.persona?.length > 1
      ? selectedCampaign?.persona?.join(", ")
      : selectedCampaign?.persona[0];

  return (
    <div className="campaign-tile">
      <div className="left-rectangle" />
      <div className="campaign-details">
        <p className="campaign-details-text">
          {t("campaignPerformance.campaignDetails")}
        </p>
        <div className="campaign-info">
          <div className="info-item">
            <p className="info-item-key">{t("manageCampaign.channel")}</p>
            <p className="info-item-value">{selectedCampaign?.channel_name}</p>
          </div>
          <div className="info-item">
            <p className="info-item-key">{t("PMSPage.type")}</p>
            <p className="info-item-value">{adTypes}</p>
          </div>
          <div className="info-item">
            <p className="info-item-key">{t("analyticsPage.personaTitle")}</p>
            <p className="info-item-value">
              {t("analyticsPage.personaTitle")} {personaTypes}
            </p>
          </div>
          <div className="info-item">
            <p className="info-item-key">{t("billing.headerName.date")}</p>
            <p className="info-item-value">{selectedCampaign?.created_date}</p>
          </div>
        </div>
      </div>
      <img src={campaignImage} alt="Campaign" className="campaign-image" />
      <div className="right-rectangle" />
    </div>
  );
}
