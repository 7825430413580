/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import MultiBarChart from "../../../../components/ChartComponents/MultiBarChart";

export default function MultiBarChartComponent({
  visitorsLineData,
  commonProps,
  t
}) {
  return (
    <MultiBarChart
      title={t("PMSPage.lineChartTitle")}
      filterTitle={t("common.date")}
      labels={visitorsLineData?.labels}
      data1={{
        dataset: visitorsLineData?.requests,
        label: t("PMSPage.requests")
      }}
      data2={{
        dataset: visitorsLineData?.bookings,
        label: t("PMSPage.bookings")
      }}
      data3={{
        dataset: visitorsLineData?.revenue,
        label: t("PMSPage.revenue")
      }}
      multiBarChart
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

MultiBarChartComponent.propTypes = {
  visitorsLineData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    requests: PropTypes.arrayOf(PropTypes.number),
    bookings: PropTypes.arrayOf(PropTypes.number),
    revenue: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired
};
