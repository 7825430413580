export const countryISO = {
  AD: {
    latitude: 42.546245,
    longitude: 1.601554,
    name: "Andorra"
  },
  AE: {
    latitude: 23.424076,
    longitude: 53.847818,
    name: "United Arab Emirates"
  },
  AF: {
    latitude: 33.93911,
    longitude: 67.709953,
    name: "Afghanistan"
  },
  AG: {
    latitude: 17.060816,
    longitude: -61.796428,
    name: "Antigua and Barbuda"
  },
  AI: {
    latitude: 18.220554,
    longitude: -63.068615,
    name: "Anguilla"
  },
  AL: {
    latitude: 41.153332,
    longitude: 20.168331,
    name: "Albania"
  },
  AM: {
    latitude: 40.069099,
    longitude: 45.038189,
    name: "Armenia"
  },
  AN: {
    latitude: 12.226079,
    longitude: -69.060087,
    name: "Netherlands Antilles"
  },
  AO: {
    latitude: -11.202692,
    longitude: 17.873887,
    name: "Angola"
  },
  AQ: {
    latitude: -75.250973,
    longitude: -0.071389,
    name: "Antarctica"
  },
  AR: {
    latitude: -38.416097,
    longitude: -63.616672,
    name: "Argentina"
  },
  AS: {
    latitude: -14.270972,
    longitude: -170.132217,
    name: "American Samoa"
  },
  AT: {
    latitude: 47.516231,
    longitude: 14.550072,
    name: "Austria"
  },
  AU: {
    latitude: -25.274398,
    longitude: 133.775136,
    name: "Australia"
  },
  AW: {
    latitude: 12.52111,
    longitude: -69.968338,
    name: "Aruba"
  },
  AZ: {
    latitude: 40.143105,
    longitude: 47.576927,
    name: "Azerbaijan"
  },
  BA: {
    latitude: 43.915886,
    longitude: 17.679076,
    name: "Bosnia and Herzegovina"
  },
  BB: {
    latitude: 13.193887,
    longitude: -59.543198,
    name: "Barbados"
  },
  BD: {
    latitude: 23.684994,
    longitude: 90.356331,
    name: "Bangladesh"
  },
  BE: {
    latitude: 50.503887,
    longitude: 4.469936,
    name: "Belgium"
  },
  BF: {
    latitude: 12.238333,
    longitude: -1.561593,
    name: "Burkina Faso"
  },
  BG: {
    latitude: 42.733883,
    longitude: 25.48583,
    name: "Bulgaria"
  },
  BH: {
    latitude: 25.930414,
    longitude: 50.637772,
    name: "Bahrain"
  },
  BI: {
    latitude: -3.373056,
    longitude: 29.918886,
    name: "Burundi"
  },
  BJ: {
    latitude: 9.30769,
    longitude: 2.315834,
    name: "Benin"
  },
  BM: {
    latitude: 32.321384,
    longitude: -64.75737,
    name: "Bermuda"
  },
  BN: {
    latitude: 4.535277,
    longitude: 114.727669,
    name: "Brunei"
  },
  BO: {
    latitude: -16.290154,
    longitude: -63.588653,
    name: "Bolivia"
  },
  BR: {
    latitude: -14.235004,
    longitude: -51.92528,
    name: "Brazil"
  },
  BS: {
    latitude: 25.03428,
    longitude: -77.39628,
    name: "Bahamas"
  },
  BT: {
    latitude: 27.514162,
    longitude: 90.433601,
    name: "Bhutan"
  },
  BV: {
    latitude: -54.423199,
    longitude: 3.413194,
    name: "Bouvet Island"
  },
  BW: {
    latitude: -22.328474,
    longitude: 24.684866,
    name: "Botswana"
  },
  BY: {
    latitude: 53.709807,
    longitude: 27.953389,
    name: "Belarus"
  },
  BZ: {
    latitude: 17.189877,
    longitude: -88.49765,
    name: "Belize"
  },
  CA: {
    latitude: 56.130366,
    longitude: -106.346771,
    name: "Canada"
  },
  CC: {
    latitude: -12.164165,
    longitude: 96.870956,
    name: "Cocos [Keeling] Islands"
  },
  CD: {
    latitude: -4.038333,
    longitude: 21.758664,
    name: "Congo [DRC]"
  },
  CF: {
    latitude: 6.611111,
    longitude: 20.939444,
    name: "Central African Republic"
  },
  CG: {
    latitude: -0.228021,
    longitude: 15.827659,
    name: "Congo [Republic]"
  },
  CH: {
    latitude: 46.818188,
    longitude: 8.227512,
    name: "Switzerland"
  },
  CI: {
    latitude: 7.539989,
    longitude: -5.54708,
    name: "Côte d'Ivoire"
  },
  CK: {
    latitude: -21.236736,
    longitude: -159.777671,
    name: "Cook Islands"
  },
  CL: {
    latitude: -35.675147,
    longitude: -71.542969,
    name: "Chile"
  },
  CM: {
    latitude: 7.369722,
    longitude: 12.354722,
    name: "Cameroon"
  },
  CN: {
    latitude: 35.86166,
    longitude: 104.195397,
    name: "China"
  },
  CO: {
    latitude: 4.570868,
    longitude: -74.297333,
    name: "Colombia"
  },
  CR: {
    latitude: 9.748917,
    longitude: -83.753428,
    name: "Costa Rica"
  },
  CU: {
    latitude: 21.521757,
    longitude: -77.781167,
    name: "Cuba"
  },
  CV: {
    latitude: 16.002082,
    longitude: -24.013197,
    name: "Cape Verde"
  },
  CX: {
    latitude: -10.447525,
    longitude: 105.690449,
    name: "Christmas Island"
  },
  CY: {
    latitude: 35.126413,
    longitude: 33.429859,
    name: "Cyprus"
  },
  CZ: {
    latitude: 49.817492,
    longitude: 15.472962,
    name: "Czech Republic"
  },
  DE: {
    latitude: 51.165691,
    longitude: 10.451526,
    name: "Germany"
  },
  DJ: {
    latitude: 11.825138,
    longitude: 42.590275,
    name: "Djibouti"
  },
  DK: {
    latitude: 56.26392,
    longitude: 9.501785,
    name: "Denmark"
  },
  DM: {
    latitude: 15.414999,
    longitude: -61.370976,
    name: "Dominica"
  },
  DO: {
    latitude: 18.735693,
    longitude: -70.162651,
    name: "Dominican Republic"
  },
  DZ: {
    latitude: 28.033886,
    longitude: 1.659626,
    name: "Algeria"
  },
  EC: {
    latitude: -1.831239,
    longitude: -78.183406,
    name: "Ecuador"
  },
  EE: {
    latitude: 58.595272,
    longitude: 25.013607,
    name: "Estonia"
  },
  EG: {
    latitude: 26.820553,
    longitude: 30.802498,
    name: "Egypt"
  },
  EH: {
    latitude: 24.215527,
    longitude: -12.885834,
    name: "Western Sahara"
  },
  ER: {
    latitude: 15.179384,
    longitude: 39.782334,
    name: "Eritrea"
  },
  ES: {
    latitude: 40.463667,
    longitude: -3.74922,
    name: "Spain"
  },
  ET: {
    latitude: 9.145,
    longitude: 40.489673,
    name: "Ethiopia"
  },
  FI: {
    latitude: 61.92411,
    longitude: 25.748151,
    name: "Finland"
  },
  FJ: {
    latitude: -16.578193,
    longitude: 179.414413,
    name: "Fiji"
  },
  FK: {
    latitude: -51.796253,
    longitude: -59.523613,
    name: "Falkland Islands [Islas Malvinas]"
  },
  FM: {
    latitude: 7.425554,
    longitude: 150.550812,
    name: "Micronesia"
  },
  FO: {
    latitude: 61.892635,
    longitude: -6.911806,
    name: "Faroe Islands"
  },
  FR: {
    latitude: 46.227638,
    longitude: 2.213749,
    name: "France"
  },
  GA: {
    latitude: -0.803689,
    longitude: 11.609444,
    name: "Gabon"
  },
  GB: {
    latitude: 55.378051,
    longitude: -3.435973,
    name: "United Kingdom"
  },
  GD: {
    latitude: 12.262776,
    longitude: -61.604171,
    name: "Grenada"
  },
  GE: {
    latitude: 42.315407,
    longitude: 43.356892,
    name: "Georgia"
  },
  GF: {
    latitude: 3.933889,
    longitude: -53.125782,
    name: "French Guiana"
  },
  GG: {
    latitude: 49.465691,
    longitude: -2.585278,
    name: "Guernsey"
  },
  GH: {
    latitude: 7.946527,
    longitude: -1.023194,
    name: "Ghana"
  },
  GI: {
    latitude: 36.137741,
    longitude: -5.345374,
    name: "Gibraltar"
  },
  GL: {
    latitude: 71.706936,
    longitude: -42.604303,
    name: "Greenland"
  },
  GM: {
    latitude: 13.443182,
    longitude: -15.310139,
    name: "Gambia"
  },
  GN: {
    latitude: 9.945587,
    longitude: -9.696645,
    name: "Guinea"
  },
  GP: {
    latitude: 16.995971,
    longitude: -62.067641,
    name: "Guadeloupe"
  },
  GQ: {
    latitude: 1.650801,
    longitude: 10.267895,
    name: "Equatorial Guinea"
  },
  GR: {
    latitude: 39.074208,
    longitude: 21.824312,
    name: "Greece"
  },
  GS: {
    latitude: -54.429579,
    longitude: -36.587909,
    name: "South Georgia and the South Sandwich Islands"
  },
  GT: {
    latitude: 15.783471,
    longitude: -90.230759,
    name: "Guatemala"
  },
  GU: {
    latitude: 13.444304,
    longitude: 144.793731,
    name: "Guam"
  },
  GW: {
    latitude: 11.803749,
    longitude: -15.180413,
    name: "Guinea-Bissau"
  },
  GY: {
    latitude: 4.860416,
    longitude: -58.93018,
    name: "Guyana"
  },
  GZ: {
    latitude: 31.354676,
    longitude: 34.308825,
    name: "Gaza Strip"
  },
  HK: {
    latitude: 22.396428,
    longitude: 114.109497,
    name: "Hong Kong"
  },
  HM: {
    latitude: -53.08181,
    longitude: 73.504158,
    name: "Heard Island and McDonald Islands"
  },
  HN: {
    latitude: 15.199999,
    longitude: -86.241905,
    name: "Honduras"
  },
  HR: {
    latitude: 45.1,
    longitude: 15.2,
    name: "Croatia"
  },
  HT: {
    latitude: 18.971187,
    longitude: -72.285215,
    name: "Haiti"
  },
  HU: {
    latitude: 47.162494,
    longitude: 19.503304,
    name: "Hungary"
  },
  ID: {
    latitude: -0.789275,
    longitude: 113.921327,
    name: "Indonesia"
  },
  IE: {
    latitude: 53.41291,
    longitude: -8.24389,
    name: "Ireland"
  },
  IL: {
    latitude: 31.046051,
    longitude: 34.851612,
    name: "Israel"
  },
  IM: {
    latitude: 54.236107,
    longitude: -4.548056,
    name: "Isle of Man"
  },
  IN: {
    latitude: 20.593684,
    longitude: 78.96288,
    name: "India"
  },
  IO: {
    latitude: -6.343194,
    longitude: 71.876519,
    name: "British Indian Ocean Territory"
  },
  IQ: {
    latitude: 33.223191,
    longitude: 43.679291,
    name: "Iraq"
  },
  IR: {
    latitude: 32.427908,
    longitude: 53.688046,
    name: "Iran"
  },
  IS: {
    latitude: 64.963051,
    longitude: -19.020835,
    name: "Iceland"
  },
  IT: {
    latitude: 41.87194,
    longitude: 12.56738,
    name: "Italy"
  },
  JE: {
    latitude: 49.214439,
    longitude: -2.13125,
    name: "Jersey"
  },
  JM: {
    latitude: 18.109581,
    longitude: -77.297508,
    name: "Jamaica"
  },
  JO: {
    latitude: 30.585164,
    longitude: 36.238414,
    name: "Jordan"
  },
  JP: {
    latitude: 36.204824,
    longitude: 138.252924,
    name: "Japan"
  },
  KE: {
    latitude: -0.023559,
    longitude: 37.906193,
    name: "Kenya"
  },
  KG: {
    latitude: 41.20438,
    longitude: 74.766098,
    name: "Kyrgyzstan"
  },
  KH: {
    latitude: 12.565679,
    longitude: 104.990963,
    name: "Cambodia"
  },
  KI: {
    latitude: -3.370417,
    longitude: -168.734039,
    name: "Kiribati"
  },
  KM: {
    latitude: -11.875001,
    longitude: 43.872219,
    name: "Comoros"
  },
  KN: {
    latitude: 17.357822,
    longitude: -62.782998,
    name: "Saint Kitts and Nevis"
  },
  KP: {
    latitude: 40.339852,
    longitude: 127.510093,
    name: "North Korea"
  },
  KR: {
    latitude: 35.907757,
    longitude: 127.766922,
    name: "South Korea"
  },
  KW: {
    latitude: 29.31166,
    longitude: 47.481766,
    name: "Kuwait"
  },
  KY: {
    latitude: 19.513469,
    longitude: -80.566956,
    name: "Cayman Islands"
  },
  KZ: {
    latitude: 48.019573,
    longitude: 66.923684,
    name: "Kazakhstan"
  },
  LA: {
    latitude: 19.85627,
    longitude: 102.495496,
    name: "Laos"
  },
  LB: {
    latitude: 33.854721,
    longitude: 35.862285,
    name: "Lebanon"
  },
  LC: {
    latitude: 13.909444,
    longitude: -60.978893,
    name: "Saint Lucia"
  },
  LI: {
    latitude: 47.166,
    longitude: 9.555373,
    name: "Liechtenstein"
  },
  LK: {
    latitude: 7.873054,
    longitude: 80.771797,
    name: "Sri Lanka"
  },
  LR: {
    latitude: 6.428055,
    longitude: -9.429499,
    name: "Liberia"
  },
  LS: {
    latitude: -29.609988,
    longitude: 28.233608,
    name: "Lesotho"
  },
  LT: {
    latitude: 55.169438,
    longitude: 23.881275,
    name: "Lithuania"
  },
  LU: {
    latitude: 49.815273,
    longitude: 6.129583,
    name: "Luxembourg"
  },
  LV: {
    latitude: 56.879635,
    longitude: 24.603189,
    name: "Latvia"
  },
  LY: {
    latitude: 26.3351,
    longitude: 17.228331,
    name: "Libya"
  },
  MA: {
    latitude: 31.791702,
    longitude: -7.09262,
    name: "Morocco"
  },
  MC: {
    latitude: 43.750298,
    longitude: 7.412841,
    name: "Monaco"
  },
  MD: {
    latitude: 47.411631,
    longitude: 28.369885,
    name: "Moldova"
  },
  ME: {
    latitude: 42.708678,
    longitude: 19.37439,
    name: "Montenegro"
  },
  MG: {
    latitude: -18.766947,
    longitude: 46.869107,
    name: "Madagascar"
  },
  MH: {
    latitude: 7.131474,
    longitude: 171.184478,
    name: "Marshall Islands"
  },
  MK: {
    latitude: 41.608635,
    longitude: 21.745275,
    name: "Macedonia [FYROM]"
  },
  ML: {
    latitude: 17.570692,
    longitude: -3.996166,
    name: "Mali"
  },
  MM: {
    latitude: 21.913965,
    longitude: 95.956223,
    name: "Myanmar [Burma]"
  },
  MN: {
    latitude: 46.862496,
    longitude: 103.846656,
    name: "Mongolia"
  },
  MO: {
    latitude: 22.198745,
    longitude: 113.543873,
    name: "Macau"
  },
  MP: {
    latitude: 17.33083,
    longitude: 145.38469,
    name: "Northern Mariana Islands"
  },
  MQ: {
    latitude: 14.641528,
    longitude: -61.024174,
    name: "Martinique"
  },
  MR: {
    latitude: 21.00789,
    longitude: -10.940835,
    name: "Mauritania"
  },
  MS: {
    latitude: 16.742498,
    longitude: -62.187366,
    name: "Montserrat"
  },
  MT: {
    latitude: 35.937496,
    longitude: 14.375416,
    name: "Malta"
  },
  MU: {
    latitude: -20.348404,
    longitude: 57.552152,
    name: "Mauritius"
  },
  MV: {
    latitude: 3.202778,
    longitude: 73.22068,
    name: "Maldives"
  },
  MW: {
    latitude: -13.254308,
    longitude: 34.301525,
    name: "Malawi"
  },
  MX: {
    latitude: 23.634501,
    longitude: -102.552784,
    name: "Mexico"
  },
  MY: {
    latitude: 4.210484,
    longitude: 101.975766,
    name: "Malaysia"
  },
  MZ: {
    latitude: -18.665695,
    longitude: 35.529562,
    name: "Mozambique"
  },
  NA: {
    latitude: -22.95764,
    longitude: 18.49041,
    name: "Namibia"
  },
  NC: {
    latitude: -20.904305,
    longitude: 165.618042,
    name: "New Caledonia"
  },
  NE: {
    latitude: 17.607789,
    longitude: 8.081666,
    name: "Niger"
  },
  NF: {
    latitude: -29.040835,
    longitude: 167.954712,
    name: "Norfolk Island"
  },
  NG: {
    latitude: 9.081999,
    longitude: 8.675277,
    name: "Nigeria"
  },
  NI: {
    latitude: 12.865416,
    longitude: -85.207229,
    name: "Nicaragua"
  },
  NL: {
    latitude: 52.132633,
    longitude: 5.291266,
    name: "Netherlands"
  },
  NO: {
    latitude: 60.472024,
    longitude: 8.468946,
    name: "Norway"
  },
  NP: {
    latitude: 28.394857,
    longitude: 84.124008,
    name: "Nepal"
  },
  NR: {
    latitude: -0.522778,
    longitude: 166.931503,
    name: "Nauru"
  },
  NU: {
    latitude: -19.054445,
    longitude: -169.867233,
    name: "Niue"
  },
  NZ: {
    latitude: -40.900557,
    longitude: 174.885971,
    name: "New Zealand"
  },
  OM: {
    latitude: 21.512583,
    longitude: 55.923255,
    name: "Oman"
  },
  PA: {
    latitude: 8.537981,
    longitude: -80.782127,
    name: "Panama"
  },
  PE: {
    latitude: -9.189967,
    longitude: -75.015152,
    name: "Peru"
  },
  PF: {
    latitude: -17.679742,
    longitude: -149.406843,
    name: "French Polynesia"
  },
  PG: {
    latitude: -6.314993,
    longitude: 143.95555,
    name: "Papua New Guinea"
  },
  PH: {
    latitude: 12.879721,
    longitude: 121.774017,
    name: "Philippines"
  },
  PK: {
    latitude: 30.375321,
    longitude: 69.345116,
    name: "Pakistan"
  },
  PL: {
    latitude: 51.919438,
    longitude: 19.145136,
    name: "Poland"
  },
  PM: {
    latitude: 46.941936,
    longitude: -56.27111,
    name: "Saint Pierre and Miquelon"
  },
  PN: {
    latitude: -24.703615,
    longitude: -127.439308,
    name: "Pitcairn Islands"
  },
  PR: {
    latitude: 18.220833,
    longitude: -66.590149,
    name: "Puerto Rico"
  },
  PS: {
    latitude: 31.952162,
    longitude: 35.233154,
    name: "Palestinian Territories"
  },
  PT: {
    latitude: 39.399872,
    longitude: -8.224454,
    name: "Portugal"
  },
  PW: {
    latitude: 7.51498,
    longitude: 134.58252,
    name: "Palau"
  },
  PY: {
    latitude: -23.442503,
    longitude: -58.443832,
    name: "Paraguay"
  },
  QA: {
    latitude: 25.354826,
    longitude: 51.183884,
    name: "Qatar"
  },
  RE: {
    latitude: -21.115141,
    longitude: 55.536384,
    name: "Réunion"
  },
  RO: {
    latitude: 45.943161,
    longitude: 24.96676,
    name: "Romania"
  },
  RS: {
    latitude: 44.016521,
    longitude: 21.005859,
    name: "Serbia"
  },
  RU: {
    latitude: 61.52401,
    longitude: 105.318756,
    name: "Russia"
  },
  RW: {
    latitude: -1.940278,
    longitude: 29.873888,
    name: "Rwanda"
  },
  SA: {
    latitude: 23.885942,
    longitude: 45.079162,
    name: "Saudi Arabia"
  },
  SB: {
    latitude: -9.64571,
    longitude: 160.156194,
    name: "Solomon Islands"
  },
  SC: {
    latitude: -4.679574,
    longitude: 55.491977,
    name: "Seychelles"
  },
  SD: {
    latitude: 12.862807,
    longitude: 30.217636,
    name: "Sudan"
  },
  SE: {
    latitude: 60.128161,
    longitude: 18.643501,
    name: "Sweden"
  },
  SG: {
    latitude: 1.352083,
    longitude: 103.819836,
    name: "Singapore"
  },
  SH: {
    latitude: -24.143474,
    longitude: -10.030696,
    name: "Saint Helena"
  },
  SI: {
    latitude: 46.151241,
    longitude: 14.995463,
    name: "Slovenia"
  },
  SJ: {
    latitude: 77.553604,
    longitude: 23.670272,
    name: "Svalbard and Jan Mayen"
  },
  SK: {
    latitude: 48.669026,
    longitude: 19.699024,
    name: "Slovakia"
  },
  SL: {
    latitude: 8.460555,
    longitude: -11.779889,
    name: "Sierra Leone"
  },
  SM: {
    latitude: 43.94236,
    longitude: 12.457777,
    name: "San Marino"
  },
  SN: {
    latitude: 14.497401,
    longitude: -14.452362,
    name: "Senegal"
  },
  SO: {
    latitude: 5.152149,
    longitude: 46.199616,
    name: "Somalia"
  },
  SR: {
    latitude: 3.919305,
    longitude: -56.027783,
    name: "Suriname"
  },
  ST: {
    latitude: 0.18636,
    longitude: 6.613081,
    name: "São Tomé and Príncipe"
  },
  SV: {
    latitude: 13.794185,
    longitude: -88.89653,
    name: "El Salvador"
  },
  SY: {
    latitude: 34.802075,
    longitude: 38.996815,
    name: "Syria"
  },
  SZ: {
    latitude: -26.522503,
    longitude: 31.465866,
    name: "Swaziland"
  },
  TC: {
    latitude: 21.694025,
    longitude: -71.797928,
    name: "Turks and Caicos Islands"
  },
  TD: {
    latitude: 15.454166,
    longitude: 18.732207,
    name: "Chad"
  },
  TF: {
    latitude: -49.280366,
    longitude: 69.348557,
    name: "French Southern Territories"
  },
  TG: {
    latitude: 8.619543,
    longitude: 0.824782,
    name: "Togo"
  },
  TH: {
    latitude: 15.870032,
    longitude: 100.992541,
    name: "Thailand"
  },
  TJ: {
    latitude: 38.861034,
    longitude: 71.276093,
    name: "Tajikistan"
  },
  TK: {
    latitude: -8.967363,
    longitude: -171.855881,
    name: "Tokelau"
  },
  TL: {
    latitude: -8.874217,
    longitude: 125.727539,
    name: "Timor-Leste"
  },
  TM: {
    latitude: 38.969719,
    longitude: 59.556278,
    name: "Turkmenistan"
  },
  TN: {
    latitude: 33.886917,
    longitude: 9.537499,
    name: "Tunisia"
  },
  TO: {
    latitude: -21.178986,
    longitude: -175.198242,
    name: "Tonga"
  },
  TR: {
    latitude: 38.963745,
    longitude: 35.243322,
    name: "Turkey"
  },
  TT: {
    latitude: 10.691803,
    longitude: -61.222503,
    name: "Trinidad and Tobago"
  },
  TV: {
    latitude: -7.109535,
    longitude: 177.64933,
    name: "Tuvalu"
  },
  TW: {
    latitude: 23.69781,
    longitude: 120.960515,
    name: "Taiwan"
  },
  TZ: {
    latitude: -6.369028,
    longitude: 34.888822,
    name: "Tanzania"
  },
  UA: {
    latitude: 48.379433,
    longitude: 31.16558,
    name: "Ukraine"
  },
  UG: {
    latitude: 1.373333,
    longitude: 32.290275,
    name: "Uganda"
  },
  UM: {
    latitude: "",
    longitude: "",
    name: "U.S. Minor Outlying Islands"
  },
  US: {
    latitude: 37.09024,
    longitude: -95.712891,
    name: "United States"
  },
  UY: {
    latitude: -32.522779,
    longitude: -55.765835,
    name: "Uruguay"
  },
  UZ: {
    latitude: 41.377491,
    longitude: 64.585262,
    name: "Uzbekistan"
  },
  VA: {
    latitude: 41.902916,
    longitude: 12.453389,
    name: "Vatican City"
  },
  VC: {
    latitude: 12.984305,
    longitude: -61.287228,
    name: "Saint Vincent and the Grenadines"
  },
  VE: {
    latitude: 6.42375,
    longitude: -66.58973,
    name: "Venezuela"
  },
  VG: {
    latitude: 18.420695,
    longitude: -64.639968,
    name: "British Virgin Islands"
  },
  VI: {
    latitude: 18.335765,
    longitude: -64.896335,
    name: "U.S. Virgin Islands"
  },
  VN: {
    latitude: 14.058324,
    longitude: 108.277199,
    name: "Vietnam"
  },
  VU: {
    latitude: -15.376706,
    longitude: 166.959158,
    name: "Vanuatu"
  },
  WF: {
    latitude: -13.768752,
    longitude: -177.156097,
    name: "Wallis and Futuna"
  },
  WS: {
    latitude: -13.759029,
    longitude: -172.104629,
    name: "Samoa"
  },
  XK: {
    latitude: 42.602636,
    longitude: 20.902977,
    name: "Kosovo"
  },
  YE: {
    latitude: 15.552727,
    longitude: 48.516388,
    name: "Yemen"
  },
  YT: {
    latitude: -12.8275,
    longitude: 45.166244,
    name: "Mayotte"
  },
  ZA: {
    latitude: -30.559482,
    longitude: 22.937506,
    name: "South Africa"
  },
  ZM: {
    latitude: -13.133897,
    longitude: 27.849332,
    name: "Zambia"
  },
  ZW: {
    latitude: -19.015438,
    longitude: 29.154857,
    name: "Zimbabwe"
  }
};

// All countries data with Alpha2, Alpha3, numeric code, name
export const countriesData = [
  {
    country: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    numeric: 8,
    latitude: 41,
    longitude: 20
  },
  {
    country: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    numeric: 12,
    latitude: 28,
    longitude: 3
  },
  {
    country: "American Samoa",
    alpha2: "AS",
    alpha3: "ASM",
    numeric: 16,
    latitude: -14.3333,
    longitude: -170
  },
  {
    country: "Andorra",
    alpha2: "AD",
    alpha3: "AND",
    numeric: 20,
    latitude: 42.5,
    longitude: 1.6
  },
  {
    country: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    numeric: 24,
    latitude: -12.5,
    longitude: 18.5
  },
  {
    country: "Anguilla",
    alpha2: "AI",
    alpha3: "AIA",
    numeric: 660,
    latitude: 18.25,
    longitude: -63.1667
  },
  {
    country: "Antarctica",
    alpha2: "AQ",
    alpha3: "ATA",
    numeric: 10,
    latitude: -90,
    longitude: 0
  },
  {
    country: "Antigua and Barbuda",
    alpha2: "AG",
    alpha3: "ATG",
    numeric: 28,
    latitude: 17.05,
    longitude: -61.8
  },
  {
    country: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    numeric: 32,
    latitude: -34,
    longitude: -64
  },
  {
    country: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    numeric: 51,
    latitude: 40,
    longitude: 45
  },
  {
    country: "Aruba",
    alpha2: "AW",
    alpha3: "ABW",
    numeric: 533,
    latitude: 12.5,
    longitude: -69.9667
  },
  {
    country: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    numeric: 36,
    latitude: -27,
    longitude: 133
  },
  {
    country: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    numeric: 40,
    latitude: 47.3333,
    longitude: 13.3333
  },
  {
    country: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    numeric: 31,
    latitude: 40.5,
    longitude: 47.5
  },
  {
    country: "Bahamas",
    alpha2: "BS",
    alpha3: "BHS",
    numeric: 44,
    latitude: 24.25,
    longitude: -76
  },
  {
    country: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    numeric: 48,
    latitude: 26,
    longitude: 50.55
  },
  {
    country: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    numeric: 50,
    latitude: 24,
    longitude: 90
  },
  {
    country: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    numeric: 52,
    latitude: 13.1667,
    longitude: -59.5333
  },
  {
    country: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    numeric: 112,
    latitude: 53,
    longitude: 28
  },
  {
    country: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    numeric: 56,
    latitude: 50.8333,
    longitude: 4
  },
  {
    country: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    numeric: 84,
    latitude: 17.25,
    longitude: -88.75
  },
  {
    country: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    numeric: 204,
    latitude: 9.5,
    longitude: 2.25
  },
  {
    country: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    numeric: 60,
    latitude: 32.3333,
    longitude: -64.75
  },
  {
    country: "Bhutan",
    alpha2: "BT",
    alpha3: "BTN",
    numeric: 64,
    latitude: 27.5,
    longitude: 90.5
  },
  {
    country: "Bolivia, Plurinational State of",
    alpha2: "BO",
    alpha3: "BOL",
    numeric: 68,
    latitude: -17,
    longitude: -65
  },
  {
    country: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    numeric: 70,
    latitude: 44,
    longitude: 18
  },
  {
    country: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    numeric: 72,
    latitude: -22,
    longitude: 24
  },
  {
    country: "Bouvet Island",
    alpha2: "BV",
    alpha3: "BVT",
    numeric: 74,
    latitude: -54.4333,
    longitude: 3.4
  },
  {
    country: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    numeric: 76,
    latitude: -10,
    longitude: -55
  },
  {
    country: "British Indian Ocean Territory",
    alpha2: "IO",
    alpha3: "IOT",
    numeric: 86,
    latitude: -6,
    longitude: 71.5
  },
  {
    country: "Brunei Darussalam",
    alpha2: "BN",
    alpha3: "BRN",
    numeric: 96,
    latitude: 4.5,
    longitude: 114.6667
  },
  {
    country: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    numeric: 100,
    latitude: 43,
    longitude: 25
  },
  {
    country: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    numeric: 854,
    latitude: 13,
    longitude: -2
  },
  {
    country: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    numeric: 108,
    latitude: -3.5,
    longitude: 30
  },
  {
    country: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    numeric: 116,
    latitude: 13,
    longitude: 105
  },
  {
    country: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    numeric: 120,
    latitude: 6,
    longitude: 12
  },
  {
    country: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    numeric: 124,
    latitude: 60,
    longitude: -95
  },
  {
    country: "Cape Verde",
    alpha2: "CV",
    alpha3: "CPV",
    numeric: 132,
    latitude: 16,
    longitude: -24
  },
  {
    country: "Cayman Islands",
    alpha2: "KY",
    alpha3: "CYM",
    numeric: 136,
    latitude: 19.5,
    longitude: -80.5
  },
  {
    country: "Central African Republic",
    alpha2: "CF",
    alpha3: "CAF",
    numeric: 140,
    latitude: 7,
    longitude: 21
  },
  {
    country: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    numeric: 148,
    latitude: 15,
    longitude: 19
  },
  {
    country: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    numeric: 152,
    latitude: -30,
    longitude: -71
  },
  {
    country: "China",
    alpha2: "CN",
    alpha3: "CHN",
    numeric: 156,
    latitude: 35,
    longitude: 105
  },
  {
    country: "Christmas Island",
    alpha2: "CX",
    alpha3: "CXR",
    numeric: 162,
    latitude: -10.5,
    longitude: 105.6667
  },
  {
    country: "Cocos (Keeling) Islands",
    alpha2: "CC",
    alpha3: "CCK",
    numeric: 166,
    latitude: -12.5,
    longitude: 96.8333
  },
  {
    country: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    numeric: 170,
    latitude: 4,
    longitude: -72
  },
  {
    country: "Comoros",
    alpha2: "KM",
    alpha3: "COM",
    numeric: 174,
    latitude: -12.1667,
    longitude: 44.25
  },
  {
    country: "Congo",
    alpha2: "CG",
    alpha3: "COG",
    numeric: 178,
    latitude: -1,
    longitude: 15
  },
  {
    country: "Congo, the Democratic Republic of the",
    alpha2: "CD",
    alpha3: "COD",
    numeric: 180,
    latitude: 0,
    longitude: 25
  },
  {
    country: "Cook Islands",
    alpha2: "CK",
    alpha3: "COK",
    numeric: 184,
    latitude: -21.2333,
    longitude: -159.7667
  },
  {
    country: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    numeric: 188,
    latitude: 10,
    longitude: -84
  },
  {
    country: "Côte d'Ivoire",
    alpha2: "CI",
    alpha3: "CIV",
    numeric: 384,
    latitude: 8,
    longitude: -5
  },
  {
    country: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    numeric: 191,
    latitude: 45.1667,
    longitude: 15.5
  },
  {
    country: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    numeric: 192,
    latitude: 21.5,
    longitude: -80
  },
  {
    country: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    numeric: 196,
    latitude: 35,
    longitude: 33
  },
  {
    country: "Czech Republic",
    alpha2: "CZ",
    alpha3: "CZE",
    numeric: 203,
    latitude: 49.75,
    longitude: 15.5
  },
  {
    country: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    numeric: 208,
    latitude: 56,
    longitude: 10
  },
  {
    country: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    numeric: 262,
    latitude: 11.5,
    longitude: 43
  },
  {
    country: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    numeric: 212,
    latitude: 15.4167,
    longitude: -61.3333
  },
  {
    country: "Dominican Republic",
    alpha2: "DO",
    alpha3: "DOM",
    numeric: 214,
    latitude: 19,
    longitude: -70.6667
  },
  {
    country: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    numeric: 218,
    latitude: -2,
    longitude: -77.5
  },
  {
    country: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    numeric: 818,
    latitude: 27,
    longitude: 30
  },
  {
    country: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    numeric: 222,
    latitude: 13.8333,
    longitude: -88.9167
  },
  {
    country: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    numeric: 226,
    latitude: 2,
    longitude: 10
  },
  {
    country: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    numeric: 232,
    latitude: 15,
    longitude: 39
  },
  {
    country: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    numeric: 233,
    latitude: 59,
    longitude: 26
  },
  {
    country: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    numeric: 231,
    latitude: 8,
    longitude: 38
  },
  {
    country: "Falkland Islands (Malvinas)",
    alpha2: "FK",
    alpha3: "FLK",
    numeric: 238,
    latitude: -51.75,
    longitude: -59
  },
  {
    country: "Faroe Islands",
    alpha2: "FO",
    alpha3: "FRO",
    numeric: 234,
    latitude: 62,
    longitude: -7
  },
  {
    country: "Fiji",
    alpha2: "FJ",
    alpha3: "FJI",
    numeric: 242,
    latitude: -18,
    longitude: 175
  },
  {
    country: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    numeric: 246,
    latitude: 64,
    longitude: 26
  },
  {
    country: "France",
    alpha2: "FR",
    alpha3: "FRA",
    numeric: 250,
    latitude: 46,
    longitude: 2
  },
  {
    country: "French Guiana",
    alpha2: "GF",
    alpha3: "GUF",
    numeric: 254,
    latitude: 4,
    longitude: -53
  },
  {
    country: "French Polynesia",
    alpha2: "PF",
    alpha3: "PYF",
    numeric: 258,
    latitude: -15,
    longitude: -140
  },
  {
    country: "French Southern Territories",
    alpha2: "TF",
    alpha3: "ATF",
    numeric: 260,
    latitude: -43,
    longitude: 67
  },
  {
    country: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    numeric: 266,
    latitude: -1,
    longitude: 11.75
  },
  {
    country: "Gambia",
    alpha2: "GM",
    alpha3: "GMB",
    numeric: 270,
    latitude: 13.4667,
    longitude: -16.5667
  },
  {
    country: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    numeric: 268,
    latitude: 42,
    longitude: 43.5
  },
  {
    country: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    numeric: 276,
    latitude: 51,
    longitude: 9
  },
  {
    country: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    numeric: 288,
    latitude: 8,
    longitude: -2
  },
  {
    country: "Gibraltar",
    alpha2: "GI",
    alpha3: "GIB",
    numeric: 292,
    latitude: 36.1833,
    longitude: -5.3667
  },
  {
    country: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    numeric: 300,
    latitude: 39,
    longitude: 22
  },
  {
    country: "Greenland",
    alpha2: "GL",
    alpha3: "GRL",
    numeric: 304,
    latitude: 72,
    longitude: -40
  },
  {
    country: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    numeric: 308,
    latitude: 12.1167,
    longitude: -61.6667
  },
  {
    country: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    numeric: 312,
    latitude: 16.25,
    longitude: -61.5833
  },
  {
    country: "Guam",
    alpha2: "GU",
    alpha3: "GUM",
    numeric: 316,
    latitude: 13.4667,
    longitude: 144.7833
  },
  {
    country: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    numeric: 320,
    latitude: 15.5,
    longitude: -90.25
  },
  {
    country: "Guernsey",
    alpha2: "GG",
    alpha3: "GGY",
    numeric: 831,
    latitude: 49.5,
    longitude: -2.56
  },
  {
    country: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    numeric: 324,
    latitude: 11,
    longitude: -10
  },
  {
    country: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    numeric: 624,
    latitude: 12,
    longitude: -15
  },
  {
    country: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    numeric: 328,
    latitude: 5,
    longitude: -59
  },
  {
    country: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    numeric: 332,
    latitude: 19,
    longitude: -72.4167
  },
  {
    country: "Heard Island and McDonald Islands",
    alpha2: "HM",
    alpha3: "HMD",
    numeric: 334,
    latitude: -53.1,
    longitude: 72.5167
  },
  {
    country: "Holy See (Vatican City State)",
    alpha2: "VA",
    alpha3: "VAT",
    numeric: 336,
    latitude: 41.9,
    longitude: 12.45
  },
  {
    country: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    numeric: 340,
    latitude: 15,
    longitude: -86.5
  },
  {
    country: "Hong Kong",
    alpha2: "HK",
    alpha3: "HKG",
    numeric: 344,
    latitude: 22.25,
    longitude: 114.1667
  },
  {
    country: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    numeric: 348,
    latitude: 47,
    longitude: 20
  },
  {
    country: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    numeric: 352,
    latitude: 65,
    longitude: -18
  },
  {
    country: "India",
    alpha2: "IN",
    alpha3: "IND",
    numeric: 356,
    latitude: 20,
    longitude: 77
  },
  {
    country: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    numeric: 360,
    latitude: -5,
    longitude: 120
  },
  {
    country: "Iran, Islamic Republic of",
    alpha2: "IR",
    alpha3: "IRN",
    numeric: 364,
    latitude: 32,
    longitude: 53
  },
  {
    country: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    numeric: 368,
    latitude: 33,
    longitude: 44
  },
  {
    country: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    numeric: 372,
    latitude: 53,
    longitude: -8
  },
  {
    country: "Isle of Man",
    alpha2: "IM",
    alpha3: "IMN",
    numeric: 833,
    latitude: 54.23,
    longitude: -4.55
  },
  {
    country: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    numeric: 376,
    latitude: 31.5,
    longitude: 34.75
  },
  {
    country: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    numeric: 380,
    latitude: 42.8333,
    longitude: 12.8333
  },
  {
    country: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    numeric: 388,
    latitude: 18.25,
    longitude: -77.5
  },
  {
    country: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    numeric: 392,
    latitude: 36,
    longitude: 138
  },
  {
    country: "Jersey",
    alpha2: "JE",
    alpha3: "JEY",
    numeric: 832,
    latitude: 49.21,
    longitude: -2.13
  },
  {
    country: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    numeric: 400,
    latitude: 31,
    longitude: 36
  },
  {
    country: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    numeric: 398,
    latitude: 48,
    longitude: 68
  },
  {
    country: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    numeric: 404,
    latitude: 1,
    longitude: 38
  },
  {
    country: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    numeric: 296,
    latitude: 1.4167,
    longitude: 173
  },
  {
    country: "Korea, Democratic People's Republic of",
    alpha2: "KP",
    alpha3: "PRK",
    numeric: 408,
    latitude: 40,
    longitude: 127
  },
  {
    country: "Korea, Republic of",
    alpha2: "KR",
    alpha3: "KOR",
    numeric: 410,
    latitude: 37,
    longitude: 127.5
  },
  {
    country: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    numeric: 414,
    latitude: 29.3375,
    longitude: 47.6581
  },
  {
    country: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    numeric: 417,
    latitude: 41,
    longitude: 75
  },
  {
    country: "Lao People's Democratic Republic",
    alpha2: "LA",
    alpha3: "LAO",
    numeric: 418,
    latitude: 18,
    longitude: 105
  },
  {
    country: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    numeric: 428,
    latitude: 57,
    longitude: 25
  },
  {
    country: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    numeric: 422,
    latitude: 33.8333,
    longitude: 35.8333
  },
  {
    country: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    numeric: 426,
    latitude: -29.5,
    longitude: 28.5
  },
  {
    country: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    numeric: 430,
    latitude: 6.5,
    longitude: -9.5
  },
  {
    country: "Libyan Arab Jamahiriya",
    alpha2: "LY",
    alpha3: "LBY",
    numeric: 434,
    latitude: 25,
    longitude: 17
  },
  {
    country: "Liechtenstein",
    alpha2: "LI",
    alpha3: "LIE",
    numeric: 438,
    latitude: 47.1667,
    longitude: 9.5333
  },
  {
    country: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    numeric: 440,
    latitude: 56,
    longitude: 24
  },
  {
    country: "Luxembourg",
    alpha2: "LU",
    alpha3: "LUX",
    numeric: 442,
    latitude: 49.75,
    longitude: 6.1667
  },
  {
    country: "Macao",
    alpha2: "MO",
    alpha3: "MAC",
    numeric: 446,
    latitude: 22.1667,
    longitude: 113.55
  },
  {
    country: "Macedonia, the former Yugoslav Republic of",
    alpha2: "MK",
    alpha3: "MKD",
    numeric: 807,
    latitude: 41.8333,
    longitude: 22
  },
  {
    country: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    numeric: 450,
    latitude: -20,
    longitude: 47
  },
  {
    country: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    numeric: 454,
    latitude: -13.5,
    longitude: 34
  },
  {
    country: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    numeric: 458,
    latitude: 2.5,
    longitude: 112.5
  },
  {
    country: "Maldives",
    alpha2: "MV",
    alpha3: "MDV",
    numeric: 462,
    latitude: 3.25,
    longitude: 73
  },
  {
    country: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    numeric: 466,
    latitude: 17,
    longitude: -4
  },
  {
    country: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    numeric: 470,
    latitude: 35.8333,
    longitude: 14.5833
  },
  {
    country: "Marshall Islands",
    alpha2: "MH",
    alpha3: "MHL",
    numeric: 584,
    latitude: 9,
    longitude: 168
  },
  {
    country: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    numeric: 474,
    latitude: 14.6667,
    longitude: -61
  },
  {
    country: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    numeric: 478,
    latitude: 20,
    longitude: -12
  },
  {
    country: "Mauritius",
    alpha2: "MU",
    alpha3: "MUS",
    numeric: 480,
    latitude: -20.2833,
    longitude: 57.55
  },
  {
    country: "Mayotte",
    alpha2: "YT",
    alpha3: "MYT",
    numeric: 175,
    latitude: -12.8333,
    longitude: 45.1667
  },
  {
    country: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    numeric: 484,
    latitude: 23,
    longitude: -102
  },
  {
    country: "Micronesia, Federated States of",
    alpha2: "FM",
    alpha3: "FSM",
    numeric: 583,
    latitude: 6.9167,
    longitude: 158.25
  },
  {
    country: "Moldova, Republic of",
    alpha2: "MD",
    alpha3: "MDA",
    numeric: 498,
    latitude: 47,
    longitude: 29
  },
  {
    country: "Monaco",
    alpha2: "MC",
    alpha3: "MCO",
    numeric: 492,
    latitude: 43.7333,
    longitude: 7.4
  },
  {
    country: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    numeric: 496,
    latitude: 46,
    longitude: 105
  },
  {
    country: "Montenegro",
    alpha2: "ME",
    alpha3: "MNE",
    numeric: 499,
    latitude: 42,
    longitude: 19
  },
  {
    country: "Montserrat",
    alpha2: "MS",
    alpha3: "MSR",
    numeric: 500,
    latitude: 16.75,
    longitude: -62.2
  },
  {
    country: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    numeric: 504,
    latitude: 32,
    longitude: -5
  },
  {
    country: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    numeric: 508,
    latitude: -18.25,
    longitude: 35
  },
  {
    country: "Myanmar",
    alpha2: "MM",
    alpha3: "MMR",
    numeric: 104,
    latitude: 22,
    longitude: 98
  },
  {
    country: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    numeric: 516,
    latitude: -22,
    longitude: 17
  },
  {
    country: "Nauru",
    alpha2: "NR",
    alpha3: "NRU",
    numeric: 520,
    latitude: -0.5333,
    longitude: 166.9167
  },
  {
    country: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    numeric: 524,
    latitude: 28,
    longitude: 84
  },
  {
    country: "Netherlands",
    alpha2: "NL",
    alpha3: "NLD",
    numeric: 528,
    latitude: 52.5,
    longitude: 5.75
  },
  {
    country: "Netherlands Antilles",
    alpha2: "AN",
    alpha3: "ANT",
    numeric: 530,
    latitude: 12.25,
    longitude: -68.75
  },
  {
    country: "New Caledonia",
    alpha2: "NC",
    alpha3: "NCL",
    numeric: 540,
    latitude: -21.5,
    longitude: 165.5
  },
  {
    country: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    numeric: 554,
    latitude: -41,
    longitude: 174
  },
  {
    country: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    numeric: 558,
    latitude: 13,
    longitude: -85
  },
  {
    country: "Niger",
    alpha2: "NE",
    alpha3: "NER",
    numeric: 562,
    latitude: 16,
    longitude: 8
  },
  {
    country: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    numeric: 566,
    latitude: 10,
    longitude: 8
  },
  {
    country: "Niue",
    alpha2: "NU",
    alpha3: "NIU",
    numeric: 570,
    latitude: -19.0333,
    longitude: -169.8667
  },
  {
    country: "Norfolk Island",
    alpha2: "NF",
    alpha3: "NFK",
    numeric: 574,
    latitude: -29.0333,
    longitude: 167.95
  },
  {
    country: "Northern Mariana Islands",
    alpha2: "MP",
    alpha3: "MNP",
    numeric: 580,
    latitude: 15.2,
    longitude: 145.75
  },
  {
    country: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    numeric: 578,
    latitude: 62,
    longitude: 10
  },
  {
    country: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    numeric: 512,
    latitude: 21,
    longitude: 57
  },
  {
    country: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    numeric: 586,
    latitude: 30,
    longitude: 70
  },
  {
    country: "Palau",
    alpha2: "PW",
    alpha3: "PLW",
    numeric: 585,
    latitude: 7.5,
    longitude: 134.5
  },
  {
    country: "Palestinian Territory, Occupied",
    alpha2: "PS",
    alpha3: "PSE",
    numeric: 275,
    latitude: 32,
    longitude: 35.25
  },
  {
    country: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    numeric: 591,
    latitude: 9,
    longitude: -80
  },
  {
    country: "Papua New Guinea",
    alpha2: "PG",
    alpha3: "PNG",
    numeric: 598,
    latitude: -6,
    longitude: 147
  },
  {
    country: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    numeric: 600,
    latitude: -23,
    longitude: -58
  },
  {
    country: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    numeric: 604,
    latitude: -10,
    longitude: -76
  },
  {
    country: "Philippines",
    alpha2: "PH",
    alpha3: "PHL",
    numeric: 608,
    latitude: 13,
    longitude: 122
  },
  {
    country: "Pitcairn",
    alpha2: "PN",
    alpha3: "PCN",
    numeric: 612,
    latitude: -24.7,
    longitude: -127.4
  },
  {
    country: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    numeric: 616,
    latitude: 52,
    longitude: 20
  },
  {
    country: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    numeric: 620,
    latitude: 39.5,
    longitude: -8
  },
  {
    country: "Puerto Rico",
    alpha2: "PR",
    alpha3: "PRI",
    numeric: 630,
    latitude: 18.25,
    longitude: -66.5
  },
  {
    country: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    numeric: 634,
    latitude: 25.5,
    longitude: 51.25
  },
  {
    country: "Réunion",
    alpha2: "RE",
    alpha3: "REU",
    numeric: 638,
    latitude: -21.1,
    longitude: 55.6
  },
  {
    country: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    numeric: 642,
    latitude: 46,
    longitude: 25
  },
  {
    country: "Russian Federation",
    alpha2: "RU",
    alpha3: "RUS",
    numeric: 643,
    latitude: 60,
    longitude: 100
  },
  {
    country: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    numeric: 646,
    latitude: -2,
    longitude: 30
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    alpha3: "SHN",
    numeric: 654,
    latitude: -15.9333,
    longitude: -5.7
  },
  {
    country: "Saint Kitts and Nevis",
    alpha2: "KN",
    alpha3: "KNA",
    numeric: 659,
    latitude: 17.3333,
    longitude: -62.75
  },
  {
    country: "Saint Lucia",
    alpha2: "LC",
    alpha3: "LCA",
    numeric: 662,
    latitude: 13.8833,
    longitude: -61.1333
  },
  {
    country: "Saint Pierre and Miquelon",
    alpha2: "PM",
    alpha3: "SPM",
    numeric: 666,
    latitude: 46.8333,
    longitude: -56.3333
  },
  {
    country: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    alpha3: "VCT",
    numeric: 670,
    latitude: 13.25,
    longitude: -61.2
  },
  {
    country: "Samoa",
    alpha2: "WS",
    alpha3: "WSM",
    numeric: 882,
    latitude: -13.5833,
    longitude: -172.3333
  },
  {
    country: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    numeric: 674,
    latitude: 43.7667,
    longitude: 12.4167
  },
  {
    country: "Sao Tome and Principe",
    alpha2: "ST",
    alpha3: "STP",
    numeric: 678,
    latitude: 1,
    longitude: 7
  },
  {
    country: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    numeric: 682,
    latitude: 25,
    longitude: 45
  },
  {
    country: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    numeric: 686,
    latitude: 14,
    longitude: -14
  },
  {
    country: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    numeric: 688,
    latitude: 44,
    longitude: 21
  },
  {
    country: "Seychelles",
    alpha2: "SC",
    alpha3: "SYC",
    numeric: 690,
    latitude: -4.5833,
    longitude: 55.6667
  },
  {
    country: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    numeric: 694,
    latitude: 8.5,
    longitude: -11.5
  },
  {
    country: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    numeric: 702,
    latitude: 1.3667,
    longitude: 103.8
  },
  {
    country: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    numeric: 703,
    latitude: 48.6667,
    longitude: 19.5
  },
  {
    country: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    numeric: 705,
    latitude: 46,
    longitude: 15
  },
  {
    country: "Solomon Islands",
    alpha2: "SB",
    alpha3: "SLB",
    numeric: 90,
    latitude: -8,
    longitude: 159
  },
  {
    country: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    numeric: 706,
    latitude: 10,
    longitude: 49
  },
  {
    country: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    numeric: 710,
    latitude: -29,
    longitude: 24
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    alpha2: "GS",
    alpha3: "SGS",
    numeric: 239,
    latitude: -54.5,
    longitude: -37
  },
  {
    country: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    numeric: 724,
    latitude: 40,
    longitude: -4
  },
  {
    country: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    numeric: 144,
    latitude: 7,
    longitude: 81
  },
  {
    country: "Sudan",
    alpha2: "SD",
    alpha3: "SDN",
    numeric: 736,
    latitude: 15,
    longitude: 30
  },
  {
    country: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    numeric: 740,
    latitude: 4,
    longitude: -56
  },
  {
    country: "Svalbard and Jan Mayen",
    alpha2: "SJ",
    alpha3: "SJM",
    numeric: 744,
    latitude: 78,
    longitude: 20
  },
  {
    country: "Swaziland",
    alpha2: "SZ",
    alpha3: "SWZ",
    numeric: 748,
    latitude: -26.5,
    longitude: 31.5
  },
  {
    country: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    numeric: 752,
    latitude: 62,
    longitude: 15
  },
  {
    country: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    numeric: 756,
    latitude: 47,
    longitude: 8
  },
  {
    country: "Syrian Arab Republic",
    alpha2: "SY",
    alpha3: "SYR",
    numeric: 760,
    latitude: 35,
    longitude: 38
  },
  {
    country: "Taiwan, Province of China",
    alpha2: "TW",
    alpha3: "TWN",
    numeric: 158,
    latitude: 23.5,
    longitude: 121
  },
  {
    country: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    numeric: 762,
    latitude: 39,
    longitude: 71
  },
  {
    country: "Tanzania, United Republic of",
    alpha2: "TZ",
    alpha3: "TZA",
    numeric: 834,
    latitude: -6,
    longitude: 35
  },
  {
    country: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    numeric: 764,
    latitude: 15,
    longitude: 100
  },
  {
    country: "Timor-Leste",
    alpha2: "TL",
    alpha3: "TLS",
    numeric: 626,
    latitude: -8.55,
    longitude: 125.5167
  },
  {
    country: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    numeric: 768,
    latitude: 8,
    longitude: 1.1667
  },
  {
    country: "Tokelau",
    alpha2: "TK",
    alpha3: "TKL",
    numeric: 772,
    latitude: -9,
    longitude: -172
  },
  {
    country: "Tonga",
    alpha2: "TO",
    alpha3: "TON",
    numeric: 776,
    latitude: -20,
    longitude: -175
  },
  {
    country: "Trinidad and Tobago",
    alpha2: "TT",
    alpha3: "TTO",
    numeric: 780,
    latitude: 11,
    longitude: -61
  },
  {
    country: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    numeric: 788,
    latitude: 34,
    longitude: 9
  },
  {
    country: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    numeric: 792,
    latitude: 39,
    longitude: 35
  },
  {
    country: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    numeric: 795,
    latitude: 40,
    longitude: 60
  },
  {
    country: "Turks and Caicos Islands",
    alpha2: "TC",
    alpha3: "TCA",
    numeric: 796,
    latitude: 21.75,
    longitude: -71.5833
  },
  {
    country: "Tuvalu",
    alpha2: "TV",
    alpha3: "TUV",
    numeric: 798,
    latitude: -8,
    longitude: 178
  },
  {
    country: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    numeric: 800,
    latitude: 1,
    longitude: 32
  },
  {
    country: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    numeric: 804,
    latitude: 49,
    longitude: 32
  },
  {
    country: "United Arab Emirates",
    alpha2: "AE",
    alpha3: "ARE",
    numeric: 784,
    latitude: 24,
    longitude: 54
  },
  {
    country: "United Kingdom",
    alpha2: "GB",
    alpha3: "GBR",
    numeric: 826,
    latitude: 54,
    longitude: -2
  },
  {
    country: "United States",
    alpha2: "US",
    alpha3: "USA",
    numeric: 840,
    latitude: 38,
    longitude: -97
  },
  {
    country: "United States Minor Outlying Islands",
    alpha2: "UM",
    alpha3: "UMI",
    numeric: 581,
    latitude: 19.2833,
    longitude: 166.6
  },
  {
    country: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    numeric: 858,
    latitude: -33,
    longitude: -56
  },
  {
    country: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    numeric: 860,
    latitude: 41,
    longitude: 64
  },
  {
    country: "Vanuatu",
    alpha2: "VU",
    alpha3: "VUT",
    numeric: 548,
    latitude: -16,
    longitude: 167
  },
  {
    country: "Venezuela, Bolivarian Republic of",
    alpha2: "VE",
    alpha3: "VEN",
    numeric: 862,
    latitude: 8,
    longitude: -66
  },
  {
    country: "Viet Nam",
    alpha2: "VN",
    alpha3: "VNM",
    numeric: 704,
    latitude: 16,
    longitude: 106
  },
  {
    country: "Virgin Islands, British",
    alpha2: "VG",
    alpha3: "VGB",
    numeric: 92,
    latitude: 18.5,
    longitude: -64.5
  },
  {
    country: "Virgin Islands, U.S.",
    alpha2: "VI",
    alpha3: "VIR",
    numeric: 850,
    latitude: 18.3333,
    longitude: -64.8333
  },
  {
    country: "Wallis and Futuna",
    alpha2: "WF",
    alpha3: "WLF",
    numeric: 876,
    latitude: -13.3,
    longitude: -176.2
  },
  {
    country: "Western Sahara",
    alpha2: "EH",
    alpha3: "ESH",
    numeric: 732,
    latitude: 24.5,
    longitude: -13
  },
  {
    country: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    numeric: 887,
    latitude: 15,
    longitude: 48
  },
  {
    country: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    numeric: 894,
    latitude: -15,
    longitude: 30
  },
  {
    country: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    numeric: 716,
    latitude: -20,
    longitude: 30
  },
  {
    country: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    numeric: 4,
    latitude: 33,
    longitude: 65
  }
];

export const emptyData = {};
