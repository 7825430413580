import axios from "../../../../api/axios";

export const updateProfileSettings = async (formData) => {
  const response = await axios.post("profile/updateProfile", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return response.data;
};

export const getProfileSettings = () => axios.get("profile/getProfile");

export const resetPasswordSettings = (payload) =>
  axios.post("customer/changePassword", payload);

export const deleteProfile = () => axios.delete("/profile/deleteProfile");
