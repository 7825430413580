import PropTypes from "prop-types";
import Insights from "./components/Insights";

export default function Index({
  insights,
  insightsToCompare,
  isCompare,
  insightsTitle,
  dashboard
}) {
  return (
    <Insights
      isCompare={isCompare}
      insights={
        isCompare
          ? { generalInsights: insights, compareInsights: insightsToCompare }
          : { generalInsights: insights }
      }
      insightsTitle={insightsTitle}
      dashboard={dashboard}
    />
  );
}

Index.propTypes = {
  insights: PropTypes.oneOfType([Object]),
  insightsToCompare: PropTypes.oneOfType([Object]),
  insightsTitle: PropTypes.oneOfType([Object]),
  isCompare: PropTypes.bool,
  dashboard: PropTypes.string
};
