/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-date-range";
import Moment from "moment";
import CalendarButtonCombination from "../CalendarButtonCombination/CalendarButtonCombination";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../../assets/stylesheets/viewCustomDate.scss";
import LocalStorage from "../../../../utils/LocalStorgae";
import localLong from "../../../../data/calendarTransalation";

export default function ViewCustomDate({
  labelData,
  setActiveCalendarComponent,
  setSelectedLabel,
  onApply
}) {
  const [startDay, startMonth, startYear] = labelData.start_date.split("-");
  const [endDay, endMonth, endYear] = labelData.end_date.split("-");
  const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
  const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);
  const { t } = useTranslation();
  const language = LocalStorage.getItem("selectedLanguage");

  const selectedRange = {
    startDate,
    endDate,
    key: "selection"
  };

  const handleEmptyChange = () => {};

  const handleEditClick = () => {
    setSelectedLabel(labelData);
    setActiveCalendarComponent(4);
  };

  const handleDelete = () => {
    setSelectedLabel(labelData);
    setActiveCalendarComponent(5);
  };

  const handleCancel = () => {
    setActiveCalendarComponent(1);
  };

  const handleApplyClick = () => {
    onApply({
      startDate,
      endDate
    });
  };

  return (
    <section className="viewLabelData">
      <div className="label-detail flex-between">
        <h3>{labelData.label}</h3>
        <div className="action-div flex">
          <div className="action-button flex" onClick={handleEditClick}>
            <p>{t("analyticsPage.calendar.custom.viewCustom.edit")}</p>
            <i className="ri-edit-fill" />
          </div>
          <div className="action-button flex" onClick={handleDelete}>
            <p>{t("analyticsPage.calendar.custom.viewCustom.delete")}</p>
            <i className="ri-delete-bin-5-line" />
          </div>
        </div>
      </div>
      <div className="date-range">
        <div className="flex">
          <p>
            {t("analyticsPage.calendar.custom.viewCustom.from")}{" "}
            {Moment(startDate).format("Do MMM YYYY")}
          </p>
          <p>
            {t("analyticsPage.calendar.custom.viewCustom.to")}{" "}
            {Moment(endDate).format("Do MMM YYYY")}
          </p>
        </div>
      </div>
      <div className="calendar-view">
        <DateRangePicker
          ranges={[selectedRange]}
          color={themeColors.primaryColor4}
          rangeColors={[themeColors.primaryColor2]}
          showSelectionPreview
          direction="horizontal"
          editableDateInputs
          dragSelectionEnabled
          showMonthAndYearPickers={false}
          showDateDisplay={false}
          showPreview
          months={2}
          onChange={handleEmptyChange}
          locale={localLong[language]}
        />
        <div className="button-combo">
          <CalendarButtonCombination
            firstButtonText={t("common.cancelButton")}
            secondButtonText={t("analyticsPage.calendar.apply")}
            onClickFirstButton={handleCancel}
            onClickSecondButton={handleApplyClick}
            // loading={loading}
          />
        </div>
      </div>
    </section>
  );
}

ViewCustomDate.propTypes = {
  labelData: PropTypes.shape({
    calendar_id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    customer_id: PropTypes.number.isRequired,
    website_id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    shared_calendar: PropTypes.number.isRequired
  }).isRequired,
  setActiveCalendarComponent: PropTypes.func.isRequired,
  setSelectedLabel: PropTypes.func.isRequired,
  onApply: PropTypes.func
};
