/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import CopyIcon from "../../../assets/icons/copy.svg";
import { Toastr } from "../../Toastr/Toastr";
import "./input.scss";

export default function CopyInput({
  type,
  placeholder,
  value,
  onChange,
  name,
  disabled,
  label,
  onClick,
  onBlur,
  readOnlyCondition
}) {
  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value);
      Toastr.success("Copied to clipboard");
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const tempInput = document.createElement("input");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      Toastr.success("Copied to clipboard");
    }
  };

  return (
    <div className="input-wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <div className="input-container">
        <input
          type={type}
          id={label}
          className={`common-input add-tag-input-label ${
            disabled && "disabled"
          }`}
          placeholder={placeholder}
          value={value}
          name={name}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={!!readOnlyCondition}
        />
        <img
          className="copy-icon"
          src={CopyIcon}
          alt="copy-icon"
          onClick={copyToClipboard}
        />
      </div>
    </div>
  );
}
CopyInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  readOnlyCondition: PropTypes.bool
};
