/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import Selecttype from "../../../../../assets/icons/Selecttype.png";
import "../assets/stylesheets/selectType.scss";

export default function SelectType({
  isHoveredType,
  handleMouseEnterType,
  handleMouseLeaveType,
  handleSelectTypes,
  selectedChannels,
  selectedTypes,
  multiSelectTypes
}) {
  const { t } = useTranslation();

  return (
    <div
      className={`select-types ${
        isHoveredType || selectedTypes?.length > 0 ? "isHovered" : ""
      }`}
      onMouseEnter={handleMouseEnterType}
      onMouseLeave={handleMouseLeaveType}
    >
      <div className="step-text">{t("manageCampaign.step")} 2</div>
      <div className="select-types-text">{t("manageCampaign.selectType")}</div>

      {selectedChannels?.length > 0 ? (
        <div className="margins-selections scrollable-container">
          {selectedChannels?.map((channel, index) => {
            return channel.type.map((type, index) => {
              const isSelected = multiSelectTypes.some(
                (ty) => ty.type_id === type.type_id
              );
              return (
                <div
                  className={`flex-center-align selected-background ${
                    isSelected ? "selected" : ""
                  }`}
                  onClick={() => {
                    handleSelectTypes(type);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    // Handling keyboard events
                    if (e.key === "Enter" || e.key === " ") {
                      handleSelectTypes(type);
                    }
                  }}
                  key={type?.type_id}
                >
                  <div className="icon-div">
                    <img className="icon" src={type?.type_image} />
                  </div>

                  <div>
                    <div
                      className={`type-name ${isSelected ? "selected" : ""}`}
                    >
                      {type?.type_name}
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
      ) : null}

      {selectedTypes?.length == 0 && (
        <div className="flex-center">
          <img src={Selecttype} />
        </div>
      )}
    </div>
  );
}
