/* eslint-disable */
import React from "react";

export default function AISettingsFieldComponent({
  option,
  text,
  selectedOption,
  onOptionClick
}) {
  return (
    <div
      className={`flex-center-align ai-settings-options-div ${
        selectedOption === option && "selected"
      }`}
      onClick={() => onOptionClick(option)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        // Handling keyboard events
        if (e.key === "Enter" || e.key === " ") {
          onOptionClick(option);
        }
      }}
    >
      <div className="ai-settings-options">{text}</div>
    </div>
  );
}
