/* eslint-disable */
import React, { useState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import { useTranslation } from "react-i18next";
import "../assets/stylesheets/advertisementStyles.scss";
import PropTypes from "prop-types";
import "../../../../assets/stylesheets/_var.scss";
import Close from "../../../../assets/icons/Close.svg";

function AdvertisementColorComponent(props) {
  const { title, options, id, handleColorOrFontChange } = props;
  const { t } = useTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [defaultColor, setDefaultColor] = useState(false);
  const [color, setColor] = useColor("#561ecb");

  const selectedColor =
    options?.length > 0
      ? options.find((option) => option.isSelected)
      : undefined;

  return (
    <div className="advertisement-color-continer">
      <div className="advertisement-color-title">{title}</div>
      <div>
        <div className="advertisement-color-button">
          <button
            type="button"
            onClick={() => {
              setShowColorPicker(true);
            }}
            className="advertisement-color-button-container"
          >
            {selectedColor === undefined ? (
              <div className="advertisement-color-button-text">
                {t("advertisementStyles.chooseColor")}
              </div>
            ) : (
              <>
                <div className="advertisement-color-button-text">
                  {selectedColor.color}
                </div>
                <div
                  className="advertisement-color-picker"
                  style={{ backgroundColor: selectedColor.color }}
                />
              </>
            )}
          </button>

          {showColorPicker ? (
            <div className="advertisement-color-selecter">
              <div className="advertisement-color-selecter-close">
                <button
                  type="button"
                  onClick={() => {
                    setShowColorPicker(false);
                    setDefaultColor(false);
                  }}
                  className="advertisement-color-selecter-close-button"
                >
                  <img src={Close} alt="close" className="close-img" />
                </button>
              </div>
              <div>
                {defaultColor ? (
                  <div>
                    <div className="color-picker-container">
                      <ColorPicker
                        height={120}
                        color={color}
                        hideInput={["hsv", "rgb"]}
                        onChange={(e) => {
                          const newId =
                            options?.length > 0 ? options.length + 1 : 1;

                          handleColorOrFontChange(id, {
                            id: newId,
                            color: e.hex,
                            isSelected: 1
                          });

                          setColor(e);
                        }}
                      />
                    </div>
                    <div className="advertisement-color-default-color">
                      <button
                        type="button"
                        onClick={() => {
                          setDefaultColor(false);
                        }}
                        className="advertisement-color-default-color-button"
                      >
                        <div className="advertisement-color-default-color-container">
                          <div className="advertisement-color-button-text ">
                            {t("advertisementStyles.closeDefault")}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                ) : null}

                {!defaultColor && (
                  <div className="advertisement-color-default-color">
                    <button
                      type="button"
                      onClick={() => {
                        setDefaultColor(true);
                      }}
                      className="advertisement-color-default-color-button"
                    >
                      <div className="advertisement-color-default-color-container">
                        <div className="advertisement-color-button-text ">
                          {t("advertisementStyles.defaultColor")}
                        </div>
                      </div>
                    </button>
                  </div>
                )}

                {!defaultColor &&
                  options?.map((c) => {
                    return (
                      <button
                        key={c.id}
                        type="button"
                        onClick={() => {
                          handleColorOrFontChange(id, {
                            id: c.id,
                            color: c.color,
                            isSelected: 1
                          });
                          setShowColorPicker(false);
                        }}
                        className="advertisement-color-selecter-button"
                        style={{
                          backgroundColor: c.isSelected
                            ? `$primary-color-4`
                            : "none"
                        }}
                      >
                        <div className="advertisement-color-selecter-container">
                          <div className="advertisement-color-button-text">
                            {c.color}
                          </div>
                          <div
                            className="advertisement-color-picker"
                            style={{ backgroundColor: c.color }}
                          />
                        </div>
                      </button>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdvertisementColorComponent;

AdvertisementColorComponent.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]),
  handleColorOrFontChange: PropTypes.func
};
