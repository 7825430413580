import React, { useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Toastr } from "../../../components/Toastr/Toastr";
import SignUpForm from "./components/SignUpForm";
import signup from "./api/signup";
import storage from "../../../utils/storage";

export default function Index() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const decodedToken = token ? JSON.parse(atob(token)) : null;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(token ? decodedToken.email : "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [scrollToEnd, setScrollToEnd] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const [acceptPrivacyError, setAcceptPrivacyError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const textareaRef = useRef(null);

  const emailValidation = () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!email) {
      return t("common.emailValidation.emailRequired");
    }
    if (!emailRegex.test(email)) {
      return t("common.emailValidation.invalidEmail");
    }
    return "";
  };

  const passwordValidation = () => {
    if (!password) {
      return t("signup.passwordRequired");
    }
    if (password.length < 8) {
      return t("signup.passwordLength");
    }
    if (!/\d/.test(password)) {
      return t("signup.passwordDigit");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return t("signup.passwordSpecialCharacter");
    }
    if (!/[A-Z]/.test(password)) {
      return t("signup.passwordUppercase");
    }
    return "";
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  const firstNameChnageHandler = (e) => {
    setFirstName(e.target.value);
    setNameError("");
  };

  // scroll feature

  useLayoutEffect(() => {
    function checkScrollPosition() {
      const { scrollHeight, scrollTop, clientHeight } = textareaRef.current;
      const isScrollable = scrollHeight > clientHeight;
      const atBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 1;
      setScrollToEnd(isScrollable && atBottom);
    }

    function handleResize() {
      checkScrollPosition();
    }

    function handleScroll() {
      checkScrollPosition();
    }

    checkScrollPosition();

    window.addEventListener("resize", handleResize);
    textareaRef.current.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      textareaRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAcceptTermsChange = () => {
    setAcceptTerms(!acceptTerms);
    setAcceptTermsError("");
  };

  const handleAcceptPrivacyChange = () => {
    setAcceptPrivacy(!acceptPrivacy);
    setAcceptPrivacyError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailValidationError = emailValidation();
    const passwordValidationError = passwordValidation();

    if (!firstName) {
      setNameError("*First name is required");
      return;
    }

    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (!acceptTerms) {
      setAcceptTermsError("*Please accept terms and condition");
      return;
    }

    if (!acceptPrivacy) {
      setAcceptPrivacyError("*Please accept privacy policies.");
      return;
    }

    try {
      setLoading(true);

      if (password !== confirmPassword) {
        setLoading(false);
        setConfirmPasswordError(t("signup.passwordConfirmMismatch"));
        return;
      }

      const response = token
        ? await signup.newUserInvite({
            token,
            firstName,
            lastName,
            password
          })
        : await signup.register({ email, password, firstName, lastName });

      if (response.data.status === "ok" && decodedToken === null) {
        storage.setToken(response.data.token.token);
        setLoading(false);
        Toastr.success(t("signup.registeredSuccessfully"));
        navigate("/onboarding", { replace: false });
      } else if (response.data.status === "ok" && decodedToken != null) {
        setLoading(false);
        Toastr.success(t("signup.registeredSuccessfully"));
        navigate("/", { replace: false });
      } else {
        setLoading(false);
        Toastr.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 404) {
        setEmailError(t("signup.userAlreadyExist"));
      }
    }
  };

  return (
    <SignUpForm
      email={email}
      token={token}
      firstNameChnageHandler={firstNameChnageHandler}
      setLastName={setLastName}
      emailChangeHandler={emailChangeHandler}
      passwordChangeHandler={passwordChangeHandler}
      confirmPasswordChangeHandler={confirmPasswordChangeHandler}
      handleSubmit={handleSubmit}
      loading={loading}
      nameError={nameError}
      emailError={emailError}
      passwordError={passwordError}
      confirmPasswordError={confirmPasswordError}
      textareaRef={textareaRef}
      acceptTerms={acceptTerms}
      handleAcceptTermsChange={handleAcceptTermsChange}
      scrollToEnd={scrollToEnd}
      acceptPrivacy={acceptPrivacy}
      handleAcceptPrivacyChange={handleAcceptPrivacyChange}
      acceptTermsError={acceptTermsError}
      acceptPrivacyError={acceptPrivacyError}
    />
  );
}
