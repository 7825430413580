/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import PropTypes from "prop-types";
import LocalStorage from "../../../../utils/LocalStorgae";
import "../assets/subscription.scss";

SwiperCore.use(Navigation);

export default function CategoryPills({ pills, pillSelect, handlePillSelect }) {
  const [slides, setSlides] = useState(3.15);
  const language = LocalStorage.getItem("selectedLanguage");
  const setSlidesPerview = () => {
    setSlides(window.innerWidth > 1700 ? 3.15 : 2.25);
  };
  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  }, []);

  const filteredPlans = pills?.map((plan) => {
    const localizedPlan = plan?.localization?.find(
      (item) => item.lang === language
    );
    return {
      id: plan.id,
      name: localizedPlan
    };
  });

  console.log("filteredPlans====>", filteredPlans);

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={0}
      slidesPerView={slides}
      navigation
    >
      {filteredPlans.map(
        (ele) => (
          /* eslint-disable */
          console.log("ele====>", ele),
          (
            <SwiperSlide key={ele?.id}>
              <div
                onClick={() => handlePillSelect(ele)}
                className={`plan-pills ${
                  ele?.id === pillSelect?.id && "selected"
                }`}
              >
                {ele?.name?.name}
              </div>
            </SwiperSlide>
          )
        )
      )}
    </Swiper>
  );
}

CategoryPills.propTypes = {
  pills: PropTypes.oneOfType([PropTypes.array]),
  pillSelect: PropTypes.number,
  handlePillSelect: PropTypes.func
};
