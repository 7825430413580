const notificationMapping = (t) => ({
  1: {
    text: t("notification.freeplan"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  2: {
    text: t("notification.planCancel"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  3: {
    text: t("notification.planUpgraded"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  4: {
    text: t("notification.planDowngraded"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  5: {
    text: t("notification.topUpSuccess"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  6: {
    text: t("notification.pageViewsExceed"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  7: {
    text: t("notification.insufficientFund"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  8: {
    text: t("notification.planRenewed"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  9: {
    text: t("notification.pageViewsExhausted"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  },
  10: {
    text: t("notification.topUpExceed"),
    body: t("notification.Body"),
    link: "/settings/subscription"
  }
});

export default notificationMapping;
