/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { LinearProgress } from "@mui/material";
import { Navigation } from "swiper";
import {
  formatDateForAPI,
  formatNumber,
  getInDateFormat,
  separatePageViews
} from "../../../../utils/dataFilter";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/subscription.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DataConsumption({ pageUsage, pageViews }) {
  const [dates, setDates] = useState(null); // {startDate, endDate}

  const [monthPageViews, setMonthPageViews] = useState(null);

  const [selected, setSelected] = useState(null);

  const { t } = useTranslation();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "600"
          }
        },
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "500"
          }
        }
      },
      y: {
        border: { dash: [4, 5] },
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "500"
          }
        },
        grid: {
          color: themeColors.tertiaryText, // for the grid lines
          offset: false,
          drawTicks: false, // true is default
          drawOnChartArea: true // true is default
        },
        beginAtZero: true
      }
    }
  };

  const updateMonth = (swiper) => {
    if (monthPageViews)
      setSelected(Object.keys(monthPageViews)[swiper.activeIndex]);
  };

  useEffect(() => {
    if (pageViews?.length > 0) {
      console.log("page views: ", getInDateFormat(pageViews[0]?.date));
      setDates({
        startDate: getInDateFormat(pageViews[0]?.date),
        endDate: new Date()
      });
    }
  }, [pageViews]);

  useEffect(() => {
    console.log("dates: ", dates);
    if (dates) {
      setMonthPageViews(
        separatePageViews(pageViews, {
          startDate: formatDateForAPI(dates?.startDate),
          endDate: formatDateForAPI(dates?.endDate)
        })
      );
    }
  }, [dates]);

  useEffect(() => {
    if (monthPageViews) {
      const eachMonth = Object.keys(monthPageViews);
      setSelected(eachMonth[eachMonth.length - 1]);
    }
  }, [monthPageViews]);

  const data = {
    labels: monthPageViews?.[selected]?.map((ele) =>
      getInDateFormat(ele.date).getDate()
    ),
    datasets: [
      {
        label: t("subscription.billing.pageViews"),
        data: monthPageViews?.[selected]?.map((ele) => ele.pageViewCount),
        backgroundColor: themeColors.primaryColor3
      }
    ]
  };

  return (
    <div>
      {pageViews && (
        <>
          <div className="swiper-month">
            {monthPageViews && (
              <Swiper
                modules={[Navigation]}
                initialSlide={Object.keys(monthPageViews).length - 1}
                spaceBetween={10}
                slidesPerView={1}
                navigation
                onSlideChange={(s) => updateMonth(s)}
              >
                {Object.keys(monthPageViews)?.map((ele) => (
                  <SwiperSlide
                    style={{
                      fontFamily: themeColors.fontQuickSand
                    }}
                  >
                    {ele}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          <div style={{ height: "30vh" }}>
            <Bar options={options} data={data} />
          </div>
        </>
      )}

      {pageUsage?.currentUsage && (
        <>
          <div className="flex-space dataconsumption">
            <div>{t("subscription.billing.expiresOn")}</div>

            <div>{pageUsage.expiry.split("-").reverse().join("/")}</div>
          </div>
          <div className="flex-space dataconsumption">
            <div>{t("subscription.billing.usageThisMonth")}</div>
            <div>
              {formatNumber(pageUsage.currentUsage.used)}/
              {formatNumber(pageUsage.currentUsage.limit)}{" "}
              {t("subscription.billing.pageViews")}
            </div>
          </div>
          <LinearProgress
            variant="determinate"
            value={
              (pageUsage.currentUsage.used / pageUsage.currentUsage.limit) * 100
            }
          />
        </>
      )}

      {pageUsage?.topupUsage && (
        <>
          {" "}
          <div className="flex-space dataconsumption">
            <div>{t("subscription.billing.topUpUsage")}</div>
            <div>
              {" "}
              {formatNumber(pageUsage.topupUsage.used)}/
              {formatNumber(pageUsage.topupUsage.limit)}{" "}
              {t("subscription.billing.pageViews")}
            </div>
          </div>{" "}
          <LinearProgress
            variant="determinate"
            value={
              (pageUsage.topupUsage.used / pageUsage.topupUsage.limit) * 100
            }
          />
        </>
      )}
    </div>
  );
}

DataConsumption.propTypes = {
  pageUsage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageViews: PropTypes.oneOfType([PropTypes.array]).isRequired
};
