import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Select } from "@mui/material";
import themeColors from "../../../../assets/stylesheets/_var.scss";

export default function MultiSelectRoleComponent({
  userWebsitesInfo,
  roleMapping,
  roles,
  handleRoleChange,
  website
}) {
  const selectedRole = userWebsitesInfo?.filter(
    (role) => role?.website === website
  );

  return (
    <Select
      value={selectedRole?.length > 0 ? selectedRole[0]?.role_id : ""}
      onChange={handleRoleChange}
      className="select-options"
    >
      {roles.map((role) => (
        <MenuItem
          style={{
            fontWeight: "500",
            fontSize: "16px",
            color: themeColors.primaryText,
            fontFamily: themeColors.fontQuickSand
          }}
          key={role}
          value={role}
        >
          {roleMapping[role]}
        </MenuItem>
      ))}
    </Select>
  );
}

MultiSelectRoleComponent.propTypes = {
  userWebsitesInfo: PropTypes.oneOfType([PropTypes.array]),
  roleMapping: PropTypes.oneOfType([PropTypes.array]),
  roles: PropTypes.oneOfType([PropTypes.array]),
  handleRoleChange: PropTypes.func,
  website: PropTypes.oneOfType([PropTypes.array])
};
