/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/dragAndDrop.scss";

export default function DragAndDropArea({
  width,
  height,
  handleClick,
  handleDrop,
  handleDragOver,
  handleFileUpload,
  folder,
  uploadedImages,
  text
}) {
  const { t } = useTranslation();

  return (
    <div className="drag-drop">
      <div
        className="main-div background-box"
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          // Handling keyboard events
          if (e.key === "Enter" || e.key === " ") {
          }
        }}
      >
        <input
          id="fileInput"
          type="file"
          multiple
          accept="image/*" // Specify accepted file types here
          style={{
            display: "none"
          }}
          onChange={(e) => handleFileUpload(e?.target?.files)}
        />
        <div className="flex-center-align">
          <div>
            <img
              src={folder}
              style={{
                border: "none",
                width: width,
                height: height
              }}
            />
          </div>

          <div
            style={{
              paddingLeft: "2%"
            }}
          >
            <div className="flex-center-align">
              <p className="drag-drop-text">{t("uploadImages.drag&Drop")}</p>
            </div>
            <div
              className="flex-center"
              style={{
                paddingTop: "2%"
              }}
            >
              <p className="select-file">{t("uploadImages.selectFile")}</p>
            </div>

            {uploadedImages?.length > 0 && (
              <div className="upload-images-div">
                <p className="upload-images-length">
                  {uploadedImages?.length} {text}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {uploadedImages?.length > 0 ? (
        <div className="image-preview">
          <p> {t("uploadImages.imagePreview")}</p>
        </div>
      ) : null}
    </div>
  );
}
