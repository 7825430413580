/* eslint-disable */
import React, { useState } from "react";
import GoogleImages from "./GoogleImages";

const marketingImagesData = [
  {
    id: 0,
    name: "Netboard",
    imageUrl:
      "https://8b30f4237c53f8ab981e-32ed400f512ec2f68c7b46cf1b23937c.ssl.cf3.rackcdn.com/blog/google-ads-sizing/580x400.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "580",
    height: "400"
  },
  {
    id: 1,
    name: "Large Rectangle",
    imageUrl:
      "https://synergyzer.com/wp-content/uploads/2016/10/336X280-BANNER.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "336",
    height: "280"
  },
  {
    id: 2,
    name: "Medium Rectangle",
    imageUrl:
      "https://cdn.learnwoo.com/wp-content/uploads/2018/11/300x250-banner-ad.png",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "300",
    height: "250"
  },
  {
    id: 3,
    name: "Mobile Banner",
    imageUrl: "/static/media/mobilead.096ac01330b875fd531c.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "300",
    height: "50"
  },
  {
    id: 4,
    name: "Mobile Leaderboard",
    imageUrl: "/static/media/mobileadnew.08e93fc21c80685f18eb.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "320",
    height: "50"
  },
  {
    id: 5,
    name: "Large Mobile Banner",
    imageUrl:
      "https://lh3.googleusercontent.com/GQHnYOD_Y3axaOs36ezjE2aRO6Pa2DUiTUvvzck-q4I4QkWEOWBnG6ShqKj74FXyD1prVa0JkoK1xLM2ocifoJsCmUMJn55RRbTqaTVzPT-4og=e365-rj-l80-w364",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "320",
    height: "100"
  },
  {
    id: 6,
    name: "Vertical Rectangle",
    imageUrl:
      "https://s3.envato.com/files/60224096/PNG/Marketing%20Banner%20ad%202%20240x400.png",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "240",
    height: "400"
  },
  {
    id: 7,
    name: "Half Page",
    imageUrl: "https://blog.rtbhouse.com/wp-content/uploads/2023/08/Zipbox.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "300",
    height: "600"
  },
  {
    id: 8,
    name: "Banner",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/33/Wikimania-468x60-en.png",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "468",
    height: "60"
  },
  {
    id: 9,
    name: "Small Square",
    imageUrl:
      "https://designimages.appypie.com/googleads200x200px/googleads200x200px-10-text-scoreboard.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "200",
    height: "200"
  },
  {
    id: 10,
    name: "Leaderboard",
    imageUrl: "/static/media/mobilebanner.dca6c0b862555ec884f3.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "728",
    height: "90"
  },
  {
    id: 11,
    name: "Top Banner",
    imageUrl:
      "https://www.samplelibraryreview.com/wp-content/uploads/2017/02/930x180.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "930",
    height: "180"
  },
  {
    id: 12,
    name: "Large Leaderboard",
    imageUrl:
      "https://www.gourmetads.com/wp-content/uploads/2021/06/Barilla-970x90-Super-Leaderboard.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "970",
    height: "90"
  },
  {
    id: 13,
    name: "Billboard",
    imageUrl:
      "https://www.gourmetads.com/wp-content/uploads/2019/02/970x250-starbucks.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "970",
    height: "250"
  },
  {
    id: 14,
    name: "Panorama",
    imageUrl:
      "https://mladiinfo.eu/wp-content/uploads/2018/06/PGLF-banner-980x120.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "980",
    height: "120"
  },
  {
    id: 15,
    name: "Skysc..",
    imageUrl:
      "https://yydevelopment.com/wp-content/uploads/2017/08/diet-tea-120x600-banner-example.gif",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "120",
    height: "600"
  },
  {
    id: 16,
    name: "Wide Skyscraper",
    imageUrl:
      "https://www.healthyads.com/wp-content/uploads/2019/01/160x600-skyscraper-fitibit.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "160",
    height: "600"
  },
  {
    id: 17,
    name: "Square",
    imageUrl:
      "https://s3.envato.com/files/56417722/Preview/Green/Marketing%20Banner%20ad%20250x250%20-%20Green.png",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "250",
    height: "250"
  },
  {
    id: 18,
    name: "Portrait",
    imageUrl:
      "https://www.gourmetads.com/wp-content/uploads/2019/02/300x1050-sodastream.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "300",
    height: "1050"
  },
  {
    id: 19,
    name: "Responsive ad",
    imageUrl:
      "https://www.adexchanger.com/wp-content/uploads/2024/05/Netflixbillboard700.jpg",
    title: "BIG SALE",
    subtitle: "For Limited Time",
    discount: "UP TO 50% OFF",
    width: "200",
    height: "200"
  }
];

export default function GoogleMarketingImages({
  orginalTemplateCreate,
  orginalTemplate,
  googleImages,
  setOpenedImage,
  setSelectedImage,
  setSelectedName,
  setSelectedDimension,
  selectedCampaign,
  navigationFrom,
  templateRefs
}) {
  console.log("googleImages", googleImages);
  const scaledTemplate0 = googleImages?.google?.[0]?.template
    .replace('width: "580px"', 'width: "520px"') // Change width to 290px
    .replace('height: "400px"', 'height: "400px"'); // Change height to 200px

  const savedScaledTemplate0 = googleImages?.[0]
    ?.replace('width: "580px"', 'width: "520px"') // Change width to 290px
    .replace('height: "400px"', 'height: "400px"'); // Change height to 200px

  const scaledTemplate1 = googleImages?.google?.[1]?.template
    .replace('width: "336px"', 'width: "300px"') // Change width to 290px
    .replace('height: "280px"', 'height: "280px"'); // Change height to 200px

  const savedscaledTemplate1 = googleImages?.[1]
    ?.replace('width: "336px"', 'width: "300px"') // Change width to 290px
    .replace('height: "280px"', 'height: "280px"'); // Change height to 200px

  const scaledTemplate5 = googleImages?.google?.[5]?.template
    .replace('width: "320px"', 'width: "270px"') // Change width to 290px
    .replace('height: "100px"', 'height: "100px"'); // Change height to 200px

  const savedScaledTemplate5 = googleImages?.[5]
    ?.replace('width: "320px"', 'width: "270px"') // Change width to 290px
    .replace('height: "100px"', 'height: "100px"'); // Change height to 200px

  const scaledTemplate6 = googleImages?.google?.[6]?.template
    .replace('width: "240px"', 'width: "170px"') // Change width to 290px
    .replace('height: "400px"', 'height: "400px"'); // Change height to 200px

  const savedScaledTemplate6 = googleImages?.[6]
    ?.replace('width: "240px"', 'width: "170px"') // Change width to 290px
    .replace('height: "400px"', 'height: "400px"'); // Change height to 200px

  const scaledTemplate7 = googleImages?.google?.[7]?.template
    .replace('width: "300px"', 'width: "185px"') // Change width to 290px
    .replace('height: "600px"', 'height: "550px"'); // Change height to 200px

  const savedScaledTemplate7 = googleImages?.[7]
    ?.replace('width: "300px"', 'width: "185px"') // Change width to 290px
    .replace('height: "600px"', 'height: "550px"'); // Change height to 200px

  const scaledTemplate8 = googleImages?.google?.[8]?.template
    .replace('width: "468px"', 'width: "468px"') // Change width to 290px
    .replace('height: "60px"', 'height: "60px"'); // Change height to 200px

  const savedScaledTemplate8 = googleImages?.[8]
    ?.replace('width: "468px"', 'width: "468px"') // Change width to 290px
    .replace('height: "60px"', 'height: "60px"'); // Change height to 200px

  const scaledTemplate10 = googleImages?.google?.[10]?.template
    .replace('width: "728px"', 'width: "500px"') // Change width to 290px
    .replace('height: "90px"', 'height: "90px"'); // Change height to 200px

  const savedScaledTemplate10 = googleImages?.[10]
    ?.replace('width: "728px"', 'width: "500px"') // Change width to 290px
    .replace('height: "90px"', 'height: "90px"'); // Change height to 200px

  const scaledTemplate11 = googleImages?.google?.[11]?.template
    .replace('width: "930px"', 'width: "730px"') // Change width to 290px
    .replace('height: "180px"', 'height: "180px"'); // Change height to 200px

  const savedScaledTemplate11 = googleImages?.[11]
    ?.replace('width: "930px"', 'width: "730px"') // Change width to 290px
    .replace('height: "180px"', 'height: "180px"'); // Change height to 200px

  const scaledTemplate12 = googleImages?.google?.[12]?.template
    .replace('width: "970px"', 'width: "800px"') // Change width to 290px
    .replace('height: "90px"', 'height: "90px"'); // Change height to 200px

  const savedScaledTemplate12 = googleImages?.[12]
    ?.replace('width: "970px"', 'width: "800px"') // Change width to 290px
    .replace('height: "90px"', 'height: "90px"'); // Change height to 200px

  const scaledTemplate13 = googleImages?.google?.[13]?.template
    .replace('width: "970px"', 'width: "800px"') // Change width to 290px
    .replace('height: "250px"', 'height: "250px"'); // Change height to 200px

  const savedScaledTemplate13 = googleImages?.[13]
    ?.replace('width: "970px"', 'width: "800px"') // Change width to 290px
    .replace('height: "250px"', 'height: "250px"'); // Change height to 200px

  const scaledTemplate14 = googleImages?.google?.[14]?.template
    .replace('width: "980px"', 'width: "840px"') // Change width to 290px
    .replace('height: "120px"', 'height: "120px"'); // Change height to 200px

  const savedScaledTemplate14 = googleImages?.[14]
    ?.replace('width: "980px"', 'width: "840px"') // Change width to 290px
    .replace('height: "120px"', 'height: "120px"'); // Change height to 200px

  const handleTemplateClick = (index) => {
    setOpenedImage(true);
    if (navigationFrom == "createFlow") {
      setSelectedImage(orginalTemplateCreate?.google?.[index]?.template);
      setSelectedName(orginalTemplateCreate?.google?.[index]?.name);
      setSelectedDimension(orginalTemplateCreate?.google?.[index]?.dimensions);
    } else {
      setSelectedImage(orginalTemplate[index]);
      setSelectedName(selectedCampaign?.advertisment_image[index]?.name);
      setSelectedDimension(
        selectedCampaign?.advertisment_image[index]?.dimensions
      );
    }
  };

  return (
    <>
      <GoogleImages
        googleImages={googleImages}
        handleTemplateClick={handleTemplateClick}
        navigationFrom={navigationFrom}
        templateRefs={templateRefs}
      />
    </>
  );
}
