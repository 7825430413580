import axios from "../../../api/axios";

const getWebsiteDetail = () => axios.get("customer/getWebsiteDetail");

const getCustomCalendar = (id) => axios.get(`calendar/getCustomCalender/${id}`);

const addCustomCalendar = (payload) =>
  axios.post("calendar/addCustomCalender", payload);

const editCustomCalendar = (payload) => {
  return axios.post("calendar/updateCustomCalender", payload);
};

const deleteCustomCalendar = (payload) => {
  return axios.post(`calendar/deleteCustomCalender`, payload);
};

const customCalendar = {
  getWebsiteDetail,
  getCustomCalendar,
  addCustomCalendar,
  editCustomCalendar,
  deleteCustomCalendar
};

export default customCalendar;
