/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import ProfileIcon from "../../assets/icons/profile2.svg";
import notificationIcon from "../../assets/icons/notificationIcon.svg";
import subscriptionIcon from "../../assets/icons/cash.svg";
import billingIcon from "../../assets/icons/reciepts.svg";
import userManagementIcon from "../../assets/icons/multiuser.svg";
import privacyPolicy from "../../assets/icons/privacy policy.svg";
import { Toastr } from "../../components/Toastr/Toastr";
import personaManagementIcon from "../../assets/icons/persona_management.svg";
import categoriesIcon from "../../assets/icons/categories.svg";
import AdvertisementStyleIcon from "../../assets/icons/AdvertisementStyleIcon.svg";

import "../stylesheets/settings.scss";
import SettingsRoutes from "./SettingsRoutes";

export default function Settings() {
  const { t } = useTranslation();
  const { websitesList } = useSelector((state) => state.generalData);

  const settings = [
    {
      id: 1,
      settingName: t("settings.profileSettings"),
      path: "/settings/profile",
      image: ProfileIcon
    },
    {
      id: 2,
      settingName: t("settings.notificationSettings"),
      path: "/settings/notification",
      image: notificationIcon
    },
    {
      id: 3,
      settingName: t("settings.userManagement"),
      path: "/settings/user-management",
      image: userManagementIcon
    },
    {
      id: 8,
      settingName: t("settings.personaManagement"),
      path: "/settings/persona-management",
      image: personaManagementIcon
    },
    {
      id: 9,
      settingName: t("settings.advertisementStyleSettings"),
      path: "/settings/advertisement-style",
      image: AdvertisementStyleIcon
    },
    {
      id: 10,
      settingName: t("settings.categories"),
      path: "/settings/categories",
      image: categoriesIcon
    },
    {
      id: 6,
      settingName: t("settings.subscription"),
      path: "/settings/subscription",
      image: subscriptionIcon
    },
    {
      id: 7,
      settingName: t("settings.billing"),
      path: "/settings/billing",
      image: billingIcon
    },
    {
      id: 5,
      settingName: t("settings.privacy"),
      path: "/settings/privacy",
      image: privacyPolicy
    }
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(settings[0]);

  useEffect(() => {
    const headEle = document.getElementsByClassName("authorized-header")[0];
    headEle.classList.add("settings-style");
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const settingPath = settings.find((ele) => ele.path === currentPath);
    if (settingPath) setSelected(settingPath);
  }, [location]);

  const handleSettingClick = (path) => {
    if (websitesList?.length < 1) {
      if (
        path === "/settings/user-management" ||
        path === "/settings/subscription"
      ) {
        Toastr.error(t("common.cannotAccessWebsites"));
      } else {
        setSelected(settings.find((setting) => setting.path === path));
        navigate(`${path}`);
      }
    } else {
      setSelected(settings.find((setting) => setting.path === path));
      navigate(`${path}`);
    }
    // navigate(`/settings/${path}`);
  };

  return (
    <>
      <AuthorizedHeader />
      <div className="flex-center settings">
        <div className="settings-body">
          <div className="modules-heading ">{t("settings.title")}</div>
          <div className="settings-grid">
            {/* Settings list */}
            <div
              style={
                !isMobile
                  ? { position: "absolute" }
                  : {
                      // width: "170px"
                    }
              }
              className="left-panel background-box"
            >
              {settings.map((ele) => (
                <div
                  key={ele.id}
                  onClick={() => handleSettingClick(ele.path)}
                  className={`setting-tile ${
                    selected.id === ele.id ? "selected" : ""
                  }`}
                >
                  {ele.image && (
                    <img
                      src={ele.image}
                      alt={`${ele.settingName} icon`}
                      className="setting-icon"
                    />
                  )}
                  <text>
                    {ele.settingName.length > 24
                      ? `${ele.settingName.substring(0, 24)}...`
                      : ele.settingName}
                  </text>
                </div>
              ))}
            </div>
            {/* Settings list end */}

            {/* Settings details */}
            <div
              style={
                isMobile
                  ? {
                      width: "1000px",
                      position: "relative",
                      left: "100px"
                    }
                  : {}
              }
              className="right-panel background-box"
            >
              <SettingsRoutes />
            </div>
            {/* Settings details end */}
          </div>
        </div>
      </div>
    </>
  );
}
