import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
import store from "./store/store";
import App from "./App";
import "./assets/stylesheets/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

function RNRedux() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  );
}

root.render(<RNRedux />);
