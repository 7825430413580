import PropTypes from "prop-types";
import Notification from "./components/Notification";

export default function Index({ handleMarkRead }) {
  return <Notification handleMarkRead={handleMarkRead} />;
}

Index.propTypes = {
  handleMarkRead: PropTypes.func
};
