import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LineChart from "./components/LineChart";
import PopUpWindow from "../../../../components/PopUpWindow";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import ChartHeader from "../../../../components/ChartHeader/ChartHeader";

export default function Index({
  labels,
  views,
  visitors,
  secondViews,
  secondVisitors,
  title
}) {
  const { t } = useTranslation();
  const { isCompare } = useSelector((state) => state.generalData);
  const [togglePopUp, setTogglePopUp] = useState(false);
  const data = isCompare
    ? {
        labels,
        datasets: [
          {
            label: `${t("analyticsPage.visitors")} (${t("common.range")} A)`,
            data: [...(visitors || [])],
            backgroundColor: themeColors.primaryColor,
            borderColor: themeColors.primaryColor,
            borderWidth: 2,
            borderCapStyle: "round",
            lineTension: 0.3
          },
          {
            label: `${t("analyticsPage.views")} (${t("common.range")} A)`,
            data: [...(views || [])],
            backgroundColor: themeColors.primaryColor5,
            borderColor: themeColors.primaryColor5,
            borderWidth: 2,
            borderCapStyle: "round",
            lineTension: 0.3
          },
          {
            label: `${t("analyticsPage.visitors")} (${t("common.range")} B)`,
            data: [...(secondVisitors || [])],
            backgroundColor: `${themeColors.primaryColor}66`,
            borderColor: themeColors.primaryColor,
            borderWidth: 2,
            borderDash: [5, 8],
            borderCapStyle: "round",
            lineTension: 0.3
          },
          {
            label: `${t("analyticsPage.views")} (${t("common.range")} B)`,
            data: [...(secondViews || [])],
            backgroundColor: `${themeColors.primaryColor5}66`,
            borderColor: themeColors.primaryColor5,
            borderWidth: 2,
            borderDash: [5, 8],
            borderCapStyle: "round",
            lineTension: 0.3
          }
        ]
      }
    : {
        labels,
        datasets: [
          {
            label: t("analyticsPage.visitors"),
            data: [...(visitors || [])],
            backgroundColor: themeColors.primaryColor,
            borderWidth: 1,
            borderCapStyle: "round",
            lineTension: 0.3,
            fill: true
          },
          {
            label: t("analyticsPage.views"),
            data: [...(views || [])],
            backgroundColor: themeColors.primaryColor5,
            borderWidth: 1,
            borderCapStyle: "round",
            lineTension: 0.3,
            fill: true
          }
        ]
      };

  const customLabel = (tooltipItem) => {
    return ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
  };

  const options = {
    animation: false,
    type: "line",
    responsive: true,
    devicePixelRatio: themeColors.devicePixelRatio,
    scales: {
      x: {
        grid: {
          display: false
        },
        beginAtZero: false,
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor
        }
      },
      y: {
        stacked: false,
        border: { dash: [4, 5] },
        grid: {
          color: themeColors.tertiaryText, // for the grid lines
          offset: false,
          drawTicks: false, // true is default
          drawOnChartArea: true // true is default
        },
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor
        },
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor
        }
      },
      tooltip: {
        cornerRadius: 10,
        backgroundColor: themeColors.primaryText,
        displayColors: false,
        padding: "10",
        titleFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        bodyFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        callbacks: {
          label: customLabel
        }
      }
    },
    elements: {
      point: {
        radius: 4 // Set the point radius to 0 to remove the dots
      }
    }
  };

  return (
    <div className="linechart-body background-box">
      {/* Line chart header */}
      <ChartHeader
        title={title}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
      />
      {/* Line chart header ends */}

      {/* Line chart */}
      <div>
        <LineChart data={data} options={options} />
      </div>
      {/* Line chart ends */}

      {/* Line chart expand */}
      {togglePopUp && (
        <PopUpWindow className="line-chart">
          <ChartHeader
            title={title}
            togglePopUp={togglePopUp}
            setTogglePopUp={setTogglePopUp}
          />

          <LineChart data={data} options={options} />
        </PopUpWindow>
      )}
      {/* Line chart expand ends */}
    </div>
  );
}

Index.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  visitors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  views: PropTypes.oneOfType([PropTypes.array]).isRequired,
  secondVisitors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  secondViews: PropTypes.oneOfType([PropTypes.array]).isRequired
};
