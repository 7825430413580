import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { saveAs } from "file-saver";
import { utils, writeFile } from "xlsx";
import { unparse } from "papaparse";
import DataExportIcon from "../../assets/icons/dataExport.svg";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./assests/stylesheets/dataexport.scss";
import CalendarButtonCombination from "../../components/DateRange/components/CalendarButtonCombination/CalendarButtonCombination";
import axios from "../../api/axios";
import "moment/locale/en-gb";
import { Toastr } from "../../components/Toastr/Toastr";
import DropdownComponent from "../../components/inputElements/selectInput/DropdownComponent";
import "moment/locale/it";
import "moment/locale/de";
import LocalStorage from "../../utils/LocalStorgae";

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function Index() {
  const { t } = useTranslation();
  const { websiteID, createdBy } = useSelector((state) => state?.generalData);
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validateStartDate, setValidateStartDate] = useState(false);
  const [validateEndDate, setValidateEndDate] = useState(false);
  const [validateDate, setValidateDate] = useState(false);
  const [validateDashboard, setValidateDashboard] = useState(false);
  const [validateFileType, setValidateFileType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboardList, setDashboardList] = useState([]);
  const language = LocalStorage.getItem("selectedLanguage");
  moment.locale(language);

  const fileTypeOptions = [
    { label: ".csv", value: "csv" },
    {
      label: ".xlsx",
      value: "xlsx"
    }
  ];

  const getDashboardList = () => {
    axios
      .get(`/export/getDashboard/${websiteID}`)
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.dashboardResult.length > 0) {
            const dashboardListFordropdown = res.data.dashboardResult.map(
              (item) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                return { ...item, label: value, value: key };
              }
            );
            setDashboardList(dashboardListFordropdown);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getDashboardList();
  }, [websiteID]);

  const handleDashboard = (e) => {
    setSelectedDashboard(e.target.value);
    setValidateDashboard(false);
  };

  const handleFileType = (e) => {
    setSelectedFileType(e.target.value);
    setValidateFileType(false);
  };

  const handleStartDate = (newValue) => {
    setStartDate(newValue);
    setValidateStartDate(false);
    setValidateDate(false);
  };

  const handleEndDate = (newValue) => {
    setEndDate(newValue);
    setValidateEndDate(false);
  };

  const handleReset = () => {
    setSelectedDashboard("");
    setSelectedFileType("");
    setStartDate(null);
    setEndDate(null);
    setValidateStartDate(false);
    setValidateEndDate(false);
    setValidateDate(false);
    setValidateFileType(false);
    setValidateDashboard(false);
  };

  const downloadCSV = (data, filename) => {
    const csv = unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  };

  const downloadXLSX = (data, filename) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, filename);
  };

  const handleExport = () => {
    if (
      startDate === null ||
      endDate === null ||
      selectedDashboard === "" ||
      selectedFileType === ""
    ) {
      if (selectedDashboard === "") setValidateDashboard(true);
      if (selectedFileType === "") setValidateFileType(true);
      if (startDate === null) setValidateStartDate(true);
      if (endDate === null) setValidateEndDate(true);
    } else if (startDate.isAfter(endDate)) {
      setValidateDate(true);
    } else {
      setLoading(true);
      const payload = {
        website_id: websiteID,
        dashboard: selectedDashboard,
        startDate: moment(startDate).format("DD-MM-YYYY"),
        endDate: moment(endDate).format("DD-MM-YYYY"),
        customer_id: createdBy
      };
      axios
        .post("/export/data-export", payload)
        .then((res) => {
          setLoading(false);
          if (res.data.status === true) {
            if (res.data.exportDataResult.length > 0) {
              if (selectedFileType === "csv") {
                downloadCSV(
                  res.data.exportDataResult,
                  selectedDashboard.concat(".csv")
                );
              } else if (selectedFileType === "xlsx") {
                downloadXLSX(
                  res.data.exportDataResult,
                  selectedDashboard.concat(".xlsx")
                );
              }
            }
            if (res?.data?.secondSheetData?.length > 0) {
              if (selectedFileType === "csv") {
                downloadCSV(
                  res.data.secondSheetData,
                  selectedDashboard.concat("_QA.csv")
                );
              } else if (selectedFileType === "xlsx") {
                downloadXLSX(
                  res.data.secondSheetData,
                  selectedDashboard.concat("_QA.xlsx")
                );
              }
            }

            Toastr.success(t("dataExport.exportedSuccess"));
            setSelectedDashboard("");
            setSelectedFileType("");
            setStartDate(null);
            setEndDate(null);
          }
          if (res.data.status === false) {
            if (res.data.exportDataResult.length < 1) {
              Toastr.error(t("dataExport.noDataFound"));
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          setSelectedDashboard("");
          setSelectedFileType("");
          setStartDate(null);
          setEndDate(null);
        });
    }
  };

  return (
    <div className="bargraph-body background-box">
      <div className="data-export">
        <div className="data-export-image">
          <img src={DataExportIcon} alt="data-export" />
        </div>
        <div className="data-export-content">
          <div className="data-export-header">{t("dataExport.exportData")}</div>
          <div
            className="data-export-header"
            style={{ marginBottom: "5px", color: themeColors.secondaryText }}
          >
            {t("dataExport.selectDashboard")}
          </div>
          <DropdownComponent
            value={
              selectedDashboard === ""
                ? t("dataExport.selectDashboard")
                : selectedDashboard
            }
            options={dashboardList}
            onChange={(e) => handleDashboard(e)}
            isRequired={false}
            defaultValue={t("dataExport.selectDashboard")}
            marginTop="0px"
            error={validateDashboard && t("dataExport.pleaseSelectDashboard")}
          />
          {validateDashboard && (
            <span
              className="data-export-header"
              style={{
                marginTop: "0px",
                color: themeColors.negative,
                fontSize: "1rem",
                fontFamily: themeColors.fontQuickSand,
                fontWeight: 500,
                letterSpacing: "0.8px"
              }}
            >
              {t("dataExport.pleaseSelectDashboard")}
            </span>
          )}
          <div
            className="data-export-header"
            style={{ marginBottom: "2px", color: themeColors.secondaryText }}
          >
            {t("dataExport.SelectFileType")}
          </div>
          <DropdownComponent
            value={
              selectedFileType === ""
                ? t("dataExport.SelectFileType")
                : selectedFileType
            }
            options={fileTypeOptions}
            onChange={(e) => handleFileType(e)}
            isRequired={false}
            defaultValue={t("dataExport.SelectFileType")}
            marginTop="0px"
            error={validateFileType && t("dataExport.pleaseSelectFiletype")}
          />
          {validateFileType && (
            <span
              className="data-export-header"
              style={{
                marginTop: "0px",
                color: themeColors.negative,
                fontSize: "1rem",
                fontFamily: themeColors.fontQuickSand,
                fontWeight: 500,
                letterSpacing: "0.8px"
              }}
            >
              {t("dataExport.pleaseSelectFiletype")}
            </span>
          )}
          <div className="half-column-grid" style={{ width: "98%" }}>
            <div>
              <LabelInputSet>
                <label
                  htmlFor="from-date"
                  className="data-export-header"
                  style={{
                    marginBottom: "0px",
                    color: themeColors.secondaryText
                  }}
                >
                  {t("dataExport.fromDate")}
                </label>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={language}
                >
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextField
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...params}
                        />
                      )}
                      slotProps={{
                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: themeColors.primaryColor
                            },
                            "&.MuiPickersDay-root": {
                              "&:hover": {
                                backgroundColor: themeColors.primaryColor4
                              }
                            }
                          }
                        }
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
                {validateStartDate && (
                  <span
                    className="data-export-header"
                    style={{
                      marginTop: "9px",
                      color: themeColors.negative,
                      fontSize: "1rem",
                      fontFamily: themeColors.fontQuickSand,
                      fontWeight: 500,
                      letterSpacing: "0.8px"
                    }}
                  >
                    {t("dataExport.pleaseSelectStartDate")}
                  </span>
                )}
                {validateDate && (
                  <span
                    className="data-export-header"
                    style={{
                      marginTop: "9px",
                      color: themeColors.negative,
                      fontSize: "1rem",
                      fontFamily: themeColors.fontQuickSand,
                      fontWeight: 500,
                      letterSpacing: "0.8px"
                    }}
                  >
                    {t("dataExport.dateValidation")}
                  </span>
                )}
              </LabelInputSet>
            </div>
            <div>
              <LabelInputSet>
                <label
                  htmlFor="to-date"
                  className="data-export-header"
                  style={{
                    marginBottom: "0px",
                    color: themeColors.secondaryText
                  }}
                >
                  {t("dataExport.toDate")}
                </label>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={language}
                >
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <TextField
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...params}
                        />
                      )}
                      slotProps={{
                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: themeColors.primaryColor
                            },
                            "&.MuiPickersDay-root": {
                              "&:hover": {
                                backgroundColor: themeColors.primaryColor4
                              }
                            }
                          }
                        }
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
                {validateEndDate && (
                  <span
                    className="data-export-header"
                    style={{
                      marginTop: "9px",
                      color: themeColors.negative,
                      fontSize: "1rem",
                      fontFamily: themeColors.fontQuickSand,
                      fontWeight: 500,
                      letterSpacing: "0.8px"
                    }}
                  >
                    {t("dataExport.pleaseSelectEndDate")}
                  </span>
                )}
              </LabelInputSet>
            </div>
          </div>
          <div className="buttons">
            <CalendarButtonCombination
              firstButtonText={t("common.reset")}
              secondButtonText={t("dataExport.export")}
              onClickFirstButton={handleReset}
              onClickSecondButton={handleExport}
              loading={loading}
              loader={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

LabelInputSet.propTypes = {
  children: PropTypes.node
};
