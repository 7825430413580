/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../../components/inputElements/Input/Input";
import {
  ButtonDark,
  ButtonLight
} from "../../../components/inputElements/buttons/MainButton";
import Copy from "../assets/icons/copy.svg";
import { Toastr } from "../../../components/Toastr/Toastr";
import "../assets/stylesheets/myWebsites.scss";

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function EditWebsiteDetails({
  website,
  handleCloseModal,
  addWebsiteName
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const snippet = `<script crossorigin="anonymous" defer="defer" type="application/javascript" src="${process.env.REACT_APP_SNIPPET_URL}snipped/getSnipped/${website?.track_token}"></script>`;

  const adjustHeight = () => {
    if (inputRef?.current) {
      inputRef.current.style.height = "auto"; // Reset the height to auto
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Set height to scrollHeight
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height initially
  }, [snippet]);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { ...website, snippet } ?? {
      url: "",
      name: "",
      code: ""
    }
  });

  const copyValue = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(snippet)
        .then(() => {
          Toastr.success(t("addScript.copiedToClipboard"));
        })
        .catch((error) => {
          Toastr.error(t("addScript.failedToCopy"), error);
        });
    }
  };

  const updateName = async (data) => {
    if (data.name !== website.name) {
      setLoading(true);
      await addWebsiteName(website.id, data.name);
      setLoading(false);
      handleCloseModal();
    }
  };

  return (
    <form onSubmit={handleSubmit(updateName)} className="flex-vertical-between">
      <div className="grid">
        <LabelInputSet>
          <label htmlFor="url" className="label-tag">
            {t("addWebsite.websiteLink")}
          </label>
          <Input
            disabled
            id="url"
            type="text"
            name="url"
            register={register("url", { required: true })}
          />
        </LabelInputSet>

        <LabelInputSet>
          <label htmlFor="name" className="label-tag">
            {t("addWebsite.websiteName")}
          </label>
          <Input
            id="name"
            type="text"
            name="name"
            register={register("name", { required: true })}
          />
          <label htmlFor="web-link" className="web-link flex-start">
            <input
              type="checkbox"
              id="web-link"
              name="website-link"
              onClick={(e) => {
                if (e.target.checked) setValue("name", website.url);
                else setValue("name", website.name);
              }}
            />
            {t("addWebsite.sameAsWebsiteLink")}
          </label>
        </LabelInputSet>
        <LabelInputSet>
          <div className="flex-between">
            <div className="snippet">
              Javascript snippet
              <div className="helper-text">
                {" "}
                {t("addWebsite.includeSnippet")}
              </div>
            </div>
            <div className="copy-icon flex-center">
              <img src={Copy} alt="copy-icon" onClick={copyValue} />
            </div>
          </div>
          <textarea
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "rgb(142 160 59)",
              borderRadius: "10px",
              overflow: "hidden",
              resize: "none"
              // height: "221px"
            }}
            className="snippet-text"
            ref={inputRef}
            rows={snippet.length / 70}
            // rows={1}
            value={snippet}
            readOnly
          />
        </LabelInputSet>
      </div>

      <div style={{ marginTop: "1em" }} className="flex-center">
        <ButtonLight
          buttonText={t("common.cancelButton")}
          onClick={() => handleCloseModal()}
        />
        <ButtonDark
          isSubmit
          buttonText={loading ? "Loading..." : t("common.saveButton")}
          className="tagManagerButton"
        />
      </div>
    </form>
  );
}

EditWebsiteDetails.propTypes = {
  website: PropTypes.oneOfType([Object]),
  addWebsiteName: PropTypes.func,
  handleCloseModal: PropTypes.func
};

LabelInputSet.propTypes = {
  children: PropTypes.node
};
