import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DonutChart from "./components/DonutChart";
import PopUpWindow from "../../PopUpWindow";
import themeColors from "../../../assets/stylesheets/_var.scss";
import ChartHeader from "../../ChartHeader/ChartHeader";
import "./assets/stylesheets/donutChart.scss";

export default function Index({
  title,
  disabled,
  labels,
  dataSet,
  compareData,
  updateFilters,
  selectedFilters,
  tooltipLabel = "visitors"
}) {
  const { t } = useTranslation();

  const [togglePopUp, setTogglePopUp] = useState(false);

  const { isCompare } = useSelector((state) => state.generalData);
  const border = [
    themeColors.background,
    themeColors.background,
    themeColors.background
  ];

  const disableColorData1 = ["#52505077", "#52505077", "#52505077"];
  const disableColorData2 = ["#969393", "#969393", "#969393"];

  const getSelectedColor = () => {
    const colors = [
      themeColors.primaryColor,
      themeColors.primaryColorShade2,
      themeColors.primaryColor3,
      themeColors.primaryColor4
    ];
    if (!Object.keys(selectedFilters).includes(title)) return colors;

    return labels?.map((ele, i) => {
      if (selectedFilters[title].includes(ele)) return colors[i];
      return `${colors[i]}80`;
    });
  };

  const colorArrayForData1 = disabled ? disableColorData1 : getSelectedColor();
  const colorArrayForData2 = disabled
    ? disableColorData2
    : [
        themeColors.secondaryColor,
        themeColors.secondaryColor2,
        themeColors.secondaryColorShade2
      ];

  const data = isCompare
    ? {
        labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: colorArrayForData1,
            borderColor: border,
            hoverBorderColor: colorArrayForData1,
            hoverBackgroundColor: colorArrayForData1
          },
          {
            data: compareData,
            backgroundColor: colorArrayForData2,
            borderColor: border,
            hoverBorderColor: colorArrayForData2,
            hoverBackgroundColor: colorArrayForData2
          }
        ]
      }
    : {
        labels,
        datasets: [
          {
            label: title,
            data: dataSet,
            backgroundColor: colorArrayForData1,
            borderColor: border,
            hoverBorderColor: colorArrayForData1,
            hoverBackgroundColor: colorArrayForData1
          }
        ]
      };

  const customLabel = (tooltipItem) => {
    return `${tooltipLabel}: ${tooltipItem.formattedValue}`;
  };
  const onHover = (event, chartElement) => {
    const { native } = event;
    native.target.style.cursor = chartElement[0] ? "pointer" : "default";
  };
  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end"
      },
      tooltip: disabled
        ? { enabled: false }
        : {
            cornerRadius: 10,
            backgroundColor: themeColors.primaryText,
            displayColors: false,
            padding: "10",
            titleFont: {
              family: themeColors.fontQuickSand,
              size: "16"
            },
            bodyFont: {
              family: themeColors.fontQuickSand,
              size: "16"
            },
            callbacks: {
              label: customLabel,

              labelTextColor: () => {
                return themeColors.primaryColor3;
              }
            }
          }
    },
    onHover
  };

  const disableMsg = t("analyticsPage.disableMsg");

  return (
    <div className="bargraph-body background-box">
      <ChartHeader
        title={title}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
      />
      <div
        className="flex-center"
        style={{
          position: "relative",
          height: isCompare ? "28vw" : "20vw"
        }}
      >
        <DonutChart
          data={data}
          options={options}
          updateFilters={updateFilters}
          selectedFilters={selectedFilters}
        />
        {disabled && <div className="donut disabled">{disableMsg}</div>}
      </div>
      {togglePopUp && (
        <PopUpWindow className="donut-chart background-box">
          <ChartHeader
            title={title}
            togglePopUp={togglePopUp}
            setTogglePopUp={setTogglePopUp}
          />
          <div
            className="flex-center"
            style={{
              position: "relative",
              height: "100%"
            }}
          >
            <DonutChart
              data={data}
              options={options}
              updateFilters={updateFilters}
              selectedFilters={selectedFilters}
            />
            {disabled && <div className="donut disabled">{disableMsg}</div>}
          </div>
        </PopUpWindow>
      )}
    </div>
  );
}

Index.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  labels: PropTypes.oneOfType([Array]),
  dataSet: PropTypes.oneOfType([Array]),
  compareData: PropTypes.oneOfType([Array]),
  updateFilters: PropTypes.func,
  selectedFilters: PropTypes.oneOfType([Object]),
  tooltipLabel: PropTypes.string
};
