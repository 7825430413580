import { useState } from "react";
// import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MultiBarChart from "./components/MultiBarChart";
import themeColors from "../../../assets/stylesheets/_var.scss";
import PopUpWindow from "../../PopUpWindow";
import ChartHeader from "../../ChartHeader/ChartHeader";
import { convertNumber } from "../../../utils/dataFilter";
import "./assets/multiBarChart.scss";

export default function Index({
  filterTitle,
  title,
  labels,
  compareLabels,
  data1,
  compareData1,
  data2,
  compareData2,
  data3,
  compareData3,
  isCompare,
  filterValues,
  dashboard,
  multiBarChart
}) {
  // data1, data2, data3
  // data structure:
  // data1={
  //   dataset: [2384,172,2397],
  //   label: visitors
  //  }
  const [togglePopUp, setTogglePopup] = useState(false);

  // const { t } = useTranslation();

  const getSelectedColor = (label, color) => {
    if (!Object.keys(filterValues).includes(filterTitle))
      return label?.map(() => `${themeColors[color]}`);

    return label?.map((ele) => {
      if (filterValues[filterTitle].includes(ele))
        return `${themeColors[color]}`;
      return `${themeColors[color]}77`;
    });
  };

  const dataset1 = {
    labels,
    datasets: [
      {
        label: data1?.label,
        backgroundColor: isCompare
          ? getSelectedColor(labels, "negativeDark")
          : getSelectedColor(labels, "negative"),
        borderColor: isCompare
          ? getSelectedColor(labels, "negativeDark")
          : getSelectedColor(labels, "negative"),
        borderWidth: 1,
        hoverBackgroundColor: isCompare
          ? getSelectedColor(labels, "negativeDark")
          : getSelectedColor(labels, "negative"),
        hoverBorderColor: isCompare
          ? getSelectedColor(labels, "negativeDark")
          : getSelectedColor(labels, "negative"),
        data: data1?.dataset
      }
    ]
  };
  const compareDataset1 = {
    labels: compareLabels,
    datasets: [
      {
        label: compareData1?.label,
        backgroundColor: getSelectedColor(compareLabels, "negative"),
        borderColor: getSelectedColor(compareLabels, "negative"),
        borderWidth: 1,
        hoverBackgroundColor: getSelectedColor(compareLabels, "negative"),
        hoverBorderColor: getSelectedColor(compareLabels, "negative"),
        data: compareData1?.dataset
      }
    ]
  };

  const dataset2 = {
    labels,
    datasets: [
      {
        label: data2?.label,
        backgroundColor: getSelectedColor(labels, "primaryColor"),
        borderColor: getSelectedColor(labels, "primaryColor"),
        borderWidth: 1,
        hoverBackgroundColor: getSelectedColor(labels, "primaryColor"),
        hoverBorderColor: getSelectedColor(labels, "primaryColor"),
        data: data2?.dataset
      }
    ]
  };
  const compareDataset2 = {
    labels: compareLabels,
    datasets: [
      {
        label: compareData2?.label,
        backgroundColor: getSelectedColor(compareLabels, "primaryColor5"),
        borderColor: getSelectedColor(compareLabels, "primaryColor5"),
        borderWidth: 1,
        hoverBackgroundColor: getSelectedColor(compareLabels, "primaryColor5"),
        hoverBorderColor: getSelectedColor(compareLabels, "primaryColor5"),
        data: compareData2?.dataset
      }
    ]
  };

  const dataset3 = {
    labels,
    datasets: [
      {
        label: data3?.label,
        backgroundColor: isCompare
          ? getSelectedColor(labels, "secondaryColor")
          : getSelectedColor(labels, "primaryColor5"),
        borderColor: isCompare
          ? getSelectedColor(labels, "secondaryColor")
          : getSelectedColor(labels, "primaryColor5"),
        borderWidth: 1,
        hoverBackgroundColor: isCompare
          ? getSelectedColor(labels, "secondaryColor")
          : getSelectedColor(labels, "primaryColor5"),
        hoverBorderColor: isCompare
          ? getSelectedColor(labels, "secondaryColor")
          : getSelectedColor(labels, "primaryColor5"),
        data: data3?.dataset
      }
    ]
  };
  const compareDataset3 = {
    labels: compareLabels,
    datasets: [
      {
        label: compareData3?.label,
        backgroundColor: getSelectedColor(
          compareLabels,
          "secondaryColorShade2"
        ),
        borderColor: getSelectedColor(compareLabels, "secondaryColorShade2"),
        borderWidth: 1,
        hoverBackgroundColor: getSelectedColor(
          compareLabels,
          "secondaryColorShade2"
        ),
        hoverBorderColor: getSelectedColor(
          compareLabels,
          "secondaryColorShade2"
        ),
        data: compareData3?.dataset
      }
    ]
  };

  const customTooltip = (tooltipItem) => {
    return ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
  };

  const onHover = (event, chartElement) => {
    const { native } = event;
    native.target.style.cursor = chartElement[0] ? "pointer" : "default";
  };

  const customLabelY = (item) => {
    return `${convertNumber(item)}`;
  };

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: themeColors.devicePixelRatio,
    plugins: {
      legend: {
        onClick: () => null,
        position: "top",
        align: "end",
        labels: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor
        }
      },
      title: {
        display: false
      },
      tooltip: {
        cornerRadius: 10,
        backgroundColor: themeColors.primaryText,
        displayColors: false,
        padding: "10",
        titleFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        bodyFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        callbacks: {
          label: customTooltip
        }
      }
    },

    scales: {
      x: {
        border: { dash: [4, 5] },
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor,
          align: "center",
          callback: customLabelY
        }
      }
    },
    onHover
  };

  const options3 = {
    ...options,
    scales: {
      x: {
        border: { dash: [4, 5] },
        grid: {
          display: false
        },
        ticks: {
          // autoSkip: false,
          maxRotation: 25,
          minRotation: 25,
          align: "center",
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor
        }
      },
      y: {
        grid: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor,
          align: "center",
          callback: customLabelY
        }
      }
    }
  };

  const ChartContent = (
    <div className="multibar-body background-box" style={{ padding: "10px" }}>
      <ChartHeader
        title={title}
        multiBarChart={multiBarChart}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopup}
        dashboard={dashboard}
      />
      {isCompare ? (
        <>
          <div className="chart-container compare">
            <MultiBarChart
              filterTitle={filterTitle}
              data={dataset1}
              options={options}
              filterValues={filterValues}
              dashboard={dashboard}
            />
            <MultiBarChart
              filterTitle={filterTitle}
              data={compareDataset1}
              options={options}
              filterValues={filterValues}
              dashboard={dashboard}
            />
          </div>
          <div className="chart-container compare">
            <MultiBarChart
              filterTitle={filterTitle}
              data={dataset2}
              options={options}
              filterValues={filterValues}
              dashboard={dashboard}
            />
            <MultiBarChart
              filterTitle={filterTitle}
              data={compareDataset2}
              options={options}
              filterValues={filterValues}
              dashboard={dashboard}
            />
          </div>
          <div className="chart-container compare">
            <MultiBarChart
              filterTitle={filterTitle}
              data={dataset3}
              options={options3}
              filterValues={filterValues}
              dashboard={dashboard}
            />
            <MultiBarChart
              filterTitle={filterTitle}
              data={compareDataset3}
              options={options3}
              filterValues={filterValues}
              dashboard={dashboard}
            />
          </div>
        </>
      ) : (
        <>
          <div className="chart-container">
            <MultiBarChart
              filterTitle={filterTitle}
              data={dataset1}
              options={options}
              filterValues={filterValues}
              dashboard={dashboard}
            />
          </div>
          <div className="chart-container">
            <MultiBarChart
              filterTitle={filterTitle}
              data={dataset2}
              options={options}
              filterValues={filterValues}
              dashboard={dashboard}
            />
          </div>
          <div className="chart-container">
            <MultiBarChart
              filterTitle={filterTitle}
              data={dataset3}
              options={options3}
              filterValues={filterValues}
              dashboard={dashboard}
            />
          </div>
        </>
      )}
    </div>
  );

  if (togglePopUp)
    return <PopUpWindow className="stacked-chart">{ChartContent}</PopUpWindow>;

  return ChartContent;
}

Index.propTypes = {
  title: PropTypes.string,
  filterTitle: PropTypes.string,
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  compareLabels: PropTypes.oneOfType([PropTypes.array]),
  data1: PropTypes.oneOfType([PropTypes.object]).isRequired,
  compareData1: PropTypes.oneOfType([PropTypes.object]),
  data2: PropTypes.oneOfType([PropTypes.object]).isRequired,
  compareData2: PropTypes.oneOfType([PropTypes.object]),
  data3: PropTypes.oneOfType([PropTypes.object]).isRequired,
  compareData3: PropTypes.oneOfType([PropTypes.object]),
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dashboard: PropTypes.string,
  multiBarChart: PropTypes.bool
};
