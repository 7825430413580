/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  website_id: null,
  session_id: "",
  page: "",
  component: "",
  component_label: "",
  actions: 0
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    // Initialize the session with website_id and page, reset previous state
    initializeSession: (state, action) => {
      const { website_id, page, session_id } = action.payload;

      // Generate a new session ID on every app load (new session each time)

      state.website_id = website_id || state.website_id;
      state.page = page || window.location.pathname;
      state.session_id = session_id;

      // Reset actions to 0 (clear previous session's actions)
      state.actions = 0;
    },

    // Update the component and component_label dynamically
    updateComponent: (state, action) => {
      const { component, component_label } = action.payload;
      state.component = component;
      state.component_label = component_label;
    },

    // Increment actions to 1
    incrementActions: (state) => {
      state.actions = 1;
    },

    // New reducer to handle page and website updates
    updatePageAndWebsite: (state, action) => {
      const { page, website_id } = action.payload;
      state.page = page;
      state.website_id = website_id;
    },

    // Reset the tracking state except for website_id and session_id
    resetTracking: (state) => {
      state.component = "";
      state.component_label = "";
      state.actions = 0;
    },

    resetSession: (state) => {
      sessionStorage.removeItem("sessionId"), (state.session_id = uuidv4()); //Reset the session ID when user refreshes or reopens the page
      sessionStorage.setItem("sessionId", state.session_id),
        (state.actions = 0);
    }
  }
});

export const {
  initializeSession,
  updateComponent,
  incrementActions,
  updatePageAndWebsite,
  resetTracking,
  resetSession
} = trackingSlice.actions;

export default trackingSlice.reducer;
