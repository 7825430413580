/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Wearesearching from "../../assets/icons/Wearesearching.png";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./assets/stylesheets/placeholders.scss";

export default function NoDataFound() {
  const { websiteID } = useSelector((state) => state?.generalData);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex-center">
      <div className="placeholder-background background-box">
        <img
          style={{
            width: "400px",
            height: "300px",
            marginTop: "19px"
          }}
          src={Wearesearching}
          alt="no-data-found"
        />
        <div className="flex-vertical">
          <span>{t("noDataFound.searching")}</span>
          <span
            style={{
              fontFamily: themeColors.fontQuickSand,
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
              color: "rgba(181, 181, 181, 1)"
            }}
          >
            {t("noDataFound.integrateWebsite")}
          </span>
          <span
            style={{
              fontFamily: themeColors.fontQuickSand,
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20.7px",
              paddingTop: "12px",
              paddingBottom: "19px",
              color: "black",
              cursor: "pointer"
            }}
            onClick={() =>
              navigate("/mySites", {
                state: { showPopUp: true, websiteId: websiteID }
              })
            }
          >
            {t("noDataFound.showScript")}
          </span>
        </div>
      </div>
    </div>
  );
}
