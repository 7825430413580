import { createSlice } from "@reduxjs/toolkit";
import { modifyData } from "../../utils/dataPersonaFilter";
import { getPersonasData } from "../apiSlice";

const initialState = {
  allData: [],
  filteredData: [],
  personasData: {},
  categoriesData: {},
  worldData: {},
  countriesData: {},
  viewsVisitorsData: {},
  citiesData: {},
  totalSpendData: {},
  ageData: {},
  genderData: {},
  visitorsLineData: {},
  roomsData: {},
  pageUrlData: {},
  sourceData: {},
  mediumData: {},
  campaignData: {},
  termData: {},
  contentData: {},
  selectedPersonaFilterType: "Daily",
  isLoading: true,
  pmsToggleActivation: false
};

const personasDataSlice = createSlice({
  name: "PersonasData",
  initialState,
  reducers: {
    updateFilteredData: (state, actions) => {
      return { ...state, filteredData: [...actions.payload] };
    },
    updatePersonaData: (state, actions) => {
      return { ...state, personasData: { ...actions.payload } };
    },
    updateCategoriesData: (state, actions) => {
      return { ...state, categoriesData: { ...actions.payload } };
    },
    updateWorldData: (state, actions) => {
      return { ...state, worldData: { ...actions.payload } };
    },
    updateCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    updateIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateViewsVisitorsData: (state, actions) => {
      return { ...state, viewsVisitorsData: { ...actions.payload } };
    },
    updateCitiesData: (state, actions) => {
      return { ...state, citiesData: { ...actions.payload } };
    },
    updateAgeData: (state, actions) => {
      return { ...state, ageData: { ...actions.payload } };
    },
    updateGenderData: (state, actions) => {
      return { ...state, genderData: { ...actions.payload } };
    },
    updateTotalSpendData: (state, actions) => {
      return { ...state, totalSpendData: { ...actions.payload } };
    },
    updateVisitorsLineData: (state, actions) => {
      return { ...state, visitorsLineData: { ...actions.payload } };
    },
    updateSelectedPersonaFilterType: (state, action) => {
      return { ...state, selectedPersonaFilterType: action.payload };
    },
    updateRoomsData: (state, actions) => {
      return { ...state, roomsData: { ...actions.payload } };
    },
    updatePageUrlData: (state, actions) => {
      return { ...state, pageUrlData: { ...actions.payload } };
    },
    updateSourceData: (state, actions) => {
      return { ...state, sourceData: { ...actions.payload } };
    },
    updateMediumData: (state, actions) => {
      return { ...state, mediumData: { ...actions.payload } };
    },
    updateCampaignData: (state, actions) => {
      return { ...state, campaignData: { ...actions.payload } };
    },
    updateTermData: (state, actions) => {
      return { ...state, termData: { ...actions.payload } };
    },
    updateContentData: (state, actions) => {
      return { ...state, contentData: { ...actions.payload } };
    }
  },
  extraReducers: {
    [getPersonasData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getPersonasData.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        pmsToggleActivation: action.payload.pmsToggleActivation,
        allData: modifyData([...action.payload.result])
      };
    },
    [getPersonasData.rejected]: (state) => {
      return { ...state, isLoading: false };
    }
  }
});

export const {
  updateFilteredData,
  updatePersonaData,
  updateCategoriesData,
  updateWorldData,
  updateCountriesData,
  updateIsLoading,
  updateViewsVisitorsData,
  updateCitiesData,
  updateAgeData,
  updateGenderData,
  updateTotalSpendData,
  updateVisitorsLineData,
  updateSelectedPersonaFilterType,
  updateRoomsData,
  updatePageUrlData,
  updateSourceData,
  updateMediumData,
  updateCampaignData,
  updateTermData,
  updateContentData
} = personasDataSlice.actions;

export default { personasDataSlice };
