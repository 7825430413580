/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import customCalendar from "../api/customCalendar";
import CrossIcon from "../assets/icons/cross.svg";
import { Toastr } from "../../Toastr/Toastr";
import AddNew from "./CustomCalendar/AddNew";
import AddCustomRange from "./CustomCalendar/AddCustomRange";
import ViewCustomDate from "./CustomCalendar/ViewCustomDate";
import EditCustomRange from "./CustomCalendar/EditCustomRange";
import DeleteCustomRange from "./CustomCalendar/DeleteCustomRange";
import "../assets/stylesheets/customYearCalendar.scss";
import "react-date-range/dist/styles.css"; // main date range css file
import "react-date-range/dist/theme/default.css"; // theme css file

export default function CustomYearCalendar({
  showCalendar,
  toggleCalendar,
  switchCalendarToggle,
  switchCalendar,
  customHandleSelect,
  customSelectedRange,
  onApply
}) {
  const [labelsList, setLabelsList] = useState([]);
  const [activeCalendarComponent, setActiveCalendarComponent] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [isNewSelected, setIsNewSelected] = useState(false);
  const { websiteID } = useSelector((state) => state.generalData);
  const { t } = useTranslation();
  const fetchLabelsList = async () => {
    try {
      const response = await customCalendar.getCustomCalendar(websiteID);
      if (response.data.success) {
        setLabelsList(response.data.result);
      }
    } catch (error) {
      Toastr.error(t("analyticsPage.calendar.custom.fetchingError"));
    }
  };

  useEffect(() => {
    fetchLabelsList();
    setIsNewSelected(true);
  }, []);

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    setIsNewSelected(false);
    setActiveCalendarComponent(3); // Set active component to ViewCustomDate
  };

  const handleEditClick = (label, event) => {
    event.stopPropagation(); // Stop event propagation to prevent parent div's onClick from being triggered
    setSelectedLabel(label);
    setIsNewSelected(false);
    setActiveCalendarComponent(4); // Set active component to EditCustomRange
  };

  const handleDelete = (label, event) => {
    event.stopPropagation(); // Stop event propagation to prevent parent div's onClick from being triggered
    setSelectedLabel(label);
    setIsNewSelected(false);
    setActiveCalendarComponent(5); // Set active component to DeleteCustomRange
  };

  const handleNewButtonClick = () => {
    setSelectedLabel([]);
    setIsNewSelected(true); // Set isNewSelected to true when "New" button is clicked
    setActiveCalendarComponent(1); // Set active component to AddNew
  };

  const renderActiveComponent = () => {
    switch (activeCalendarComponent) {
      case 1:
        return (
          <AddNew setActiveCalendarComponent={setActiveCalendarComponent} />
        );
      case 2:
        return (
          <AddCustomRange
            customHandleSelect={customHandleSelect}
            customSelectedRange={customSelectedRange}
            fetchLabelsList={fetchLabelsList}
            setActiveCalendarComponent={setActiveCalendarComponent}
          />
        );
      case 3:
        return (
          <ViewCustomDate
            labelData={selectedLabel}
            setActiveCalendarComponent={setActiveCalendarComponent}
            setSelectedLabel={setSelectedLabel}
            onApply={onApply}
          />
        );
      case 4:
        return (
          <EditCustomRange
            labelData={selectedLabel}
            fetchLabelsList={fetchLabelsList}
            setActiveCalendarComponent={setActiveCalendarComponent}
          />
        );
      case 5:
        return (
          <DeleteCustomRange
            labelData={selectedLabel}
            fetchLabelsList={fetchLabelsList}
            setActiveCalendarComponent={setActiveCalendarComponent}
          />
        );
      default:
        return null;
    }
  };

  return (
    showCalendar && (
      <div className="custom-calendar">
        <div className="close-icon">
          <img src={CrossIcon} alt="cross-icon" onClick={toggleCalendar} />
        </div>
        <div className="flex-between calendar-options">
          <div>
            <h3>{t("analyticsPage.calendar.selectDate")}</h3>
          </div>
          <div className="flex calendar-toggle">
            <p
              onClick={switchCalendarToggle}
              className={!switchCalendar ? "active" : ""}
            >
              {t("analyticsPage.calendar.standardYear")}
            </p>
            <p
              onClick={switchCalendarToggle}
              className={switchCalendar ? "active" : ""}
            >
              {t("analyticsPage.calendar.customTitle")}
            </p>
          </div>
        </div>
        <div className="label-and-rendered-component-parent flex">
          <div className="label-list-parent">
            {labelsList.map((label) => (
              <div
                className={`label-list-child flex-between ${
                  selectedLabel === label ? "selected" : ""
                }`}
                key={label.calendar_id}
                onClick={() => handleLabelClick(label)}
              >
                <p className="label">{label.label}</p>
                <div className="action-icon flex">
                  <i
                    className="ri-edit-fill"
                    onClick={(event) => handleEditClick(label, event)}
                  />
                  <i
                    className="ri-delete-bin-5-line"
                    onClick={(event) => handleDelete(label, event)}
                  />
                </div>
              </div>
            ))}
            <button
              className={isNewSelected ? "add-new" : "add-new unselected"}
              type="button"
              onClick={handleNewButtonClick}
            >
              {t("analyticsPage.calendar.custom.addNew")}
            </button>
          </div>
          <div className="rendered-component">{renderActiveComponent()}</div>
        </div>
      </div>
    )
  );
}

CustomYearCalendar.propTypes = {
  showCalendar: PropTypes.bool.isRequired,
  toggleCalendar: PropTypes.func.isRequired,
  switchCalendar: PropTypes.bool.isRequired,
  switchCalendarToggle: PropTypes.func.isRequired,
  customSelectedRange: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string
    })
  ).isRequired,
  customHandleSelect: PropTypes.func.isRequired,
  onApply: PropTypes.func
};
