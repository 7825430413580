import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { Pagination } from "@mui/material";
import themeColors from "../../../../../../assets/stylesheets/_var.scss";
import "../assets/content.scss";

export default function Content({
  content,
  pageCount,
  pageNumber,
  handlePagination,
  loading
}) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <section className="content-body loader">
        <h3>{t("personaDashboard.content")}</h3>
        <div className="loader-child flex-center">
          <CircularProgress sx={{ color: themeColors.primaryColorShade2 }} />
        </div>
      </section>
    );
  }

  if (content.length === 0) {
    return (
      <section className="content-body no-data">
        <h3>{t("personaDashboard.content")}</h3>
        <p className="flex-center">
          {t("personaDashboard.contentPlaceholder")}
        </p>
      </section>
    );
  }

  return (
    <section className="content-body data">
      <h3>{t("personaDashboard.content")}</h3>
      {content?.map((ele, i) => (
        <div className="content" key={`${ele.content}${i + 1}`}>
          <span className="content-number">
            {(pageNumber - 1) * 20 + i + 1}.&nbsp;&nbsp;
          </span>
          {ele.content}
        </div>
      ))}
      <div className="pagination-container">
        <Pagination
          count={pageCount}
          variant="outlined"
          shape="rounded"
          page={pageNumber}
          sx={{
            "& .MuiPaginationItem-root": {
              backgroundColor: themeColors.background1,
              color: "#181818",
              fontSize: "14px",
              borderColor: themeColors.tertiaryText,
              "&.Mui-selected": {
                backgroundColor: themeColors.primaryColor4,
                color: "#181818"
              }
            }
          }}
          onChange={(event, page) => handlePagination(event, page)}
        />
      </div>
    </section>
  );
}

Content.propTypes = {
  content: PropTypes.oneOfType([PropTypes.array]).isRequired,
  pageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
