/* eslint-disable */
import React, { useEffect } from "react";
import PhotoAlbum from "react-photo-album";
import themeColors from "../../assets/stylesheets/_var.scss";

export default function UploadedImage({
  allImages,
  setPhotos,
  photos,
  selecetedImages,
  setSelectedImagesNew,
  handleMouseEnter,
  handleMouseLeave,
  handleImageClick,
  hoveredIndex
}) {
  const loadImagesWithDimensions = async (images) => {
    const loadedImages = await Promise.all(
      images?.map(
        (image) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            img.src = image?.image;
            img.onload = () => {
              resolve({
                ...image,
                width: img.naturalWidth,
                height: img.naturalHeight
              });
            };
            img.onerror = (err) => {
              console.error("Image loading error:", err, image);
              reject(err);
            };
          })
      )
    );
    return loadedImages;
  };

  const calculateSrcSet = (url, width, height) => {
    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48, 32, 24];
    return breakpoints.map((breakpoint) => {
      const calculatedHeight = Math.round((height / width) * breakpoint);
      return {
        src: `${url}?w=${breakpoint}&h=${calculatedHeight}`,
        width: breakpoint,
        height: calculatedHeight
      };
    });
  };

  const initializePhotos = async (images, setPhotos) => {
    if (!images || images.length === 0) {
      console.warn("No images to initialize");
      return;
    }
    try {
      const loadedImages = await loadImagesWithDimensions(images);
      const photos = loadedImages?.map((image, index) => ({
        image_id: image.image_id,
        src: image.image,
        width: image.width,
        height: image.height,
        srcSet: calculateSrcSet(image.image, image.width, image.height),
        originalIndex: index,
        name: image.file_name,
        date: image.date
      }));
      setPhotos(photos);
    } catch (error) {
      console.error("Error initializing photos:", error);
    }
  };

  useEffect(() => {
    if (allImages?.length > 0) {
      initializePhotos(allImages, setPhotos);
    }
  }, [allImages, photos]);

  useEffect(() => {
    if (allImages?.length === 0) {
      setPhotos([]);
    }
  }, [allImages, photos, setPhotos]);

  const handleCheckboxChangeNew = (photo) => {
    setSelectedImagesNew((prevSelectedImages) => {
      if (prevSelectedImages.some((img) => img.image_id === photo.image_id)) {
        return prevSelectedImages.filter(
          (img) => img.image_id !== photo.image_id
        );
      } else {
        return [
          ...prevSelectedImages,
          { image_id: photo.image_id, file_name: photo.name }
        ];
      }
    });
  };

  const renderPhoto = ({ photo, imageProps }) => (
    <div
      key={photo.originalIndex}
      style={{
        position: "relative",
        cursor: "pointer",
        border:
          photos?.length > 1 &&
          selecetedImages?.some((img) => img.image_id === photo.image_id)
            ? `2px solid ${themeColors.primaryColor}`
            : "0px",
        borderRadius: "7px",
        marginRight: "4px",
        boxSizing: "border-box"
      }}
      className="image-container"
      onMouseEnter={() => handleMouseEnter(photo.originalIndex)}
      onMouseLeave={handleMouseLeave}
    >
      {/* Checkbox */}
      <label>
        <input
          type="checkbox"
          style={{
            position: "absolute",
            top: "5px",
            left: "5px",
            zIndex: 1,
            cursor: "pointer"
          }}
          onChange={() => handleCheckboxChangeNew(photo)}
          checked={selecetedImages?.some(
            (img) => img.image_id === photo.image_id
          )}
        />
      </label>
      {/* Checkbox ends */}

      {/* Image */}
      <div>
        <img
          {...imageProps}
          style={{
            width: photos?.length === 1 ? "30%" : "100%",
            height: "auto",
            display: "block",
            objectFit: "contain",
            borderRadius: "7px"
          }}
          onClick={() => handleImageClick(photo.originalIndex, photo)}
          alt={`Image ${photo.originalIndex}`}
        />
      </div>
      {/* Image ends*/}

      {/* Hovered image details */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: photos?.length === 1 ? "30%" : "100%",
          height: "100%",
          display: hoveredIndex === photo.originalIndex ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          borderRadius: "7px"
        }}
        onClick={() => handleImageClick(photo.originalIndex, photo)}
      >
        <div style={{ textAlign: "center" }}>
          <p className="image-name">{photo.name}</p>
          <p className="image-date">{photo.date}</p>
        </div>
      </div>
      {/* Hovered image details ends */}
    </div>
  );

  return (
    <div>
      <div
        style={{
          paddingLeft: "7%",
          paddingRight: "7%"
        }}
      >
        <PhotoAlbum
          layout="rows"
          photos={photos}
          renderPhoto={renderPhoto}
          // spacing={4}
          // columns={(containerWidth) => {
          //   if (containerWidth < 500) return 2;
          //   if (containerWidth < 900) return 3;
          //   return 4;
          // }}
          // columns={(containerWidth) => 3} // Ensures 3 images per row
        />
      </div>
    </div>
  );
}
