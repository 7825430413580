/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteComponent from "../../components/DeletePopUp/DeleteComponent";
import { ButtonLight } from "../../components/inputElements/buttons/MainButton";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/openedImageModel.scss";

export default function OpenedImageModel({
  backbutton,
  nextbutton,
  close,
  loading,
  setCurrentImage,
  setImagePopup,
  showDeletePopup,
  setShowDeletePopup,
  handleDeleteImages,
  images,
  currentImageIndex,
  handlePreviousImage,
  handleNextImage
}) {
  const { t } = useTranslation();

  return (
    <div className="opened-image-model-images">
      {/* Navigation buttons */}
      <div>
        {currentImageIndex > 0 && (
          <div
            className="left-button fixed-element"
            onClick={handlePreviousImage}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              // Handling keyboard events
              if (e.key === "Enter" || e.key === " ") {
                handlePreviousImage();
              }
            }}
          >
            <img
              src={backbutton}
              alt="bin-icon"
              className="bin-icon-image-model"
            />
          </div>
        )}

        {currentImageIndex < images.length - 1 && (
          <div
            className="fixed-element-right right-button"
            onClick={handleNextImage}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              // Handling keyboard events
              if (e.key === "Enter" || e.key === " ") {
                handleNextImage();
              }
            }}
          >
            <img src={nextbutton} alt="next-icon" className="next-image" />
          </div>
        )}
      </div>
      {/* Navigation buttons ends */}

      <div>
        <div className="close-icon-div">
          <img
            src={close}
            onClick={() => {
              setCurrentImage(null);
              setImagePopup(false);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              // Handling keyboard events
              if (e.key === "Enter" || e.key === " ") {
                setCurrentImage(null);
                setImagePopup(false);
              }
            }}
            className="close-icon"
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px"
          }}
          className="flex center"
        >
          <img
            className="image-attributes"
            src={images[currentImageIndex]?.image}
            alt={images[currentImageIndex]?.file_name}
          />
        </div>
        <div
          style={{
            paddingLeft: "5%",
            paddingTop: "2%"
          }}
          className="image-name"
        >
          <text>{images[currentImageIndex]?.file_name}</text>
        </div>
        <div
          style={{
            paddingLeft: "5%",
            paddingTop: "2%",
            color: themeColors.secondaryText
          }}
          className="image-date"
        >
          <text>
            {t("common.date")}: {images[currentImageIndex]?.date}
          </text>
        </div>

        <ButtonLight
          buttonText={t("common.confirmation.delete")}
          style={{
            marginLeft: "5%",
            marginTop: "2%"
          }}
          onClick={() => {
            setShowDeletePopup(true);
          }}
        />
        {showDeletePopup && (
          <DeleteComponent
            onCancel={() => setShowDeletePopup(false)}
            loading={loading}
            onConfirmDelete={() => {
              handleDeleteImages();
            }}
          />
        )}
      </div>
    </div>
  );
}
