/* eslint-disable */

export const modifyData = (data) => {
  return data.map((ele) => {
    const dateUTC = new Date(ele.date);
    const tempDate = `${dateUTC.getDate()} ${dateUTC.toLocaleDateString(
      "en-US",
      { month: "short" }
    )}`;

    return {
      ...ele,
      dateName: tempDate,
      weekName: `Week ${ele.weekNumber}`,
      monthName: `${ele.month} ${ele.year}`
    };
  });
};

export const getInsights = (data) => {
  const temp = {
    totalRevenue: 0,
    numberOfOrders: 0,
    avgOrderValue: 0,
    activeCustomers: 0,
    ordersAndCustomers: 0
  };
  const numberOfOrdersArray = [];
  const numberOfActiveCustomers = [];
  data.map((ele) => {
    if (ele.financial_status === "paid" && ele.transaction_type !== "REFUND") {
      temp.totalRevenue += ele.total_spent;

      if (!numberOfActiveCustomers.includes(ele.customer_id)) {
        numberOfActiveCustomers.push(ele.customer_id);
      }
    }
    if (!numberOfOrdersArray.includes(ele.order_id)) {
      numberOfOrdersArray.push(ele.order_id);
    }
    return ele;
  });
  temp.numberOfOrders = numberOfOrdersArray?.length;
  temp.avgOrderValue = temp.totalRevenue / numberOfOrdersArray.length;
  temp.activeCustomers = numberOfActiveCustomers?.length;
  const uniqueCustomers = new Set(data.map((item) => item.customer_id)).size;
  temp.ordersAndCustomers = numberOfOrdersArray.length / uniqueCustomers;

  return temp;
};

export const getMultibarchartData = (data) => {
  // Group data by date
  const groupedData = data.reduce((result, entry) => {
    const date = entry.date;

    // Initialize structure for the date if not already present
    if (!result[date]) {
      result[date] = { revenue: 0, orderIds: new Set(), requests: 0 };
    }

    if (
      entry.financial_status === "paid" &&
      entry.transaction_type !== "REFUND"
    ) {
      result[date].revenue += entry.total_spent;
      result[date].orderIds.add(entry.order_id); // Track unique order IDs
    } else {
      result[date].requests += 1;
    }

    return result;
  }, {});
  const sortedDates = Object.keys(groupedData).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  const labels = [];
  const revenue = [];
  const purchase = [];
  const requests = [];

  sortedDates.forEach((date) => {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric"
    });
    const [month, day] = formattedDate.split(" ");
    labels.push(`${day} ${month}`);
    revenue.push(groupedData[date].revenue);
    purchase.push(groupedData[date].orderIds.size);
    requests.push(groupedData[date].requests);
  });

  return { labels, revenue, purchase, requests };
};

export const getMultibarchartDataByWeek = (data) => {
  // Group data by weekNumber
  const groupedData = data.reduce((result, entry) => {
    const week = `Week ${entry.weekNumber}`;
    if (!result[week]) {
      result[week] = { revenue: 0, orderIds: new Set(), requests: 0 };
    }

    if (
      entry.financial_status === "paid" &&
      entry.transaction_type !== "REFUND"
    ) {
      result[week].revenue += entry.total_spent;
      result[week].orderIds.add(entry.order_id);
    } else {
      result[week].requests += 1;
    }

    return result;
  }, {});

  // Sort keys by week number
  const sortedWeeks = Object.keys(groupedData).sort((a, b) => {
    const weekA = parseInt(a.split(" ")[1], 10); // Extract week number
    const weekB = parseInt(b.split(" ")[1], 10); // Extract week number
    return weekA - weekB;
  });

  const labels = [];
  const revenue = [];
  const purchase = [];
  const requests = [];

  sortedWeeks.forEach((week) => {
    labels.push(week);
    revenue.push(groupedData[week].revenue);
    purchase.push(groupedData[week].orderIds.size);
    requests.push(groupedData[week].requests);
  });

  return { labels, revenue, purchase, requests };
};

export const getMultibarchartDataByMonth = (data) => {
  // Group data by month and year
  const groupedData = data.reduce((result, entry) => {
    const monthYear = `${new Date(entry.date).toLocaleDateString("en-US", {
      month: "short",
      year: "numeric"
    })}`;
    if (!result[monthYear]) {
      result[monthYear] = { revenue: 0, orderIds: new Set(), requests: 0 };
    }

    if (
      entry.financial_status === "paid" &&
      entry.transaction_type !== "REFUND"
    ) {
      result[monthYear].revenue += entry.total_spent;
      result[monthYear].orderIds.add(entry.order_id);
    } else {
      result[monthYear].requests += 1;
    }

    return result;
  }, {});

  const sortedMonthYears = Object.keys(groupedData).sort((a, b) => {
    const dateA = new Date(a); // Parse monthYear string into a Date object
    const dateB = new Date(b);
    return dateA - dateB; // Sort by ascending date
  });

  const labels = [];
  const revenue = [];
  const purchase = [];
  const requests = [];

  sortedMonthYears.forEach((monthYear) => {
    labels.push(monthYear);
    revenue.push(groupedData[monthYear].revenue);
    purchase.push(groupedData[monthYear].orderIds.size);
    requests.push(groupedData[monthYear].requests);
  });

  return { labels, revenue, purchase, requests };
};

export const getMultibarchartDataByYear = (data) => {
  // Group data by year
  const groupedData = data.reduce((result, entry) => {
    const year = new Date(entry.date).getFullYear();
    if (!result[year]) {
      result[year] = { revenue: 0, orderIds: new Set(), requests: 0 };
    }

    if (
      entry.financial_status === "paid" &&
      entry.transaction_type !== "REFUND"
    ) {
      result[year].revenue += entry.total_spent;
      result[year].orderIds.add(entry.order_id);
    } else {
      result[year].requests += 1;
    }

    return result;
  }, {});

  const labels = [];
  const revenue = [];
  const purchase = [];
  const requests = [];

  Object.keys(groupedData).forEach((year) => {
    labels.push(year);
    revenue.push(groupedData[year].revenue);
    purchase.push(groupedData[year].orderIds.size);
    requests.push(groupedData[year].requests);
  });

  return { labels, revenue, purchase, requests };
};

export const getBestSellingProducts = (data) => {
  const groupedData = {};

  // Group the data by product_id
  data.forEach((item) => {
    const productId = item.product_id;
    const productName = item.product_name;

    // If the product_id doesn't exist in groupedData, initialize it
    if (!groupedData[productId]) {
      groupedData[productId] = {
        productNames: [],
        productCount: 0
      };
    }

    // Add product name to the productNames array if it's not already added
    if (!groupedData[productId].productNames.includes(productName)) {
      groupedData[productId].productNames.push(productName);
    }

    // Increment the productCount
    groupedData[productId].productCount += 1;
  });

  const result = {
    productNames: [],
    productCount: []
  };

  Object.keys(groupedData).forEach((productId) => {
    result.productNames.push(groupedData[productId].productNames.join(", "));
    result.productCount.push(groupedData[productId].productCount);
  });

  const sortedResult = result.productNames
    .map((name, index) => ({
      name,
      count: result.productCount[index]
    }))
    .sort((a, b) => b.count - a.count);

  const labels = sortedResult.map((item) => item.name);
  const productCount = sortedResult.map((item) => item.count);

  return { labels, productCount };
};

export const getRevenuePerCategory = (data) => {
  const result = data.reduce((acc, item) => {
    const { product_type, total_spent, financial_status, transaction_type } =
      item;
    if (financial_status === "paid" && transaction_type === "SALE") {
      if (acc[product_type]) {
        acc[product_type] += total_spent;
      } else {
        acc[product_type] = total_spent;
      }
    }
    return acc;
  }, {});

  // Convert the result object into an array and sort by total_spent
  const sortedResult = Object.entries(result).sort(
    ([, priceA], [, priceB]) => priceB - priceA
  );

  return {
    labels: sortedResult.map(([type]) => type),
    revenue: sortedResult.map(([, price]) => price)
  };
};

export const getTopVariantsData = (data, key) => {
  const counts = data.reduce((acc, item) => {
    const value = item[key];
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  // Convert counts to an array and sort by value in descending order
  const sortedEntries = Object.entries(counts).sort((a, b) => b[1] - a[1]);

  // Separate keys and values into two arrays
  const labels = sortedEntries.map((entry) => entry[0]);
  const dataset = sortedEntries.map((entry) => entry[1]);

  return { labels, dataset };
};

export const orderStatusCounts = (data) => {
  // Create a map to group unique order_id by financial_status
  const uniqueOrders = new Map();
  data.forEach((item) => {
    const { order_id, financial_status } = item;

    // If the order_id is not already in the map, add it
    if (!uniqueOrders.has(order_id)) {
      uniqueOrders.set(order_id, financial_status);
    }
  });
  // Count occurrences of each financial_status
  const counts = {};
  uniqueOrders.forEach((status) => {
    counts[status] = (counts[status] || 0) + 1;
  });

  const labels = Object.keys(counts);
  const dataset = Object.values(counts);

  return { labels, dataset };
};

export const getUniqueVsReturningCustomers = (data) => {
  // Filter data based on the given conditions
  const filteredData = data.filter(
    (item) =>
      item.financial_status === "paid" && item.transaction_type === "SALE"
  );

  // Create a Map to keep track of unique order dates per customer_id
  const customerOrderDates = new Map();
  filteredData.forEach((item) => {
    const customerId = item.customer_id;
    const orderDate = new Date(item.date).toDateString(); // Normalize date to string format

    // Add the date to the customer's set of order dates
    if (customerOrderDates.has(customerId)) {
      customerOrderDates.get(customerId).add(orderDate);
    } else {
      customerOrderDates.set(customerId, new Set([orderDate])); // First order date for this customer
    }
  });

  // Count "New" and "Returning" customers based on unique order dates
  const result = { New: 0, Returning: 0 };
  const newAndReturningCustomers = { newCustomers: [], returningCustomers: [] };

  customerOrderDates.forEach((orderDatesSet, customerId) => {
    if (orderDatesSet.size === 1) {
      result.New += 1;
      if (!newAndReturningCustomers.newCustomers.includes(customerId)) {
        newAndReturningCustomers.newCustomers.push(customerId);
      }
    } else {
      result.Returning += 1;
      if (!newAndReturningCustomers.returningCustomers.includes(customerId)) {
        newAndReturningCustomers.returningCustomers.push(customerId);
      }
    }
  });

  const labels = Object.keys(result);
  const dataset = Object.values(result);

  return { labels, dataset, newAndReturningCustomers };
};

export const getSpendingIntensityData = (data) => {
  const ranges = {
    "Above 500": 0,
    "401-500": 0,
    "301-400": 0,
    "201-300": 0,
    "100-200": 0,
    "Below 100": 0
  };

  const rangesByCustomers = {
    "Above 500": [],
    "401-500": [],
    "301-400": [],
    "201-300": [],
    "100-200": [],
    "Below 100": []
  };

  // Step 1: Group data by customer_id and calculate the total_spent for each customer
  const customerSpendMap = data
    .filter(
      (item) =>
        item.financial_status === "paid" && item.transaction_type !== "REFUND"
    )
    .reduce((acc, item) => {
      const customerId = item.customer_id;
      if (!acc[customerId]) {
        acc[customerId] = 0;
      }
      acc[customerId] += item.total_spent;
      return acc;
    }, {});

  // Step 2: Categorize each customer into spending ranges
  Object.entries(customerSpendMap).forEach(([customerId, totalSpent]) => {
    if (totalSpent < 100) {
      ranges["Below 100"] += 1;
      rangesByCustomers["Below 100"].push(customerId);
    } else if (totalSpent >= 100 && totalSpent <= 200) {
      ranges["100-200"] += 1;
      rangesByCustomers["100-200"].push(customerId);
    } else if (totalSpent >= 201 && totalSpent <= 300) {
      ranges["201-300"] += 1;
      rangesByCustomers["201-300"].push(customerId);
    } else if (totalSpent >= 301 && totalSpent <= 400) {
      ranges["301-400"] += 1;
      rangesByCustomers["301-400"].push(customerId);
    } else if (totalSpent >= 401 && totalSpent <= 500) {
      ranges["401-500"] += 1;
      rangesByCustomers["401-500"].push(customerId);
    } else if (totalSpent > 500) {
      ranges["Above 500"] += 1;
      rangesByCustomers["Above 500"].push(customerId);
    }
  });

  const labels = Object.keys(ranges);
  const dataset = Object.values(ranges);

  return { labels, dataset, rangesByCustomers };
};

export const groupByCountryAndCount = (data, key) => {
  // Filter the data based on the given conditions
  const filteredData = data.filter(
    (item) =>
      item.financial_status === "paid" && item.transaction_type === "SALE"
  );

  // Create a Map to track unique customer_id for each group
  const worldData = filteredData.reduce((result, item) => {
    const groupKey = item[key];
    const customerId = item.customer_id;

    // Initialize a Set for the groupKey if it doesn't exist
    if (!result[groupKey]) {
      result[groupKey] = new Set();
    }

    // Add the customer_id to the Set for the groupKey
    result[groupKey].add(customerId);

    return result;
  }, {});

  // Convert the Sets to counts
  const worldDataCounts = Object.keys(worldData).reduce((counts, groupKey) => {
    counts[groupKey] = worldData[groupKey].size; // Count unique customer_ids
    return counts;
  }, {});

  const labels = Object.keys(worldDataCounts);
  const dataset = Object.values(worldDataCounts);

  return { labels, dataset };
};

export const calculateAverageTimeBWOrders = (data) => {
  // Step 1: Group orders by customerId
  const groupedOrders = data.reduce((acc, order) => {
    if (!acc[order.customer_id]) {
      acc[order.customer_id] = [];
    }
    acc[order.customer_id].push(new Date(order.date));
    return acc;
  }, {});

  // Step 2: Compute the average time between orders for each customer
  const results = Object.entries(groupedOrders).map(
    ([customerId, orderDates]) => {
      // Sort the order dates
      orderDates.sort((a, b) => a - b);

      // Calculate differences in days between consecutive orders
      const differences = [];
      for (let i = 1; i < orderDates.length; i++) {
        const diff =
          (orderDates[i] - orderDates[i - 1]) / (1000 * 60 * 60 * 24); // Difference in days
        differences.push(diff);
      }

      // Calculate the average time between orders
      const average =
        differences.length > 0
          ? differences.reduce((sum, diff) => sum + diff, 0) /
            differences.length
          : null; // Null for customers with only one order

      return {
        customerId,
        averageTimeBetweenOrders: average
          ? average.toFixed(2)
          : "No sufficient data"
      };
    }
  );

  const validResults = results.filter(
    (ele) => ele.averageTimeBetweenOrders !== "No sufficient data"
  );
  const averageTimes = validResults.map((ele) =>
    parseFloat(ele.averageTimeBetweenOrders)
  ); //[4.5, 10.2, 6.8, 3.1, 12.7, 8.9, 7.3, 15.6, 2.8];

  // Step 1: Find the range
  const minValue = Math.min(...averageTimes);
  const maxValue = Math.max(...averageTimes);

  // Step 2: Determine the number of bins (e.g., square root of the number of data points)
  const numberOfBins = Math.ceil(Math.sqrt(averageTimes.length));

  // Step 3: Calculate bin width
  const binWidth = Math.ceil((maxValue - minValue) / numberOfBins);

  // Step 4: Generate bins dynamically
  const bins = [];
  for (let i = minValue; i < maxValue; i += binWidth) {
    const lowerBound = Math.round(i); // Round off the lower bound
    const upperBound = Math.round(Math.min(i + binWidth, maxValue)); // Round off the upper bound
    bins.push([lowerBound, upperBound]);
  }

  // Step 5: Count data points and collect customerId's for each bin
  const dataset = Array(bins.length).fill(0);
  const customerIdsByBin = {}; // Initialize an object for customerId's grouped by labels
  bins.forEach((bin) => {
    const label = `${bin[0]}-${bin[1]} days`;
    customerIdsByBin[label] = []; // Initialize empty arrays for each bin label
  });
  validResults.forEach(({ customerId, averageTimeBetweenOrders }) => {
    const avgTime = parseFloat(averageTimeBetweenOrders);
    bins.forEach((bin, index) => {
      if (avgTime >= bin[0] && avgTime < bin[1]) {
        const label = `${bin[0]}-${bin[1]} days`;
        dataset[index]++;
        customerIdsByBin[label].push(customerId);
      }
    });
  });

  // Step 6: Prepare data for the histogram
  const labels = bins.map((bin) => `${bin[0]}-${bin[1]} days`);
  return { labels, dataset, customerIdsByBin };
};

export const getRepeatPurchaseRateData = (data) => {
  // Filter the data based on the given conditions
  const filteredData = data.filter(
    (order) =>
      order.financial_status === "paid" && order.transaction_type === "SALE"
  );

  // Create a Map to group orders by customer_id and track unique order dates
  const customerOrders = filteredData.reduce((acc, order) => {
    const customerId = order.customer_id;
    const orderDate = order.date;

    if (!acc[customerId]) {
      acc[customerId] = new Set();
    }

    acc[customerId].add(orderDate);
    return acc;
  }, {});

  // Create an array of customer_id and the number of unique purchase days for each customer
  const customerPurchaseDaysArray = Object.entries(customerOrders).map(
    ([customerId, orderDates]) => ({
      customerId,
      uniquePurchaseDays: orderDates.size // Count unique purchase days
    })
  );

  const repeatPurchaseBins = {
    "Repeat purchase 1": 0,
    "Repeat purchase 2": 0,
    "Repeat purchase 3": 0,
    "Repeat purchase 4": 0,
    "Repeat purchase 5+": 0
  };

  const repeatPurchaseCustomers = {
    "Repeat purchase 1": [],
    "Repeat purchase 2": [],
    "Repeat purchase 3": [],
    "Repeat purchase 4": [],
    "Repeat purchase 5+": []
  };

  // Classify customers into repeat purchase bins
  customerPurchaseDaysArray.forEach(({ uniquePurchaseDays, customerId }) => {
    if (uniquePurchaseDays === 2) {
      repeatPurchaseBins["Repeat purchase 1"] += 1;
      repeatPurchaseCustomers["Repeat purchase 1"].push(customerId);
    } else if (uniquePurchaseDays === 3) {
      repeatPurchaseBins["Repeat purchase 2"] += 1;
      repeatPurchaseCustomers["Repeat purchase 2"].push(customerId);
    } else if (uniquePurchaseDays === 4) {
      repeatPurchaseBins["Repeat purchase 3"] += 1;
      repeatPurchaseCustomers["Repeat purchase 3"].push(customerId);
    } else if (uniquePurchaseDays === 5) {
      repeatPurchaseBins["Repeat purchase 4"] += 1;
      repeatPurchaseCustomers["Repeat purchase 4"].push(customerId);
    } else if (uniquePurchaseDays >= 6) {
      repeatPurchaseBins["Repeat purchase 5+"] += 1;
      repeatPurchaseCustomers["Repeat purchase 5+"].push(customerId);
    }
  });

  // Get total number of unique customers
  const totalUniqueCustomers = customerPurchaseDaysArray.length;

  // Prepare labels and dataset
  const labels = [
    "Total no of Customers",
    "Repeat purchase 1",
    "Repeat purchase 2",
    "Repeat purchase 3",
    "Repeat purchase 4",
    "Repeat purchase 5+" // Combined 5 or more repeat purchases
  ];

  const dataset = [
    totalUniqueCustomers,
    repeatPurchaseBins["Repeat purchase 1"],
    repeatPurchaseBins["Repeat purchase 2"],
    repeatPurchaseBins["Repeat purchase 3"],
    repeatPurchaseBins["Repeat purchase 4"],
    repeatPurchaseBins["Repeat purchase 5+"]
  ];

  return { labels, dataset, repeatPurchaseCustomers };
};

export const getDiscountCodeCounts = (data) => {
  const discountCodeCounts = {};
  const customerDiscountMap = {};

  // Group data by unique customer_id
  data.forEach((order) => {
    const customerId = order.customer_id;
    const discountCode = order.discount_codes;

    if (!customerDiscountMap[customerId]) {
      customerDiscountMap[customerId] = new Set();
    }

    // Add the discount code for the customer
    if (discountCode) {
      customerDiscountMap[customerId].add(discountCode);
    }
  });

  // Count the occurrences of each discount code across unique customers
  Object.values(customerDiscountMap).forEach((discountCodes) => {
    discountCodes.forEach((code) => {
      if (discountCodeCounts[code]) {
        discountCodeCounts[code] += 1;
      } else {
        discountCodeCounts[code] = 1;
      }
    });
  });

  // Convert the object to an array of [label, count] pairs and sort by count
  const sortedEntries = Object.entries(discountCodeCounts).sort(
    (a, b) => b[1] - a[1]
  );

  // Separate the sorted entries back into labels and dataset
  const labels = sortedEntries.map(([label]) => label);
  const dataset = sortedEntries.map(([, count]) => count);

  return { labels, dataset };
};

export const getCustomerAcquisition = (data) => {
  const result = {};

  data.forEach((item) => {
    const acquisition = item.customer_aquasation;
    const orderId = item.order_id;
    if (
      item.financial_status === "paid" &&
      item.transaction_type !== "REFUND"
    ) {
      // Initialize the acquisition group if it doesn't exist
      if (!result[acquisition]) {
        result[acquisition] = new Set();
      }

      // Add the unique order_id to the acquisition group
      result[acquisition].add(orderId);
    }
  });

  const sortedResult = Object.entries(result)
    .map(([acquisition, orderIds]) => ({
      customer_aquasation: acquisition,
      unique_order_count: orderIds.size
    }))
    .sort((a, b) => b.unique_order_count - a.unique_order_count);

  const labels = sortedResult.map((item) => item.customer_aquasation);
  const dataset = sortedResult.map((item) => item.unique_order_count);

  return { labels, dataset };
};

export const getCustomerPreferences = (data) => {
  const filteredData = data.filter(
    (item) =>
      item.financial_status === "paid" && item.transaction_type === "SALE"
  );

  // Step 1: Group filtered data by product_type and collect unique customer_ids
  const groupedData = filteredData.reduce((acc, item) => {
    if (!acc[item.product_type]) acc[item.product_type] = new Set();
    acc[item.product_type].add(item.customer_id);
    return acc;
  }, {});

  // Step 2: Count the unique customer_ids for each product_type
  const result = Object.entries(groupedData).map(
    ([productType, customerIds]) => {
      return {
        productType,
        uniqueCustomerCount: customerIds.size
      };
    }
  );

  const sortedResult = result.sort(
    (a, b) => b.uniqueCustomerCount - a.uniqueCustomerCount
  );

  const labels = sortedResult.map((item) => item.productType);
  const dataset = sortedResult.map((item) => item.uniqueCustomerCount);

  return { labels, dataset };
};
