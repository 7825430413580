import React from "react";
import { useTranslation } from "react-i18next";
import loading from "../../assets/icons/loading.gif";
import "./assets/stylesheets/placeholders.scss";

export default function Loading() {
  const { t } = useTranslation();
  return (
    <div className="flex-center">
      <div className="placeholder-background background-box">
        <img
          style={
            {
              // paddingTop: "37px"
            }
          }
          src={loading}
          alt="loading"
          className="loading-gif"
        />
        <div className="flex-vertical">
          <span>{t("loading.collectingData")}</span>
          <span
            style={{
              paddingBottom: "37px"
            }}
          >
            {t("loading.buildingDashboard")}
          </span>
        </div>
      </div>
    </div>
  );
}
