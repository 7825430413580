import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../api/axios";
import { Toastr } from "../components/Toastr/Toastr";

export const getDataItems = createAsyncThunk(
  "yoda/getDataItems",
  async (payload, t) => {
    return (
      axios
        // .post(`dashboard-data/olap`, payload)
        .post(`dashboard-data/olap-click-house`, payload)
        .then((res) => {
          return res.data.result;
        })
        .catch((err) => {
          console.log(err);

          if (err.response.status === 401) {
            Toastr.error(t(""));
            return [];
          }

          if (err.response.status === 404) return [];
          return [];
        })
    );
  }
);

export const getCompareDataItems = createAsyncThunk(
  "yoda/getCompareDataItems",
  async (payload, t) => {
    return (
      axios
        // .post(`dashboard-data/olap`, payload)
        .post(`dashboard-data/olap-click-house`, payload)
        .then((res) => {
          return res.data.result;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            Toastr.error(t("common.loginError"));
            return [[], []];
          }
          if (err.response.status === 404) return [];
          return [];
        })
    );
  }
);

export const getPMSData = createAsyncThunk(
  "yoda/getPMSData",
  async (payload, t) => {
    return (
      axios
        // .post("dashboard-data/pms", payload)
        .post("dashboard-data/pms-click-house", payload)
        .then((res) => {
          return res.data.result[0];
        })
        .catch((err) => {
          console.log(err);

          if (err.response.status === 401) {
            Toastr.error(t("common.loginError"));
            return [];
          }

          if (err.response.status === 404) return [];
          return [];
        })
    );
  }
);

export const getComparePMSData = createAsyncThunk(
  "yoda/getComparePMSData",
  async (payload, t) => {
    return (
      axios
        // .post("dashboard-data/pms", payload)
        .post("dashboard-data/pms-click-house", payload)
        .then((res) => {
          return res.data.result;
        })
        .catch((err) => {
          console.log(err);

          if (err.response.status === 401) {
            Toastr.error(t("common.loginError"));
            return [];
          }

          if (err.response.status === 404) return [];
          return [];
        })
    );
  }
);

export const getPMSBasedHeatmap = createAsyncThunk(
  "yoda/getPMSBasedHeatmap",
  async (payload, t) => {
    return axios
      .post("dashboard-data/pms-based-heat-map", payload)
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status === 401) {
          Toastr.error(t("common.loginError"));
          return [];
        }

        if (err.response.status === 404) return [];
        return [];
      });
  }
);

export const getHeatMapData = createAsyncThunk(
  "yoda/getHeatMapData",
  async (payload, t) => {
    return axios
      .post("dashboard-data/pms/heat-map", payload)
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status === 401) {
          Toastr.error(t("common.loginError"));
          return [];
        }

        if (err.response.status === 404) return [];
        return [];
      });
  }
);

export const getAnalyticsPages = createAsyncThunk(
  "yoda/getAnalyticsPages",
  async (payload, t) => {
    return axios
      .post("dashboard-data/analytics-pages", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status === 401) {
          Toastr.error(t("common.loginError"));
          return [];
        }

        if (err.response.status === 404) return [];
        return [];
      });
  }
);

export const getCompareAnalyticsPages = createAsyncThunk(
  "yoda/getCompareAnalyticsPages",
  async (payload, t) => {
    return axios
      .post("dashboard-data/analytics-pages", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status === 401) {
          Toastr.error(t("common.loginError"));
          return [];
        }

        if (err.response.status === 404) return [];
        return [];
      });
  }
);

export const getPersonasData = createAsyncThunk(
  "yoda/getPersonasData",
  async (payload, t) => {
    try {
      const response = await axios.post(`/persona-dashboard/persona`, payload);
      const { pmsToggleActivation, result } = response.data;

      return {
        pmsToggleActivation,
        result: result[0]
      };
    } catch (err) {
      console.log(err);

      const { response } = err;
      if (response && response.status === 401) {
        Toastr.error(t(""));
      }

      return {
        pmsToggleActivation: false,
        result: []
      };
    }
  }
);
export const getIsiChatData = createAsyncThunk(
  "yoda/getIsiChatData",
  async (payload) => {
    return axios
      .post("isi-dashboard/getIsiChat", payload)
      .then((res) => {
        return res.data.isiChatResult;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toastr.error("Please login to view the dashboard");
          return [[]];
        }
        if (err.response.status === 404) return [[]];
        return [[]];
      });
  }
);

export const getCompareIsiChatData = createAsyncThunk(
  "yoda/getCompareIsiChatData",
  async (payload) => {
    return axios
      .post("isi-dashboard/getIsiChat", payload)
      .then((res) => {
        return res.data.isiChatResult;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toastr.error("Please login to view the dashboard");
          return [[], []];
        }
        if (err.response.status === 404) return [[], []];
        return [[], []];
      });
  }
);

export const getAllQuestionsForIsiChat = createAsyncThunk(
  "yoda/getAllQuestionsForIsiChat",
  async (payload) => {
    return axios
      .post("isi-dashboard/getIsiChatQuestion", payload)
      .then((res) => {
        return res.data.isiChatResult;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toastr.error("Please login to view the dashboard");
          return [];
        }
        if (err.response.status === 404) return [];
        return [];
      });
  }
);

export const getCompareAllQuestionsForIsiChat = createAsyncThunk(
  "yoda/getCompareAllQuestionsForIsiChat",
  async (payload) => {
    return axios
      .post("isi-dashboard/getIsiChatQuestion", payload)
      .then((res) => {
        return res.data.isiChatResult;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toastr.error("Please login to view the dashboard");
          return [];
        }
        if (err.response.status === 404) return [];
        return [];
      });
  }
);

export const getPerformanceCampaignData = createAsyncThunk(
  "yoda/getPerformanceCampaignData",
  async (payload) => {
    return axios
      .post("campaign-dashboard/get-campaign", payload)
      .then((res) => {
        return res?.data?.result;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toastr.error("Please login to view the dashboard");
          return [[]];
        }
        if (err.response.status === 404) return [[]];
        return [[]];
      });
  }
);

export const getEcommerceData = createAsyncThunk(
  "yoda/getEcommerceData",
  async (payload) => {
    return axios
      .post("shopify/get-shopify-dashboard", payload)
      .then((res) => {
        return res?.data?.result;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toastr.error("Please login to view the dashboard");
          return [[]];
        }
        if (err.response.status === 404) return [[]];
        return [[]];
      });
  }
);
