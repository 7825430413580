import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Toastr } from "../../../Toastr/Toastr";
import customCalendar from "../../api/customCalendar";
import "../../assets/stylesheets/deleteCustomRange.scss";
import Delete from "../../assets/icons/trash.png";
import CalendarButtonCombination from "../CalendarButtonCombination/CalendarButtonCombination";

export default function DeleteCustomRange({
  labelData,
  fetchLabelsList,
  setActiveCalendarComponent
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await customCalendar.deleteCustomCalendar({
        calendarId: labelData.calendar_id,
        website_id: labelData.website_id
      });
      if (response.data.success === true) {
        Toastr.success(
          t("analyticsPage.calendar.custom.deleteCustom.deleteSuccess")
        );
        setLoading(false);
        fetchLabelsList();
        setActiveCalendarComponent(1);
      }
    } catch (error) {
      setLoading(false);
      Toastr.error(t("analyticsPage.calendar.custom.deleteCustom.deleteError"));
    }
  };

  const handleCancel = () => {
    setActiveCalendarComponent(1);
  };

  return (
    <section className="delete-custom-range">
      <img src={Delete} alt="tarsh" />
      <p>{t("analyticsPage.calendar.custom.deleteCustom.confirmationTitle")}</p>
      <div className="delete-btn-group flex">
        <CalendarButtonCombination
          firstButtonText={t("analyticsPage.calendar.custom.deleteCustom.no")}
          secondButtonText={t("analyticsPage.calendar.custom.deleteCustom.yes")}
          onClickFirstButton={handleCancel}
          onClickSecondButton={handleDelete}
          loading={loading}
        />
      </div>
    </section>
  );
}

DeleteCustomRange.propTypes = {
  fetchLabelsList: PropTypes.func.isRequired,
  setActiveCalendarComponent: PropTypes.func.isRequired,
  labelData: PropTypes.shape({
    calendar_id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    customer_id: PropTypes.number.isRequired,
    website_id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    shared_calendar: PropTypes.number.isRequired
  }).isRequired
};
