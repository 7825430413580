/* eslint-disable */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../../features/Authentication/LogIn";

export default function NewLoginPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("useEffect called, current path:", location.pathname);
    if (location.pathname === "/") {
      console.log("Pushing state to history");
      window.history.pushState({ noBackExits: true }, "");

      const handlePopState = (event) => {
        console.log("Back button was clicked", event.state);
        if (event.state && event.state.noBackExits) {
          console.log("Preventing back navigation");
          window.history.pushState({ noBackExits: true }, "");
        }
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        console.log("Cleaning up popstate listener");
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [location?.pathname, navigate]);

  return <Login />;
}
