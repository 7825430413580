/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { TailSpin } from "react-loader-spinner";
import { Dialog, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonDark } from "../../../components/inputElements/buttons/Buttons";
import AuthorizedHeader from "../../../components/layouts/AuthorizedHeader";
import addIcon from "../../../assets/icons/add.svg";
import yodaLogo from "../assets/icons/yoda-logo.svg";
import pinOff from "../assets/icons/pin-off.svg";
import pinOn from "../assets/icons/pin-on.svg";
import info from "../assets/icons/info.svg";
import bin from "../assets/icons/bin.svg";
import starSelected from "../assets/icons/home-full.svg";
import starEmpty from "../assets/icons/home-empty.svg";
import CloseButton from "../../../assets/icons/closeModal.svg";
import GreenIcon from "../../../assets/icons/status (3).svg";
import Inactive2 from "../../../assets/icons/Inactive2.png";
import DeleteIcon from "../../../assets/icons/buttons.svg";
import themeColors from "../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/myWebsites.scss";
import EditWebsiteDetails from "./EditWebsiteDetails";
import DeleteComponent from "../../../components/DeletePopUp/DeleteComponent";
import AddWebsite from "./AddWebsite";
import NoWebsite from "../../Placeholders/NoWebsites";
import LocalStorage from "../../../utils/LocalStorgae";
import SearchFilter from "../../../components/inputElements/selectInput/SearchFilter";
import SearchBar from "../../../components/inputElements/Input/SearchBar";

const roleMappingsNew = {
  en: {
    1: "Owner",
    2: "Collaborator",
    3: "Accountant",
    4: "Reader",
    5: "Collaborator, Accountant",
    6: "Accountant, Reader",
    7: "Admin"
  },
  de: {
    1: "Eigentümer",
    2: "Mitarbeiter",
    3: "Buchhalter",
    4: "Leser",
    5: "Mitarbeiter, Buchhalter",
    6: "Buchhalter, Leser",
    7: "Administrator"
  },
  it: {
    1: "Proprietario",
    2: "Collaboratore",
    3: "Contabile",
    4: "Lettore",
    5: "Collaboratore, Contabile",
    6: "Contabile, Lettore",
    7: "Amministratore"
  }
};

function WebsiteTile({
  website,
  websitePinLoading,
  defaultWebsite,
  updateDefaultWebsite,
  updateNonDefaultWebsite,
  selectWebsite,
  handleOpenModal,
  deleteWebsite,
  updatePinWebsite,
  updateUnPinWebsite,
  modifyWebsiteID,
  showPopUp,
  websiteId
}) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const baseUrlRegex = /^(https?:\/\/[^/]+)(?:\/|$)/;
  const match = website.url.match(baseUrlRegex);
  const selectedLanguage = LocalStorage.getItem("selectedLanguage");
  const newRoleMapping = roleMappingsNew[selectedLanguage];
  const { t } = useTranslation();

  useEffect(() => {
    if (showPopUp && website.id === websiteId) {
      handleOpenModal(website.id);
    }
  }, [website]);

  return (
    <div
      className="website-tile flex-vertical-between"
      onClick={() => modifyWebsiteID(website.id)}
    >
      <div className="flex-between">
        <div className="flex-start">
          <img
            src={`${match?.[1]}/favicon.ico`}
            alt="website-favicon"
            className="logo"
            onError={(e) => {
              e.target.onerror = null; // Reset to prevent infinite loop
              e.target.src = yodaLogo; // Use default logo if favicon is not found
            }}
          />
          <span className="name">{website.name}</span>
        </div>
        <div className="info-pin-icons flex-between">
          <img
            src={info}
            alt="info-icon"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenModal(website.id);
            }}
          />
          <label htmlFor={`option-${website.id}`}>
            <input
              type="checkbox"
              id={`option-${website.id}`}
              name="select"
              onClick={(e) => {
                e.stopPropagation();
                selectWebsite(e.target.checked, website.id);
              }}
            />
          </label>
        </div>
      </div>

      <div className="flex-between">
        {/* Role name */}
        <div className="flex-row">
          <span className="name">{t("common.role")} :-</span>
          <span className="name"> {newRoleMapping[website?.role_id]}</span>
        </div>
        {/* Role name ends */}

        {/* Integrated status */}
        <div className="flex-row">
          {website?.integrated === 0 ? (
            <img src={Inactive2} alt="Verified" />
          ) : (
            <img src={GreenIcon} alt="Verified" />
          )}
        </div>
        {/* Integrated status */}
      </div>

      <div className="flex-between tile-bottom">
        <div className="helper-text flex-between">
          {defaultWebsite === website.id ? (
            <img
              src={starSelected}
              alt="default"
              className="mark-default"
              onClick={(event) => {
                event.stopPropagation();
                updateNonDefaultWebsite(null);
              }}
            />
          ) : (
            <img
              src={starEmpty}
              alt="default"
              className="mark-default"
              onClick={(event) => {
                event.stopPropagation();
                updateDefaultWebsite(website.id, true);
              }}
            />
          )}
          {website.pinned ? (
            <img
              src={pinOn}
              alt="pinned"
              onClick={(event) => {
                event.stopPropagation();
                updateUnPinWebsite(website.id);
              }}
            />
          ) : (
            <img
              src={pinOff}
              alt="pin"
              onClick={(event) => {
                event.stopPropagation();
                updatePinWebsite(website.id);
              }}
            />
          )}
          {websitePinLoading === website.id && (
            <TailSpin
              height="25"
              width="25"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible
            />
          )}
        </div>
        <img
          src={DeleteIcon}
          className="delete-icon-tile"
          alt="bin-icon"
          onClick={(event) => {
            event.stopPropagation();
            setShowDeletePopup(true);
          }}
        />
      </div>
      {showDeletePopup && (
        <DeleteComponent
          loading={false}
          onCancel={(event) => {
            event.stopPropagation();
            setShowDeletePopup(false);
          }}
          onConfirmDelete={(event) => {
            event.stopPropagation();
            setShowDeletePopup(false);
            deleteWebsite([website.id]);
          }}
        />
      )}
    </div>
  );
}

export default function MyWebsites({
  websiteDetails,
  updateDefaultWebsite,
  updateNonDefaultWebsite,
  pageLoading,
  websitePinLoading,
  isModalOpen,
  handleCloseModal,
  handleOpenModal,
  deleteWebsite,
  isAddWebsiteOpen,
  setIsAddWebsiteOpen,
  updatePinWebsite,
  updateUnPinWebsite,
  addWebsiteName,
  addNewWebsite,
  getWebsiteTrackToken,
  companyTypes,
  collectData,
  modifyWebsiteID,
  showPopUp,
  websiteId
}) {
  const { websitesList, defaultWebsite } = websiteDetails;

  const selectedLanguage = LocalStorage.getItem("selectedLanguage");
  const newRoleMapping = roleMappingsNew[selectedLanguage];

  const { t } = useTranslation();

  const searchFilters = [
    {
      name: t("billing.headerName.websites"),
      value: "name"
    },
    {
      name: t("userManagement.dataTable.roles"),
      value: "role_id"
    }
  ];

  const [selected, setSelected] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [searchFilter, setSearchFilter] = useState("name");
  const [sortedWebsites, setSortedWebsites] = useState([]);
  const [filteredSortedWebsites, setFilteredSortedWebsites] = useState([]);

  const selectWebsite = (checked, id) => {
    if (checked) {
      setSelected([...selected, id]);
    } else {
      const index = selected.indexOf(id);
      if (index > -1) {
        const temp = selected;
        temp.splice(index, 1);
        setSelected(temp);
      }
    }
  };

  useEffect(() => {
    const sortedWebsitesList = [...websitesList]?.sort((a, b) => {
      // Check if either 'a' or 'b' is the default website
      if (a?.id === defaultWebsite) return -1;
      if (b?.id === defaultWebsite) return 1;

      // Sort by pinned status
      if (a?.pinned && !b?.pinned) return -1;
      if (!a?.pinned && b?.pinned) return 1;

      return 0;
    });

    setSortedWebsites(sortedWebsitesList);
  }, [websitesList]);

  const handleSearch = (searchTerm) => {
    const filteredResults = [];
    if (searchTerm?.length >= 1) {
      sortedWebsites?.forEach((website) => {
        const value = website[searchFilter];
        const roleName = newRoleMapping[value];
        if (searchFilter === "role_id") {
          const lowerCaseValue = roleName?.toLowerCase?.();
          const lowerCaseSearchTerm = searchTerm?.toLowerCase();

          const startsWithCondition =
            lowerCaseValue.startsWith(lowerCaseSearchTerm);

          const includesCondition =
            lowerCaseValue.includes(lowerCaseSearchTerm);

          const exactMatchCondition = lowerCaseValue === lowerCaseSearchTerm;

          if (startsWithCondition) {
            filteredResults.unshift(website);
          } else if (exactMatchCondition) {
            filteredResults.push(website);
          } else if (includesCondition) {
            filteredResults.push(website);
          }
        } else {
          const lowerCaseValue = value?.toLowerCase?.();
          const lowerCaseSearchTerm = searchTerm?.toLowerCase();

          const startsWithCondition =
            lowerCaseValue.startsWith(lowerCaseSearchTerm);

          const includesCondition =
            lowerCaseValue.includes(lowerCaseSearchTerm);

          const exactMatchCondition = lowerCaseValue === lowerCaseSearchTerm;

          if (startsWithCondition) {
            filteredResults.unshift(website);
          } else if (exactMatchCondition) {
            filteredResults.push(website);
          } else if (includesCondition) {
            filteredResults.push(website);
          }
        }
      });
    } else {
      filteredResults.push(...sortedWebsites);
    }
    setFilteredSortedWebsites(filteredResults);
  };

  const websites =
    filteredSortedWebsites?.length > 0
      ? filteredSortedWebsites
      : sortedWebsites;

  return (
    <>
      <AuthorizedHeader />
      <div className="container my-websites">
        <div className="modules-heading flex-between">
          <text>{t("noWebsite.mysites")}</text>
          <div className="flex-between">
            <img
              src={DeleteIcon}
              className="delete-button-main"
              alt="bin-icon"
              onClick={() => {
                if (selected.length > 0) setShowDeletePopup(true);
              }}
            />
            <ButtonDark
              buttonText={t("noWebsite.link")}
              icon={addIcon}
              onClick={() => {
                setIsAddWebsiteOpen(true);
              }}
            />
          </div>
        </div>
        {showDeletePopup && (
          <DeleteComponent
            loading={false}
            onCancel={() => setShowDeletePopup(false)}
            onConfirmDelete={() => {
              setShowDeletePopup(false);
              deleteWebsite([...selected]);
              setSelected([]);
            }}
          />
        )}

        {pageLoading && !websitePinLoading ? (
          <div className="flex-center loader">
            <TailSpin
              height="50"
              width="50"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible
            />
          </div>
        ) : (
          websitesList?.length > 0 && (
            <div>
              {/* Search filter and search bar */}
              <div className="search-bar-websites">
                <div className="flex">
                  {/* Search Filter */}
                  <div className="search-filter-websites">
                    <SearchFilter
                      searchFilters={searchFilters}
                      searchFilter={searchFilter}
                      setSearchFilter={setSearchFilter}
                    />
                  </div>
                  {/* Search Filter */}

                  {/* Search bar */}
                  <div className="search-bar-input">
                    <SearchBar onSearch={handleSearch} />
                  </div>
                </div>
                {/* Search bar ends */}
              </div>
              {/* Search filter and search bar ends */}

              <div className="websites-list">
                {websites?.map((web) => (
                  <WebsiteTile
                    modifyWebsiteID={modifyWebsiteID}
                    website={web}
                    websitePinLoading={websitePinLoading}
                    defaultWebsite={defaultWebsite}
                    updateDefaultWebsite={updateDefaultWebsite}
                    updateNonDefaultWebsite={updateNonDefaultWebsite}
                    selectWebsite={selectWebsite}
                    handleOpenModal={handleOpenModal}
                    deleteWebsite={deleteWebsite}
                    updatePinWebsite={updatePinWebsite}
                    updateUnPinWebsite={updateUnPinWebsite}
                    showPopUp={showPopUp}
                    websiteId={websiteId}
                  />
                ))}
              </div>
            </div>
          )
        )}

        {!pageLoading && !websitesList?.length && (
          <NoWebsite addNewWeb={() => setIsAddWebsiteOpen(true)} />
        )}
      </div>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        scroll="paper"
        maxWidth="md"
        fullWidth
        className="my-websites-modal"
      >
        <DialogTitle className="flex-between">
          {t("addWebsite.editWebsite")}
          <img
            src={CloseButton}
            alt="close-button"
            className="close-button"
            onClick={handleCloseModal}
          />
        </DialogTitle>
        <EditWebsiteDetails
          website={websitesList?.find((ele) => ele.id === isModalOpen)}
          handleCloseModal={handleCloseModal}
          addWebsiteName={addWebsiteName}
        />
      </Dialog>
      <Dialog
        open={isAddWebsiteOpen}
        onClose={() => setIsAddWebsiteOpen(false)}
        scroll="paper"
        maxWidth="md"
        fullWidth
        className="my-websites-modal"
        PaperProps={{
          style: {
            height: "85vh", // Adjust the height as needed
            overflow: "hidden" // Disable scrolling
          }
        }}
      >
        <DialogTitle className="flex-between">
          {t("addWebsite.addWebsiteButton")}
          <img
            src={CloseButton}
            alt="close-button"
            className="close-button"
            onClick={() => setIsAddWebsiteOpen(false)}
          />
        </DialogTitle>
        <AddWebsite
          handleCloseModal={() => setIsAddWebsiteOpen(false)}
          addNewWebsite={addNewWebsite}
          getWebsiteTrackToken={getWebsiteTrackToken}
          companyTypes={companyTypes}
          collectData={collectData}
        />
      </Dialog>
    </>
  );
}

MyWebsites.propTypes = {
  pageLoading: PropTypes.bool,
  websiteDetails: PropTypes.oneOfType([Object]),
  updateDefaultWebsite: PropTypes.func,
  updateNonDefaultWebsite: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleOpenModal: PropTypes.func,
  deleteWebsite: PropTypes.func,
  isAddWebsiteOpen: PropTypes.bool,
  setIsAddWebsiteOpen: PropTypes.func,
  updatePinWebsite: PropTypes.func,
  updateUnPinWebsite: PropTypes.func,
  websitePinLoading: PropTypes.bool,
  addWebsiteName: PropTypes.func,
  addNewWebsite: PropTypes.func,
  getWebsiteTrackToken: PropTypes.func,
  companyTypes: PropTypes.oneOfType([Array]),
  collectData: PropTypes.func,
  modifyWebsiteID: PropTypes.func,
  showPopUp: PropTypes.bool,
  websiteId: PropTypes.number
};

WebsiteTile.propTypes = {
  website: PropTypes.oneOfType([Object]),
  defaultWebsite: PropTypes.number,
  updateDefaultWebsite: PropTypes.func,
  updateNonDefaultWebsite: PropTypes.func,
  selectWebsite: PropTypes.func,
  handleOpenModal: PropTypes.func,
  deleteWebsite: PropTypes.func,
  updatePinWebsite: PropTypes.func,
  updateUnPinWebsite: PropTypes.func,
  websitePinLoading: PropTypes.bool,
  modifyWebsiteID: PropTypes.func,
  showPopUp: PropTypes.bool,
  websiteId: PropTypes.number
};
