/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import "./toggleSwitch.scss";
import { useTranslation } from "react-i18next";
import MainToggle from "./MainToggle";

export default function ToggleSwitch({
  isToggled,
  setIsToggled,
  label,
  onPress
}) {
  const { t } = useTranslation();

  const handleToggle = () => {
    onPress();
  };

  return (
    <MainToggle
      isToggled={isToggled}
      setIsToggled={setIsToggled}
      handleToggle={handleToggle}
      label={label}
    />
  );
}

ToggleSwitch.propTypes = {
  isToggled: PropTypes.bool,
  setIsToggled: PropTypes.func,
  label: PropTypes.string
};
