import React from "react";
import { useTranslation } from "react-i18next";
import "./assets/stylesheets/sidebar.scss";
import PropTypes from "prop-types";
import Check from "./assets/icons/check.svg";
import Active from "./assets/icons/active.svg";
import Passive from "./assets/icons/passive.svg";

export default function Sidebar({ activeComponent }) {
  const getIcon = (step) => {
    if (step < activeComponent) {
      // Render the checkmark icon for completed steps
      return <img src={Check} alt="check-icon" />;
    }
    if (step === activeComponent) {
      // Render the active icon for the current step
      return <img src={Active} alt="active-icon" />;
    }
    // Render the passive icon for future steps
    return <img src={Passive} alt="not-active-state" />;
  };

  const getClassName = (step) => {
    if (step < activeComponent) {
      return "check";
    }
    if (step === activeComponent) {
      return "active";
    }
    return "passive";
  };

  const { t } = useTranslation();

  return (
    <section className="sidebar">
      <div className="column-flex">
        <div className="sidebar-nav">
          <div className="flex">
            <img src={Check} alt="check-icon" />
            <p className={getClassName(0)}>
              {t("onboarding.sidebar.registerCreateAccount")}
            </p>
          </div>
          <div className="flex">
            {getIcon(1)}
            <p className={getClassName(1)}>
              {t("onboarding.sidebar.validateAccount")}
            </p>
          </div>
          {/* <div className="flex">
            {getIcon(2)}
            <p className={getClassName(2)}>
              {t("onboarding.sidebar.addWebsite")}
            </p>
          </div> */}
          {/* <div className="flex ">
            {getIcon(3)}
            <p className={getClassName(3)}>
              {t("onboarding.sidebar.pasteScript")}
            </p>
          </div> */}
        </div>
        <div className="sidebar-footer">
          <p>{t("onboarding.sidebar.contactUs")}</p>
          <p>{t("onboarding.sidebar.supportEmail")}</p>
        </div>
      </div>
    </section>
  );
}

Sidebar.propTypes = {
  activeComponent: PropTypes.number
};
