import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Moment from "moment";
import { Toastr } from "../../../Toastr/Toastr";
import customCalendar from "../../api/customCalendar";
import CalendarButtonCombination from "../CalendarButtonCombination/CalendarButtonCombination";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../../assets/stylesheets/addCustomRange.scss";
import LocalStorage from "../../../../utils/LocalStorgae";
import localLong from "../../../../data/calendarTransalation";

export default function AddCustomRange({
  customHandleSelect,
  fetchLabelsList,
  customSelectedRange,
  setActiveCalendarComponent
}) {
  const [loading, setLoading] = useState(false);
  const [customRangeName, setCustomRangeName] = useState("");
  const { t } = useTranslation();
  const language = LocalStorage.getItem("selectedLanguage");

  const { websiteID } = useSelector((state) => state.generalData);

  const handleCustomRangeConfirm = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!customRangeName) {
        Toastr.error(
          t("analyticsPage.calendar.custom.addCustom.addCustomRequired")
        );
        return; // Return when the custom range name is missing
      }
      const websiteDetail = await customCalendar.getWebsiteDetail();
      if (websiteDetail.data.success === true) {
        const responseObj = {
          startDate: Moment(customSelectedRange[0].startDate).format(
            "DD-MM-YYYY"
          ),
          endDate: Moment(customSelectedRange[0].endDate).format("DD-MM-YYYY"),
          website_id: websiteID,
          labelTitle: customRangeName,
          sharedCalendar: 1
        };
        const response = await customCalendar.addCustomCalendar(responseObj);
        if (response.data.result.success === true) {
          Toastr.success(
            t("analyticsPage.calendar.custom.addCustom.addCustomSuccess")
          );
          setCustomRangeName("");
          setActiveCalendarComponent(1);
          fetchLabelsList(); // Refresh labels list after adding a new custom range
        }
      }
    } catch (error) {
      setLoading(false);
      Toastr.error(t("analyticsPage.calendar.custom.addCustom.addCustomError"));
      // return Promise.reject(error); // Return a rejected Promise when there's an error
    }
  };

  const handleCancel = () => {
    setActiveCalendarComponent(1);
  };

  return (
    <div className="add-custom-calendar-range">
      <input
        className="custom-label-input"
        type="text"
        placeholder={t("analyticsPage.calendar.custom.addCustom.placeholder")}
        value={customRangeName}
        onChange={(e) => setCustomRangeName(e.target.value)}
      />
      <DateRangePicker
        ranges={customSelectedRange}
        onChange={customHandleSelect}
        color={themeColors.primaryColor4}
        rangeColors={[themeColors.primaryColor]}
        showSelectionPreview
        direction="horizontal"
        editableDateInputs
        dragSelectionEnabled
        showMonthAndYearPickers
        showDateDisplay
        showPreview
        months={2}
        locale={localLong[language]}
      />
      <div className="button-combo">
        <CalendarButtonCombination
          firstButtonText={t("common.cancelButton")}
          secondButtonText={t("common.saveButton")}
          onClickFirstButton={handleCancel}
          onClickSecondButton={handleCustomRangeConfirm}
          loading={loading}
        />
      </div>
    </div>
  );
}

AddCustomRange.propTypes = {
  customHandleSelect: PropTypes.func.isRequired,
  fetchLabelsList: PropTypes.func.isRequired,
  customSelectedRange: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string
    })
  ).isRequired,
  setActiveCalendarComponent: PropTypes.func.isRequired
};
