import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StackedChart from "./components/StackedChart";
import themeColors from "../../../assets/stylesheets/_var.scss";
import PopUpWindow from "../../PopUpWindow";
import ChartHeader from "../../ChartHeader/ChartHeader";
import "./assets/stylesheets/stackedChart.scss";

export default function Index({
  title,
  labels,
  visitors,
  views,
  secondViews,
  secondVisitors,
  updatedComponentFilters,
  selectedFilters,
  dashboard
}) {
  const { isCompare } = useSelector((state) => state.generalData);
  const { t } = useTranslation();
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [option, setOption] = useState(t("analyticsPage.visitors"));
  const [data1, setData1] = useState(visitors);
  const [data2, setData2] = useState(views);
  const [filteredLabels, setFilteredLabels] = useState(labels || []);
  const otherDataIncluded = labels?.includes(t("common.other"));
  const [isToggled, setIsToggled] = useState(false);

  const removeElementAtIndex = (items, index) => {
    if (!Array.isArray(items)) return [];
    return items.filter((_, i) => i !== index);
  };

  useEffect(() => {
    let indexToRemove;
    if (otherDataIncluded && !isToggled) {
      indexToRemove = labels.indexOf(t("common.other"));
      const labelsArray = labels.filter((item) => item !== t("common.other"));
      setFilteredLabels(labelsArray);
    } else {
      setFilteredLabels(labels);
    }
    if (isCompare) {
      if (option === t("analyticsPage.views")) {
        if (otherDataIncluded && !isToggled) {
          setData1(removeElementAtIndex(views, indexToRemove));
          setData2(removeElementAtIndex(secondViews, indexToRemove));
        } else {
          setData1(views);
          setData2(secondViews);
        }
      } else if (option === t("analyticsPage.visitors")) {
        if (otherDataIncluded && !isToggled) {
          setData1(removeElementAtIndex(visitors, indexToRemove));
          setData2(removeElementAtIndex(secondVisitors, indexToRemove));
        } else {
          setData1(visitors);
          setData2(secondVisitors);
        }
      }
    } else if (otherDataIncluded && !isToggled) {
      setData1(removeElementAtIndex(visitors, indexToRemove));
      setData2(removeElementAtIndex(views, indexToRemove));
    } else {
      setData1(visitors);
      setData2(views);
    }
  }, [option, views, visitors, secondViews, secondVisitors, isToggled]);

  const getSelectedColorData1 = () => {
    if (isCompare) {
      if (dashboard === "isichat") {
        if (!Object.keys(selectedFilters).includes(title))
          return filteredLabels?.map(() => `${themeColors.primaryColor}EE`); // "rgba(224, 186, 128, 1)"

        return filteredLabels?.map((ele) => {
          if (selectedFilters[title].includes(ele))
            return `${themeColors.primaryColor}EE`;

          return `${themeColors.primaryColor}55`;
        });
      }
      if (!Object.keys(selectedFilters).includes(title))
        return filteredLabels?.map(
          () => `${themeColors.secondaryColorShade2}EE`
        ); // "rgba(224, 186, 128, 1)"

      return filteredLabels?.map((ele) => {
        if (selectedFilters[title].includes(ele))
          return `${themeColors.secondaryColorShade2}EE`;
        return `${themeColors.secondaryColorShade2}55`;
      });
    }

    if (!Object.keys(selectedFilters).includes(title))
      return filteredLabels?.map(() => themeColors.primaryColor);

    return filteredLabels?.map((ele) => {
      if (selectedFilters[title].includes(ele)) return themeColors.primaryColor;
      return `${themeColors.primaryColor}77`;
    });
  };

  const getSelectedColorData2 = () => {
    if (!Object.keys(selectedFilters).includes(title))
      return filteredLabels?.map(() => "rgba(187, 200, 126, 1)");

    return filteredLabels?.map((ele) => {
      if (selectedFilters[title].includes(ele)) return "rgba(187, 200, 126, 1)";
      return "rgba(187, 200, 126, .4)";
    });
  };

  const getDatasets = () => {
    const getDataset = (label, backgroundColor, data) => ({
      label,
      backgroundColor,
      borderColor: backgroundColor,
      borderWidth: 1,
      hoverBackgroundColor: backgroundColor,
      hoverBorderColor: backgroundColor,
      data,
      barThickness: filteredLabels?.length <= 6 ? 60 : "flex"
    });

    if (dashboard === "isichat") {
      if (isCompare) {
        return [
          getDataset(t("common.range_a"), getSelectedColorData1(), data1),
          getDataset(t("common.range_b"), getSelectedColorData2(), data2)
        ];
      }
      return [getDataset("", getSelectedColorData1(), data1)];
    }
    if (dashboard === "ecommerce") {
      return [getDataset("", getSelectedColorData1(), data1)];
    }
    return [
      getDataset(
        isCompare ? option : t("analyticsPage.visitors"),
        getSelectedColorData1(),
        data1
      ),
      getDataset(
        isCompare ? option : t("analyticsPage.views"),
        getSelectedColorData2(),
        data2
      )
    ];
  };

  const dataForPersona = {
    labels: filteredLabels,
    datasets: getDatasets()
  };

  const customLabel = (tooltipItem) => {
    if (dashboard === "isichat") {
      return ` ${t("analyticsPage.visitors")}: ${tooltipItem.formattedValue}`;
    }
    if (dashboard === "ecommerce") {
      return ` ${t("ecommerce.customers")}: ${tooltipItem.formattedValue}`;
    }
    return ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
  };

  const getLegend = () => {
    const commonLegend = {
      position: "top",
      align: "end",
      labels: {
        font: {
          family: themeColors.fontQuickSand,
          weight: "550",
          size: "15vh"
        },
        color: themeColors.graphAxisColor
      }
    };
    if (dashboard === "isichat") {
      return isCompare ? commonLegend : false;
    }
    if (dashboard === "ecommerce") {
      return isCompare ? commonLegend : false;
    }
    return isCompare ? false : commonLegend;
  };

  const handleHideOtherData = () => {
    setIsToggled(!isToggled);
  };

  const options = {
    animation: true,
    maintainAspectRatio: true,
    devicePixelRatio: themeColors.devicePixelRatio,
    responsive: true,
    plugins: {
      legend: getLegend(),
      title: {
        display: false
      },
      tooltip: {
        cornerRadius: 10,
        backgroundColor: themeColors.primaryText,
        displayColors: false,
        padding: "10",
        titleFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        bodyFont: {
          family: themeColors.fontQuickSand,
          size: "16"
        },
        callbacks: {
          title: () => "",
          label: customLabel
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          align: "center",
          callback: (item) => {
            const labelItem = filteredLabels[item]?.split(/[,]/);
            // return `${labelItem[0]} ${labelItem[1] ? `-${labelItem[1]}` : ""}`;
            return labelItem[0].split(" ");
          },
          color: themeColors.graphAxisColor
        }
      },
      y: {
        border: { dash: [4, 5] },
        grid: {
          color: themeColors.tertiaryText, // for the grid lines
          offset: false,
          drawTicks: false, // true is default
          drawOnChartArea: true // true is default
        },
        ticks: {
          font: {
            family: themeColors.fontQuickSand,
            weight: "550",
            size: "15vh"
          },
          color: themeColors.graphAxisColor
        },
        beginAtZero: true
      }
    },
    onHover: (event, chartElement) => {
      const { native } = event;
      native.target.style.cursor = chartElement[0] ? "pointer" : "default";
    }
  };

  const ChartContent = (
    <div className="persona-body background-box">
      <ChartHeader
        title={title}
        togglePopUp={togglePopUp}
        setTogglePopUp={setTogglePopUp}
        isToggled={isToggled}
        handleHideOtherData={handleHideOtherData}
        otherDataIncluded={otherDataIncluded}
      />
      {isCompare && dashboard !== "isichat" && (
        <div className="radio-options flex-end">
          <label htmlFor="Visitors">
            <input
              type="radio"
              id="Visitors"
              name="select"
              defaultChecked
              onClick={(e) => setOption(e.target.id)}
            />
            {t("analyticsPage.visitors")}
          </label>

          <label htmlFor="Views">
            <input
              type="radio"
              id="Views"
              name="select"
              onClick={(e) => setOption(e.target.id)}
            />
            {t("analyticsPage.views")}
          </label>
        </div>
      )}
      <StackedChart
        title={title}
        data={dataForPersona}
        options={options}
        selectedFilters={selectedFilters}
        updatedComponentFilters={updatedComponentFilters}
        height={110}
      />
    </div>
  );

  if (togglePopUp)
    return <PopUpWindow className="stacked-chart">{ChartContent}</PopUpWindow>;

  return ChartContent;
}

Index.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  visitors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  views: PropTypes.oneOfType([PropTypes.array]).isRequired,
  secondVisitors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  secondViews: PropTypes.oneOfType([PropTypes.array]).isRequired,
  updatedComponentFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.oneOfType([Object]).isRequired,
  dashboard: PropTypes.string
};
