/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import "./mainButton.scss";
import CircularProgress from "@mui/material/CircularProgress";
import themeColors from "../../../assets/stylesheets/_var.scss";

function ButtonDark({
  buttonText,
  onClick,
  loading,
  disabled,
  className,
  isSubmit,
  loader
}) {
  return (
    <button
      type={isSubmit ? "submit" : "button"}
      className={`main-dark-btn ${className}  ${disabled && "disabled"}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loader ? (
        <CircularProgress
          sx={{
            color: disabled ? themeColors.primaryColor : themeColors.background
          }}
        />
      ) : (
        <span>{loading ? `${buttonText}...` : buttonText}</span>
      )}
    </button>
  );
}

ButtonDark.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  isSubmit: PropTypes.bool,
  disabled: PropTypes.bool,
  loader: PropTypes.bool
};

function ButtonLight({
  buttonText,
  style,
  onClick,
  loading,
  className,
  isSubmit
}) {
  return (
    <button
      type={isSubmit ? "submit" : "button"}
      style={style}
      className={`main-light-btn ${className}`}
      onClick={onClick}
    >
      {loading ? `${buttonText}...` : buttonText}
    </button>
  );
}

ButtonLight.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  isSubmit: PropTypes.bool
};

export { ButtonDark, ButtonLight };
