/* eslint-disable no-console */
/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import login from "./api/login";
import getRememberMeToken from "./api/rememberMeToken";
import LocalStorage from "../../../utils/LocalStorgae";
import setStorages from "../../../utils/loginSetProcess";
import { useEffect } from "react";
// import LocalStorage from "../../../utils/LocalStorgae";

export default function Index() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(""); // State for email validation error
  const [passwordError, setPasswordError] = useState(""); // State for password validation error
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Event handler to clear email error when the user starts typing
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(""); // Clear email error
  };

  // Event handler to clear password error when the user starts typing
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(""); // Clear password error
  };

  const emailValidation = () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!email) {
      return t("common.emailValidation.emailRequired");
    }
    if (!emailRegex.test(email)) {
      return t("common.emailValidation.invalidEmail");
    }
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const error = emailValidation();
      if (error) {
        setEmailError(error);
        return;
      }
      setEmailError("");

      // Validate password
      if (!password) {
        setPasswordError(t("login.passwordRequired"));
        return; // Exit early if there's a password validation error
      }
      setPasswordError("");

      const response = await login({
        email,
        password
      });

      setStorages(response, dispatch, navigate, t);

      if (rememberMe) {
        console.log("checked");
        const rememberMeToken = await getRememberMeToken();
        LocalStorage.setItem("RememberMeToken", rememberMeToken.data.token);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        setEmailError(t("login.userDoesNotExist"));
        return;
      }
      if (error.response?.status === 403) {
        setPasswordError(t("login.invalidCredential"));
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     // Push a new state to the history stack
  //     navigate("/", { replace: true });

  //     // Add an event listener for popstate (back button)
  //     const handlePopState = (event) => {
  //       console.log("Back button was clicked");
  //       if (event.state && event.state.idx === 0) {
  //         navigate("/", { replace: true });
  //       }
  //     };

  //     window.addEventListener("popstate", handlePopState);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       window.removeEventListener("popstate", handlePopState);
  //     };
  //   }
  // }, [location, navigate]);

  return (
    <LoginForm
      setRememberMe={setRememberMe}
      rememberMe={rememberMe}
      setEmail={handleEmailChange}
      setPassword={handlePasswordChange}
      handleSubmit={handleSubmit}
      loading={loading}
      emailError={emailError} // Pass email validation error to child component
      passwordError={passwordError} // Pass password validation error to child component
      email={email}
      password={password}
    />
  );
}
