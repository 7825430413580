import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import MultiSelectDashboardComponent from "./MultiSelectDashboardComponent";
import MultiSelectRoleComponent from "./MultiSelectRoleComponent";
import Delete from "../../../../assets/icons/DeleteAccount.svg";

function WebsiteRoleTableComponent({
  selectedWebsites,
  setSelectedWebsites,
  getWebsiteName,

  // role component props,
  roleMapping,
  roles,
  // role component props ends,

  // dashboard component props,
  dashboardMapping,
  options,
  // dashboard component props ends,

  selectedRolesNew,
  setSelectedRolesNew,
  selectedDashboards,
  setSelectedDashboards,
  userWebsitesInfo,
  setAddWebsiteRole
}) {
  const { t } = useTranslation();

  const handleRoleNewsChange = (event, websiteId) => {
    const roleId = event.target.value;
    setSelectedRolesNew((prevSelectedRoles) => {
      const existingRoleIndex = prevSelectedRoles.findIndex(
        (role) => role.website === websiteId
      );
      if (existingRoleIndex !== -1) {
        return prevSelectedRoles.map((role, index) => {
          if (index === existingRoleIndex) {
            return { website: websiteId, role_id: roleId };
          }
          return role;
        });
      }
      return [...prevSelectedRoles, { website: websiteId, role_id: roleId }];
    });
  };

  const handleDashboardNewsChange = (event, websiteId) => {
    const dashboardId = event.target.value;
    setSelectedDashboards((prevSelectedRoles) => {
      const existingRoleIndex = prevSelectedRoles.findIndex(
        (role) => role.website === websiteId
      );

      if (existingRoleIndex !== -1) {
        return prevSelectedRoles.map((role, index) => {
          if (index === existingRoleIndex) {
            return { website: websiteId, dashboard_id: dashboardId };
          }
          return role;
        });
      }

      return [
        ...prevSelectedRoles,
        { website: websiteId, dashboard_id: dashboardId }
      ];
    });
  };

  const handleDeleteWebsite = (websiteId, index) => {
    const updatedSelectedWebsites = [...selectedWebsites];
    updatedSelectedWebsites.splice(index, 1);

    const deletedWebsiteRoles = selectedRolesNew?.filter(
      (role) => role?.website !== websiteId
    );
    const deletedWebsiteDashboards = selectedDashboards?.filter(
      (dashboard) => dashboard?.website !== websiteId
    );

    setSelectedWebsites(updatedSelectedWebsites);
    setSelectedRolesNew(deletedWebsiteRoles);
    setSelectedDashboards(deletedWebsiteDashboards);
    if (updatedSelectedWebsites.length < 1) {
      setAddWebsiteRole(false);
    }
  };

  return (
    <TableContainer className="table-website">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="table-cell">
              <text className="table-cell-text">
                {t("userManagement.addedWebsites")}
              </text>
            </TableCell>
            <TableCell className="table-cell-1">
              <text className="table-cell-text">
                {t("userManagement.dataTable.roles")}
              </text>
            </TableCell>
            <TableCell className="table-cell-2">
              <text className="table-cell-text">
                {t("dashboardMenu.dashboard")}
              </text>
            </TableCell>
            <TableCell className="table-cell-2">
              <text className="table-cell-text">
                {t("common.confirmation.delete")}
              </text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedWebsites.map((website, index) => (
            <TableRow>
              <TableCell className="table-cell">
                <text className="table-cell-text">
                  {getWebsiteName(website)}
                </text>
              </TableCell>

              <TableCell className="table-cell-1">
                <MultiSelectRoleComponent
                  userWebsitesInfo={userWebsitesInfo}
                  roleMapping={roleMapping}
                  roles={roles}
                  handleRoleChange={(event) =>
                    handleRoleNewsChange(event, website)
                  }
                  website={website}
                />
              </TableCell>

              <TableCell className="table-cell-2">
                <MultiSelectDashboardComponent
                  userWebsitesInfo={userWebsitesInfo}
                  options={options}
                  handleDashboardChange={(event) =>
                    handleDashboardNewsChange(event, website)
                  }
                  dashboardMapping={dashboardMapping}
                  website={website}
                />
              </TableCell>

              <TableCell className="table-cell-2">
                {/* eslint-disable-next-line */}
                <img
                  className="delete-icon"
                  src={Delete}
                  alt="delete"
                  onClick={() => {
                    handleDeleteWebsite(website, index);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

WebsiteRoleTableComponent.propTypes = {
  selectedWebsites: PropTypes.oneOfType([PropTypes.array]),
  setSelectedWebsites: PropTypes.func,
  getWebsiteName: PropTypes.func,
  roleMapping: PropTypes.oneOfType([PropTypes.array]),
  roles: PropTypes.oneOfType([PropTypes.array]),
  dashboardMapping: PropTypes.oneOfType([PropTypes.array]),
  options: PropTypes.oneOfType([PropTypes.array]),
  selectedRolesNew: PropTypes.oneOfType([PropTypes.array]),
  setSelectedRolesNew: PropTypes.func,
  selectedDashboards: PropTypes.oneOfType([PropTypes.array]),
  setSelectedDashboards: PropTypes.func,
  userWebsitesInfo: PropTypes.oneOfType([PropTypes.array]),
  setAddWebsiteRole: PropTypes.func
};

export default WebsiteRoleTableComponent;
