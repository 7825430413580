/* eslint-disable react/prop-types */
import React from "react";
import { CircularProgress } from "@mui/material";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/loader.scss";

export default function Loader({ text }) {
  return (
    <div className="flex-center">
      <div>
        <CircularProgress
          sx={{ color: themeColors.primaryColorShade2 }}
          size={20}
          thickness={5}
        />
      </div>
      <div className="connection-text-padding">
        <p className="connection-text">{text}</p>
      </div>
      <div className="flex-start">
        <div className="dotted-progress-container">
          <div className="dotted-progress" />
        </div>
      </div>
    </div>
  );
}
