import React from "react";
import PropTypes from "prop-types";
import "../assets/stylesheets/advertisementStyles.scss";
import DropdownComponent from "../../../../components/inputElements/selectInput/DropdownComponent";

function AdvertisementFontComponent(props) {
  const { title, options, id, handleColorOrFontChange } = props;
  console.log("options", options);
  const dropDownOptions =
    options?.length > 0
      ? options.map((option) => {
          return {
            ...option,
            label: option.fontFamily,
            name: option.fontFamily,
            value: option.id
          };
        })
      : [{ id: 1, fontFamily: "Arial", label: "Arial", value: 1 }];
  console.log("dropDownOptions", dropDownOptions);
  const selectedFont =
    options?.length > 0
      ? options.find((option) => {
          return option.isSelected === 1;
        })
      : { id: 1, fontFamily: "Arial", isSelected: 1 };
  console.log("selectedFont", selectedFont);
  return (
    <div className="advertisement-color-continer">
      <div className="advertisement-color-title">{title}</div>
      <div>
        <DropdownComponent
          value={selectedFont?.id}
          name={selectedFont?.fontFamily}
          options={dropDownOptions}
          onChange={(e) => {
            const selectedId = e.target.value;
            const newSelectedFont = options.find(
              (option) => option.id === selectedId
            );
            handleColorOrFontChange(id, {
              id: newSelectedFont.id,
              fontFamily: newSelectedFont.fontFamily,
              isSelected: 1
            });
          }}
          marginTop="0px"
          minWidth="200px"
          //   defaultValue="new"
        />
        {/* <button
          type="button"
          onClick={() => {
            handleColorOrFontChange(id, { id: 1, fontFamily: "Arial" });
          }}
          style={{ cursor: "pointer", border: "none", background: "none" }}
        >
          <div className="advertisement-font-button">
            <div className="advertisement-color-button-text">Quickstand</div>

            <div className="advertisement-font-down-arrow">
               <img
                src={downArrow}
                alt="down-arrow"
                style={{ width: "100%", hei: "100%" }}
              />
            </div>
          </div>
        </button>  */}
      </div>
    </div>
  );
}

export default AdvertisementFontComponent;

AdvertisementFontComponent.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]),
  handleColorOrFontChange: PropTypes.func
};
