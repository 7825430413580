import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NoDataFound from "../Placeholders/NoDataFound";
import AddBarGraph from "../../components/ChartComponents/GeneralBarGraph";
import Insights from "../../components/Insights";
import MultiBarChart from "../../components/ChartComponents/MultiBarChart";
import DonutChart from "../../components/ChartComponents/DonutChart";
import WorldMap from "../../components/ChartComponents/WorldMap";
import StackedChart from "../../components/ChartComponents/StackedChart";

export default function GeneralDashboard({
  insightsTitle,
  dashboard,
  isCompare,
  selectedEcommerceFilters,
  updateSelectedFilter
}) {
  const { t } = useTranslation();
  const {
    allData,
    insights,
    multiBarChartData,
    bestSellingProducts,
    revenuePerCategory,
    topVriants,
    orderStatus,
    uniqueVsReturningCustomers,
    spendingIntensity,
    worldData,
    averageTimeBetweenOrders,
    repeatPurchaseRate,
    discountsCodeUsage,
    customerAcquisition,
    customerPreferences
  } = useSelector((state) => state.ecommerce);

  if (allData.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
          dashboard={dashboard}
        />
        <MultiBarChart
          title={t("ecommerce.ordersAndRevenueAnalysis")}
          filterTitle={t("common.date")}
          dashboard={dashboard}
          labels={multiBarChartData?.labels}
          data1={{
            dataset: multiBarChartData?.requests,
            label: t("PMSPage.requests")
          }}
          data2={{
            dataset: multiBarChartData?.purchase,
            label: t("ecommerce.orders")
          }}
          data3={{
            dataset: multiBarChartData?.revenue,
            label: t("campaignPerformance.revenue")
          }}
          multiBarChart
          isCompare={isCompare}
          filterValues={selectedEcommerceFilters}
        />
        <div className="grid grid-template general-bar-graph-height">
          <AddBarGraph
            title={t("ecommerce.bestSellingProducts")}
            subtitle={t("ecommerce.products")}
            labels={bestSellingProducts?.labels}
            numBars={
              bestSellingProducts?.labels?.length < 6
                ? bestSellingProducts?.labels?.length
                : 6
            }
            data={{
              tooltip: t("ecommerce.quantity"),
              values: bestSellingProducts?.productCount
            }}
            columnData={[
              {
                name: t("ecommerce.quantity"),
                diff: bestSellingProducts?.productCount,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
          <AddBarGraph
            title={t("ecommerce.revenuePerCategory")}
            subtitle={t("ecommerce.productCategories")}
            labels={revenuePerCategory?.labels}
            numBars={
              revenuePerCategory?.labels?.length < 6
                ? revenuePerCategory?.labels?.length
                : 6
            }
            data={{
              tooltip: t("campaignPerformance.revenue"),
              values: revenuePerCategory?.revenue
            }}
            columnData={[
              {
                name: t("campaignPerformance.revenue"),
                diff: revenuePerCategory?.revenue,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
          <DonutChart
            title={t("ecommerce.orderStatus")}
            labels={orderStatus?.labels}
            dataSet={orderStatus?.dataset}
            selectedFilters={selectedEcommerceFilters}
            updateFilters={updateSelectedFilter}
            tooltipLabel={t("ecommerce.orders")}
          />
        </div>
        <div className="general-bar-graph-height">
          <AddBarGraph
            labels={topVriants?.labels}
            title={t("ecommerce.topVariants")}
            subtitle={t("isiChatDashboard.categories")}
            data={{
              tooltip: t("ecommerce.count"),
              values: topVriants?.dataset
            }}
            numBars={
              topVriants?.labels?.length < 6 ? topVriants?.labels?.length : 6
            }
            columnData={[
              {
                id: 1,
                name: t("ecommerce.count"),
                diff: topVriants?.dataset,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
        </div>
        <div className="grid half-column-grid">
          <DonutChart
            title={t("ecommerce.uniqueVsReturningCustomers")}
            labels={uniqueVsReturningCustomers?.labels}
            dataSet={uniqueVsReturningCustomers?.dataset}
            selectedFilters={selectedEcommerceFilters}
            updateFilters={updateSelectedFilter}
            tooltipLabel={t("ecommerce.customers")}
          />
          <AddBarGraph
            title={t("ecommerce.spendingIntensity")}
            labels={spendingIntensity?.labels}
            numBars={
              spendingIntensity?.labels?.length < 6
                ? spendingIntensity?.labels?.length
                : 6
            }
            data={{
              tooltip: t("ecommerce.count"),
              values: spendingIntensity?.dataset
            }}
            columnData={[
              {
                name: t("ecommerce.count"),
                diff: spendingIntensity?.dataset,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
        </div>
        <WorldMap
          data={worldData?.dataset}
          labels={worldData?.labels}
          title={t("ecommerce.customersLocations")}
          selectedFilters={selectedEcommerceFilters}
          updateFilters={updateSelectedFilter}
          dashboard={dashboard}
        />

        {averageTimeBetweenOrders?.dataset?.some((value) => value > 0) && (
          <StackedChart
            title={t("ecommerce.averageTimeBetweenOrders")}
            visitors={averageTimeBetweenOrders?.dataset}
            labels={averageTimeBetweenOrders?.labels}
            updatedComponentFilters={updateSelectedFilter}
            selectedFilters={selectedEcommerceFilters}
            dashboard={dashboard}
          />
        )}
        <div className="grid half-column-grid general-bar-graph-height">
          <AddBarGraph
            title={t("ecommerce.repeatPurchaseRate")}
            subtitle={t("ecommerce.customerId")}
            labels={repeatPurchaseRate?.labels}
            numBars={
              repeatPurchaseRate?.labels?.length < 6
                ? repeatPurchaseRate?.labels?.length
                : 6
            }
            data={{
              tooltip: t("ecommerce.count"),
              values: repeatPurchaseRate?.dataset
            }}
            columnData={[
              {
                name: t("ecommerce.count"),
                diff: repeatPurchaseRate?.dataset,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
          <AddBarGraph
            title={t("ecommerce.discountsCodeUsage")}
            subtitle={t("analyticsPage.browsersSubtitle")}
            labels={discountsCodeUsage?.labels}
            numBars={
              discountsCodeUsage?.labels?.length < 6
                ? discountsCodeUsage?.labels?.length
                : 6
            }
            data={{
              tooltip: t("ecommerce.count"),
              values: discountsCodeUsage?.dataset
            }}
            columnData={[
              {
                name: t("ecommerce.count"),
                diff: discountsCodeUsage?.dataset,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
        </div>
        <div className="grid half-column-grid general-bar-graph-height">
          <AddBarGraph
            title={t("ecommerce.customerAcquisition")}
            subtitle={t("analyticsPage.browsersSubtitle")}
            labels={customerAcquisition?.labels}
            numBars={
              customerAcquisition?.labels?.length < 6
                ? customerAcquisition?.labels?.length
                : 6
            }
            data={{
              tooltip: t("ecommerce.count"),
              values: customerAcquisition?.dataset
            }}
            columnData={[
              {
                name: t("ecommerce.count"),
                diff: customerAcquisition?.dataset,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
          <AddBarGraph
            title={t("ecommerce.customerPreferences")}
            subtitle={t("analyticsPage.browsersSubtitle")}
            labels={customerPreferences?.labels}
            numBars={
              customerPreferences?.labels?.length < 6
                ? customerPreferences?.labels?.length
                : 6
            }
            data={{
              tooltip: t("ecommerce.count"),
              values: customerPreferences?.dataset
            }}
            columnData={[
              {
                name: t("ecommerce.count"),
                diff: customerPreferences?.dataset,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedEcommerceFilters}
          />
        </div>
      </>
    );
  return <NoDataFound />;
}

GeneralDashboard.propTypes = {
  insightsTitle: PropTypes.oneOfType([Object]),
  isCompare: PropTypes.bool,
  selectedEcommerceFilters: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  updateSelectedFilter: PropTypes.func
};
