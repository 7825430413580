/* eslint-disable */
import React, { useState } from "react";
import close from "../../../../../assets/icons/close.png";
import generate from "../../../../../assets/icons/generate.png";
import editimage from "../../../../../assets/icons/editimage.png";
import "../assets/stylesheets/generatedDesignsComponent.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import ReactJsxParser from "react-jsx-parser";
import { ButtonDark } from "../../../../../components/inputElements/buttons/MainButton";
import { useSelector } from "react-redux";
import {
  generateTemplate,
  getCategorisedImage
} from "../../AddCampaign/api/addCampaignAPI";
import Toastr from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";
import { useTranslation } from "react-i18next";

export default function GeneratedDesignsComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    randomObjects,
    newRandomObjects,
    multiSelectTypes,
    selectedChannels,
    originalTemplates,
    cleanTemplates,
    selectedPersonas
  } = location.state || {}; // Extract randomObjects from state

  const { websiteID } = useSelector((state) => state.generalData);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [getImagesLoading, setGetImagesLoading] = useState(false);

  const [newDesigns, setNewDesigns] = useState([]);

  const handleGetCategorisedImage = async () => {
    setGetImagesLoading(true);
    const payload = {
      website_id: websiteID,
      persona: selectedPersonas
    };
    try {
      // First API call to getCategorisedImage
      const res = await getCategorisedImage(payload);
      if (res.data.success === true) {
        const randomObjects = res?.data?.randomObjects;

        // Call the second API (generate-template) with the response from the first API
        const generateTemplatePayload = [...randomObjects];
        const templateResponse = await generateTemplate(
          generateTemplatePayload
        );
        const originalTemplates = templateResponse?.data?.templates;
        setNewDesigns(originalTemplates); // Save the templates generated for each ad
        // If second API is successful, show success message and navigate
        if (templateResponse?.status === 200) {
          Toastr.success("Success");
        } else {
          Toastr.error("Template generation failed.");
        }
      } else {
        Toastr.error("Categorised Image Fetch Failed.");
      }
    } catch (error) {
      setGetImagesLoading(false);
      Toastr.error("Something went wrong.");
    } finally {
      setGetImagesLoading(false);
    }
  };

  return (
    <>
      <AuthorizedHeader />

      <div className="container">
        <div className="generated-designs background-box">
          <div className="generated-designs-margins">
            <div className="flex-between">
              <div className="modules-heading">
                <p className="modules-heading">
                  {t("manageCampaign.generatedDesigns")}
                </p>
              </div>
              <div className="close-icon">
                <img
                  src={close}
                  onClick={() => {
                    navigate(-1);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    // Handling keyboard events
                    if (e.key === "Enter" || e.key === " ") {
                      navigate(-1);
                    }
                  }}
                  className="close-icon-img"
                />
              </div>
            </div>
            <div className="generated-designs-subtitle">
              {t("manageCampaign.recoImages")}
            </div>
          </div>

          <div className="preview-background">
            <div
              style={{
                paddingBottom: "10px"
              }}
              className="flex-between"
            >
              <div className="preview-text">{t("manageCampaign.preview")}</div>

              <div
                className="flex get-categorised-images"
                onClick={handleGetCategorisedImage}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  // Handling keyboard events
                  if (e.key === "Enter" || e.key === " ") {
                    handleGetCategorisedImage();
                  }
                }}
              >
                <p className="regenerate-text">
                  {t("manageCampaign.generateImages")}
                </p>
                <div className="generate-icon-div">
                  <img
                    src={generate}
                    //   onClick={handleAddImagePopUpClose}
                    className="generate-icon-img"
                  />
                </div>
              </div>
            </div>

            <div className="flex-between">
              {getImagesLoading ? (
                <div className="get-images-loading">
                  <Loader text={t("manageCampaign.generatingImages")} />
                </div>
              ) : newDesigns?.length > 0 ? (
                newDesigns?.map((template, index) => (
                  <div key={index} className="get-images-main">
                    <div
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      className={`images-padding ${
                        hoveredIndex === index ? "isHovered" : ""
                      }`}
                    >
                      <div style={{ maxHeight: "300px", overflow: "hidden" }}>
                        <ReactJsxParser components={{}} jsx={template} />
                      </div>
                      <div
                        className="flex-between"
                        style={{
                          marginTop: "20px"
                        }}
                      >
                        <div>
                          <ButtonDark
                            buttonText={t("manageCampaign.useDesign")}
                            onClick={() => {
                              const selectedRandomObject = newRandomObjects
                                ? newRandomObjects[index]
                                : randomObjects[index];
                              navigate("/manage/design-preview", {
                                state: {
                                  selectedRandomObject,
                                  cleanTemplates,
                                  template,
                                  selectedChannels,
                                  multiSelectTypes,
                                  selectedPersonas
                                }
                              });
                            }}
                          />
                        </div>
                        <div>
                          <img
                            src={editimage}
                            className="edit-icon-img"
                            onClick={() => {
                              const selectedRandomObject = newRandomObjects
                                ? newRandomObjects[index]
                                : randomObjects[index];
                              navigate("/manage/edit-design", {
                                state: {
                                  selectedRandomObject,
                                  cleanTemplates,
                                  template,
                                  selectedChannels,
                                  multiSelectTypes,
                                  selectedPersonas
                                }
                              });
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                              // Handling keyboard events
                              if (e.key === "Enter" || e.key === " ") {
                                const selectedRandomObject = newRandomObjects
                                  ? newRandomObjects[index]
                                  : randomObjects[index];
                                navigate("/manage/edit-design", {
                                  state: {
                                    selectedRandomObject,
                                    cleanTemplates,
                                    template,
                                    selectedChannels,
                                    multiSelectTypes,
                                    selectedPersonas
                                  }
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                originalTemplates?.map((template, index) => (
                  <div key={index} className="get-images-main">
                    <div
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      className={`images-padding ${
                        hoveredIndex === index ? "isHovered" : ""
                      }`}
                    >
                      <div style={{ maxHeight: "300px", overflow: "hidden" }}>
                        <ReactJsxParser components={{}} jsx={template} />
                      </div>
                      <div
                        style={{
                          marginTop: "20px"
                        }}
                        className="flex-between"
                      >
                        <div>
                          <ButtonDark
                            buttonText={t("manageCampaign.useDesign")}
                            onClick={() => {
                              const selectedRandomObject = newRandomObjects
                                ? newRandomObjects[index]
                                : randomObjects[index];
                              navigate("/manage/design-preview", {
                                state: {
                                  selectedRandomObject,
                                  cleanTemplates,
                                  template,
                                  selectedChannels,
                                  multiSelectTypes,
                                  selectedPersonas
                                }
                              });
                            }}
                          />
                        </div>
                        <div>
                          <img
                            src={editimage}
                            className="edit-icon-img"
                            onClick={() => {
                              const selectedRandomObject = newRandomObjects
                                ? newRandomObjects[index]
                                : randomObjects[index];
                              navigate("/manage/edit-design", {
                                state: {
                                  selectedRandomObject,
                                  cleanTemplates,
                                  template,
                                  selectedChannels,
                                  multiSelectTypes,
                                  selectedPersonas
                                }
                              });
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                              // Handling keyboard events
                              if (e.key === "Enter" || e.key === " ") {
                                const selectedRandomObject = newRandomObjects
                                  ? newRandomObjects[index]
                                  : randomObjects[index];
                                navigate("/manage/edit-design", {
                                  state: {
                                    selectedRandomObject,
                                    cleanTemplates,
                                    template,
                                    selectedChannels,
                                    multiSelectTypes,
                                    selectedPersonas
                                  }
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            {/* Navigation buttoons */}
            {/* <div className="navigation-buttons flex-between">
              <img
                src={backbutton}
                alt="back-button"
                className="navigation-icons"
              />

              <div className="generated-images-number">
                Showing 03 of 03 images
              </div>

              <div>
                <img
                  src={nextbutton}
                  alt="next-icon"
                  className="navigation-icons"
                />
              </div>
            </div> */}
            {/* Navigation buttoons */}
          </div>
        </div>
      </div>
    </>
  );
}
