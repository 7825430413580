/* eslint-disable */
import React from "react";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../Toggle/toggle.scss";

export default function MainToggle({
  handleToggle,
  isToggled,
  setIsToggled,
  label
}) {
  return (
    <div className="toggle flex">
      <p
        style={{
          fontFamily: themeColors.fontQuickSand,
          color: themeColors.secondaryText,
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "20px",
          letterSpacing: "5%",
          paddingRight: "7px"
        }}
      >
        {label}
      </p>
      <div
        className={isToggled ? "outer-div toggle-on" : "outer-div"}
        onClick={handleToggle}
      >
        <div className={`${isToggled ? "inner-div active" : "inner-div"}`} />
      </div>
    </div>
  );
}
