import { Toastr } from "../components/Toastr/Toastr";
import {
  updateCustomerId,
  updateDefWebsite,
  updateWebsiteID,
  updateWebsitesList
} from "../store/slices/generalSlice";
import storage from "./storage";

async function setStorages(response, dispatch, navigate, t) {
  if (response.data.status === "ok") {
    let integratedFlag = 1;
    if (response.data.website_id.length === 1) {
      integratedFlag = response.data.website_id[0].integrated;
    }
    if (
      response.data.is_verified === 1 &&
      response.data.website_id.length !== 0 &&
      integratedFlag === 1
    ) {
      // return;
      storage.setToken(response.data.token);
      storage.setItem("email", response.data.customer_email);
      storage.setItem("websiteId", response.data.defaultWebsite);
      storage.setItem("websitesList", JSON.stringify(response.data.website_id));
      storage.setItem("defaultWeb", response.data.defaultWebsite);
      dispatch(updateWebsitesList(response.data.website_id));
      const websiteList = response.data.website_id;
      const role = response.data.defaultWebsite
        ? websiteList.find((ele) => ele.id === response.data.defaultWebsite)
        : websiteList.find((ele) => ele.id === response.data.website_id[0].id);
      storage.setItem("role", role.role_id);
      storage.setItem("createdBy", role.created_by);
      storage.setItem("customerId", response.data.customer_id);
      dispatch(
        updateWebsiteID({
          role_id: role.role_id,
          created_by: role.created_by,
          website_id: response.data.defaultWebsite
        })
      );
      dispatch(updateCustomerId(response.data.customer_id));

      dispatch(updateDefWebsite(response.data.defaultWebsite));
      storage.setItem("isVerified", response.data.is_verified);
      if (response.data.defaultWebsite) {
        navigate("/analytics", { replace: true });
      } else {
        navigate("/mySites");
      }
    }
  } else if (response.data.status === 400) {
    if (response.data.is_verified !== 1) {
      storage.setToken(response.data.token);
      storage.setItem("activeComponent", "1");
      navigate("/onboarding");
    } else if (
      response.data.is_verified === 1 &&
      response.data.website_id.length === 0 &&
      response.data.invited_user === null
    ) {
      storage.setToken(response.data.token);
      storage.setItem("isVerified", response.data.is_verified);
      navigate("/mySites");
    } else if (
      response.data.is_verified === 1 &&
      response.data.website_id.length === 0 &&
      response.data.invited_user === 1
    ) {
      storage.setToken(response.data.token);
      storage.setItem("isVerified", response.data.is_verified);
      navigate("/mySites");
    } else if (
      response.data.is_verified === 1 &&
      response.data.website_id.length !== 0 &&
      response.data.integrated === 0
    ) {
      storage.setToken(response.data.token);
      storage.setItem("isVerified", response.data.is_verified);
      navigate("/mySites");
    } else {
      Toastr.error(t("login.errorDuringLogin"));
    }
  } else {
    Toastr.error(response.data.error);
  }
}

export default setStorages;
