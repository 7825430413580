/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TailSpin } from "react-loader-spinner";
import { convertToEUFormat } from "../../../../utils/dataFilter";
import { getDifferenceAmount, taxChecker } from "../api/subscriptionAPI";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/subscription.scss";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";
import PlanTile from "./PlanTile";
import DisplayBillingInfo from "./DisplayBillingInfo";

export default function PlanDetails({
  pillSelect,
  planSelect,
  address,
  currentPlan,
  expiryDate,
  downGradedPlan,
  currentTopup,
  handleChangeModal,
  handleCloseModal,
  handleCancelSubscription,
  fetchVivaWallet,
  website
}) {
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState(0);
  const [diffAmount, setDiffAmount] = useState(0);
  const { createdBy: customerId } = useSelector((state) => state.generalData);
  const { websiteID } = useSelector((state) => state.generalData);
  const fetchTaxPercentage = async () => {
    setLoading(true);
    try {
      const res = await taxChecker({
        country_name: address.country
      });
      setTax(res.data.tax);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const payment = {
    charges: planSelect.amount,
    diffAmount,
    tax,
    taxAmount:
      address.country.toLowerCase() === "italy" || address.vatNumber === ""
        ? (planSelect.amount - diffAmount) * (tax * 0.01)
        : (planSelect.amount - diffAmount) * 0
  };

  const fetchDifference = async () => {
    setLoading(true);
    try {
      const res = await getDifferenceAmount({
        websiteId: website.id,
        categoryId: pillSelect.id,
        customer_id: customerId
      });
      setDiffAmount(res.data.amount);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    fetchTaxPercentage();
  }, [address, websiteID]);

  useEffect(() => {
    if (planSelect.planId > currentPlan && planSelect.type !== "topup") {
      fetchDifference();
    }
  }, [websiteID]);

  return (
    <div className="display-billing plan-list plan-details">
      <div className="flex-between">
        {t("subscription.billing.website")} <span>{website.name}</span>
      </div>
      <div className="flex-between">
        {t("subscription.billing.planFor")} <span>{pillSelect.name}</span>
      </div>
      <div className="flex-between detail-title">
        {" "}
        {t("subscription.billing.subscriptionPlan")}
        <span onClick={() => handleChangeModal("changePlan", null)}>
          {t("subscription.billing.change")}
        </span>
      </div>
      <PlanTile
        plan={planSelect}
        currentPlan={
          currentPlan === planSelect.planId ||
          currentTopup === planSelect.planId
        }
        expiryDate={expiryDate}
        downGradedPlan={downGradedPlan}
      />

      <div className="flex-between">
        {t("subscription.billing.planCharges")}
        {loading ? (
          <TailSpin
            height="30"
            width="30"
            color={themeColors.primaryColor}
            ariaLabel="tail-spin-loading"
            radius=".25"
            wrapperStyle={{}}
            wrapperClass=""
            visible
          />
        ) : (
          <span>{`${convertToEUFormat(payment.charges)} €`}</span>
        )}
      </div>
      {diffAmount > 0 && (
        <div className="flex-between">
          {t("subscription.billing.deductedAmountForUnusedDays")}
          {loading ? (
            <TailSpin
              height="30"
              width="30"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius=".25"
              wrapperStyle={{}}
              wrapperClass=""
              visible
            />
          ) : (
            <span>{`- ${convertToEUFormat(payment.diffAmount)} €`}</span>
          )}
        </div>
      )}
      {(address.vatNumber === "" ||
        address.country.toLowerCase() === "italy") && (
        <div className="flex-between">
          {t("subscription.billing.additionalTax")}
          <div className="flex-between" style={{ width: "6em", margin: 0 }}>
            {loading ? (
              <TailSpin
                height="30"
                width="30"
                color={themeColors.primaryColor}
                ariaLabel="tail-spin-loading"
                radius=".25"
                wrapperStyle={{}}
                wrapperClass=""
                visible
              />
            ) : (
              <span>{`${payment.tax} %`}</span>
            )}
            <span>{`${convertToEUFormat(payment.taxAmount)} €`}</span>
          </div>
        </div>
      )}

      {!loading && (
        <div className="flex-between">
          {t("subscription.billing.total")}{" "}
          <span>{`${convertToEUFormat(
            payment.charges - payment.diffAmount + payment.taxAmount
          )} €`}</span>
        </div>
      )}

      <div className="flex-between detail-title">
        {t("subscription.billing.billingAddress")}{" "}
        <span onClick={() => handleChangeModal("changeAddress", null)}>
          {t("subscription.billing.change")}
        </span>
      </div>
      <DisplayBillingInfo
        id={address.billingId}
        value={address}
        handleChangeModal={handleChangeModal}
      />
      {currentTopup !== planSelect?.planId &&
        (currentPlan === planSelect?.planId && planSelect?.planId !== 1 ? (
          <ButtonDark
            buttonText={t("subscription.billing.cancelSubscription")}
            onClick={() => {
              handleCancelSubscription();
              handleCloseModal();
            }}
          />
        ) : (
          <div className="button-group">
            <ButtonLight
              buttonText={t("common.cancelButton")}
              onClick={() => {
                handleCloseModal();
              }}
            />
            <ButtonDark
              buttonText={
                planSelect.planId === 1
                  ? t("subscription.billing.ok")
                  : t("subscription.billing.payNow")
              }
              onClick={
                planSelect.planId === 1
                  ? () => {
                      if (currentPlan !== 1) handleCancelSubscription();
                      handleCloseModal();
                    }
                  : () =>
                      fetchVivaWallet({
                        amount:
                          (payment.charges -
                            payment.diffAmount +
                            payment.taxAmount) *
                          100,
                        websiteId: website.id,
                        planId: planSelect.planId,
                        billingId: address.billingId,
                        categoryId: pillSelect.id
                      })
              }
            />
          </div>
        ))}
    </div>
  );
}

PlanDetails.propTypes = {
  pillSelect: PropTypes.number,
  currentPlan: PropTypes.number,
  expiryDate: PropTypes.number,
  downGradedPlan: PropTypes.number,
  currentTopup: PropTypes.number,
  planSelect: PropTypes.oneOfType([PropTypes.object]),
  address: PropTypes.oneOfType([PropTypes.object]),
  handleChangeModal: PropTypes.func,
  handleCloseModal: PropTypes.func,
  fetchVivaWallet: PropTypes.func,
  handleCancelSubscription: PropTypes.func,
  website: PropTypes.oneOfType([PropTypes.object])
};
