/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import addWebsite from "../../assets/icons/add-website.svg";
import "./assets/stylesheets/placeholders.scss";

export default function NoWebsite({ addNewWeb }) {
  const { t } = useTranslation();
  return (
    <div className="flex-center">
      <div className="placeholder-background background-box">
        <img src={addWebsite} alt="add-new-website" className="add-website" />
        <div className="flex-vertical">
          <span>{t("noWebsite.message")}</span>

          <div>
            {`${t("noWebsite.detail1")} `}
            <span onClick={addNewWeb} className="add-web-link">
              {t("noWebsite.link")}
            </span>
            {` ${t("noWebsite.detail2")}`}
          </div>
        </div>
      </div>
    </div>
  );
}

NoWebsite.propTypes = {
  addNewWeb: PropTypes.func
};
