import axios from "./axios";

const addLanguage = async (payload) => {
  try {
    const response = await axios.post("/customer/addLanguage", payload);
    return response.data; // Assuming the response contains data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error adding language:", error);
    throw error;
  }
};

const updateLanguage = async (payload) => {
  try {
    const response = await axios.post("/customer/updateLanguage", payload);
    return response.data; // Assuming the response contains data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error adding language:", error);
    throw error;
  }
};

const getLanguage = () => axios.get("/customer/getCustomerLang");

const languageApi = {
  addLanguage,
  updateLanguage,
  getLanguage
};

export default languageApi;
