const storagePrefix = "yodalytics_";

const LocalStorage = {
  setItem: (key, value) => {
    window.localStorage.setItem(
      `${storagePrefix}${key}`,
      JSON.stringify(value)
    );
  },
  getItem: (key) => {
    return JSON.parse(window.localStorage.getItem(storagePrefix + key));
  },
  deleteItem: (key) => {
    return window.localStorage.removeItem(key);
  }
};

export default LocalStorage;
