import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../features/Onboarding/Header";
import Otp from "../../features/Onboarding/Otp";
import Logo from "../../assets/icons/logo.svg";
import "../stylesheets/onboarding.scss";
import Sidebar from "../../components/layouts/SideBar";
import storage from "../../utils/storage";

export default function Onboarding() {
  const [activeComponent, setActiveComponent] = useState(1);

  useEffect(() => {
    // Retrieve the activeComponent value from storage when the component mounts
    const storedActiveComponent = storage.getItem("activeComponent");
    if (storedActiveComponent) {
      setActiveComponent(parseInt(storedActiveComponent, 10));
    }
  }, []);

  useEffect(() => {
    // Store the activeComponent value in storage whenever it changes
    storage.setItem("activeComponent", activeComponent.toString());
  }, [activeComponent]);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 1:
        return <Otp />;
      default:
        return null;
    }
  };

  const componentMargin =
    activeComponent === 1 ? "2rem 0 1.5rem 0" : "4rem 0 3.5rem 0";

  const { t } = useTranslation();

  return (
    <section className="onboarding-section">
      <Header />
      <div className="container-2">
        <div className="yoda-logo flex">
          <img src={Logo} alt="yodalytics logo" />
          <h2>Yodalytics.com</h2>
        </div>
        {activeComponent === 1 && (
          <div className="notification-warning flex-between">
            <p>{t("onboarding.validateAccount")}</p>
            <i className="ri-close-line" />
          </div>
        )}
        <div
          className="flex-between rendered-component-based-on-state"
          style={{ margin: componentMargin }}
        >
          {renderActiveComponent()}
          <Sidebar activeComponent={activeComponent} />
        </div>
      </div>
    </section>
  );
}
