import { createSlice } from "@reduxjs/toolkit";
import {
  getIsiChatData,
  getAllQuestionsForIsiChat,
  getCompareIsiChatData,
  getCompareAllQuestionsForIsiChat
} from "../apiSlice";

const initialState = {
  allData: [],
  filteredData: [],
  insights: {
    visitors: 0,
    sessions: 0,
    questions: 0,
    tokens: 0,
    average_time: 0
  },
  multiBarChartData: {
    labels: [],
    visitors: [],
    sessions: [],
    questions: []
  },
  visitorsData: {},
  personaData: { labels: [], visitors: [], sessions: [], questions: [] },
  categoriesData: { labels: [], questions: [], visitors: [], sessions: [] },
  questionsData: [],
  questions: [],
  isLoading: false,
  loadingQuestions: false,
  selectedFilterType: "Daily",
  pageCountOfQuestions: 1,
  selectedRange: "",
  showPersonaData: true,
  userRoleId: 0
};

const isiChatDataSlice = createSlice({
  name: "isiChatData",
  initialState,
  reducers: {
    updateInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    updateMultiBarChartData: (state, actions) => {
      return {
        ...state,
        multiBarChartData: { ...state.multiBarChartData, ...actions.payload }
      };
    },
    updateFilteredData: (state, action) => {
      return { ...state, filteredData: [...action.payload] };
    },
    updatePersonaData: (state, action) => {
      return { ...state, personaData: { ...action.payload } };
    },
    updateCategoriesData: (state, action) => {
      return { ...state, categoriesData: { ...action.payload } };
    },
    updateQuestions: (state, action) => {
      return { ...state, questions: [...action.payload] };
    },
    updateSelectedFilterType: (state, action) => {
      return { ...state, selectedFilterType: action.payload };
    },
    updateSelectedRange: (state, action) => {
      return { ...state, selectedRange: action.payload };
    }
  },
  extraReducers: {
    [getIsiChatData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getIsiChatData.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        allData: [...action.payload[0]],
        showPersonaData: action.payload[1]?.personaResult,
        userRoleId: action.payload[2]?.role_id
      };
    },
    [getIsiChatData.rejected]: (state) => {
      return { ...state, isLoading: false };
    },
    [getCompareIsiChatData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getCompareIsiChatData.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        allData: [...action.payload[0]],
        showPersonaData: action.payload[2]?.personaResult,
        userRoleId: action.payload[3]?.role_id
      };
    },
    [getCompareIsiChatData.rejected]: (state) => {
      return { ...state, isLoading: false };
    },
    [getAllQuestionsForIsiChat.pending]: (state) => {
      return { ...state, loadingQuestions: true, questionsData: [] };
    },
    [getAllQuestionsForIsiChat.fulfilled]: (state, action) => {
      return {
        ...state,
        loadingQuestions: false,
        questionsData: [...action.payload[0].conversationPairs],
        pageCountOfQuestions: action.payload[0].pageCount
      };
    },
    [getAllQuestionsForIsiChat.rejected]: (state) => {
      return { ...state, loadingQuestions: false };
    },
    [getCompareAllQuestionsForIsiChat.pending]: (state) => {
      return { ...state, loadingQuestions: true, questionsData: [] };
    },
    [getCompareAllQuestionsForIsiChat.fulfilled]: (state, action) => {
      return {
        ...state,
        loadingQuestions: false,
        questionsData: [...action.payload[0].conversationPairs],
        pageCountOfQuestions: action.payload[0].pageCount
      };
    },
    [getCompareAllQuestionsForIsiChat.rejected]: (state) => {
      return { ...state, loadingQuestions: false };
    }
  }
});

const isiChatCompareDataSlice = createSlice({
  name: "isiChatCompareData",
  initialState,
  reducers: {
    updateCompareIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateCompareInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    updateCompareFilteredData: (state, action) => {
      return { ...state, filteredData: [...action.payload] };
    },
    updateCompareMultiBarChartData: (state, actions) => {
      return {
        ...state,
        multiBarChartData: { ...state.multiBarChartData, ...actions.payload }
      };
    },
    updateComparePersonaData: (state, action) => {
      return { ...state, personaData: { ...action.payload } };
    },
    updateCompareCategoriesData: (state, action) => {
      return { ...state, categoriesData: { ...action.payload } };
    },
    updateCompareQuestions: (state, action) => {
      return { ...state, questions: [...action.payload] };
    }
  },
  extraReducers: {
    [getCompareIsiChatData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getCompareIsiChatData.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        allData: [...action.payload[1]],
        showPersonaData: action.payload[2]?.personaResult,
        userRoleId: action.payload[3]?.role_id
      };
    },
    [getCompareIsiChatData.rejected]: (state) => {
      return { ...state, isLoading: false };
    },
    [getCompareAllQuestionsForIsiChat.pending]: (state) => {
      return { ...state, loadingQuestions: true, questionsData: [] };
    },
    [getCompareAllQuestionsForIsiChat.fulfilled]: (state, action) => {
      return {
        ...state,
        loadingQuestions: false,
        questionsData: [...action.payload[1].conversationPairs],
        pageCountOfQuestions: action.payload[1].pageCount
      };
    },
    [getCompareAllQuestionsForIsiChat.rejected]: (state) => {
      return { ...state, loadingQuestions: false };
    }
  }
});

export const {
  updateInsights,
  updateMultiBarChartData,
  updateFilteredData,
  updatePersonaData,
  updateCategoriesData,
  updateQuestions,
  updateSelectedFilterType,
  updateSelectedRange
} = isiChatDataSlice.actions;

export const {
  updateCompareIsLoading,
  updateCompareInsights,
  updateCompareFilteredData,
  updateCompareMultiBarChartData,
  updateComparePersonaData,
  updateCompareCategoriesData,
  updateCompareQuestions
} = isiChatCompareDataSlice.actions;

export default { isiChatDataSlice, isiChatCompareDataSlice };
