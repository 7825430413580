import React from "react";
import PropTypes from "prop-types";
import "./button.scss";

function ButtonDark({ buttonText, onClick, icon, width }) {
  return (
    <button
      type="button"
      className="dark-btn"
      onClick={onClick}
      style={width ? { width: `${width}` } : {}}
    >
      <div className="flex">
        {icon && <img src={icon} alt={`${buttonText} icon`} />}
        <p>{buttonText}</p>
      </div>
    </button>
  );
}

ButtonDark.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.object]),
  width: PropTypes.string
};

function ButtonLight({ buttonText, onClick }) {
  return (
    <button type="button" className="light-btn" onClick={onClick}>
      {buttonText}
    </button>
  );
}

ButtonLight.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func
};

export { ButtonDark, ButtonLight };
