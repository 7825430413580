import React from "react";
import { useTranslation } from "react-i18next";
import Restricted from "../../assets/icons/restriction.svg";
import "./assets/stylesheets/placeholders.scss";

export default function RestrictedPage() {
  const { t } = useTranslation();
  return (
    <div className="flex-center">
      <div className="placeholder-background">
        <img src={Restricted} alt="no-data-found" className="coming-soon" />
        <div className="flex-vertical">
          <span>{t("noWebsite.message")}</span>
          <div>{t("noWebsite.restrictedAccess")}</div>
        </div>
      </div>
    </div>
  );
}
