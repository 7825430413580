import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./DeleteComponent.scss";
import { TailSpin } from "react-loader-spinner";
import { ButtonDark, ButtonLight } from "../inputElements/buttons/MainButton";
import themeColors from "../../assets/stylesheets/_var.scss";

export default function DeleteComponent({
  onCancel,
  onConfirmDelete,
  loading
}) {
  const { t } = useTranslation();

  return (
    <div className="delete-popup-overlay">
      <div className="delete-popup-box">
        <div className="delete-popup-content">
          <div>{t("common.confirmation.deletePrompt")}</div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "15%"
              }}
            >
              <TailSpin
                height="50"
                width="50"
                color={themeColors.primaryColor}
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="spinner"
                visible
              />
            </div>
          ) : (
            <div className="delete-popup-buttons">
              <ButtonLight
                className="cancel-button"
                buttonText={t("common.confirmation.cancel")}
                type="button"
                onClick={onCancel}
              />
              <ButtonDark
                className="delete-button"
                buttonText={t("common.confirmation.delete")}
                type="button"
                onClick={onConfirmDelete}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

DeleteComponent.propTypes = {
  onCancel: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  loading: PropTypes.bool
};
