import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NoDataFound from "../Placeholders/NoDataFound";
import AddBarGraph from "../../components/ChartComponents/GeneralBarGraph";
import Insights from "../../components/Insights";
import Table from "../../components/ChartComponents/Table";

export default function GeneralDashboard({
  insightsTitle,
  dashboard,
  isCompare,
  selectedCampPerfomanceFilters
}) {
  const { t } = useTranslation();
  const {
    allData,
    insights,
    channelData,
    campaignTypeData,
    campaignData,
    allSourcesData,
    campaignDetailsData,
    allCampaignTypeData
  } = useSelector((state) => state.campaignPerformance);
  const [sourcesLabels, setSourcesLabels] = useState([]);
  const [sourcesColumns, setSourcesColumns] = useState([]);
  const [separatedColumnData, setSeparatedColumnData] = useState([]);

  const [campaignLabels, setCampaignLabels] = useState([]);
  const [campaignColumns, setCampaignColumns] = useState([]);
  const [separatedCampaignColumnData, setSeparatedCampaignColumnData] =
    useState([]);

  const [campaignTypeLabels, setCampaignTypeLabels] = useState([]);
  const [campaignTypeColumns, setCampaignTypeColumns] = useState([]);
  const [separatedCampaignTypeColumnData, setSeparatedCampaignTypeColumnData] =
    useState([]);

  const [filteredSourcesData, setFilteredSourcesData] = useState({});
  const [filteredSourcesLabels, setFilteredSourcesLabels] = useState([]);

  const [filteredCampaignData, setFilteredCampaignData] = useState({});
  const [filteredCampaignLabels, setFilteredCampaignLabels] = useState([]);

  const [filteredCampaignTypeData, setFilteredCampaignTypeData] = useState({});
  const [filteredCampaignTypeLabels, setFilteredCampaignTypeLabels] = useState(
    []
  );

  const groupedColumnData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(value);
      });
      return acc;
    }, {});
    return groupedData;
  };

  useEffect(() => {
    if (allSourcesData.length) {
      const sources = allSourcesData.map((item) => ({
        channel: item.channel
      }));
      const columns = allSourcesData.map((item) => {
        return item;
      });
      setSourcesLabels(sources);
      setSourcesColumns(columns);
    }
  }, [allSourcesData]);

  useEffect(() => {
    if (sourcesColumns.length) {
      const groupedData = groupedColumnData(sourcesColumns);
      setSeparatedColumnData(groupedData);
    }
  }, [sourcesColumns]);

  useEffect(() => {
    if (campaignDetailsData.length) {
      const channelsAndCampaign = campaignDetailsData.map((item) => ({
        campaignName: item.campaignName
      }));
      const columns = campaignDetailsData.map((item) => {
        return item;
      });
      setCampaignLabels(channelsAndCampaign);
      setCampaignColumns(columns);
    }
  }, [campaignDetailsData]);

  useEffect(() => {
    if (campaignColumns.length) {
      const groupedData = groupedColumnData(campaignColumns);
      setSeparatedCampaignColumnData(groupedData);
    }
  }, [campaignColumns]);

  useEffect(() => {
    if (allCampaignTypeData.length) {
      const allCampaignTypes = allCampaignTypeData.map((item) => ({
        campaignType: item.campaignType
      }));
      const columns = allCampaignTypeData.map((item) => {
        return item;
      });
      setCampaignTypeLabels(allCampaignTypes);
      setCampaignTypeColumns(columns);
    }
  }, [allCampaignTypeData]);

  useEffect(() => {
    if (campaignTypeColumns.length) {
      const groupedData = groupedColumnData(campaignTypeColumns);
      setSeparatedCampaignTypeColumnData(groupedData);
    }
  }, [campaignTypeColumns]);

  const sourceLabelHeades = [t("campaignPerformance.sources")];
  const campagnLabelHeades = [t("campaignPerformance.campaignName")];
  const campagnTypeLabelHeades = [t("campaignPerformance.campaignType")];

  const filteredData = (filteredChannels, type) => {
    const separatedData = {
      ...(type === "channel" && {
        channel: filteredChannels.map((item) => item.channelName)
      }),
      ...(type === "campaign" && {
        campaignName: filteredChannels.map((item) => item.campaign)
      }),
      totalSpend: filteredChannels.map((item) => item.totalSpend),
      generatedRevenue: filteredChannels.map((item) => item.generatedRevenue),
      totalImpressions: filteredChannels.map((item) => item.totalImpressions),
      totalReturn: filteredChannels.map((item) => item.totalReturn),
      sales: filteredChannels.map((item) => item.sales),
      roi: filteredChannels.map((item) => item.roi),
      leads: filteredChannels.map((item) => item.leads),
      conversionRateLeadsToImpressions: filteredChannels.map(
        (item) => item.conversionRateLeadsToImpressions
      ),
      conversionRateSalesToImpressions: filteredChannels.map(
        (item) => item.conversionRateSalesToImpressions
      ),
      conversionRateSalesToLeads: filteredChannels.map(
        (item) => item.conversionRateSalesToLeads
      ),
      costPerImpression: filteredChannels.map((item) => item.costPerImpression),
      costPerLead: filteredChannels.map((item) => item.costPerLead),
      costPerSales: filteredChannels.map((item) => item.costPerSales),
      revenuePerSales: filteredChannels.map((item) => item.revenuePerSales),
      totalReturnPerSales: filteredChannels.map(
        (item) => item.totalReturnPerSales
      )
    };
    return separatedData;
  };

  const handleSearchOnSources = (search) => {
    if (search && search.length >= 3) {
      const filteredChannels = separatedColumnData?.channel
        .map((channelName, index) => {
          if (channelName.toLowerCase().includes(search.toLowerCase())) {
            return {
              channelName,
              totalSpend: separatedColumnData.totalSpend[index],
              generatedRevenue: separatedColumnData.generatedRevenue[index],
              totalImpressions: separatedColumnData.totalImpressions[index],
              totalReturn: separatedColumnData.totalReturn[index],
              sales: separatedColumnData.sales[index],
              roi: separatedColumnData.roi[index],
              leads: separatedColumnData.leads[index],
              conversionRateLeadsToImpressions:
                separatedColumnData.conversionRateLeadsToImpressions[index],
              conversionRateSalesToImpressions:
                separatedColumnData.conversionRateSalesToImpressions[index],
              conversionRateSalesToLeads:
                separatedColumnData.conversionRateSalesToLeads[index],
              costPerImpression: separatedColumnData.costPerImpression[index],
              costPerLead: separatedColumnData.costPerLead[index],
              costPerSales: separatedColumnData.costPerSales[index],
              revenuePerSales: separatedColumnData.revenuePerSales[index],
              totalReturnPerSales:
                separatedColumnData.totalReturnPerSales[index]
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Remove null values

      setFilteredSourcesData(filteredData(filteredChannels, "channel"));
      const labels = filteredChannels.map((item) => ({
        channel: item.channelName
      }));
      setFilteredSourcesLabels(labels);
    } else {
      setFilteredSourcesData({});
      setFilteredSourcesLabels([]);
    }
  };
  const handleSearchOnCampaign = (search) => {
    if (search && search.length >= 3) {
      const filteredChannels = separatedCampaignColumnData?.campaignName
        .map((campaign, index) => {
          if (campaign.toLowerCase().includes(search.toLowerCase())) {
            return {
              campaign,
              totalSpend: separatedCampaignColumnData.totalSpend[index],
              generatedRevenue:
                separatedCampaignColumnData.generatedRevenue[index],
              totalImpressions:
                separatedCampaignColumnData.totalImpressions[index],
              totalReturn: separatedCampaignColumnData.totalReturn[index],
              sales: separatedCampaignColumnData.sales[index],
              roi: separatedCampaignColumnData.roi[index],
              leads: separatedCampaignColumnData.leads[index],
              conversionRateLeadsToImpressions:
                separatedCampaignColumnData.conversionRateLeadsToImpressions[
                  index
                ],
              conversionRateSalesToImpressions:
                separatedCampaignColumnData.conversionRateSalesToImpressions[
                  index
                ],
              conversionRateSalesToLeads:
                separatedCampaignColumnData.conversionRateSalesToLeads[index],
              costPerImpression:
                separatedCampaignColumnData.costPerImpression[index],
              costPerLead: separatedCampaignColumnData.costPerLead[index],
              costPerSales: separatedCampaignColumnData.costPerSales[index],
              revenuePerSales:
                separatedCampaignColumnData.revenuePerSales[index],
              totalReturnPerSales:
                separatedCampaignColumnData.totalReturnPerSales[index]
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Remove null values
      setFilteredSourcesData(filteredData(filteredChannels, "campaign"));
      const labels = filteredChannels.map((item) => ({
        campaignName: item.campaign
      }));
      setFilteredCampaignLabels(labels);
    } else {
      setFilteredCampaignData({});
      setFilteredCampaignLabels([]);
    }
  };

  const handleSearchOnCampaignType = (search) => {
    if (search && search.length >= 3) {
      const filteredChannels = separatedCampaignTypeColumnData?.campaignType
        .map((campaignType, index) => {
          if (campaignType.toLowerCase().includes(search.toLowerCase())) {
            return {
              campaignType,
              totalSpend: separatedCampaignTypeColumnData.totalSpend[index],
              generatedRevenue:
                separatedCampaignTypeColumnData.generatedRevenue[index],
              totalImpressions:
                separatedCampaignTypeColumnData.totalImpressions[index],
              sales: separatedCampaignTypeColumnData.sales[index],
              leads: separatedCampaignTypeColumnData.leads[index],
              likes: separatedCampaignTypeColumnData.likes[index],
              comments: separatedCampaignTypeColumnData.comments[index],
              shares: separatedCampaignTypeColumnData.shares[index],
              conversionRateLeadsToImpressions:
                separatedCampaignTypeColumnData
                  .conversionRateLeadsToImpressions[index],
              conversionRateSalesToImpressions:
                separatedCampaignTypeColumnData
                  .conversionRateSalesToImpressions[index],
              conversionRateSalesToLeads:
                separatedCampaignTypeColumnData.conversionRateSalesToLeads[
                  index
                ],
              costPerImpression:
                separatedCampaignTypeColumnData.costPerImpression[index],
              costPerLead: separatedCampaignTypeColumnData.costPerLead[index],
              costPerSales: separatedCampaignTypeColumnData.costPerSales[index],
              revenuePerSales:
                separatedCampaignTypeColumnData.revenuePerSales[index],
              totalReturnPerSales:
                separatedCampaignTypeColumnData.totalReturnPerSales[index]
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Remove null values
      setFilteredCampaignTypeData(
        filteredData(filteredChannels, "campaignType")
      );
      const labels = filteredChannels.map((item) => ({
        campaignType: item.campaignType
      }));
      setFilteredCampaignTypeLabels(labels);
    } else {
      setFilteredCampaignTypeData({});
      setFilteredCampaignTypeLabels([]);
    }
  };

  if (allData.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
          dashboard={dashboard}
        />
        <div className="grid grid-template general-bar-graph-height">
          <AddBarGraph
            title={t("campaignPerformance.sources")}
            labels={channelData.labels}
            numBars={
              channelData?.labels?.length < 6 ? channelData?.labels?.length : 6
            }
            data={{
              tooltip: t("campaignPerformance.spend"),
              values: channelData?.totalSpend
            }}
            columnData={[
              {
                name: t("campaignPerformance.spend"),
                diff: channelData?.totalSpend,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedCampPerfomanceFilters}
          />
          <AddBarGraph
            title={t("campaignPerformance.campaignType")}
            labels={campaignTypeData.labels}
            numBars={
              campaignTypeData?.labels?.length < 6
                ? campaignTypeData?.labels?.length
                : 6
            }
            data={{
              tooltip: t("campaignPerformance.spend"),
              values: campaignTypeData?.totalSpend
            }}
            columnData={[
              {
                name: t("campaignPerformance.spend"),
                diff: campaignTypeData?.totalSpend,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedCampPerfomanceFilters}
          />
          <AddBarGraph
            title={t("campaignPerformance.campaignUTM")}
            labels={campaignData.labels}
            numBars={
              campaignData?.labels?.length < 6
                ? campaignData?.labels?.length
                : 6
            }
            data={{
              tooltip: t("campaignPerformance.spend"),
              values: campaignData?.totalSpend
            }}
            columnData={[
              {
                name: t("campaignPerformance.spend"),
                diff: campaignData?.totalSpend,
                number: true
              }
            ]}
            dashboard={dashboard}
            isCompare={isCompare}
            filterValues={selectedCampPerfomanceFilters}
          />
        </div>
        <Table
          labels={
            filteredSourcesLabels?.length
              ? filteredSourcesLabels
              : sourcesLabels
          }
          labelHeaders={sourceLabelHeades}
          columnData={[
            {
              id: 1,
              name: t("campaignPerformance.totalSpend"),
              totalSpend:
                (filteredSourcesData?.totalSpend?.length &&
                  filteredSourcesData?.totalSpend) ||
                (separatedColumnData?.totalSpend?.length &&
                  separatedColumnData?.totalSpend) ||
                []
            },
            {
              id: 2,
              name: t("campaignPerformance.generatedRevenue"),
              totalSpend:
                (filteredSourcesData?.generatedRevenue?.length &&
                  filteredSourcesData?.generatedRevenue) ||
                (separatedColumnData?.generatedRevenue?.length &&
                  separatedColumnData?.generatedRevenue) ||
                []
            },
            {
              id: 3,
              name: t("campaignPerformance.totalReturn"),
              totalSpend:
                (filteredSourcesData?.totalReturn?.length &&
                  filteredSourcesData?.totalReturn) ||
                (separatedColumnData?.totalReturn?.length &&
                  separatedColumnData?.totalReturn) ||
                []
            },
            {
              id: 4,
              name: t("campaignPerformance.roi"),
              totalSpend:
                (filteredSourcesData?.roi?.length &&
                  filteredSourcesData?.roi) ||
                (separatedColumnData?.roi?.length &&
                  separatedColumnData?.roi) ||
                []
            },
            {
              id: 5,
              name: t("campaignPerformance.impressions"),
              totalSpend:
                (filteredSourcesData?.totalImpressions?.length &&
                  filteredSourcesData?.totalImpressions) ||
                (separatedColumnData?.totalImpressions?.length &&
                  separatedColumnData?.totalImpressions) ||
                []
            },
            {
              id: 6,
              name: t("campaignPerformance.leads"),
              totalSpend:
                (filteredSourcesData?.leads?.length &&
                  filteredSourcesData?.leads) ||
                (separatedColumnData?.leads?.length &&
                  separatedColumnData?.leads) ||
                []
            },
            {
              id: 7,
              name: t("campaignPerformance.sales"),
              totalSpend:
                (filteredSourcesData?.sales?.length &&
                  filteredSourcesData?.sales) ||
                (separatedColumnData?.sales?.length &&
                  separatedColumnData?.sales) ||
                []
            },
            {
              id: 8,
              name: t("campaignPerformance.conversionRateLeadsToImpressions"),
              totalSpend:
                (filteredSourcesData?.conversionRateLeadsToImpressions
                  ?.length &&
                  filteredSourcesData?.conversionRateLeadsToImpressions) ||
                (separatedColumnData?.conversionRateLeadsToImpressions
                  ?.length &&
                  separatedColumnData?.conversionRateLeadsToImpressions) ||
                []
            },
            {
              id: 9,
              name: t("campaignPerformance.conversionRateSalesToImpressions"),
              totalSpend:
                (filteredSourcesData?.conversionRateSalesToImpressions
                  ?.length &&
                  filteredSourcesData?.conversionRateSalesToImpressions) ||
                (separatedColumnData?.conversionRateSalesToImpressions
                  ?.length &&
                  separatedColumnData?.conversionRateSalesToImpressions) ||
                []
            },
            {
              id: 10,
              name: t("campaignPerformance.conversionRateSalesToLeads"),
              totalSpend:
                (filteredSourcesData?.conversionRateSalesToLeads?.length &&
                  filteredSourcesData?.conversionRateSalesToLeads) ||
                (separatedColumnData?.conversionRateSalesToLeads?.length &&
                  separatedColumnData?.conversionRateSalesToLeads) ||
                []
            },
            {
              id: 11,
              name: t("campaignPerformance.costPerImpression"),
              totalSpend:
                (filteredSourcesData?.costPerImpression?.length &&
                  filteredSourcesData?.costPerImpression) ||
                (separatedColumnData?.costPerImpression?.length &&
                  separatedColumnData?.costPerImpression) ||
                []
            },
            {
              id: 12,
              name: t("campaignPerformance.costPerLead"),
              totalSpend:
                (filteredSourcesData?.costPerLead?.length &&
                  filteredSourcesData?.costPerLead) ||
                (separatedColumnData?.costPerLead?.length &&
                  separatedColumnData?.costPerLead) ||
                []
            },
            {
              id: 13,
              name: t("campaignPerformance.costPerSales"),
              totalSpend:
                (filteredSourcesData?.costPerSales?.length &&
                  filteredSourcesData?.costPerSales) ||
                (separatedColumnData?.costPerSales?.length &&
                  separatedColumnData?.costPerSales) ||
                []
            },
            {
              id: 14,
              name: t("campaignPerformance.revenuePerSales"),
              totalSpend:
                (filteredSourcesData?.revenuePerSales?.length &&
                  filteredSourcesData?.revenuePerSales) ||
                (separatedColumnData?.revenuePerSales?.length &&
                  separatedColumnData?.revenuePerSales) ||
                []
            },
            {
              id: 15,
              name: t("campaignPerformance.totalReturnPerSales"),
              totalSpend:
                (filteredSourcesData?.totalReturnPerSales?.length &&
                  filteredSourcesData?.totalReturnPerSales) ||
                (separatedColumnData?.totalReturnPerSales?.length &&
                  separatedColumnData?.totalReturnPerSales) ||
                []
            }
          ]}
          title={t("campaignPerformance.allSources")}
          handleSearch={handleSearchOnSources}
        />
        <Table
          labels={
            filteredCampaignLabels?.length
              ? filteredCampaignLabels
              : campaignLabels
          }
          labelHeaders={campagnLabelHeades}
          columnData={[
            {
              id: 1,
              name: t("campaignPerformance.totalSpend"),
              totalSpend:
                (filteredCampaignData?.totalSpend?.length &&
                  filteredCampaignData?.totalSpend) ||
                (separatedCampaignColumnData?.totalSpend?.length &&
                  separatedCampaignColumnData?.totalSpend) ||
                []
            },
            {
              id: 2,
              name: t("campaignPerformance.generatedRevenue"),
              totalSpend:
                (filteredCampaignData?.generatedRevenue?.length &&
                  filteredCampaignData?.generatedRevenue) ||
                (separatedCampaignColumnData?.generatedRevenue?.length &&
                  separatedCampaignColumnData?.generatedRevenue) ||
                []
            },
            {
              id: 3,
              name: t("campaignPerformance.totalReturn"),
              totalSpend:
                (filteredCampaignData?.totalReturn?.length &&
                  filteredCampaignData?.totalReturn) ||
                (separatedCampaignColumnData?.totalReturn?.length &&
                  separatedCampaignColumnData?.totalReturn) ||
                []
            },
            {
              id: 4,
              name: t("campaignPerformance.roi"),
              totalSpend:
                (filteredCampaignData?.roi?.length &&
                  filteredCampaignData?.roi) ||
                (separatedCampaignColumnData?.roi?.length &&
                  separatedCampaignColumnData?.roi) ||
                []
            },
            {
              id: 5,
              name: t("campaignPerformance.impressions"),
              totalSpend:
                (filteredCampaignData?.totalImpressions?.length &&
                  filteredCampaignData?.totalImpressions) ||
                (separatedCampaignColumnData?.totalImpressions?.length &&
                  separatedCampaignColumnData?.totalImpressions) ||
                []
            },
            {
              id: 6,
              name: t("campaignPerformance.leads"),
              totalSpend:
                (filteredCampaignData?.leads?.length &&
                  filteredCampaignData?.leads) ||
                (separatedCampaignColumnData?.leads?.length &&
                  separatedCampaignColumnData?.leads) ||
                []
            },
            {
              id: 7,
              name: t("campaignPerformance.sales"),
              totalSpend:
                (filteredCampaignData?.sales?.length &&
                  filteredCampaignData?.sales) ||
                (separatedCampaignColumnData?.sales?.length &&
                  separatedCampaignColumnData?.sales) ||
                []
            },
            {
              id: 8,
              name: t("campaignPerformance.conversionRateLeadsToImpressions"),
              totalSpend:
                (filteredCampaignData?.conversionRateLeadsToImpressions
                  ?.length &&
                  filteredCampaignData?.conversionRateLeadsToImpressions) ||
                (separatedCampaignColumnData?.conversionRateLeadsToImpressions
                  ?.length &&
                  separatedCampaignColumnData?.conversionRateLeadsToImpressions) ||
                []
            },
            {
              id: 9,
              name: t("campaignPerformance.conversionRateSalesToImpressions"),
              totalSpend:
                (filteredCampaignData?.conversionRateSalesToImpressions
                  ?.length &&
                  filteredCampaignData?.conversionRateSalesToImpressions) ||
                (separatedCampaignColumnData?.conversionRateSalesToImpressions
                  ?.length &&
                  separatedCampaignColumnData?.conversionRateSalesToImpressions) ||
                []
            },
            {
              id: 10,
              name: t("campaignPerformance.conversionRateSalesToLeads"),
              totalSpend:
                (filteredCampaignData?.conversionRateSalesToLeads?.length &&
                  filteredCampaignData?.conversionRateSalesToLeads) ||
                (separatedCampaignColumnData?.conversionRateSalesToLeads
                  ?.length &&
                  separatedCampaignColumnData?.conversionRateSalesToLeads) ||
                []
            },
            {
              id: 11,
              name: t("campaignPerformance.costPerImpression"),
              totalSpend:
                (filteredCampaignData?.costPerImpression?.length &&
                  filteredCampaignData?.costPerImpression) ||
                (separatedCampaignColumnData?.costPerImpression?.length &&
                  separatedCampaignColumnData?.costPerImpression) ||
                []
            },
            {
              id: 12,
              name: t("campaignPerformance.costPerLead"),
              totalSpend:
                (filteredCampaignData?.costPerLead?.length &&
                  filteredCampaignData?.costPerLead) ||
                (separatedCampaignColumnData?.costPerLead?.length &&
                  separatedCampaignColumnData?.costPerLead) ||
                []
            },
            {
              id: 13,
              name: t("campaignPerformance.costPerSales"),
              totalSpend:
                (filteredCampaignData?.costPerSales?.length &&
                  filteredCampaignData?.costPerSales) ||
                (separatedCampaignColumnData?.costPerSales?.length &&
                  separatedCampaignColumnData?.costPerSales) ||
                []
            },
            {
              id: 14,
              name: t("campaignPerformance.revenuePerSales"),
              totalSpend:
                (filteredCampaignData?.revenuePerSales?.length &&
                  filteredCampaignData?.revenuePerSales) ||
                (separatedCampaignColumnData?.revenuePerSales?.length &&
                  separatedCampaignColumnData?.revenuePerSales) ||
                []
            },
            {
              id: 15,
              name: t("campaignPerformance.totalReturnPerSales"),
              totalSpend:
                (filteredCampaignData?.totalReturnPerSales?.length &&
                  filteredCampaignData?.totalReturnPerSales) ||
                (separatedCampaignColumnData?.totalReturnPerSales?.length &&
                  separatedCampaignColumnData?.totalReturnPerSales) ||
                []
            }
          ]}
          title={t("campaignPerformance.campaignDetails")}
          handleSearch={handleSearchOnCampaign}
        />
        <Table
          labels={
            filteredCampaignTypeLabels?.length
              ? filteredCampaignTypeLabels
              : campaignTypeLabels
          }
          labelHeaders={campagnTypeLabelHeades}
          columnData={[
            {
              id: 1,
              name: t("campaignPerformance.totalSpend"),
              totalSpend:
                (filteredCampaignTypeData?.totalSpend?.length &&
                  filteredCampaignTypeData?.totalSpend) ||
                (separatedCampaignTypeColumnData?.totalSpend?.length &&
                  separatedCampaignTypeColumnData?.totalSpend) ||
                []
            },
            {
              id: 2,
              name: t("campaignPerformance.generatedRevenue"),
              totalSpend:
                (filteredCampaignTypeData?.generatedRevenue?.length &&
                  filteredCampaignTypeData?.generatedRevenue) ||
                (separatedCampaignTypeColumnData?.generatedRevenue?.length &&
                  separatedCampaignTypeColumnData?.generatedRevenue) ||
                []
            },
            {
              id: 3,
              name: t("campaignPerformance.impressions"),
              totalSpend:
                (filteredCampaignTypeData?.totalImpressions?.length &&
                  filteredCampaignTypeData?.totalImpressions) ||
                (separatedCampaignTypeColumnData?.totalImpressions?.length &&
                  separatedCampaignTypeColumnData?.totalImpressions) ||
                []
            },
            {
              id: 4,
              name: t("campaignPerformance.likes"),
              totalSpend:
                (filteredCampaignTypeData?.totalSpend?.length &&
                  filteredCampaignTypeData?.totalSpend) ||
                (separatedCampaignTypeColumnData?.totalSpend?.length &&
                  separatedCampaignTypeColumnData?.totalSpend) ||
                []
            },
            {
              id: 5,
              name: t("campaignPerformance.comments"),
              totalSpend:
                (filteredCampaignTypeData?.generatedRevenue?.length &&
                  filteredCampaignTypeData?.generatedRevenue) ||
                (separatedCampaignTypeColumnData?.generatedRevenue?.length &&
                  separatedCampaignTypeColumnData?.generatedRevenue) ||
                []
            },
            {
              id: 6,
              name: t("campaignPerformance.shares"),
              totalSpend:
                (filteredCampaignTypeData?.totalImpressions?.length &&
                  filteredCampaignTypeData?.totalImpressions) ||
                (separatedCampaignTypeColumnData?.totalImpressions?.length &&
                  separatedCampaignTypeColumnData?.totalImpressions) ||
                []
            },
            {
              id: 7,
              name: t("campaignPerformance.leads"),
              totalSpend:
                (filteredCampaignTypeData?.leads?.length &&
                  filteredCampaignTypeData?.leads) ||
                (separatedCampaignTypeColumnData?.leads?.length &&
                  separatedCampaignTypeColumnData?.leads) ||
                []
            },
            {
              id: 8,
              name: t("campaignPerformance.sales"),
              totalSpend:
                (filteredCampaignTypeData?.sales?.length &&
                  filteredCampaignTypeData?.sales) ||
                (separatedCampaignTypeColumnData?.sales?.length &&
                  separatedCampaignTypeColumnData?.sales) ||
                []
            },
            {
              id: 9,
              name: t("campaignPerformance.conversionRateLeadsToImpressions"),
              totalSpend:
                (filteredCampaignTypeData?.conversionRateLeadsToImpressions
                  ?.length &&
                  filteredCampaignTypeData?.conversionRateLeadsToImpressions) ||
                (separatedCampaignTypeColumnData
                  ?.conversionRateLeadsToImpressions?.length &&
                  separatedCampaignTypeColumnData?.conversionRateLeadsToImpressions) ||
                []
            },
            {
              id: 10,
              name: t("campaignPerformance.conversionRateSalesToImpressions"),
              totalSpend:
                (filteredCampaignTypeData?.conversionRateSalesToImpressions
                  ?.length &&
                  filteredCampaignTypeData?.conversionRateSalesToImpressions) ||
                (separatedCampaignTypeColumnData
                  ?.conversionRateSalesToImpressions?.length &&
                  separatedCampaignTypeColumnData?.conversionRateSalesToImpressions) ||
                []
            },
            {
              id: 11,
              name: t("campaignPerformance.conversionRateSalesToLeads"),
              totalSpend:
                (filteredCampaignTypeData?.conversionRateSalesToLeads?.length &&
                  filteredCampaignTypeData?.conversionRateSalesToLeads) ||
                (separatedCampaignTypeColumnData?.conversionRateSalesToLeads
                  ?.length &&
                  separatedCampaignTypeColumnData?.conversionRateSalesToLeads) ||
                []
            },
            {
              id: 12,
              name: t("campaignPerformance.costPerImpression"),
              totalSpend:
                (filteredCampaignTypeData?.costPerImpression?.length &&
                  filteredCampaignTypeData?.costPerImpression) ||
                (separatedCampaignTypeColumnData?.costPerImpression?.length &&
                  separatedCampaignTypeColumnData?.costPerImpression) ||
                []
            },
            {
              id: 13,
              name: t("campaignPerformance.costPerLead"),
              totalSpend:
                (filteredCampaignTypeData?.costPerLead?.length &&
                  filteredCampaignTypeData?.costPerLead) ||
                (separatedCampaignTypeColumnData?.costPerLead?.length &&
                  separatedCampaignTypeColumnData?.costPerLead) ||
                []
            },
            {
              id: 14,
              name: t("campaignPerformance.costPerSales"),
              totalSpend:
                (filteredCampaignTypeData?.costPerSales?.length &&
                  filteredCampaignTypeData?.costPerSales) ||
                (separatedCampaignTypeColumnData?.costPerSales?.length &&
                  separatedCampaignTypeColumnData?.costPerSales) ||
                []
            },
            {
              id: 15,
              name: t("campaignPerformance.revenuePerSales"),
              totalSpend:
                (filteredCampaignTypeData?.revenuePerSales?.length &&
                  filteredCampaignTypeData?.revenuePerSales) ||
                (separatedCampaignTypeColumnData?.revenuePerSales?.length &&
                  separatedCampaignTypeColumnData?.revenuePerSales) ||
                []
            },
            {
              id: 16,
              name: t("campaignPerformance.totalReturnPerSales"),
              totalSpend:
                (filteredCampaignTypeData?.totalReturnPerSales?.length &&
                  filteredCampaignTypeData?.totalReturnPerSales) ||
                (separatedCampaignTypeColumnData?.totalReturnPerSales?.length &&
                  separatedCampaignTypeColumnData?.totalReturnPerSales) ||
                []
            }
          ]}
          title={t("campaignPerformance.organicActivities")}
          handleSearch={handleSearchOnCampaignType}
        />
      </>
    );
  return <NoDataFound />;
}

GeneralDashboard.propTypes = {
  insightsTitle: PropTypes.oneOfType([Object]),
  isCompare: PropTypes.bool,
  selectedCampPerfomanceFilters: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string
};
