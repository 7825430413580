import axios from "../../../../api/axios";

const validateOtp = (payload) => axios.post("customer/validate", payload);

const resendOtp = () => axios.post("customer/resendOTP");

const otpApi = {
  validateOtp,
  resendOtp
};

export default otpApi;
