import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./personaLabel.scss";

export default function PersonaLabel({ personaDescription }) {
  const { t } = useTranslation();
  return (
    <section className="persona-label-section">
      <h6>{t("analyticsPage.personaDescriptionTitle")}</h6>
      <p>{personaDescription}</p>
    </section>
  );
}

PersonaLabel.propTypes = {
  personaDescription: PropTypes.string
};
