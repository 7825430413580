import axios from "../../../api/axios";

const rememberMeLogin = async (token) => {
  const axiosWithoutInterceptor = axios.create();
  return axiosWithoutInterceptor.get("/setup/getSessionStorage", {
    headers: {
      Authorization: token
    }
  });
};

export default rememberMeLogin;
