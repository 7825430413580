import React from "react";
import { toast, Slide } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";

function ToastrComponent({ message }) {
  return (
    <div>
      <p>{message}</p>
    </div>
  );
}

const showToastr = (message) => {
  toast.success(<ToastrComponent message={message} />, {
    position: toast.POSITION.TOP_RIGHT,
    transition: Slide
  });
};

const isError = (e) => e && e.stack && e.message;

const showErrorToastr = (error) => {
  const errorMessage = isError(error) ? error.message : error;
  toast.error(<ToastrComponent message={errorMessage} />, {
    position: toast.POSITION.TOP_RIGHT,
    transition: Slide
  });
};

export const Toastr = {
  success: showToastr,
  error: showErrorToastr
};

export default Toastr;

ToastrComponent.propTypes = {
  message: PropTypes.string
};
