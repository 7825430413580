import axios from "../../../../api/axios";

export const getBillingAddresses = (payload) =>
  axios.post("/billing/getBilling", payload);

export const updateBillingAddress = (payload) =>
  axios.post("/billing/updateBilling", payload);

export const addBillingAddress = (payload) =>
  axios.post("/billing/addBilling", payload);

export const deleteBillingAddress = (payload) =>
  axios.post("/billing/deleteBilling", payload);

export const getPlansList = (payload) =>
  axios.post("/vivaWallet/getPlanList", payload);

export const getDifferenceAmount = (payload) =>
  axios.post("/vivaWallet/getDifferenceAmount", payload);

export const getPageUsage = (payload) =>
  axios.post("/vivaWallet/getCurrentUsage", payload);

export const getMonthlyUsage = (payload) =>
  axios.post("/vivaWallet/getMonthlyUsage", payload);

export const getCategories = () => axios.get("/vivaWallet/getCategoryList");

export const vatChecker = (payload) => axios.post("/billing/vat-info", payload);

export const taxChecker = (payload) =>
  axios.post("/vivaWallet/tax-percent", payload);

export const payVivaWallet = (payload) =>
  axios.post("/vivaWallet/create-token", payload);

export const cancelSubscription = (payload) =>
  axios.post("/vivaWallet/cancelSubscription", payload);
