export function findPersonaDescription(allData, filterValues, t) {
  // Check if Persona array is defined and has at least one element
  if (
    filterValues[t("analyticsPage.personaTitle")] &&
    filterValues[t("analyticsPage.personaTitle")].length > 0
  ) {
    const personaFilter = filterValues.Persona[0];
    const matchingObject = allData.find(
      (data) => data.persona_label === personaFilter
    );
    if (matchingObject) {
      return matchingObject.persona_description;
    }
  }
  return t("analyticsPage.personaDescriptionEmptyDetail");
}

export function findPersonaDescriptionDashboard(allData, filterValues, t) {
  // Check if Persona array is defined and has at least one element
  if (
    filterValues[t("personaDashboard.personas")] &&
    filterValues[t("personaDashboard.personas")].length > 0
  ) {
    const personaFilter = filterValues[t("personaDashboard.personas")][0];
    const matchingObject = allData.find(
      (data) => data.personaLabel === personaFilter
    );
    if (matchingObject) {
      return matchingObject.personaDescription;
    }
  }
  return t("analyticsPage.personaDescriptionEmptyDetail");
}

export default findPersonaDescriptionDashboard;
