/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function PageUrlBarGraph({ pageUrlData, commonProps, t }) {
  return (
    <AddBarGraph
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: pageUrlData?.visitors
      }}
      labels={pageUrlData?.labels}
      title={t("analyticsPage.pagesTitle")}
      subtitle={t("analyticsPage.pagesTitle")}
      numBars={
        pageUrlData?.labels?.length < 25 ? pageUrlData?.labels?.length : 25
      }
      columnData={[
        {
          id: 1,
          name: t("analyticsPage.visitors"),
          diff: pageUrlData?.visitors,
          number: true
        },
        {
          id: 2,
          name: t("analyticsPage.views"),
          diff: pageUrlData?.views,
          number: true
        },
        {
          id: 3,
          name: t("analyticsPage.preSalesEvent"),
          diff: pageUrlData?.preSales,
          number: true
        },
        {
          id: 4,
          name: t("analyticsPage.salesEvent"),
          diff: pageUrlData?.sales,
          number: true
        },
        {
          id: 5,
          name: t("analyticsPage.revenue"),
          diff: pageUrlData?.revenue,
          euro: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

PageUrlBarGraph.propTypes = {
  pageUrlData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    visitors: PropTypes.arrayOf(PropTypes.number),
    views: PropTypes.arrayOf(PropTypes.number),
    preSales: PropTypes.arrayOf(PropTypes.number),
    sales: PropTypes.arrayOf(PropTypes.number),
    revenue: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired
};
