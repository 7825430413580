/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import StandardYearCalendar from "./components/StandardYearCalendar";
import CustomYearCalendar from "./components/CustomYearCalendar";
import "./assets/stylesheets/dateRange.scss";
import {
  updateDateRange,
  updateCompareDateRange,
  updateIsCompare,
  updateTimeType
} from "../../store/slices/generalSlice";
import storage from "../../utils/storage";
import { formatDateForAPI, getInDateFormat } from "../../utils/dataFilter";

export default function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCompare, dateRange, compareDateRange } = useSelector(
    (state) => state.generalData
  );
  const { selectedHeatMap } = useSelector((state) => state.PMSData);

  const storedDateRange = JSON.parse(storage.getItem("dateRange"));
  const storedCompareDateRange = JSON.parse(
    storage.getItem("compareDateRange")
  );

  const { startDate: storedStartDate, endDate: storedEndDate } =
    storedDateRange ?? {};

  const {
    startDate: startDate1,
    endDate: endDate1,
    compareStartDate: startDate2,
    compareEndDate: endDate2
  } = storedCompareDateRange ?? {};

  const [selectedDateRanges, setSelectedDateRanges] = useState(
    isCompare
      ? {
          startDate: startDate1
            ? getInDateFormat(startDate1)
            : new Date(Date.now() - 13 * 24 * 60 * 60 * 1000),
          endDate: endDate1
            ? getInDateFormat(endDate1)
            : new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
          comparedStartDate: startDate2
            ? getInDateFormat(startDate2)
            : new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
          comparedEndDate: endDate2 ? getInDateFormat(endDate2) : new Date()
        }
      : {
          startDate: storedStartDate
            ? getInDateFormat(storedStartDate)
            : new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
          endDate: storedEndDate ? getInDateFormat(storedEndDate) : new Date()
        }
  );

  const [showCalendar, setShowCalendar] = useState(false);
  const [switchCalendar, setSwitchCalendar] = useState(false);
  const [startDate, setStartDate] = useState(
    storedStartDate
      ? getInDateFormat(storedStartDate)
      : new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(
    storedEndDate ? getInDateFormat(storedEndDate) : new Date()
  );
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate,
      endDate,
      key: "selection"
    }
  ]);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [customSelectedRange, setCustomSelectedRange] = useState([
    {
      startDate: customStartDate,
      endDate: customEndDate,
      key: "selection"
    }
  ]);
  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setSelectedRange([ranges.selection]);
  };

  const customHandleSelect = (ranges) => {
    setCustomStartDate(ranges.selection.startDate);
    setCustomEndDate(ranges.selection.endDate);
    setCustomSelectedRange([ranges.selection]);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const switchCalendarToggle = () => {
    setSwitchCalendar(!switchCalendar);
  };

  const [multipleRange, setMultipleRange] = useState({
    selection1: {
      startDate: startDate1
        ? getInDateFormat(startDate1)
        : new Date(Date.now() - 13 * 24 * 60 * 60 * 1000),
      endDate: endDate1
        ? getInDateFormat(endDate1)
        : new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      key: "selection1"
    },
    selection2: {
      startDate: startDate2
        ? getInDateFormat(startDate2)
        : new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
      endDate: endDate2 ? getInDateFormat(endDate2) : new Date(),
      key: "selection2"
    }
  });

  const multipleHandleSelect = (ranges) => {
    setMultipleRange({ ...multipleRange, ...ranges });
  };

  const applyDateRangeChanges = (range) => {
    const {
      startDate: sDate,
      endDate: eDate,
      comparedStartDate,
      comparedEndDate
    } = range;
    const daysDiff =
      Math.abs(eDate.getTime() - sDate.getTime()) / (1000 * 3600 * 24);

    if (daysDiff > 365) dispatch(updateTimeType("years"));
    else if (daysDiff <= 31) dispatch(updateTimeType("days"));
    else if (daysDiff > 31) dispatch(updateTimeType("months"));

    // if comparison date ranges are available
    if (comparedStartDate && comparedEndDate) {
      const tempDateRange = {
        startDate: formatDateForAPI(sDate),
        endDate: formatDateForAPI(eDate),
        compareStartDate: formatDateForAPI(comparedStartDate),
        compareEndDate: formatDateForAPI(comparedEndDate)
      };
      if (JSON.stringify(compareDateRange) !== JSON.stringify(tempDateRange)) {
        dispatch(updateCompareDateRange(tempDateRange));
        storage.setItem("compareDateRange", JSON.stringify(tempDateRange));
      }
      storage.setItem("compare", true);

      dispatch(updateIsCompare(true));
    } else if (sDate && eDate) {
      const tempDateRange = {
        startDate: formatDateForAPI(sDate),
        endDate: formatDateForAPI(eDate)
      };
      if (JSON.stringify(dateRange) !== JSON.stringify(tempDateRange)) {
        dispatch(updateDateRange(tempDateRange));
        storage.setItem("dateRange", JSON.stringify(tempDateRange));
      }
      storage.setItem("compare", false);

      dispatch(updateIsCompare(false));
    }
  };

  const handleApply = (dateRanges) => {
    setSelectedDateRanges(dateRanges);

    applyDateRangeChanges(dateRanges);
    setShowCalendar(!showCalendar);
  };

  return (
    <div className="custom-date-range">
      <div
        className="selected-range"
        onClick={toggleCalendar}
        style={{ width: "fit-content", minWidth: 0 }}
      >
        {selectedHeatMap ? (
          <p className="date-range-display">
            Select a date
            <span className="icon">
              <i className="ri-calendar-2-line" />
            </span>
          </p>
        ) : (
          <p className="date-range-display">
            <span className={isCompare && "range1"}>
              {`${Moment(selectedDateRanges.startDate).format(
                "DD/MM/YYYY"
              )} ${t("common.to")}
          ${Moment(selectedDateRanges.endDate).format("DD/MM/YYYY")}`}
            </span>
            {isCompare && (
              <span className={isCompare && "range2"}>{` - 
            ${Moment(selectedDateRanges.comparedStartDate).format("DD/MM/YYYY")}
            ${t("common.to")} ${Moment(
                selectedDateRanges.comparedEndDate
              ).format("DD/MM/YYYY")}`}</span>
            )}
            <span className="icon">
              <i className="ri-calendar-2-line" />
            </span>
          </p>
        )}
      </div>

      {!switchCalendar ? (
        <StandardYearCalendar
          showCalendar={showCalendar}
          toggleCalendar={toggleCalendar}
          switchCalendar={switchCalendar}
          switchCalendarToggle={switchCalendarToggle}
          selectedRange={selectedRange}
          handleSelect={handleSelect}
          maxDate={new Date()}
          multipleRange={multipleRange}
          multipleHandleSelect={multipleHandleSelect}
          selectedDateRanges={selectedDateRanges}
          onApply={handleApply}
        />
      ) : (
        <CustomYearCalendar
          showCalendar={showCalendar}
          toggleCalendar={toggleCalendar}
          switchCalendar={switchCalendar}
          switchCalendarToggle={switchCalendarToggle}
          customSelectedRange={customSelectedRange}
          customHandleSelect={customHandleSelect}
          onApply={handleApply}
        />
      )}
    </div>
  );
}
