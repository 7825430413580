/* eslint-disable */
import React from "react";

export default function AISettingsValueComponent({
  option,
  text,
  isSelected,
  onOptionClick,
  name
}) {
  return (
    <div>
      <hr className="horizontal-break" />
      <div className="flex-between">
        <p className="ai-settings-values">{text}</p>
        <input
          type="radio"
          name={name} // Ensure all radio buttons in a group have the same name
          checked={isSelected}
          onChange={() => {
            onOptionClick(name, option);
          }} // Ensure it's properly used here
          className="custom-radio"
          // checked={selectedPersonas.includes(persona.personaId)}
          // onChange={() => handleCheckboxChange(persona.personaId)}
        />
      </div>
    </div>
  );
}
