import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Insights from "../../../components/Insights";
import NoDataFound from "../../Placeholders/NoDataFound";
import themeColors from "../../../assets/stylesheets/_var.scss";
import MultiBarChart from "../../../components/ChartComponents/MultiBarChart";
import QuestionsComponent from "../DashboardLandingPage/Questions";
import PersonaLabel from "../../../components/PersonaLabel";
import StackedChart from "../../../components/ChartComponents/StackedChart";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import SubscribeIcon from "../../../assets/icons/subscribe.svg";
import MainComponent from "./MainComponent";

export default function CompareISIChatDashboard({
  insightsTitle,
  isCompare,
  selectedFilters,
  dashboard,
  handlePagination,
  pageNumber,
  comparePageNumber,
  handlePagination2,
  updateSelectedFilter,
  websiteID
}) {
  const { t } = useTranslation();
  const { multiBarChartData } = useSelector(
    (state) => state.compareISIChatData
  );

  const {
    allData: allData2,
    insights: insightsToCompare,
    questions: questions2,
    pageCountOfQuestions: pageCountOfQuestions2,
    personaData: personaData2,
    categoriesData: categoriesData2,
    filteredData: filteredData2
  } = useSelector((state) => state.isiChatCompareData);
  const {
    allData: allData1,
    insights,
    questions: questions1,
    pageCountOfQuestions: pageCountOfQuestions1,
    loadingQuestions,
    personaData: personaData1,
    categoriesData: categoriesData1,
    filteredData: filteredData1,
    selectedRange,
    showPersonaData,
    userRoleId
  } = useSelector((state) => state.isiChatData);

  const getPersonaDescription = () => {
    if (selectedFilters?.[t("dashboardMenu.personas")]?.length > 1) {
      return t("isiChatDashboard.personaDescriptionForTwo");
    }
    if (selectedFilters?.[t("dashboardMenu.personas")]?.length === 1) {
      const dataOnj = filteredData1.length > 0 ? filteredData1 : filteredData2;
      const data = dataOnj.find(
        (item) =>
          item?.personaLabel ===
          selectedFilters?.[t("dashboardMenu.personas")][0]
      );
      return data?.personaDescription;
    }
    return t("analyticsPage.personaDescriptionEmptyDetail");
  };

  if (allData1.length > 0 && allData2.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
          insightsToCompare={insightsToCompare}
        />
        <MultiBarChart
          title={t("analyticsPage.visitors")}
          filterTitle={t("common.date")}
          dashboard={dashboard}
          isCompare={isCompare}
          filterValues={selectedFilters}
          multiBarChart={false}
          labels={multiBarChartData.labels}
          compareLabels={multiBarChartData.compareLabels}
          data1={{
            dataset: multiBarChartData.visitors,
            label: `${t("analyticsPage.visitors")} ${t("common.range_a")}`
          }}
          compareData1={{
            dataset: multiBarChartData.secondVisitors,
            label: `${t("analyticsPage.visitors")} ${t("common.range_b")}`
          }}
          data2={{
            dataset: multiBarChartData.sessions,
            label: `${t("dashboardMenu.sessions")} ${t("common.range_a")}`
          }}
          compareData2={{
            dataset: multiBarChartData.secondSessions,
            label: `${t("dashboardMenu.sessions")} ${t("common.range_b")}`
          }}
          data3={{
            dataset: multiBarChartData.questions,
            label: `${t("isiChatDashboard.questions")} ${t("common.range_a")}`
          }}
          compareData3={{
            dataset: multiBarChartData.secondQuestions,
            label: `${t("isiChatDashboard.questions")} ${t("common.range_b")}`
          }}
        />
        <div style={{ position: "relative" }}>
          {!showPersonaData && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#474747E5",
                zIndex: 10,
                padding: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                borderRadius: "10px"
              }}
            >
              {[1, 7].includes(userRoleId) ? (
                <div
                  className="modules-heading"
                  style={{ color: themeColors.background, marginTop: "20px" }}
                >
                  {t("isiChatDashboard.integrateYodalyticsMessage")}
                </div>
              ) : (
                <div
                  className="modules-heading"
                  style={{ color: themeColors.background, marginTop: "20px" }}
                >
                  {t("isiChatDashboard.integrateReaderMessage")}
                </div>
              )}
              {[1, 7].includes(userRoleId) && (
                <MainComponent websiteID={websiteID} />
              )}
              <img
                src={SubscribeIcon}
                alt="subscribe-Icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "250px",
                  marginTop: "30px"
                }}
              />
            </div>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <StackedChart
              title={t("dashboardMenu.personas")}
              visitors={personaData1?.visitors}
              secondVisitors={personaData2?.visitors}
              labels={
                personaData1?.labels.length > personaData2?.labels.length
                  ? personaData1?.labels
                  : personaData2?.labels
              }
              selectedFilters={selectedFilters}
              updatedComponentFilters={updateSelectedFilter}
              dashboard={dashboard}
            />
            <PersonaLabel personaDescription={getPersonaDescription()} />
          </div>
        </div>
        <div className="grid half-column-grid" style={{ height: "22.5rem" }}>
          {/* <div
            style={{
              pointerEvents:
                selectedRange === t("common.range_b") ? "none" : "auto",
              opacity: selectedRange === t("common.range_b") ? "0.2" : "1"
            }}
          > */}
          <AddBarGraph
            loading={false}
            data={{
              tooltip: t("isiChatDashboard.questions"),
              values: categoriesData1?.questions
            }}
            labels={categoriesData1?.labels}
            title={t("isiChatDashboard.categories")}
            subtitle={t("isiChatDashboard.topics")}
            numBars={
              categoriesData1?.labels?.length < 6
                ? categoriesData1?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("isiChatDashboard.asked"),
                diff: categoriesData1?.questions,
                number: true
              }
            ]}
            isCompare={false}
            dashboard={dashboard}
            filterValues={selectedFilters}
            selectedRange={t("common.range_a")}
            hideComponent={selectedRange === t("common.range_b")}
          />
          {/* </div> */}
          {/* <div
            style={{
              pointerEvents:
                selectedRange === t("common.range_a") ? "none" : "auto",
              opacity: selectedRange === t("common.range_a") ? "0.2" : "1"
            }}
          > */}
          <AddBarGraph
            loading={false}
            data={{
              tooltip: t("isiChatDashboard.questions"),
              values: categoriesData2?.questions
            }}
            labels={categoriesData2?.labels}
            title={t("isiChatDashboard.categories")}
            subtitle={t("isiChatDashboard.topics")}
            numBars={
              categoriesData2?.labels?.length < 6
                ? categoriesData2?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("isiChatDashboard.asked"),
                diff: categoriesData2?.questions,
                number: true
              }
            ]}
            isCompare={false}
            dashboard={dashboard}
            filterValues={selectedFilters}
            selectedRange={t("common.range_b")}
            hideComponent={selectedRange === t("common.range_a")}
          />
          {/* </div> */}
        </div>
        <QuestionsComponent
          title={t("isiChatDashboard.qestions_asked")}
          isCompare={isCompare}
          questions={questions1}
          compareQuestions={questions2}
          pageCountOfQuestions={pageCountOfQuestions1}
          comparePageCountOfQuestions={pageCountOfQuestions2}
          loadingQuestions={loadingQuestions}
          handlePagination={handlePagination}
          pageNumber={pageNumber}
          comparePageNumber={comparePageNumber}
          handlePagination2={handlePagination2}
        />
      </>
    );
  return <NoDataFound />;
}

CompareISIChatDashboard.propTypes = {
  isCompare: PropTypes.bool,
  insightsTitle: PropTypes.oneOfType([Object]),
  selectedFilters: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  handlePagination: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  handlePagination2: PropTypes.func.isRequired,
  comparePageNumber: PropTypes.number,
  updateSelectedFilter: PropTypes.func,
  websiteID: PropTypes.number
};
