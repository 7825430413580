import React from "react";
import PropTypes from "prop-types";
import {
  ButtonDark,
  ButtonLight
} from "../../../inputElements/buttons/MainButton";
import "./calendarButtonCombination.scss";

export default function CalendarButtonCombination({
  firstButtonText,
  secondButtonText,
  onClickFirstButton,
  onClickSecondButton,
  loading,
  loader = false
}) {
  return (
    <div className="calendar-button-combo flex">
      <ButtonLight buttonText={firstButtonText} onClick={onClickFirstButton} />
      <ButtonDark
        buttonText={secondButtonText}
        onClick={onClickSecondButton}
        loading={loading}
        loader={loader}
      />
    </div>
  );
}

CalendarButtonCombination.propTypes = {
  firstButtonText: PropTypes.string,
  secondButtonText: PropTypes.string,
  onClickFirstButton: PropTypes.func,
  onClickSecondButton: PropTypes.func,
  loading: PropTypes.bool,
  loader: PropTypes.bool
};
