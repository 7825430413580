function generatePassword(length = 12) {
  const pwdChars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
  const randPassword = new Array(length)
    .fill(0)
    .map(() =>
      ((chars) => {
        const umax = 2 ** 32;
        const r = new Uint32Array(1);
        const max = umax - (umax % chars.length);
        do {
          crypto.getRandomValues(r);
        } while (r[0] > max);
        return chars[r[0] % chars.length];
      })(pwdChars)
    )
    .join("");
  return randPassword;
}

export default generatePassword;
