/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import NoApiPlaceholder from "../../features/ManageApi/NoApiPlaceholder";
import Loader from "../../components/Loader/Loader";
import "../stylesheets/manageApi.scss";
import ManageApiServices from "../../features/ManageApi/SelectedService";
import { getManageApiServises } from "../../features/ManageApi/SelectedService/api/serviceApis";
import LocalStorage from "../../utils/LocalStorgae";

export default function ManageApi() {
  const { websiteID, createdBy } = useSelector((state) => state.generalData);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [manageApiServices, setManageApiServices] = useState([]);
  const language = LocalStorage.getItem("selectedLanguage");

  const fetchManageApiServices = async () => {
    try {
      setLoading(true);
      const resp = await getManageApiServises(`${websiteID}/${createdBy}`);
      if (resp?.data?.result) {
        setManageApiServices(resp.data.result);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchManageApiServices();
  }, [websiteID, language]);

  const renderSavedApis = () => {
    if (loading) {
      return <Loader />;
    }
    return <ManageApiServices manageApiServices={manageApiServices} />;
  };

  return (
    <section className="manage-api-section">
      <AuthorizedHeader />
      <div className="container">
        <div className="flex-between">
          <h3 className="modules-heading">{t("ManageApi.title")}</h3>
        </div>
        <div className="component-rendered">{renderSavedApis()}</div>
      </div>
    </section>
  );
}
