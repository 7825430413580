import { createSlice } from "@reduxjs/toolkit";
import { getPerformanceCampaignData } from "../apiSlice";

const initialState = {
  allData: [],
  filteredData: [],
  insights: {
    spend: 0,
    revenue: 0,
    roi: 0,
    impressions: 0,
    leads: 0,
    sales: 0,
    cpa: 0,
    revenuePerSales: 0
  },
  channelData: { labels: [], totalSpend: [] },
  campaignTypeData: { labels: [], totalSpend: [] },
  campaignData: { labels: [], totalSpend: [] },
  allSourcesData: [],
  campaignDetailsData: [],
  allCampaignTypeData: [],
  isLoading: false
};

const campaignPerformanceSlice = createSlice({
  name: "campaignPerformance",
  initialState,
  reducers: {
    updateInsights: (state, action) => {
      return { ...state, insights: { ...action.payload } };
    },
    updateFilteredData: (state, action) => {
      return { ...state, filteredData: [...action.payload] };
    },
    updateChannelData: (state, action) => {
      return { ...state, channelData: { ...action.payload } };
    },
    updateCampaignTypeData: (state, action) => {
      return { ...state, campaignTypeData: { ...action.payload } };
    },
    updateCampaignData: (state, action) => {
      return { ...state, campaignData: { ...action.payload } };
    },
    updateAllSourcesData: (state, action) => {
      return { ...state, allSourcesData: [...action.payload] };
    },
    updateCampainDetailsData: (state, action) => {
      return { ...state, campaignDetailsData: [...action.payload] };
    },
    updateAllCampaignTypeData: (state, action) => {
      return { ...state, allCampaignTypeData: [...action.payload] };
    }
  },
  extraReducers: {
    [getPerformanceCampaignData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getPerformanceCampaignData.fulfilled]: (state, action) => {
      return { ...state, isLoading: false, allData: [...action.payload[0]] };
    },
    [getPerformanceCampaignData.rejected]: (state) => {
      return { ...state, isLoading: false };
    }
  }
});

export const {
  updateInsights,
  updateFilteredData,
  updateChannelData,
  updateCampaignTypeData,
  updateCampaignData,
  updateAllSourcesData,
  updateCampainDetailsData,
  updateAllCampaignTypeData
} = campaignPerformanceSlice.actions;
export default { campaignPerformanceSlice };
