import { useEffect } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import themeColors from "../../../assets/stylesheets/_var.scss";
import "./multiSelect.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function MultipleSelect({
  names,
  selectedWebsites,
  setSelectedWebsites,
  preFilledData,
  editMode,
  error = ""
}) {
  useEffect(() => {
    if (editMode) {
      const userWebsiteIds = preFilledData?.websites?.map(
        (website) => website.website_id
      );
      setSelectedWebsites(userWebsiteIds);
    }
  }, [editMode, preFilledData]);

  const handleWebsiteSelect = (event) => {
    const { value } = event.target;
    setSelectedWebsites(value);
  };

  return (
    <div className="flex-column">
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        style={{
          fontWeight: "500",
          fontSize: "16px",
          color: themeColors.primaryText,
          fontFamily: themeColors.fontQuickSand
        }}
        value={selectedWebsites}
        onChange={handleWebsiteSelect}
        renderValue={(selected) => {
          const selectedWebsitesData = names
            .filter((name) => selected.includes(name.id))
            .map((selectedName) => selectedName.name);

          return selected.length > 1 ? (
            <div>{selected.length} Websites Selected</div>
          ) : (
            <div>{selectedWebsitesData.join(", ")}</div>
          );
        }}
        MenuProps={MenuProps}
      >
        {names.map((name) => (
          <MenuItem
            style={{
              fontWeight: "500",
              fontSize: "16px",
              color: themeColors.primaryText,
              fontFamily: themeColors.fontQuickSand
            }}
            key={name.id}
            value={name.id}
          >
            {/* Removed the input and label elements for custom checkbox */}
            <div className="menuItemList">{name.name}</div>
          </MenuItem>
        ))}
      </Select>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

MultipleSelect.propTypes = {
  names: PropTypes.oneOfType([PropTypes.array]),
  selectedWebsites: PropTypes.oneOfType([PropTypes.array]),
  setSelectedWebsites: PropTypes.func,
  preFilledData: PropTypes.oneOfType([PropTypes.array]),
  editMode: PropTypes.bool,
  error: PropTypes.string
};
