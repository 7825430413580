/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddIcon from "./assets/icons/add.svg";
import GlobeIcon from "./assets/icons/globe.svg";
import SettingIcon from "./assets/icons/setting.svg";
import DownIcon from "./assets/icons/down.svg";
import "./assets/stylesheets/websiteDropdown.scss";
import {
  updateDashboardLink,
  updateWebsiteID
} from "../../../store/slices/generalSlice";
import storage from "../../../utils/storage";

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { websitesList, websiteID } = useSelector((state) => state.generalData);
  const { dashBoardLink } = useSelector((state) => state.generalData);

  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const checkCurrentPath = () => {
    return location.pathname === "/mySites";
  };

  useEffect(() => {
    dispatch(updateDashboardLink(location?.pathname));
  }, [location]);

  const modifyWebsiteID = (id) => {
    // updates the website ID in redux (globally)
    const role = websitesList.find((ele) => ele.id === id);
    storage.setItem("defaultWeb", id);
    storage.setItem("role", role.role_id);
    storage.setItem("createdBy", role.created_by);
    dispatch(
      updateWebsiteID({
        role_id: role.role_id,
        website_id: id,
        created_by: role.created_by
      })
    );
    navigate(dashBoardLink);
  };

  return (
    <div className="website-dropdown" ref={dropdownRef}>
      <div
        // className={`label flex-between ${checkCurrentPath() && "active-page"}`}
        className="label flex-between"
        onClick={toggleDropdown}
      >
        <div className="details flex">
          <img src={GlobeIcon} alt="globe-logo" />
          <p>
            {checkCurrentPath() || websitesList?.length === 0
              ? t("noWebsite.mysites")
              : websitesList.find((ele) => ele.id === websiteID)?.name}
          </p>
        </div>
        <img src={DownIcon} alt="down-icon" />
      </div>
      {isOpen && (
        <div className="website-dropdown-items">
          {websitesList
            .filter((ele) => {
              if (checkCurrentPath()) return true;
              return ele.id !== websiteID;
            })
            .map((website) => {
              return (
                <div
                  className="dropdown-item flex-between"
                  key={website.id}
                  onClick={() => modifyWebsiteID(website.id)}
                >
                  <p>{website.name}</p>
                </div>
              );
            })}

          <div
            onClick={() => navigate("/mySites")}
            className="dropdown-item flex-between"
          >
            <p>{t("noWebsite.link")}</p>
            <img src={AddIcon} alt="add-icon" />
          </div>

          <div
            className="dropdown-item flex-between"
            onClick={() => navigate("/mySites")}
          >
            <p>{t("noWebsite.mysites")}</p>
            <img src={SettingIcon} alt="setting-icon" />
          </div>
        </div>
      )}
    </div>
  );
}
