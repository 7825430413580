/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AddTagManager from "./component/AddTagManager";
import closeButton from "../../../assets/icons/Close.svg";
import LoadWebsite from "./component/LoadWebsite";
import storage from "../../../utils/storage";
import "./assets/stylesheets/general.scss";

export default function Index({
  handleShowAddTag,
  fetchData,
  initialData,
  rowClicked
}) {
  const { t } = useTranslation();
  const options = [
    t("tagManager.bookNow"),
    t("tagManager.sendRequest"),
    t("tagManager.addCustomLabel")
  ];
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showPricePopUp, setShowPricePopUp] = useState(
    (rowClicked && initialData.price_location_type) ?? false
  );
  const [inputValue, setInputValue] = useState("");
  const [websiteEventRelationId, setWebsiteEventRelationId] = useState();
  const [isEditing, setIsEditing] = useState(!rowClicked);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [selectedId, setSelectedId] = useState();
  const [addCustom, setAddCustom] = useState(false);
  const [customTag, setCustomTag] = useState(false);
  const [customTagList, setCustomTagList] = useState([]);

  const [selectorType, setSelectorType] = useState("price");
  const [locationSelector, setLocationSelector] = useState(null);
  const [priceSelector, setPriceSelector] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [selectorPopup, setSelectorPopup] = useState(false);
  const websiteList = JSON.parse(storage.getItem("websitesList"));
  const { websiteID } = useSelector((state) => state.generalData);
  const websiteUrl = websiteList.find(
    (website) => website.id === websiteID
  )?.url;

  function showSelector(type) {
    setSelectorType(type);
    setSelectorPopup(true);
  }

  return (
    <>
      <AddTagManager
        fetchData={fetchData}
        handleShowAddTag={handleShowAddTag}
        initialData={initialData}
        rowClicked={rowClicked}
        options={options}
        showDeletePopup={showDeletePopup}
        setShowDeletePopup={setShowDeletePopup}
        showPricePopUp={showPricePopUp}
        setShowPricePopUp={setShowPricePopUp}
        inputValue={inputValue}
        setInputValue={setInputValue}
        websiteEventRelationId={websiteEventRelationId}
        setWebsiteEventRelationId={setWebsiteEventRelationId}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        addCustom={addCustom}
        setAddCustom={setAddCustom}
        customTag={customTag}
        setCustomTag={setCustomTag}
        customTagList={customTagList}
        setCustomTagList={setCustomTagList}
        priceSelector={priceSelector}
        locationSelector={locationSelector}
        showSelector={showSelector}
      />
      {selectorPopup && (
        <div className="popup">
          <div className="popup-content">
            <img
              src={closeButton}
              alt="close"
              className="close-icon"
              onClick={() => setSelectorPopup(false)}
            />
            <LoadWebsite
              className="load-website"
              url={websiteUrl}
              setSelectorPopup={setSelectorPopup}
              setSelector={
                selectorType === "price"
                  ? setPriceSelector
                  : setLocationSelector
              }
              locationUrl={locationUrl}
              setLocationUrl={setLocationUrl}
            />
          </div>
        </div>
      )}
    </>
  );
}

Index.propTypes = {
  handleShowAddTag: PropTypes.func,
  fetchData: PropTypes.func,
  initialData: PropTypes.oneOfType([PropTypes.object]),
  rowClicked: PropTypes.bool
};
