/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ButtonDark } from "../../../../components/inputElements/buttons/MainButton";
import { Toastr } from "../../../../components/Toastr/Toastr";
import "../assets/stylesheets/LoadWebsite.scss";

function LoadWebsite({
  url,
  setSelector,
  setSelectorPopup,
  locationUrl,
  setLocationUrl
}) {
  const iframeRef = useRef(null);
  const { t } = useTranslation();

  function startSelection() {
    const iframe = iframeRef.current;
    const value = sessionStorage.getItem("sessionId");
    try {
      iframe.contentWindow.postMessage(value, url);
    } catch (error) {
      console.log("invalid url configured in your webpage");
      Toastr.error(t("tagManager.invalidUrlError"));
    }
  }

  useEffect(() => {
    function handleMessage(event) {
      try {
        if (url && new URL(event.origin).origin !== new URL(url).origin) {
          return;
        }
        setSelector(event.data.cssSelector);
        setLocationUrl(event.data.url);
      } catch (error) {
        console.log("invalid url configured in your webpage");
        Toastr.error(t("tagManager.invalidUrlError"));
      }
      setSelectorPopup(false);
    }

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [url]);

  return (
    <div className="load-website-container">
      <iframe
        className="iframe"
        ref={iframeRef}
        src={locationUrl !== "" ? locationUrl : url}
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        title="Website"
      />
      <div className="load-website-button-container">
        <ButtonDark onClick={startSelection} buttonText={t("common.select")} />
      </div>
    </div>
  );
}

LoadWebsite.propTypes = {
  url: PropTypes.string.isRequired,
  setSelector: PropTypes.func.isRequired,
  setSelectorPopup: PropTypes.func.isRequired,
  locationUrl: PropTypes.string.isRequired,
  setLocationUrl: PropTypes.func.isRequired
};

export default LoadWebsite;
