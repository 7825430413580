import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import rememberMeLogin from "./api/rememberMeLogin";
import storage from "../../utils/storage";
import LocalStorage from "../../utils/LocalStorgae";
import setStorages from "../../utils/loginSetProcess";

export default function Index() {
  const dispatch = useDispatch();
  const rememberMeToken = LocalStorage.getItem("RememberMeToken");
  const navigate = useNavigate();

  async function handleLogin() {
    const response = await rememberMeLogin(rememberMeToken); // .then(() => {
    setStorages(response, dispatch, navigate);
  }

  useEffect(() => {
    if (rememberMeToken && !storage.getItem("email")) {
      handleLogin();
    }
  }, []);
}
