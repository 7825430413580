import axios from "../../../../api/axios";

const register = (payload) => axios.post("/register", payload);

const newUserInvite = (payload) => axios.post("/invitedUserRegister", payload);

const signup = {
  register,
  newUserInvite
};

export default signup;
