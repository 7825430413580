import increaseArrow from "../assets/icons/up.svg";
import decreaseArrow from "../assets/icons/down.svg";
import { countryISO } from "../data/countryData";

// For separating the data to each bargraph
export const dataFilterFunction = (data, prop) => {
  const filtered = {};

  data?.forEach((arr) => {
    if (!filtered[arr[prop]]) {
      filtered[arr[prop]] = [];
    }
    filtered[arr[prop]].push(arr);
  });
  return filtered;
};

// Moving the selected value to the top along with corresponding data
export const filterAccordingToSelected = (data, selected) => {
  const {
    labels,
    dataset,
    visitors,
    views,
    bounce,
    links,
    preSales,
    sales,
    revenue,
    total,
    counts
  } = data;

  const tempLabels = labels ? [...labels] : [];
  const tempLinks = links ? [...links] : [];
  const tempData = dataset ? [...dataset] : [];
  const tempVisitors = visitors ? [...visitors] : [];
  const tempViews = views ? [...views] : [];
  const tempBounce = bounce ? [...bounce] : [];
  const tempPreSales = preSales ? [...preSales] : [];
  const tempSales = sales ? [...sales] : [];
  const tempRevenue = revenue ? [...revenue] : [];
  const tempTotal = total ? [...total] : [];
  const tempCounts = counts ? [...counts] : [];

  selected.map((ele) => {
    const index = labels?.indexOf(ele);

    if (index > -1) {
      tempLabels.unshift(...new Set(tempLabels.splice(index, 1)));
      tempLinks.unshift(...new Set(tempLinks.splice(index, 1)));
      tempData.unshift(...new Set(tempData.splice(index, 1)));
      tempVisitors.unshift(...new Set(tempVisitors.splice(index, 1)));
      tempViews.unshift(...new Set(tempViews.splice(index, 1)));
      tempBounce.unshift(...new Set(tempBounce.splice(index, 1)));
      tempPreSales.unshift(...new Set(tempPreSales.splice(index, 1)));
      tempSales.unshift(...new Set(tempSales.splice(index, 1)));
      tempRevenue.unshift(...new Set(tempRevenue.splice(index, 1)));
      tempTotal.unshift(...new Set(tempTotal.splice(index, 1)));
      tempCounts.unshift(...new Set(tempCounts.splice(index, 1)));
    } else {
      tempLabels.unshift(ele);
      tempLinks.unshift("");
      tempData.unshift(0);
      tempVisitors.unshift(0);
      tempViews.unshift(0);
      tempBounce.unshift(0);
      tempPreSales.unshift(0);
      tempSales.unshift(0);
      tempRevenue.unshift(0);
      tempTotal.unshift(0);
      tempCounts.unshift(0);
    }
    return ele;
  });

  return {
    labels: tempLabels,
    links: tempLinks,
    dataset: tempData,
    visitors: tempVisitors,
    views: tempViews,
    bounce: tempBounce,
    preSales: tempPreSales,
    sales: tempSales,
    revenue: tempRevenue,
    total: tempTotal,
    counts: tempCounts
  };
};

function switcher(match) {
  if (match !== "," && match !== ".") return match;
  return match === "," ? "." : ",";
}

// EU format conversion
export const convertToEUFormat = (value) => {
  const number = parseFloat(parseFloat(value).toFixed(2))
    .toFixed(2)
    .toLocaleString("en-US", {
      useGrouping: true
    });

  return number.replaceAll(/.|,/g, switcher);
};

// for number conversion
export const convertToK = (convert) => {
  if (Number.isNaN(convert)) return 0;
  let tempValue = convert;
  if (tempValue >= 1000000000) {
    tempValue = `${(convert / 1000000000)
      .toFixed(2)
      .replaceAll(/.|,/g, switcher)}B`;
  } else if (tempValue >= 1000000) {
    tempValue = `${(convert / 1000000)
      .toFixed(2)
      .replaceAll(/.|,/g, switcher)}M`;
  } else if (tempValue >= 1000) {
    tempValue = `${(convert / 1000).toFixed(2).replaceAll(/.|,/g, switcher)}k`;
  } else {
    const value = tempValue.toString().split(".");
    if (value.length > 1 && value[1].length > 2) {
      tempValue = tempValue.toFixed(2);
    }
  }

  return tempValue.toString().replaceAll(/.|,/g, switcher);
};
export const convertNumber = (convert) => {
  let tempValue = convert;
  if (tempValue >= 1000000000) {
    tempValue = `${(convert / 1000000000)
      .toFixed(2)
      .replaceAll(/.|,/g, switcher)}B`;
  } else if (tempValue >= 1000000) {
    tempValue = `${(convert / 1000000)
      .toFixed(2)
      .replaceAll(/.|,/g, switcher)}M`;
  } else if (tempValue >= 1000) {
    tempValue = `${(convert / 1000).toString().replaceAll(/.|,/g, switcher)}k`;
  }

  return tempValue.toString().replaceAll(/.|,/g, switcher);
};

// percentage conversion
export const convertToPercent = (value) => {
  if (value === Infinity) return `0 %`;
  if (value === Math.floor(value)) return `${value} %`;

  return `${value?.toFixed(2).replaceAll(/.|,/g, switcher)} %`;
};

export const formatNumber = (value) => {
  return parseFloat(value)
    .toLocaleString("en-US", {
      useGrouping: true
    })
    .replaceAll(/.|,/g, switcher);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const convertTime = (value) => {
  // const days = Math.floor(value / (3600 * 24));
  const hours = Math.floor((value % (3600 * 24)) / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.round(value % 60);

  if (hours) return `${padTo2Digits(hours)}:${padTo2Digits(minutes)} hrs`;
  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)} min`;
};

// Common for sorting the graph data based on visitors
export const getGraphDataSorted = (data, { visitors, views }) => {
  const graphData = [];
  Object.keys(data).forEach((key) => {
    if (key) {
      let tempVisitors = 0;
      let tempViews = 0;

      data[key]?.map((ele) => {
        if (visitors) tempVisitors += Number(ele.visitors);
        if (views) tempViews += Number(ele.views);
        return ele;
      });

      const dataToAdd = {
        label: key,
        visitors: tempVisitors,
        views: tempViews
      };

      if (tempVisitors) graphData.push(dataToAdd);
    }
  });
  graphData.sort((a, b) => {
    return b.visitors - a.visitors;
  });
  return graphData;
};

export const getMonths = (start, end) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  return months.slice(start, end);
};

export const getDatesInRange = ({ startDate, endDate, dmyFormat }) => {
  const date = new Date(startDate.split("-").reverse().join("/"));
  const endTemp = new Date(endDate.split("-").reverse().join("/"));

  const dates = [];
  while (date <= endTemp) {
    if (dmyFormat)
      dates.push(
        new Date(date)
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
          .split("/")
          .reverse()
          .join("-")
      );
    else
      dates.push(
        `${date.getDate()}/${date.getMonth() + 1}/${date
          .getFullYear()
          .toString()
          .slice(-2)}`
      );
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const getMonthsInRange = ({ startDate, endDate }) => {
  const date = new Date(startDate.split("-").reverse().join("/"));
  const endTemp = new Date(endDate.split("-").reverse().join("/"));

  const months = getMonths(0, 12);
  const dates = [];
  while (date.getMonth() <= endTemp.getMonth()) {
    dates.push(`${months[date.getMonth()]} ${date.getFullYear()}`);
    date.setMonth(date.getMonth() + 1);
  }

  return dates;
};

export const getYearsInRange = ({ startDate, endDate }) => {
  const start = new Date(
    startDate.split("-").reverse().join("/")
  ).getFullYear();
  const end = new Date(endDate.split("-").reverse().join("/")).getFullYear();

  const years = [];
  for (let i = start; i <= new Date().getFullYear() && i <= end; i += 1)
    years.push(i);

  return years;
};
export const getInDateFormat = (date) => {
  return new Date(date.split("-").reverse().join("/"));
};

export const separatePageViews = (data, { startDate, endDate }) => {
  const allDates = getDatesInRange({ startDate, endDate, dmyFormat: true });
  const dateToMonth = (date) => {
    const d = getInDateFormat(date);

    const months = getMonths(0, 12);

    return `${months[d.getMonth()]} ${d.getFullYear()}`;
  };

  const manipulated = allDates.map((ele) => {
    const dataFound = data.find(
      (e) => e.date === ele.split("-").reverse().join("-")
    );

    if (dataFound) return dataFound;
    return { date: ele.split("-").reverse().join("-"), pageViewCount: 0 };
  });

  const prop = "date";
  return manipulated.reduce((group, arr) => {
    const propGroup = group[dateToMonth(arr[prop])] ?? [];
    const updatedGroup = {
      ...group,
      [dateToMonth(arr[prop])]: [...propGroup, arr]
    };
    return updatedGroup;
  }, {});
};

// Views and visitors data for line chart only
export const getViewsVisitorsData = (data, dateRange) => {
  // populate the label, visitors and views
  const graphData = [];
  const dateArray = getDatesInRange(dateRange);

  dateArray.forEach((key) => {
    let tempVisitors = 0;
    let tempViews = 0;
    if (data[key]) {
      data[key]?.map((ele) => {
        tempVisitors += Number(ele.visitors);
        tempViews += Number(ele.views);
        return ele;
      });

      graphData.push({ label: key, visitors: tempVisitors, views: tempViews });
    } else graphData.push({ label: key, visitors: 0, views: 0 });
  });
  return graphData;
};

export const findMedianValue = (data) => {
  const sortedArray = data.slice().sort((a, b) => a - b);
  if (sortedArray.length === 0) return 0;
  const middleIndex = Math.floor(sortedArray.length / 2);
  return sortedArray.length % 2 === 1
    ? sortedArray[middleIndex]
    : (sortedArray[middleIndex - 1] + sortedArray[middleIndex]) / 2;
};

// KPI values calculation for all data
export const getInsights = (data) => {
  const temp = {
    visitors: 0,
    actions: 0,
    views: 0,
    avgTime: 0,
    bounceRate: 0,
    tag_actions: 0,
    revenue: 0
  };
  const avgTimeArray = [];

  let distinctSessions = 0;
  let sessionWithBounce = 0;

  data.map((ele) => {
    temp.actions += Number(ele.actions);
    avgTimeArray.push(Number(ele.timeSpent));
    temp.tag_actions += Number(ele.tagActions);

    temp.revenue += Number(ele.revenue);

    temp.visitors += Number(ele.visitors);
    temp.views += Number(ele.views);
    sessionWithBounce += Number(ele.bouncedSessions);
    distinctSessions += Number(ele.sessions);
    return ele;
  });

  temp.avgTime /= distinctSessions;

  temp.bounceRate =
    distinctSessions && sessionWithBounce
      ? (sessionWithBounce / distinctSessions) * 100
      : 0;
  temp.avgTime = findMedianValue(avgTimeArray);
  return temp;
};

export const insightsDiff = (data1, data2) => {
  if (data1 > data2)
    return (
      <div className="comparison-value">
        <div className="decreasing-value">
          {convertToPercent((Math.abs(data1 - data2) / data1) * 100)}
        </div>
        <img src={decreaseArrow} alt="decrease-arrow" className="arrow" />
      </div>
    );
  if (data1 === data2)
    return (
      <div className="comparison-value">
        <div className="increasing-value">-- %</div>
      </div>
    );
  return (
    <div className="comparison-value">
      <div className="increasing-value">
        {convertToPercent((Math.abs(data1 - data2) / data2) * 100)}
      </div>
      <img src={increaseArrow} alt="increase-arrow" className="arrow" />
    </div>
  );
};

export const formatDateForAPI = (date) => {
  return `${date
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })
    .split("/")
    .join("-")}`;
};

// modifying the data
// (replacing country code with name, link with slashes removed,
// Date with formatted value based on the date range)
export const modifyData = (data) => {
  return data?.map((ele) => {
    const { country, current_link: link } = ele;

    const parts = link?.split("/");
    let pageUrl = link;
    if (parts?.length >= 3) {
      pageUrl = `/${parts.slice(3).join("/")}`;
    }

    const dateUTC = getInDateFormat(ele.Date);
    const tempDate = `${dateUTC.getDate()}/${dateUTC.getMonth() + 1}/${dateUTC
      .getFullYear()
      .toString()
      .slice(-2)}`;

    return {
      ...ele,
      oldDate: ele.Date,
      countryCode: country,
      country:
        country && countryISO[country] ? countryISO[country].name : "Others",
      whole_link: ele.current_link,
      current_link: pageUrl,
      Date: tempDate
    };
  });
};

// bar graph data merging
export const compareAndMergeData = (data1, data2) => {
  const {
    labels: labels1,
    links: links1,
    dataset: dataset1,
    visitors: visitors1,
    views: views1,
    bounce: bounce1,
    preSales: preSales1,
    sales: sales1,
    revenue: revenue1,
    counts: counts1
  } = data1;
  const {
    labels: labels2,
    links: links2,
    dataset: dataset2,
    visitors: visitors2,
    views: views2,
    bounce: bounce2,
    preSales: preSales2,
    sales: sales2,
    revenue: revenue2,
    counts: counts2
  } = data2;

  const tempLabels = [];
  const tempLinks = links1 && links2 ? [] : null;
  const tempData1 = [];
  const tempData2 = [];
  const tempViews1 = [];
  const tempViews2 = [];

  const tempBounce1 = [];
  const tempBounce2 = [];
  const tempPreSales1 = [];
  const tempPreSales2 = [];

  const tempSales1 = [];
  const tempSales2 = [];

  const tempRevenue1 = [];
  const tempRevenue2 = [];

  const tempCounts1 = [];
  const tempCounts2 = [];

  const tempVisitors = visitors1 && visitors2 ? [] : null;
  const tempViews = views1 && views2 ? [] : null;
  const tempBounce = bounce1 && bounce2 ? [] : null;
  const tempPreSales = preSales1 && preSales2 ? [] : null;
  const tempSales = sales1 && sales2 ? [] : null;
  const tempRevenue = revenue1 && revenue2 ? [] : null;
  const tempCounts = counts1 && counts2 ? [] : null;

  labels1?.map((ele, i) => {
    tempLabels.push(ele);
    if (links1 && links2) tempLinks.push(links1[i]);

    if (labels2.includes(ele)) {
      const tempIndex = labels2.indexOf(ele);
      if (dataset1) tempData1.push(dataset1[i]);
      if (dataset2) tempData2.push(dataset2[tempIndex]);

      if (visitors1 && visitors2)
        tempVisitors.push(Math.abs(visitors1[i] - visitors2[tempIndex]));
      if (views1 && views2) {
        tempViews1.push(views1[i]);
        tempViews2.push(views2[tempIndex]);
        tempViews.push(Math.abs(views1[i] - views2[tempIndex]));
      }
      if (bounce1 && bounce2) {
        tempBounce1.push(bounce1[i]);
        tempBounce2.push(bounce2[tempIndex]);
        tempBounce.push(Math.abs(bounce1[i] - bounce2[tempIndex]));
      }
      if (preSales1 && preSales2) {
        tempPreSales1.push(preSales1[i]);
        tempPreSales2.push(preSales2[tempIndex]);
        tempPreSales.push(Math.abs(preSales1[i] - preSales2[tempIndex]));
      }
      if (sales1 && sales2) {
        tempSales1.push(sales1[i]);
        tempSales2.push(sales2[tempIndex]);
        tempSales.push(Math.abs(sales1[i] - sales2[tempIndex]));
      }
      if (revenue1 && revenue2) {
        tempRevenue1.push(revenue1[i]);
        tempRevenue2.push(revenue2[tempIndex]);
        tempRevenue.push(Math.abs(revenue1[i] - revenue2[tempIndex]));
      }
      if (counts1 && counts2) {
        tempCounts1.push(counts1[i]);
        tempCounts2.push(counts2[tempIndex]);
        tempCounts.push(Math.abs(counts1[i] - counts2[tempIndex]));
      }
    } else {
      if (dataset1) {
        tempData1.push(dataset1?.[i]);
        tempViews1.push(views1?.[i]);
        tempBounce1.push(bounce1?.[i]);
        tempPreSales1.push(preSales1?.[i]);
        tempSales1.push(sales1?.[i]);
        tempRevenue1.push(revenue1?.[i]);
        tempCounts1.push(counts1?.[i]);
      }
      tempData2.push(0);
      tempViews2.push(0);
      tempBounce2.push(0);
      tempPreSales2.push(0);
      tempSales2.push(0);
      tempRevenue2.push(0);
      tempCounts2.push(0);
      if (visitors1 && visitors2) tempVisitors.push(visitors1[i]);
      if (views1 && views2) tempViews.push(views1[i]);
      if (bounce1 && bounce2) tempBounce.push(bounce1[i]);
      if (preSales1 && preSales2) tempPreSales.push(preSales1[i]);
      if (sales1 && sales2) tempSales.push(sales1[i]);
      if (revenue1 && revenue2) tempRevenue.push(revenue1[i]);
      if (counts1 && counts2) tempCounts.push(counts1[i]);
    }

    return ele;
  });

  labels2.map((ele, i) => {
    if (!tempLabels.includes(ele)) {
      tempLabels.push(ele);
      if (links1 && links2) tempLinks.push(links2[i]);
      tempData1.push(0);
      tempData1.push(0);
      tempViews1.push(0);
      tempBounce1.push(0);
      tempPreSales1.push(0);
      tempSales1.push(0);
      tempRevenue1.push(0);
      tempCounts1.push(0);
      if (dataset2) {
        tempData2.push(dataset2?.[i]);
        tempViews2.push(views2?.[i]);
        tempBounce2.push(bounce2?.[i]);
        tempPreSales2.push(preSales2?.[i]);
        tempSales2.push(sales2?.[i]);
        tempRevenue2.push(revenue2?.[i]);
        tempCounts2.push(counts2?.[i]);
      }
      if (visitors1 && visitors2) tempVisitors.push(visitors2[i]);
      if (views1 && views2) tempViews.push(views2[i]);
      if (bounce1 && bounce2) tempBounce.push(bounce2[i]);
      if (preSales1 && preSales2) tempPreSales.push(preSales2[i]);
      if (sales1 && sales2) tempSales.push(sales2[i]);
      if (revenue1 && revenue2) tempRevenue.push(revenue2[i]);
      if (counts1 && counts2) tempCounts.push(counts2[i]);
    }

    return ele;
  });

  return {
    labels: tempLabels,
    links: tempLinks,
    dataset1: tempData1,
    dataset2: tempData2,
    visitors: tempVisitors,
    views: tempViews,
    views1: tempViews1,
    views2: tempViews2,
    bounce: tempBounce,
    bounce1: tempBounce1,
    bounce2: tempBounce2,
    preSales: tempPreSales,
    preSales1: tempPreSales1,
    preSales2: tempPreSales2,
    sales: tempSales,
    sales1: tempSales1,
    sales2: tempSales2,
    revenue: tempRevenue,
    revenue1: tempRevenue1,
    revenue2: tempRevenue2,
    counts: tempCounts,
    counts1: tempCounts1,
    counts2: tempCounts2
  };
};

// line chart data merging
export const compareMergeViewsVisitors = (data1, data2) => {
  const { labels: labels1, visitors: visitors1, views: views1 } = data1;
  const { labels: labels2, visitors: visitors2, views: views2 } = data2;

  const tempLabels = [];
  const tempVisitors = [];
  const tempViews = [];
  const tempSecondViews = [];
  const tempSecondVisitors = [];

  labels1?.map((ele, i) => {
    tempLabels.push(ele);
    tempVisitors.push(visitors1[i]);
    tempViews.push(views1[i]);
    if (labels2.includes(ele)) {
      const tempIndex = labels2.indexOf(ele);
      tempSecondVisitors.push(visitors2[tempIndex]);
      tempSecondViews.push(views2[tempIndex]);
    } else {
      tempSecondVisitors.push(NaN);
      tempSecondViews.push(NaN);
    }
    return ele;
  });

  labels2.map((ele, i) => {
    if (!tempLabels.includes(ele)) {
      tempLabels.push(ele);
      tempSecondVisitors.push(visitors2[i]);
      tempSecondViews.push(views2[i]);
    }
    return ele;
  });

  return {
    labels: tempLabels,
    visitors: tempVisitors,
    views: tempViews,
    secondVisitors: tempSecondVisitors,
    secondViews: tempSecondViews
  };
};

function padZero(num) {
  return num.toString().padStart(2, "0");
}

export const convertSecToHours = (seconds) => {
  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${padZero(minutes)} mins`;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${padZero(hours)}:${padZero(minutes)} hrs`;
};
