/* eslint-disable */
import React from "react";

export default function CommonContent({ content }) {
  return (
    <div
      style={{
        paddingTop: "20px"
      }}
    >
      <text className="privacy-subheadings">{content}</text>
    </div>
  );
}
