/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import "./toggle.scss";
import MainToggle from "../ToggleSwitch/MainToggle";

export default function Toggle({ isToggled, setIsToggled, label }) {
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  return (
    <MainToggle
      isToggled={isToggled}
      setIsToggled={setIsToggled}
      handleToggle={handleToggle}
      label={label}
    />
  );
}

Toggle.propTypes = {
  isToggled: PropTypes.bool,
  setIsToggled: PropTypes.func,
  label: PropTypes.string
};
