/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SubscriptionLimitIcon from "../../assets/icons/subscription-limit.svg";
import "./assets/stylesheets/limitexceeded.scss";
import { ButtonDark } from "../../components/inputElements/buttons/Buttons";

export default function LimitExceeded() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex-center">
      <div className="limit-exceeded-background background-box">
        <img
          className="limit-exceeded-icon"
          src={SubscriptionLimitIcon}
          alt="subscription-limitIcon"
        />
        <div className="flex-vertical margin-container">
          <span className="comp-heading">
            {t("subscription.limitExceeded")}
          </span>
          <span className="comp-sub-heading">
            {t("subscription.subscriptionText")}
          </span>
          <ButtonDark
            buttonText={t("subscription.updateSubscription")}
            onClick={() => navigate("/settings/subscription")}
            width="364px"
          />
        </div>
      </div>
    </div>
  );
}
