/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import close from "../../../../../assets/icons/close.png";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import noImageFound from "../assets/icons/Noimagefound.png";
import "../assets/stylesheets/noImageFound.scss";

export default function NoImageFoundModel({
  setShowNoImageFoundModel,
  setShowUploadImagesModel
}) {
  const { t } = useTranslation();

  return (
    <div className="no-image-found">
      <div
        className="close-img-div"
        onClick={() => {
          setShowNoImageFoundModel(false);
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          // Handling keyboard events
          if (e.key === "Enter" || e.key === " ") {
            setShowNoImageFoundModel(false);
          }
        }}
      >
        <img src={close} className="close-img" alt="close" />
      </div>

      <div className="not-found-div">
        <img className="not-found-img" src={noImageFound} alt="no" />
      </div>

      <div className="warning-div">
        <p className="warning-text">{t("common.confirmation.warning")}!</p>
      </div>

      <div className="warning-div">
        <p className="no-images-text">
          {t("manageCampaign.noImagesAvailable")}
        </p>
      </div>

      <div className="warning-div">
        <p className="no-images-text">
          {t("manageCampaign.pleaseChooseFromUploadImages")}
        </p>
      </div>

      <div className="flex-center cancel-proceed-buttons">
        <div className="cancel-right">
          <ButtonLight
            buttonText={t("common.confirmation.cancel")}
            onClick={() => {
              setShowNoImageFoundModel(false);
            }}
          />
        </div>
        <ButtonDark
          buttonText={t("common.confirmation.proceed")}
          onClick={() => {
            setShowNoImageFoundModel(false);
            setShowUploadImagesModel(true);
          }}
        />
      </div>
    </div>
  );
}
