import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Calendar from "../../assets/icons/calendar.svg";
import "../../assets/stylesheets/addNew.scss";
import { ButtonDark } from "../../../inputElements/buttons/MainButton";

export default function AddNew({ setActiveCalendarComponent }) {
  const { t } = useTranslation();
  return (
    <section className="add-new">
      <div className="add-action">
        <img src={Calendar} alt="calendar" />
        <ButtonDark
          buttonText={t("analyticsPage.calendar.custom.addNew")}
          onClick={() => setActiveCalendarComponent(2)}
        />
      </div>
    </section>
  );
}

AddNew.propTypes = {
  setActiveCalendarComponent: PropTypes.func.isRequired
};
