import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementActions,
  updateComponent
} from "../../../../store/slices/trackingSlice";

export default function StackedChart({
  title,
  data,
  options,
  updatedComponentFilters,
  selectedFilters,
  height
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const chartRef = useRef();
  const dispatch = useDispatch();

  const trackingData = useSelector((state) => state.tracking);

  console.log("trackingData", trackingData);

  const handleChartClick = (event) => {
    console.log("clicked");
    const clickedElements = getElementsAtEvent(chartRef.current, event);
    if (clickedElements.length === 0) return;

    const { index } = clickedElements[0];
    const clickedLabel = data.labels[index];
    console.log("clickedLabel", clickedLabel);

    dispatch(
      updateComponent({
        component: "Persona",
        component_label: clickedLabel
      })
    );
    dispatch(incrementActions());
    const currentFilters = selectedFilters[title] || [];
    const updatedFilters = { ...selectedFilters };

    if (currentFilters.includes(clickedLabel)) {
      const newFilters = currentFilters.filter((item) => item !== clickedLabel);
      if (newFilters.length > 0) {
        updatedFilters[title] = newFilters;
      } else {
        delete updatedFilters[title];
      }
    } else {
      updatedFilters[title] =
        event.metaKey || event.ctrlKey
          ? [...currentFilters, clickedLabel]
          : [clickedLabel];
    }

    dispatch(updatedComponentFilters(updatedFilters));
  };

  return (
    <Bar
      data={data}
      options={options}
      ref={chartRef}
      onClick={handleChartClick}
      height={height}
    />
  );
}

StackedChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updatedComponentFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.oneOfType([Object]).isRequired,
  height: PropTypes.number
};
