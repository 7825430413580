/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HamburgerIcon from "./assets/icons/menu.svg";
import ProfileIcon from "./assets/icons/profile.svg";
import SettingIcon from "./assets/icons/setting.svg";
import LogoutIcon from "./assets/icons/logout.svg";
import LanguageDropdown from "../LanguageDropdown";
import "./assets/stylesheets/hamburger.scss";
import LocalStorage from "../../../utils/LocalStorgae";

export default function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    const selectedLanguage = LocalStorage.getItem("selectedLanguage");
    localStorage.clear();
    if (selectedLanguage !== null) {
      LocalStorage.setItem("selectedLanguage", selectedLanguage);
    }
    sessionStorage.clear();
    // window.location.href = "/";
    navigate("/", { replace: true });
  };

  return (
    <div className="hamburger-dropdown" ref={dropdownRef}>
      <img
        src={HamburgerIcon}
        alt="hamburger-icon"
        className="hamburger-icon"
        onClick={toggleDropdown}
      />
      {isOpen && (
        <div className="dropdown">
          <div
            className="dropdown-item flex-between"
            onClick={() => {
              navigate("/settings/profile");
            }}
          >
            <p>{t("hamburgerHeader.profile")}</p>
            <img src={ProfileIcon} alt="profile-icon" />
          </div>
          <div
            className="dropdown-item flex-between"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <p>{t("hamburgerHeader.settings")}</p>
            <img src={SettingIcon} alt="setting-icon" />
          </div>
          <div className="dropdown-item language">
            {/* <p>{t("hamburgerHeader.changeLanguage")}</p> */}
            <LanguageDropdown showLabel />
          </div>
          <div className="dropdown-item flex-between" onClick={handleLogout}>
            <p>{t("hamburgerHeader.logout")}</p>
            <img src={LogoutIcon} alt="logout-icon" />
          </div>
        </div>
      )}
    </div>
  );
}
