/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import "../assets/stylesheets/selectService.scss";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";
import Toggle from "../../../../components/Toggle/Toggle";
import SuccessIcon from "../../../../assets/icons/successMark.svg";
import CloseIcon from "../../../../assets/icons/closeButton.svg";
import {
  manageApiConnection,
  updateManageApiConnection,
  deleteManageApiConnection,
  manageIsiChatConnection,
  updateAsaConnection,
  deleteAsaConnection,
  asaRegisterApi,
  getTokenForIsiChat,
  deleteIsiChatConnection,
  shopifyConnection,
  deleteShopifyConnection,
  updateShopifyConnection
} from "../api/serviceApis";
import { Toastr } from "../../../../components/Toastr/Toastr";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import CopyInput from "../../../../components/inputElements/Input/CopyInput";
import asaServerUrl from "../../../../data/AsaServerUrl";
import generatePassword from "../../../../utils/generatePassword";
import ServiceField from "./ServiceField";

export default function Index({ selectedService }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { websiteID, websitesList, createdBy } = useSelector(
    (state) => state.generalData
  );
  // ASA
  const [roomNumber, setRoomNumber] = useState(
    selectedService?.room_count || null
  );
  const [password, setPassword] = useState("");
  const userName = websitesList
    .find((ele) => ele.id === websiteID)
    ?.name.replace(/[^a-zA-Z0-9]/g, "");

  // ISI Chat
  const [token, setToken] = useState("");

  // Facebook
  const [facebookData, setFacebookData] = useState({
    accountId: selectedService?.integrated ? selectedService?.account_ids : "",
    accessToken: selectedService?.integrated
      ? `${selectedService?.credentials?.slice(
          0,
          4
        )}*****************${selectedService?.credentials?.slice(-4)}`
      : ""
  });

  // Instagram
  const [accessTokenForInsta, setAccessTokenForInsta] = useState("");

  // Google adds
  const [googleAds, setGoogleAds] = useState({
    developerToken: "",
    clientId: "",
    clientSecret: "",
    refreshToken: ""
  });

  // Bing ads
  const [bingAdsData, setBingAdsData] = useState({
    clientId: "",
    developerToken: "",
    refreshToken: ""
  });

  // Google analytics
  const [googleAnalytics, setGoogleAnalytics] = useState({
    credentials: "",
    propertyId: ""
  });

  // Linkdin
  const [linkdinData, setLinkdinData] = useState({
    accessToken: "",
    startDate: ""
  });

  // Tiktok
  const [tiktokData, setTiktokData] = useState({
    accessToken: "",
    advitiserId: ""
  });

  // Shopify
  const [shopifyData, setShopifyData] = useState({
    accountUrl: selectedService?.integrated ? selectedService?.account_url : "",
    accessToken: selectedService?.integrated
      ? `${selectedService?.credentials?.slice(
          0,
          4
        )}*****************${selectedService?.credentials?.slice(-4)}`
      : ""
  });

  // common fields
  const [isToggled, setIsToggled] = useState(
    selectedService?.integrated ? selectedService?.connection_enable : true
  );
  const [serviceIntegrated, setServiceIntegrated] = useState(
    selectedService?.integrated || false
  );
  const [apiResponse, setApiResponse] = useState(false);
  const [testConnection, setTestConnection] = useState(false);
  const [testConnectionMsg, setTestConnectionMsg] = useState(false);
  const [connectionErrorMsgFlag, setConnectionErrorMsgFlag] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");

  const [deleteConnection, setDeleteConnection] = useState(false);
  const [deleteConnectionMsg, setDeleteConnectionMsg] = useState(false);

  const [updateConnection, setUpdateConnection] = useState(false);
  const [updateConnectionMsg, setUpdateConnectionMsg] = useState(false);

  const [loaderForToken, setLoaderForToken] = useState(false);
  const [saveConnection, setSaveConnection] = useState(false);
  const [showAsaCredentials, setShowAsaCredentials] = useState(
    selectedService?.manual_integration
  );

  const handleGoogleAdsDeveloperToken = (value) => {
    setGoogleAds((prevState) => ({
      ...prevState,
      developerToken: value
    }));
  };
  const handleGoogleAdsClientId = (value) => {
    setGoogleAds((prevState) => ({
      ...prevState,
      clientId: value
    }));
  };
  const handleGoogleAdsClientSecret = (value) => {
    setGoogleAds((prevState) => ({
      ...prevState,
      clientSecret: value
    }));
  };
  const handleGoogleAdsRefreshToken = (value) => {
    setGoogleAds((prevState) => ({
      ...prevState,
      refreshToken: value
    }));
  };

  const handleBingAdsClientId = (value) => {
    setBingAdsData((prevState) => ({
      ...prevState,
      clientId: value
    }));
  };

  const handleBingAdsDeveloperToken = (value) => {
    setBingAdsData((prevState) => ({
      ...prevState,
      developerToken: value
    }));
  };

  const handleBingAdsRefreshToken = (value) => {
    setBingAdsData((prevState) => ({
      ...prevState,
      refreshToken: value
    }));
  };

  const handleClose = () => {
    setTestConnection(false);
    setSaveConnection(false);
    setTestConnectionMsg(false);
    setUpdateConnection(false);
    setUpdateConnectionMsg(false);
    setDeleteConnection(false);
    setDeleteConnectionMsg(false);
    setConnectionErrorMsg("");
    setConnectionErrorMsgFlag(false);
    navigate("/manage/api");
  };

  const handleRoomNumber = (value) => {
    setRoomNumber(value);
  };

  const handleGoogleAnalyticsCredentials = (value) => {
    setGoogleAnalytics((prevState) => ({
      ...prevState,
      credentials: value
    }));
  };

  const handleGoogleAnalyticsPropertyId = (value) => {
    setGoogleAnalytics((prevState) => ({
      ...prevState,
      propertyId: value
    }));
  };

  const handleFacebookAccountId = (value) => {
    setFacebookData((prevState) => ({
      ...prevState,
      accountId: value
    }));
  };
  const handleFacebookAccessToken = (value) => {
    setFacebookData((prevState) => ({
      ...prevState,
      accessToken: value
    }));
  };

  const handleAccesTokenForInsta = (value) => {
    setAccessTokenForInsta(value);
  };

  const handleTiktokAccesToken = (value) => {
    setTiktokData((prevState) => ({
      ...prevState,
      accessToken: value
    }));
  };

  const handleTiktokAdvitiserId = (value) => {
    setTiktokData((prevState) => ({
      ...prevState,
      advitiserId: value
    }));
  };

  const handleLinkdinAccesToken = (value) => {
    setLinkdinData((prevState) => ({
      ...prevState,
      accessToken: value
    }));
  };

  const handleLinkdinStartDate = (value) => {
    setLinkdinData((prevState) => ({
      ...prevState,
      startDate: value
    }));
  };

  const handleGenerateToken = async () => {
    setLoaderForToken(true);
    try {
      const response = await getTokenForIsiChat({
        websiteId: websiteID,
        customer_id: createdBy,
        company_type_id: selectedService?.company_type_id,
        api_service_id: selectedService?.api_service_id
      });
      if (response.data.status === "ok") {
        setToken(response.data.token);
        setLoaderForToken(false);
        setApiResponse(true);
      }
    } catch (error) {
      setLoaderForToken(false);
    }
  };

  const handleGenerateCred = () => {
    setPassword(generatePassword(24));
    setShowAsaCredentials(true);
  };

  const handleShopifyAccountUrl = (value) => {
    setShopifyData((prevState) => ({
      ...prevState,
      accountUrl: value
    }));
  };

  const handleShopifyAccesToken = (value) => {
    setShopifyData((prevState) => ({
      ...prevState,
      accessToken: value
    }));
  };

  const selectedServiceFields = () => {
    switch (selectedService?.api_service_id) {
      case 1:
        return (
          <>
            <ServiceField
              label={t("ManageApi.clientId")}
              value={bingAdsData.clientId}
              onChange={(e) => handleBingAdsClientId(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.refreshToken")}
              value={bingAdsData.refreshToken}
              onChange={(e) => handleBingAdsRefreshToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.developerToken")}
              value={bingAdsData.developerToken}
              onChange={(e) => handleBingAdsDeveloperToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 3:
        return (
          <>
            <ServiceField
              label={t("ManageApi.roomCount")}
              value={roomNumber}
              onChange={(e) => handleRoomNumber(e.target.value)}
              isDisabled={apiResponse}
              type="number"
            />
            {!selectedService?.integrated &&
              !selectedService?.manual_integration &&
              !password && (
                <ButtonDark
                  buttonText={t("ManageApi.createAPi.generateCred")}
                  onClick={handleGenerateCred}
                />
              )}
            {showAsaCredentials && (
              <>
                <CopyInput
                  type="text"
                  label={t("ManageApi.createAPi.serverUrl")}
                  value={asaServerUrl}
                  readOnlyCondition
                />
                <CopyInput
                  type="text"
                  label={t("ManageApi.createAPi.user")}
                  value={userName}
                  readOnlyCondition
                />
                <CopyInput
                  type="text"
                  label={t("ManageApi.createAPi.password")}
                  value={password}
                  readOnlyCondition
                />
                {!selectedService?.integrated &&
                  selectedService?.manual_integration && (
                    <span
                      className="regenrate-password"
                      onClick={handleGenerateCred}
                    >
                      {t("ManageApi.editApi.regenaratePassword")}
                    </span>
                  )}
                <p className="note-text">
                  <span className="connection-text">
                    {t("userManagement.note")}
                  </span>
                  <span className="connection-text">
                    {t("ManageApi.asaNoteText")}
                  </span>
                </p>
              </>
            )}
          </>
        );
      case 4:
        return (
          <>
            <CopyInput
              type="text"
              label={t("ManageApi.token")}
              value={token}
              readOnlyCondition
            />
            {!selectedService?.integrated &&
              !selectedService?.manual_integration &&
              !loaderForToken && (
                <ButtonDark
                  buttonText={t("ManageApi.generateToken")}
                  onClick={handleGenerateToken}
                  disabled={token !== ""}
                />
              )}
            {selectedService?.manual_integration && !loaderForToken && (
              <span className="regenrate-token" onClick={handleGenerateToken}>
                {t("ManageApi.editApi.regenrateToken")}
              </span>
            )}
            {loaderForToken && (
              <div className="flex">
                <div>
                  <CircularProgress
                    sx={{ color: themeColors.primaryColorShade2 }}
                    size={20}
                    thickness={5}
                  />
                </div>
                <div className="connection-text-padding">
                  <p className="connection-text">
                    {t("ManageApi.generatingToken")}
                  </p>
                </div>
                <div className="flex-start">
                  <div className="dotted-progress-container">
                    <div className="dotted-progress" />
                  </div>
                </div>
              </div>
            )}
            <p className="note-text">
              <span className="connection-text">
                {t("userManagement.note")}
              </span>
              <span className="connection-text">
                {t("ManageApi.isiChatNoteText")}
              </span>
            </p>
          </>
        );
      case 5:
        return (
          <>
            <ServiceField
              label={t("ManageApi.developerToken")}
              value={googleAds.developerToken}
              onChange={(e) => handleGoogleAdsDeveloperToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <div className="half-column-grid" style={{ gap: "10px" }}>
              <ServiceField
                label={t("ManageApi.clientId")}
                value={googleAds.clientId}
                onChange={(e) => handleGoogleAdsClientId(e.target.value)}
                isDisabled={apiResponse || selectedService?.integrated}
              />
              <ServiceField
                label={t("ManageApi.clientSecret")}
                value={googleAds.clientSecret}
                onChange={(e) => handleGoogleAdsClientSecret(e.target.value)}
                isDisabled={apiResponse || selectedService?.integrated}
              />
            </div>
            <ServiceField
              label={t("ManageApi.refreshToken")}
              value={googleAds.refreshToken}
              onChange={(e) => handleGoogleAdsRefreshToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 6:
        return (
          <>
            <ServiceField
              label={t("ManageApi.credentials")}
              value={googleAnalytics.credentials}
              onChange={(e) => handleGoogleAnalyticsCredentials(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.propertyId")}
              value={googleAnalytics.propertyId}
              onChange={(e) => handleGoogleAnalyticsPropertyId(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 7:
        return (
          <>
            <ServiceField
              label={t("ManageApi.credentials")}
              value={googleAnalytics.credentials}
              onChange={(e) => handleGoogleAnalyticsCredentials(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.propertyId")}
              value={googleAnalytics.propertyId}
              onChange={(e) => handleGoogleAnalyticsPropertyId(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 8:
        return (
          <>
            <ServiceField
              label={t("ManageApi.accountId")}
              value={facebookData.accountId}
              onChange={(e) => handleFacebookAccountId(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
              type="number"
            />
            <ServiceField
              label={t("ManageApi.accessToken")}
              value={facebookData.accessToken}
              onChange={(e) => handleFacebookAccessToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 9:
        return (
          <ServiceField
            label={t("ManageApi.accessToken")}
            value={accessTokenForInsta}
            onChange={(e) => handleAccesTokenForInsta(e.target.value)}
            isDisabled={apiResponse || selectedService?.integrated}
          />
        );
      case 10:
        return (
          <>
            <ServiceField
              label={t("ManageApi.advertiserId")}
              value={tiktokData.advitiserId}
              onChange={(e) => handleTiktokAdvitiserId(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.accessToken")}
              value={tiktokData.accessToken}
              onChange={(e) => handleTiktokAccesToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 11:
        return (
          <>
            <ServiceField
              label={t("ManageApi.accessToken")}
              value={linkdinData.accessToken}
              onChange={(e) => handleLinkdinAccesToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.accessToken")}
              value={linkdinData.startDate}
              onChange={(e) => handleLinkdinStartDate(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      case 14:
        return (
          <>
            <ServiceField
              label={t("ManageApi.accountUrl")}
              value={shopifyData.accountUrl}
              onChange={(e) => handleShopifyAccountUrl(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
            <ServiceField
              label={t("ManageApi.accessToken")}
              value={shopifyData.accessToken}
              onChange={(e) => handleShopifyAccesToken(e.target.value)}
              isDisabled={apiResponse || selectedService?.integrated}
            />
          </>
        );
      default:
        return null;
    }
  };

  const serviceConnection = async () => {
    if (apiResponse) setApiResponse(false);
    if (connectionErrorMsgFlag) setConnectionErrorMsg("");
    if (selectedService?.api_service_id === 3) {
      if (selectedService?.manual_integration) {
        if (
          password === "" &&
          selectedService?.room_count === Number(roomNumber)
        ) {
          Toastr.error(t("ManageApi.passwordText"));
          return;
        }
        setSaveConnection(true);
        const response = await updateAsaConnection({
          website_id: websiteID,
          company_type_id: selectedService?.company_type_id,
          api_service_id: selectedService?.api_service_id,
          room_count: Number(roomNumber),
          password,
          customer_id: createdBy
        });
        if (response?.data?.success) {
          setApiResponse(true);
          setSaveConnection(false);
        }
      } else {
        if (password === "") {
          Toastr.error(t("ManageApi.passwordText"));
          return;
        }
        setSaveConnection(true);
        const response = await asaRegisterApi({
          website_id: websiteID,
          company_type_id: selectedService?.company_type_id,
          api_service_id: selectedService?.api_service_id,
          room_count: Number(roomNumber),
          username: userName,
          password,
          customer_id: createdBy
        });
        if (response?.data?.success) {
          setApiResponse(true);
          setSaveConnection(false);
        }
      }
    }
    if (selectedService?.api_service_id === 4) {
      if (token === "") {
        Toastr.error(t("ManageApi.tokenText"));
        return;
      }
      setSaveConnection(true);
      try {
        const response = await manageIsiChatConnection({
          website_id: websiteID,
          company_type_id: selectedService?.company_type_id,
          api_service_id: selectedService?.api_service_id,
          isi_token: token,
          customer_id: createdBy
        });
        if (response?.data?.success) {
          setSaveConnection(false);
          setApiResponse(true);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
        setSaveConnection(false);
      }
    }

    if (selectedService?.api_service_id === 8) {
      if (facebookData.accountId === "") {
        Toastr.error(t("ManageApi.accountIdText"));
        return;
      }
      if (facebookData.accessToken === "") {
        Toastr.error(t("ManageApi.accessTokenText"));
        return;
      }
      const payload = {
        type: selectedService?.api_service_name,
        sourceDefinitionId: "e7778cfc-e97c-4458-9ecb-b4f2bba8946c",
        workspaceId: "72fcaaba-25fa-4963-90f3-fb7f6a6ba508",
        configParams: {
          account_ids: [facebookData.accountId],
          credentials: {
            auth_type: "Service",
            access_token: facebookData.accessToken
          }
        },
        name: selectedService?.api_service_name,
        scheduleType: "cron",
        cronExpression: "0 0 * * * ?",
        destinationId: "4ed89044-36b8-4027-b593-20506072b909",
        status: isToggled,
        website_id: websiteID,
        company_type_id: selectedService?.company_type_id,
        api_service_id: selectedService?.api_service_id,
        customer_id: createdBy
      };
      setTestConnection(true);
      try {
        const response = await manageApiConnection(payload);
        if (response?.data?.status) {
          setTestConnection(false);
          setTestConnectionMsg(true);
          setApiResponse(true);
        } else {
          setTestConnection(false);
          setConnectionErrorMsgFlag(true);
          setConnectionErrorMsg(response?.data?.error);
          setApiResponse(false);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
      }
    }
    if (selectedService?.api_service_id === 14) {
      if (shopifyData.accountUrl === "") {
        Toastr.error(t("ManageApi.accountUrlText"));
        return;
      }
      if (shopifyData.accessToken === "") {
        Toastr.error(t("ManageApi.accessTokenText"));
        return;
      }
      const payload = {
        customer_id: createdBy,
        website_id: websiteID,
        company_type_id: selectedService?.company_type_id,
        api_service_id: selectedService?.api_service_id,
        status: isToggled,
        workspaceId: "72fcaaba-25fa-4963-90f3-fb7f6a6ba508",
        sourceDefinitionId: "9da77001-af33-4bcd-be46-6252bf9342b9",
        api_password: shopifyData.accessToken,
        shop: shopifyData.accountUrl,
        name: "My Shopify Store",
        destinationId: "4ed89044-36b8-4027-b593-20506072b909"
      };
      setTestConnection(true);
      try {
        const response = await shopifyConnection(payload);
        if (response?.data?.success) {
          setTestConnection(false);
          setTestConnectionMsg(true);
          setApiResponse(true);
        } else {
          setTestConnection(false);
          setConnectionErrorMsgFlag(true);
          setConnectionErrorMsg(response?.data?.error);
          setApiResponse(false);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
      }
    }
  };

  const handleUpdateConnection = async () => {
    if (apiResponse) setApiResponse(false);
    if (connectionErrorMsgFlag) setConnectionErrorMsg("");
    if (selectedService?.api_service_id === 8) {
      const payload = {
        connectionId: selectedService?.connection_id,
        configuration: {
          status: isToggled,
          scheduleType: "cron",
          scheduleData: {
            cron: {
              cronExpression: "0 0 * * * ?",
              cronTimeZone: "UTC"
            }
          }
        },
        website_id: websiteID,
        api_service_id: selectedService?.api_service_id,
        customer_id: createdBy
      };

      try {
        setUpdateConnection(true);
        const resp = await updateManageApiConnection(payload);
        if (resp?.data?.status) {
          setUpdateConnection(false);
          setUpdateConnectionMsg(true);
          setServiceIntegrated(resp?.data?.data?.connection?.status);
          setApiResponse(true);
        } else {
          setUpdateConnection(false);
          setConnectionErrorMsgFlag(true);
          setConnectionErrorMsg(resp?.data?.error);
          setApiResponse(false);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
      }
    }
    if (selectedService?.api_service_id === 14) {
      const payload = {
        connectionId: selectedService?.connection_id,
        configuration: {
          status: isToggled,
          scheduleType: "cron",
          scheduleData: {
            cron: {
              cronExpression: "0 0 * * * ?",
              cronTimeZone: "UTC"
            }
          }
        },
        customer_id: createdBy,
        website_id: websiteID,
        api_service_id: selectedService?.api_service_id
      };
      try {
        setUpdateConnection(true);
        const resp = await updateShopifyConnection(payload);
        if (resp?.data?.success) {
          setUpdateConnection(false);
          setUpdateConnectionMsg(true);
          setServiceIntegrated(resp?.data?.data?.connection?.status);
          setApiResponse(true);
        } else {
          setUpdateConnection(false);
          setConnectionErrorMsgFlag(true);
          setConnectionErrorMsg(resp?.data?.error);
          setApiResponse(false);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
      }
    }
  };

  const handledeleteConnection = async () => {
    if (apiResponse) setApiResponse(false);
    if (connectionErrorMsgFlag) setConnectionErrorMsg("");
    setDeleteConnection(true);
    if (selectedService?.api_service_id === 3) {
      const resp = await deleteAsaConnection({
        website_id: websiteID,
        company_type_id: selectedService?.company_type_id,
        api_service_id: selectedService?.api_service_id,
        customer_id: createdBy
      });
      if (resp?.data?.success) {
        setDeleteConnection(false);
        setDeleteConnectionMsg(true);
        setServiceIntegrated(resp?.data?.success);
        setApiResponse(true);
        setRoomNumber("");
        setShowAsaCredentials(false);
      } else {
        setDeleteConnection(false);
        setConnectionErrorMsgFlag(true);
        setConnectionErrorMsg(resp?.data?.error);
        setApiResponse(false);
      }
    }
    if (selectedService?.api_service_id === 4) {
      const resp = await deleteIsiChatConnection({
        website_id: websiteID,
        company_type_id: selectedService?.company_type_id,
        api_service_id: selectedService?.api_service_id,
        customer_id: createdBy
      });
      if (resp?.data?.success) {
        setDeleteConnection(false);
        setDeleteConnectionMsg(true);
        setServiceIntegrated(resp?.data?.success);
        setApiResponse(true);
      } else {
        setDeleteConnection(false);
        setConnectionErrorMsgFlag(true);
        setConnectionErrorMsg(resp?.data?.error);
        setApiResponse(false);
      }
    }
    if (selectedService?.api_service_id === 8) {
      const payload = {
        connectionId: selectedService?.connection_id,
        website_id: websiteID,
        api_service_id: selectedService?.api_service_id,
        customer_id: createdBy
      };
      try {
        const resp = await deleteManageApiConnection(payload);
        if (resp?.data?.status) {
          setDeleteConnection(false);
          setDeleteConnectionMsg(true);
          setServiceIntegrated(resp?.data?.status);
          setApiResponse(true);
          setFacebookData({
            accountId: "",
            accessToken: ""
          });
          setIsToggled(false);
        } else {
          setDeleteConnection(false);
          setConnectionErrorMsgFlag(true);
          setConnectionErrorMsg(resp?.data?.error);
          setApiResponse(false);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
      }
    }
    if (selectedService?.api_service_id === 14) {
      const payload = {
        connectionId: selectedService?.connection_id,
        website_id: websiteID,
        api_service_id: selectedService?.api_service_id,
        customer_id: createdBy
      };
      try {
        const resp = await deleteShopifyConnection(payload);
        if (resp?.data?.success) {
          setDeleteConnection(false);
          setDeleteConnectionMsg(true);
          setServiceIntegrated(resp?.data?.success);
          setApiResponse(true);
          setShopifyData({
            accountUrl: "",
            accessToken: ""
          });
          setIsToggled(false);
        } else {
          setDeleteConnection(false);
          setConnectionErrorMsgFlag(true);
          setConnectionErrorMsg(resp?.data?.error);
          setApiResponse(false);
        }
      } catch (error) {
        console.log("error", error);
        Toastr.error(t("ManageApi.wrongMessage"));
      }
    }
  };

  return (
    <div className="flex-vertical-between full-height">
      <div>
        <div className="heading3 head-padding">
          {selectedService?.api_service_name}
        </div>
        <div className="grid-gap">
          {!deleteConnectionMsg && selectedServiceFields()}
          {![3, 4].includes(selectedService?.api_service_id) &&
            !deleteConnectionMsg && (
              <div>
                <Toggle
                  isToggled={isToggled}
                  setIsToggled={setIsToggled}
                  label={t("ManageApi.enableConnection")}
                />
              </div>
            )}
          {(testConnection ||
            updateConnection ||
            deleteConnection ||
            saveConnection) && (
            <div className="flex">
              <div>
                <CircularProgress
                  sx={{ color: themeColors.primaryColorShade2 }}
                  size={20}
                  thickness={5}
                />
              </div>
              <div className="connection-text-padding">
                <p className="connection-text">
                  {testConnection && t("ManageApi.testingConnection")}
                  {updateConnection && t("ManageApi.updatingConnection")}
                  {deleteConnection && t("ManageApi.deletingConnection")}
                  {saveConnection && t("ManageApi.savingDetails")}
                </p>
              </div>
              <div className="flex-start">
                <div className="dotted-progress-container">
                  <div className="dotted-progress" />
                </div>
              </div>
            </div>
          )}
          {(testConnectionMsg || updateConnectionMsg) && (
            <div className="flex">
              <div>
                <img
                  src={SuccessIcon}
                  alt="close-button"
                  className="close-button img-style"
                />
              </div>
              <div className="left-padding">
                <p className="connection-text">
                  {testConnectionMsg && t("ManageApi.connectionSuccessful")}
                  {updateConnectionMsg && t("ManageApi.connectionUpdated")}
                </p>
              </div>
            </div>
          )}
          {deleteConnectionMsg && (
            <div className="flex" style={{ marginTop: "1rem" }}>
              <div>
                <img
                  src={SuccessIcon}
                  alt="close-button"
                  className="close-button img-style"
                />
              </div>
              <div className="left-padding">
                <p className="connection-text">
                  {deleteConnectionMsg && (
                    <>
                      {t("ManageApi.connectionDeleted")}{" "}
                      <span
                        className="regenrate-token"
                        onClick={() => navigate("/manage/api")}
                      >
                        {t("isiChatDashboard.clickHere")}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          )}
          {connectionErrorMsgFlag && !testConnection && !testConnectionMsg && (
            <div className="flex">
              <div>
                <img
                  src={CloseIcon}
                  alt="close-button"
                  className="close-button img-style"
                />
              </div>
              <div className="left-padding">
                <p className="connection-text nagative-status">
                  {connectionErrorMsg}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {!apiResponse && (
          <div className="flex-center button-gap">
            {!serviceIntegrated &&
              !(testConnection || saveConnection) &&
              !deleteConnection &&
              selectedService?.api_service_id !== 4 && (
                <>
                  {selectedService?.api_service_id === 3 &&
                  selectedService?.manual_integration ? (
                    <ButtonDark
                      buttonText={t("common.confirmation.delete")}
                      onClick={() => handledeleteConnection()}
                    />
                  ) : (
                    <ButtonLight
                      buttonText={t("common.confirmation.cancel")}
                      onClick={() => handleClose()}
                    />
                  )}
                  <ButtonDark
                    buttonText={
                      [3, 4].includes(selectedService?.api_service_id)
                        ? t("common.saveButton")
                        : t("ManageApi.testAndSave")
                    }
                    onClick={() => serviceConnection()}
                  />
                </>
              )}

            {selectedService?.api_service_id === 4 &&
              selectedService?.manual_integration &&
              !deleteConnection && (
                <>
                  <ButtonLight
                    buttonText={t("common.confirmation.cancel")}
                    onClick={() => handleClose()}
                  />
                  <ButtonDark
                    buttonText={t("common.confirmation.delete")}
                    onClick={() => handledeleteConnection()}
                  />
                </>
              )}

            {serviceIntegrated &&
              selectedService?.api_service_id !== 4 &&
              !deleteConnection &&
              !updateConnection && (
                <>
                  <ButtonDark
                    buttonText={t("common.confirmation.delete")}
                    onClick={() => handledeleteConnection()}
                  />
                  <ButtonDark
                    buttonText={t("common.confirmation.update")}
                    onClick={() => handleUpdateConnection()}
                  />
                </>
              )}
          </div>
        )}
        {apiResponse && (
          <div className="flex-center">
            <ButtonLight
              buttonText={t("subscription.billing.ok")}
              onClick={() => navigate("/manage/api")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

Index.propTypes = {
  selectedService: PropTypes.oneOfType([PropTypes.object]).isRequired
};
