import PropTypes from "prop-types";
import PlanTile from "./PlanTile";

export default function PlanList({
  plansList,
  handlePlanClick,
  currentPlanId
}) {
  return plansList.map((ele) => (
    <PlanTile
      currentPlanId={currentPlanId}
      showInList
      plan={ele}
      handlePlanClick={handlePlanClick}
    />
  ));
}

PlanList.propTypes = {
  plansList: PropTypes.oneOfType([PropTypes.array]),
  handlePlanClick: PropTypes.func
};
