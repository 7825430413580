/* eslint-disable */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/tableStyles.scss";

export default function TableComponent({
  labels,
  labelHeaders,
  columnData,
  columns,
  numBars
}) {
  return (
    <div className="half-column-grid-30-70" style={{ minHeight: "270px" }}>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "98%",
          overflow: "auto",
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  borderBottom: "none",
                  fontWeight: 600,
                  lineHeight: "1rem",
                  fontSize: "12px",
                  fontFamily: themeColors.fontQuickSand,
                  color: themeColors.fourthText,
                  padding: "10px 10px 0 10px"
                }
              }}
            >
              {labelHeaders.map((label) => (
                <TableCell key={uuidv4()}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {labels?.slice(0, numBars).map((sources) => (
              <TableRow
                key={uuidv4()}
                sx={{
                  "& td, & th": {
                    borderBottom: "none",
                    fontSize: "14px",
                    fontFamily: themeColors.fontQuickSand,
                    color: themeColors.primaryText,
                    fontWeight: 500,
                    lineHeight: "1.175rem",
                    padding: "10px"
                  }
                }}
              >
                {sources?.channel ? (
                  <TableCell component="th" scope="row">
                    {sources?.channel}
                  </TableCell>
                ) : sources?.campaignName ? (
                  <TableCell component="th" scope="row">
                    {sources?.campaignName}
                  </TableCell>
                ) : (
                  <TableCell component="th" scope="row">
                    {sources?.campaignType}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        className="scrollable-container"
        component={Paper}
        sx={{
          maxWidth: "98%",
          overflow: "auto",
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  borderBottom: "none",
                  fontWeight: 600,
                  lineHeight: "1rem",
                  fontSize: "12px",
                  fontFamily: themeColors.fontQuickSand,
                  color: themeColors.fourthText,
                  padding: "10px 10px 0 10px",
                  whiteSpace: "nowrap" // Prevent text wrapping
                }
              }}
            >
              {columnData.map((row) => {
                return (
                  columns.includes(row.id) && (
                    <TableCell key={row.id} align="center">
                      {row.name}
                    </TableCell>
                  )
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {columnData[0]?.totalSpend?.slice(0, numBars).map((_, index) => (
              <TableRow
                key={uuidv4()}
                sx={{
                  "& td, & th": {
                    borderBottom: "none",
                    fontSize: "14px",
                    fontFamily: themeColors.fontQuickSand,
                    color: themeColors.primaryText,
                    fontWeight: 500,
                    lineHeight: "1.175rem",
                    padding: "10px",
                    minWidth: "100px"
                  }
                }}
              >
                {columnData.map((row) => {
                  return (
                    columns?.includes(row.id) && (
                      <TableCell key={uuidv4()} align="center">
                        {row?.totalSpend[index]}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

TableComponent.propTypes = {
  columnData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  labelHeaders: PropTypes.oneOfType([PropTypes.array]).isRequired,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  numBars: PropTypes.number
};
