import { createSlice } from "@reduxjs/toolkit";
import { countryISO } from "../../data/countryData";
import storage from "../../utils/storage";
import { formatDateForAPI } from "../../utils/dataFilter";

const storedDateRange = JSON.parse(storage.getItem("dateRange"));
const storedRole = JSON.parse(storage.getItem("role"));
const storedCustomerId = JSON.parse(storage.getItem("customerId"));
const storedCreatedBy = JSON.parse(storage.getItem("createdBy"));
const storedCompareDateRange = JSON.parse(storage.getItem("compareDateRange"));
const storedCompare = JSON.parse(storage.getItem("compare"));
const storedWebsiteID = JSON.parse(storage.getItem("websiteId"));
const storedWebsitesList = JSON.parse(storage.getItem("websitesList"));
const storedUserEmail = storage.getItem("email");
const storedDefWeb = storage.getItem("defaultWeb");
let selectedWeb = null;
if (storedDefWeb) {
  selectedWeb = storedWebsitesList.find((ele) => ele.id === storedDefWeb);
}
// initial State
const initialState = {
  dateRange: storedDateRange
    ? { ...storedDateRange }
    : {
        startDate: formatDateForAPI(
          new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
        ),
        endDate: formatDateForAPI(new Date())
      },
  compareDateRange: storedCompareDateRange
    ? { ...storedCompareDateRange }
    : {
        startDate: formatDateForAPI(
          new Date(Date.now() - 13 * 24 * 60 * 60 * 1000)
        ),
        endDate: formatDateForAPI(
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        ),
        compareStartDate: formatDateForAPI(
          new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
        ),
        compareEndDate: formatDateForAPI(new Date())
      },
  countryISO,
  scrollArchive: false,
  selectedFilters: {},
  selectedPMSFilters: {},
  selectedPersonasFilters: {},
  selectedISIFilters: {},
  selectedCampPerfomanceFilters: {},
  selectedEcommerceFilters: {},
  timeType: "days",
  isCompare: storedCompare ?? false,
  websitesList: storedWebsitesList ?? [],
  websiteID: storedDefWeb ?? storedWebsiteID,
  defaultWebsite: storedDefWeb ?? "",
  userEmail: storedUserEmail ?? "",
  role: selectedWeb ? selectedWeb.role_id : storedRole,
  customerId: storedCustomerId ?? "",
  createdBy: selectedWeb
    ? selectedWeb.created_by ?? storedCustomerId
    : storedCreatedBy,
  toggleState: false,
  dashBoardLink: "/analytics"
};

const generalSlice = createSlice({
  name: "generalData",
  initialState,
  reducers: {
    updateDateRange: (state, actions) => {
      return { ...state, dateRange: actions.payload };
    },
    updateCompareDateRange: (state, actions) => {
      return { ...state, compareDateRange: actions.payload };
    },
    updateSelectedFilters: (state, actions) => {
      return {
        ...state,
        selectedFilters: actions.payload
      };
    },
    updateSelectedPMSFilters: (state, actions) => {
      return {
        ...state,
        selectedPMSFilters: actions.payload
      };
    },
    updateSelectedPersonasFilters: (state, actions) => {
      return {
        ...state,
        selectedPersonasFilters: actions.payload
      };
    },
    updateSelectedISIFilters: (state, actions) => {
      return {
        ...state,
        selectedISIFilters: actions.payload
      };
    },
    updateSelectedCampPerfomanceFilters: (state, action) => {
      return {
        ...state,
        selectedCampPerfomanceFilters: action.payload
      };
    },
    updateSelectedEcommerceFilters: (state, action) => {
      return {
        ...state,
        selectedEcommerceFilters: action.payload
      };
    },
    updateTimeType: (state, actions) => {
      return {
        ...state,
        timeType: actions.payload
      };
    },
    updateIsCompare: (state, actions) => {
      return { ...state, isCompare: actions.payload };
    },
    updateWebsitesList: (state, actions) => {
      return {
        ...state,
        websitesList: actions.payload
        // websiteID: storedWebsiteID ?? actions.payload[0].website_id
      };
    },
    updateWebsiteID: (state, actions) => {
      const roleId = actions.payload.role_id;
      const websiteID = actions.payload.website_id;
      const createdBy = actions.payload.created_by ?? storedCustomerId;

      return { ...state, role: roleId, websiteID, createdBy };
    },
    updateCustomerId: (state, actions) => {
      return { ...state, customerId: actions.payload };
    },
    updateDefWebsite: (state, actions) => {
      return { ...state, defaultWebsite: actions.payload };
    },
    updateUserEmail: (state, actions) => {
      return { ...state, userEmail: actions.payload };
    },
    updateScrollArchive: (state, actions) => {
      return { ...state, scrollArchive: actions.payload };
    },
    updateToggleState: (state, actions) => {
      return { ...state, toggleState: actions.payload };
    },
    updateDashboardLink: (state, actions) => {
      return { ...state, dashBoardLink: actions.payload };
    }
  }
});

export const {
  updateDateRange,
  updateTimeType,
  updateCompareDateRange,
  updateSelectedFilters,
  updateSelectedPMSFilters,
  updateSelectedPersonasFilters,
  updateSelectedISIFilters,
  updateSelectedCampPerfomanceFilters,
  updateSelectedEcommerceFilters,
  updateIsCompare,
  updateWebsitesList,
  updateWebsiteID,
  updateDefWebsite,
  updateUserEmail,
  updateScrollArchive,
  updateToggleState,
  updateDashboardLink,
  updateCustomerId
} = generalSlice.actions;

export default generalSlice.reducer;
