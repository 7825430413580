/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import themeColors from "../../../assets/stylesheets/_var.scss";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import NoDataFound from "../../Placeholders/NoDataFound";
import StackedChart from "../../../components/ChartComponents/StackedChart";
import PersonaLabel from "../../../components/PersonaLabel";
import QuestionsComponent from "../DashboardLandingPage/Questions";
import Insights from "../../../components/Insights";
import MultiBarChart from "../../../components/ChartComponents/MultiBarChart";
import SubscribeIcon from "../../../assets/icons/subscribe.svg";
import MainComponent from "./MainComponent";
import CollectingData from "../../../assets/icons/collectingData.svg";

export default function GeneralDashboard({
  insightsTitle,
  isCompare,
  selectedISIFilters,
  dashboard,
  handlePagination,
  pageNumber,
  updateSelectedFilter,
  websiteID
}) {
  const { t } = useTranslation();
  const {
    allData,
    insights,
    multiBarChartData,
    questions,
    pageCountOfQuestions,
    loadingQuestions,
    personaData,
    categoriesData,
    filteredData,
    showPersonaData,
    userRoleId
  } = useSelector((state) => state.isiChatData);

  const commonProps = {
    isCompare,
    dashboard,
    filterValues: selectedISIFilters
  };

  const getPersonaDescription = () => {
    if (selectedISIFilters?.[t("dashboardMenu.personas")]?.length > 1) {
      return t("isiChatDashboard.personaDescriptionForTwo");
    }
    if (selectedISIFilters?.[t("dashboardMenu.personas")]?.length === 1) {
      const data = filteredData.find(
        (item) =>
          item?.personaLabel ===
          selectedISIFilters?.[t("dashboardMenu.personas")][0]
      );
      return data?.personaDescription;
    }
    return t("analyticsPage.personaDescriptionEmptyDetail");
  };

  console.log("allData====>", allData, "websiteID====>", websiteID);

  if (allData.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
        />
        <MultiBarChart
          title={t("analyticsPage.visitors")}
          filterTitle={t("common.date")}
          dashboard={dashboard}
          labels={multiBarChartData?.labels}
          data1={{
            dataset: multiBarChartData?.visitors,
            label: t("analyticsPage.visitors")
          }}
          data2={{
            dataset: multiBarChartData?.sessions,
            label: t("dashboardMenu.sessions")
          }}
          data3={{
            dataset: multiBarChartData?.questions,
            label: t("isiChatDashboard.questions")
          }}
          multiBarChart
          {...commonProps}
        />
        <div className="position-relative">
          {!showPersonaData && (
            <div className="illustration-background">
              {[1, 7].includes(userRoleId) ? (
                <>
                  <div
                    className="modules-heading"
                    style={{ color: themeColors.background, marginTop: "20px" }}
                  >
                    {t("isiChatDashboard.integrateYodalyticsMessage")}
                  </div>
                  <MainComponent websiteID={websiteID} />
                </>
              ) : (
                <div
                  className="modules-heading"
                  style={{ color: themeColors.background, marginTop: "20px" }}
                >
                  {t("isiChatDashboard.integrateReaderMessage")}
                </div>
              )}
              <img
                src={SubscribeIcon}
                alt="subscribe-Icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "250px",
                  marginTop: "30px"
                }}
              />
            </div>
          )}
          {showPersonaData &&
            personaData?.labels?.length === 1 &&
            personaData?.labels?.includes(t("common.other")) && (
              <div className="illustration-background">
                <img
                  src={CollectingData}
                  alt="collecting-data-Icon"
                  className="illustration-icon"
                />
                <span className="illustration-heading">
                  {t("common.collectingYourData")}
                </span>
              </div>
            )}
          <div className="flex-column gap-10">
            <StackedChart
              title={t("dashboardMenu.personas")}
              visitors={personaData?.visitors}
              labels={personaData?.labels}
              selectedFilters={selectedISIFilters}
              updatedComponentFilters={updateSelectedFilter}
              dashboard={dashboard}
            />
            <PersonaLabel personaDescription={getPersonaDescription()} />
          </div>
        </div>
        <AddBarGraph
          loading={false}
          data={{
            tooltip: t("isiChatDashboard.questions"),
            values: categoriesData?.questions
          }}
          labels={categoriesData?.labels}
          title={t("isiChatDashboard.categories")}
          subtitle={t("isiChatDashboard.topics")}
          numBars={
            categoriesData?.labels?.length < 20
              ? categoriesData?.labels?.length
              : 20
          }
          columnData={[
            {
              id: 1,
              name: t("isiChatDashboard.asked"),
              diff: categoriesData?.questions,
              number: true
            }
          ]}
          {...commonProps}
        />
        <QuestionsComponent
          title={t("isiChatDashboard.qestions_asked")}
          isCompare={isCompare}
          questions={questions}
          pageCountOfQuestions={pageCountOfQuestions}
          loadingQuestions={loadingQuestions}
          handlePagination={handlePagination}
          pageNumber={pageNumber}
        />
      </>
    );

  return <NoDataFound />;
}

GeneralDashboard.propTypes = {
  insightsTitle: PropTypes.oneOfType([Object]),
  isCompare: PropTypes.bool,
  selectedISIFilters: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  handlePagination: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  updateSelectedFilter: PropTypes.func,
  websiteID: PropTypes.number
};
