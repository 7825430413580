/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import themeColors from "../../../assets/stylesheets/_var.scss";

export default function MainComponent({ websiteID }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="heading4" style={{ color: themeColors.background }}>
      <p>
        <button
          onClick={() =>
            navigate("/mySites", {
              state: { showPopUp: true, websiteId: websiteID }
            })
          }
          type="button"
          style={{
            background: "none",
            border: "none",
            color: themeColors.background,
            cursor: "pointer",
            textDecoration: "underline"
          }}
        >
          {t("isiChatDashboard.clickHere")}
        </button>
        {t("isiChatDashboard.toProceed")}
      </p>
    </div>
  );
}
