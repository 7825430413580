/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../../components/inputElements/Input/Input";
import {
  ButtonDark,
  ButtonLight
} from "../../../components/inputElements/buttons/MainButton";
import Copy from "../assets/icons/copy.svg";
import { Toastr } from "../../../components/Toastr/Toastr";
import "../assets/stylesheets/myWebsites.scss";
import DropdownComponent from "../../../components/inputElements/selectInput/DropdownComponent";

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function AddWebsite({
  addNewWebsite,
  getWebsiteTrackToken,
  handleCloseModal,
  companyTypes,
  collectData
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      type: null,
      url: "",
      name: ""
    }
  });

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [websiteId, setWebsiteId] = useState(null);
  const [industryType, setIndustryType] = useState(undefined);
  const [industryTypeError, setIndustryTypeError] = useState(false);
  const snippet = token
    ? `<script crossorigin="anonymous" defer="defer" type="application/javascript" src="${process.env.REACT_APP_SNIPPET_URL}snipped/getSnipped/${token}"></script>`
    : "";

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"; // Reset the height to auto
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Set height to scrollHeight
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height initially
  }, [snippet]);

  const generateCode = async (data) => {
    if (industryType === undefined) {
      setIndustryTypeError(true);
      return;
    }
    setLoading(true);
    const res = await addNewWebsite(data.url, data.name, industryType);
    setWebsiteId(res.website_id);
    setToken(await getWebsiteTrackToken(res.website_id));
    setLoading(false);
  };

  const collectWebsiteData = async () => {
    setLoading(true);
    if (await collectData(websiteId)) {
      handleCloseModal();
    }

    setLoading(false);
  };

  const copyValue = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(snippet)
        .then(() => {
          Toastr.success(t("addScript.copiedToClipboard"));
        })
        .catch((error) => {
          Toastr.error(t("addScript.failedToCopy"), error);
        });
    }
  };

  const newcompanyTypes = companyTypes?.map((type, index) => ({
    label: type?.name,
    value: type?.id
  }));

  return (
    <form
      onSubmit={handleSubmit(token ? collectWebsiteData : generateCode)}
      className="flex-vertical-between"
    >
      <div className="grid">
        <LabelInputSet>
          <label htmlFor="industry-type" className="label-tag">
            {t("addWebsite.selectIndustryLabel")}
            <span className="asterisk">*</span>
          </label>
          {/* <SelectInput
            name="industry-type"
            register={register("type", { required: true })}
            options={
              companyTypes &&
              companyTypes?.map((ele) => {
                return { label: ele.name, value: ele.id };
              })
            }
          /> */}
          <DropdownComponent
            name="industry-type"
            value={industryType || t("tagManager.addTagForm.selectPlaceholder")}
            onChange={(e) => {
              setIndustryType(e?.target?.value);
              setIndustryTypeError(false);
            }}
            register={register}
            options={newcompanyTypes}
            isRequired={true}
            defaultValue={t("tagManager.addTagForm.selectPlaceholder")}
            error={industryTypeError && t("addWebsite.selectIndustryType")}
          />
        </LabelInputSet>
        <LabelInputSet>
          <label htmlFor="url" className="label-tag">
            {t("addWebsite.websiteLink")}
            <span className="asterisk">*</span>
          </label>
          <Input
            id="url"
            type="text"
            name="url"
            register={register("url", {
              required: true,
              validate: (value) =>
                value.startsWith("https://") ||
                t("addWebsite.websiteLinkStartsWith")
            })}
            error={errors?.url?.message}
          />
        </LabelInputSet>

        <LabelInputSet>
          <label htmlFor="name" className="label-tag">
            {t("addWebsite.websiteName")}
            <span className="asterisk">*</span>
          </label>
          <Input
            id="name"
            type="text"
            name="name"
            register={register("name", { required: true })}
            error={errors.name && "**Website name cannot be empty"}
          />
          <label htmlFor="web-link" className="web-link flex-start">
            <input
              type="checkbox"
              id="web-link"
              name="website-link"
              onClick={(e) => {
                if (e.target.checked) setValue("name", getValues().url);
                else setValue("name", "");
              }}
            />
            {t("addWebsite.sameAsWebsiteLink")}
          </label>
        </LabelInputSet>
        {token ? (
          <LabelInputSet>
            <div className="flex-between">
              <div className="snippet">
                Javascript snippet
                <div className="helper-text">{`Include this snippet in the <head> of your website`}</div>
              </div>
              <div className="copy-icon flex-center">
                <img src={Copy} alt="copy-icon" onClick={copyValue} />
              </div>
            </div>
            <textarea
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: "rgb(142 160 59)",
                borderRadius: "10px",
                overflow: "hidden",
                resize: "none"
                // height: "221px"
              }}
              className="snippet-text"
              ref={inputRef}
              rows={snippet.length / 70}
              // rows={1}
              value={snippet}
              readOnly
            />
          </LabelInputSet>
        ) : (
          <ButtonDark
            isSubmit
            buttonText={
              loading ? "Generating..." : t("addWebsite.generateCode")
            }
            className="tagManagerButton"
          />
        )}
      </div>
      <div style={{ marginTop: "1em" }} className="flex-center">
        <ButtonLight
          buttonText={t("common.confirmation.cancel")}
          onClick={() => handleCloseModal()}
        />
        <ButtonDark
          disabled={token ? 0 : 1}
          isSubmit
          buttonText={
            token && loading ? "Collecting..." : t("addWebsite.collectData")
          }
          className="tagManagerButton"
        />
      </div>
    </form>
  );
}

AddWebsite.propTypes = {
  addNewWebsite: PropTypes.func,
  getWebsiteTrackToken: PropTypes.func,
  handleCloseModal: PropTypes.func,
  companyTypes: PropTypes.oneOfType([Array]).isRequired,
  collectData: PropTypes.func
};

LabelInputSet.propTypes = {
  children: PropTypes.node
};
