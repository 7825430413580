/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import { ColorPicker, useColor } from "react-color-palette";
import "../assets/stylesheets/aiSettings.scss";
import AISettingsFieldComponent from "./AISettingsFieldComponent";
import AISettingsValueComponent from "./AISettingsValueComponent";
import ReactJsxParser from "react-jsx-parser";
import { translateText, changeSentiment } from "../api/editCampaignAPI";
import Toastr from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
export default function AISettingsModel(props) {
  const { close, field, handleClose, adTemplate, setAdTemplate, setParentKey } =
    props;

  const { t } = useTranslation();

  const { websiteID } = useSelector((state) => state.generalData);

  const jsxContainerRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("Translate");
  const [key, setKey] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");
  const [color, setColor] = useColor("#561ecb");

  const [templateTitle, setTemplateTitle] = useState({
    id: 0,
    heading: "Heading",
    title: "",
    fontFamily: "",
    fontSize: "",
    fontColor: "",
    errorMessage: "",
    errorStatus: false,
    status: false
  });
  const [templateContent, setTemplateContent] = useState({
    id: 1,
    heading: "Content",
    content: "",
    fontFamily: "",
    fontSize: "",
    fontColor: "",
    errorMessage: "",
    errorStatus: false,
    status: false
  });
  const [templateButton, setTemplateButton] = useState({
    id: 2,
    heading: "Call to action button",
    text: "",
    fontFamily: "",
    fontSize: "",
    fontColor: "",
    buttonColor: "",
    errorMessage: "",
    errorStatus: false,
    status: false
  });

  const inputFieldTexts = [templateTitle, templateContent, templateButton];

  const translateOptions = [
    {
      id: 1,
      lang: "English",
      text: t("manageCampaign.editDesignModel.English")
    },
    {
      id: 2,
      lang: "Italian",
      text: t("manageCampaign.editDesignModel.Italian")
    },
    {
      id: 3,
      lang: "German",
      text: t("manageCampaign.editDesignModel.German")
    }
  ];

  const sentimentOptions = [
    {
      id: 1,
      name: "Professional",
      text: t("manageCampaign.editDesignModel.Professional"),
      keywords: "Clear, concise, and authoritative."
    },
    {
      id: 2,
      name: "Friendly",
      text: t("manageCampaign.editDesignModel.Friendly"),
      keywords: "Warm, approachable, and conversational."
    },
    {
      id: 3,
      name: "Playful",
      text: t("manageCampaign.editDesignModel.Playful"),
      keywords: "Light-hearted, fun, and creative."
    },
    {
      id: 4,
      name: "Empathetic",
      text: t("manageCampaign.editDesignModel.Empathetic"),
      keywords: "Understanding, supportive, and compassionate."
    },
    {
      id: 5,
      name: "Confident",
      text: t("manageCampaign.editDesignModel.Confident"),
      keywords: "Assertive, strong, and self-assured"
    },
    {
      id: 6,
      name: "Persuasive",
      text: t("manageCampaign.editDesignModel.Persuasive"),
      keywords: "Assertive, strong, and self-assured"
    },
    {
      id: 7,
      name: "Excited",
      text: t("manageCampaign.editDesignModel.Excited"),
      keywords: "Enthusiastic, energetic, and positive"
    },
    {
      id: 8,
      name: "Urgent",
      text: t("manageCampaign.editDesignModel.Urgent"),
      keywords: "Time-sensitive, direct, and pressing"
    },
    {
      id: 9,
      name: "Informative",
      text: t("manageCampaign.editDesignModel.Informative"),
      keywords: "Detailed, factual, and educational"
    },
    {
      id: 10,
      name: "Inspirational",
      text: t("manageCampaign.editDesignModel.Inspirational"),
      keywords: "Motivational, encouraging, and optimistic."
    }
  ];

  const FontSize = [
    "10px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "28px"
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedValue("");
    if (field == 0) {
      handleInputChange("title", templateTitle.title);
    } else if (field == 1) {
      handleInputChange("content", templateContent.content);
    } else if (field == 2) {
      handleInputChange("text", templateButton.text);
    }
  };

  const handleValueClick = (tab, value) => {
    console.log("tab,value", tab, value);
    setSelectedValue(value);
    if (tab == "Font size") {
      handleInputChange("fontSize", value);
    } else if (tab == "Translate") {
      handleTranslateText(value);
    } else if (tab == "Sentiment") {
      handleChangeSentiment(value);
    }

    if (field == 0) {
      handleInputChange("title", templateTitle.title);
    } else if (field == 1) {
      handleInputChange("content", templateContent.content);
    } else if (field == 2) {
      handleInputChange("text", templateButton.text);
    }
  };

  // Function to save the rendered DOM as JSX-like string
  const saveJsxFromDom = () => {
    try {
      if (jsxContainerRef.current) {
        // Step 1: Extract the inner HTML
        let domHtml = jsxContainerRef.current.innerHTML;

        // Step 2: Convert style attributes to JSX-compatible format
        domHtml = domHtml.replace(/style="([^"]*)"/g, (_, styles) => {
          const jsxStyle = styles
            .split(";")
            .map((style) => {
              const [prop, value] = style.split(":");
              if (!prop) return "";
              console.log("prop", prop);
              const camelCasedProp = prop
                .trim()
                .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
              return `${camelCasedProp}: '${value.trim()}'`;
            })
            .filter(Boolean)
            .join(", ");
          return `style={{ ${jsxStyle} }}`;
        });

        // Step 3: Convert self-closing tags and boolean attributes to JSX format
        domHtml = domHtml
          .replace(/<([a-zA-Z]+)([^>]*)><\/\1>/g, "<$1$2 />") // Self-closing tags
          .replace(/(checked|disabled|selected)=""/g, "$1"); // Remove empty quotes for boolean attributes

        // Step 4: Display or save the converted JSX
        console.log("after editing ", domHtml); // You can store this in state or use it as needed

        setAdTemplate(domHtml);
        setParentKey((state) => state + 1);
        Toastr.success(t("manageCampaign.editDesignModel.templateSaved"));
      }
    } catch (error) {
      Toastr.error(t("manageCampaign.editDesignModel.templateEditFailed"));
    }
  };

  // Function to handle Input value change
  const handleInputChange = (ObjKey, data) => {
    if (field == 0) {
      const title = jsxContainerRef.current.querySelector("#title");
      setTemplateTitle((state) => {
        return { ...state, [ObjKey]: data, errorStatus: false };
      });
      setLoader(true);
    }

    if (field == 1) {
      const content = jsxContainerRef.current.querySelector("#content");
      setTemplateContent((state) => {
        return { ...state, [ObjKey]: data, errorStatus: false };
      });
      setLoader(true);
    }

    if (field == 2) {
      const button = jsxContainerRef.current.querySelector("#action-button");
      setTemplateButton((state) => {
        return { ...state, [ObjKey]: data, errorStatus: false };
      });
      setLoader(true);
    }
  };

  // Function to change the color of the Text
  const handleColorChange = (color, event) => {
    let rgb = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
    console.log("rgb", rgb);
    handleInputChange("fontColor", rgb);
  };

  // Function to translate text from one language to another

  const handleTranslateText = async (lang) => {
    try {
      setLoading(true);
      if (field == 0) {
        handleInputChange("title", templateTitle.title);
      } else if (field == 1) {
        handleInputChange("content", templateContent.content);
      } else if (field == 2) {
        handleInputChange("text", templateButton.text);
      }
      let inputText =
        field == 0
          ? templateTitle.title
          : field == 1
          ? templateContent.content
          : templateButton.text;
      const payload = { text: inputText, lang: lang, website_id: websiteID };
      const TranslatedText = await translateText(payload);

      console.log("TranslatedText", TranslatedText);

      if (TranslatedText.status == 200) {
        const new_text = TranslatedText.data.translatedText;
        if (field == 0) {
          handleInputChange("title", new_text);
        } else if (field == 1) {
          handleInputChange("content", new_text);
        } else if (field == 2) {
          handleInputChange("text", new_text);
        }
        setSelectedValue("");
        Toastr.success(t("manageCampaign.editDesignModel.translationSuccess"));
      } else {
        Toastr.error(t("manageCampaign.editDesignModel.translationFailed"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toastr.error(error.message);
    }
  };

  // Function to Change the sentiment of the text
  const handleChangeSentiment = async (name) => {
    try {
      setLoading(true);
      if (field == 0) {
        handleInputChange("title", templateTitle.title);
      } else if (field == 1) {
        handleInputChange("content", templateContent.content);
      } else if (field == 2) {
        handleInputChange("text", templateButton.text);
      }
      let sentiment = sentimentOptions.find((sent) => sent.name == name);
      let inputText =
        field == 0
          ? templateTitle.title
          : field == 1
          ? templateContent.content
          : templateButton.text;

      let max_words = field == 0 ? 15 : field == 1 ? 18 : 3;

      const payload = {
        text: inputText,
        sentiment: sentiment.name,
        keywords: sentiment.keywords,
        website_id: websiteID,
        max_words: max_words
      };
      const updatedText = await changeSentiment(payload);
      console.log("newText", updatedText);

      if (updatedText.status == 200) {
        const new_text = updatedText.data.updatedText;
        if (field == 0) {
          handleInputChange("title", new_text);
        } else if (field == 1) {
          handleInputChange("content", new_text);
        } else if (field == 2) {
          handleInputChange("text", new_text);
        }
        setSelectedValue("");
        Toastr.success(t("manageCampaign.editDesignModel.toneSucess"));
      } else {
        Toastr.error(t("manageCampaign.editDesignModel.toneFailed"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toastr.error(error.message);
    }
  };

  // UseEffect to update initial state of the input fields
  useEffect(() => {
    if (jsxContainerRef.current) {
      const title = jsxContainerRef.current.querySelector("#title");
      const content = jsxContainerRef.current.querySelector("#content");
      const button = jsxContainerRef.current.querySelector("#action-button");
      // console.log("jsxContainerRef.current", jsxContainerRef.current);
      // console.log("title,content,button", title, content, button);
      // console.log("adTemplate", adTemplate);
      setTemplateTitle((state) => {
        return {
          ...state,
          title: title.textContent.trim(),
          fontSize: title.style.fontSize,
          fontColor: title.style.color
        };
      });

      setTemplateContent((state) => {
        return {
          ...state,
          content: content.textContent.trim(),
          fontSize: content.style.fontSize,
          fontColor: content.style.color
        };
      });
      setTemplateButton((state) => {
        return {
          ...state,
          text: button.textContent.trim(),
          fontSize: button.style.fontSize,
          fontColor: button.style.color
        };
      });
    }
  }, [jsxContainerRef.current, adTemplate]);

  useEffect(() => {
    if (jsxContainerRef.current && templateTitle.title !== "") {
      const title = jsxContainerRef.current.querySelector("#title");
      title.textContent = templateTitle.title;
      title.style.fontSize = templateTitle.fontSize;
      title.style.color = templateTitle.fontColor;
    }
  }, [templateTitle]);

  useEffect(() => {
    if (jsxContainerRef.current && loader) {
      const content = jsxContainerRef.current.querySelector("#content");
      content.textContent = templateContent.content;
      content.style.fontSize = templateContent.fontSize;
      content.style.color = templateContent.fontColor;
    }
  }, [templateContent]);

  useEffect(() => {
    if (jsxContainerRef.current && loader) {
      const button = jsxContainerRef.current.querySelector("#action-button");
      button.textContent = templateButton.text;
      button.style.fontSize = templateButton.fontSize;
      button.style.color = templateButton.color;
    }
  }, [templateButton]);

  useEffect(() => {
    console.log("templateTitle", templateTitle);
  }, [templateTitle, templateContent, templateButton]);

  return (
    <DialogContent>
      <div className="ai-settings-div">
        <div className="ai-settings">
          {/* Header componet  */}
          <div className="generated-designs-margins flex-between">
            <div className="tab-container">
              <div className="modules-heading tabs">{t("settings.title")}</div>
            </div>

            <div className="close-icon">
              <img
                role="button"
                tabIndex={0}
                src={close}
                onClick={handleClose}
                className="close-icon-img"
              />
            </div>
          </div>
          {/* Header Component End  */}

          {/* Body Container  */}
          <div className="body-container">
            {/* Body Left Container  */}
            <div className="body-left-container">
              {/* Edit Field with Lable  */}
              {inputFieldTexts.map((inputData) => {
                if (inputData.id == field) {
                  return (
                    <div>
                      <div className="label-name">{inputData.heading}</div>
                      <div className="input-field-width-ai-model">
                        <input
                          type="text"
                          className="input-field-common input-placeholder"
                          style={{ width: "100%" }}
                          value={
                            field == 0
                              ? inputData.title
                              : field == 1
                              ? inputData.content
                              : inputData.text
                          }
                          onChange={(e) => {
                            let value = e.target.value;

                            if (field == 0) {
                              handleInputChange("title", value);
                            } else if (field == 1) {
                              handleInputChange("content", value);
                            } else if (field == 2) {
                              handleInputChange("text", value);
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {/* Edit field with lable End  */}

              {/* Options */}

              <div className="flex">
                <div className="options">
                  <AISettingsFieldComponent
                    option="Translate"
                    text={t("manageCampaign.editDesignModel.Translate")}
                    selectedOption={selectedOption}
                    onOptionClick={handleOptionClick}
                  />
                  <AISettingsFieldComponent
                    option="Change sentiment"
                    text={t("manageCampaign.editDesignModel.changeSentiment")}
                    selectedOption={selectedOption}
                    onOptionClick={handleOptionClick}
                  />
                  <AISettingsFieldComponent
                    option="Font size"
                    text={t("manageCampaign.editDesignModel.fontSize")}
                    selectedOption={selectedOption}
                    onOptionClick={handleOptionClick}
                  />
                  <AISettingsFieldComponent
                    option="Font color"
                    text={t("manageCampaign.editDesignModel.fontColor")}
                    selectedOption={selectedOption}
                    onOptionClick={handleOptionClick}
                  />
                </div>

                <div className="ai-settings-values-div">
                  <div className="flex-column-height">
                    <div>
                      {selectedOption === "Translate" ? (
                        <div>
                          {translateOptions?.map((option, index) => (
                            <React.Fragment key={option?.id}>
                              <AISettingsValueComponent
                                index={index}
                                option={option.lang}
                                text={option.text}
                                name={"Translate"}
                                isSelected={selectedValue == option.lang}
                                onOptionClick={handleValueClick}
                              />
                            </React.Fragment>
                          ))}
                          {selectedValue.length > 0 && (
                            <div className="button-container">
                              {loading ? (
                                <Loader text={"Generating text"} />
                              ) : // <ButtonDark
                              //   buttonText={t(
                              //     "manageCampaign.editDesignModel.Translate"
                              //   )}
                              //   onClick={() => {
                              //     handleTranslateText(selectedValue);
                              //   }}
                              // />
                              null}
                            </div>
                          )}
                        </div>
                      ) : selectedOption === "Change sentiment" ? (
                        <p className="ai-settings-value">
                          <div>
                            {sentimentOptions?.map((option, index) => (
                              <React.Fragment key={option?.id}>
                                <AISettingsValueComponent
                                  index={index}
                                  option={option?.name}
                                  text={option?.text}
                                  name={"Sentiment"}
                                  isSelected={selectedValue == option.name}
                                  onOptionClick={handleValueClick}
                                />
                              </React.Fragment>
                            ))}
                            {selectedValue.length > 0 && (
                              <div className="button-container">
                                {loading ? (
                                  <Loader text={"Generating text"} />
                                ) : // <ButtonDark
                                //   buttonText={t(
                                //     "manageCampaign.editDesignModel.UpdateTone"
                                //   )}
                                //   onClick={() => {
                                //     handleChangeSentiment(selectedValue);
                                //   }}
                                // />
                                null}
                              </div>
                            )}
                          </div>
                        </p>
                      ) : selectedOption === "Font size" ? (
                        FontSize?.map((option, index) => (
                          <React.Fragment key={index}>
                            <AISettingsValueComponent
                              index={index}
                              option={option}
                              text={option}
                              name={"Font size"}
                              isSelected={selectedValue == option}
                              onOptionClick={handleValueClick}
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        // <SketchPicker
                        //   color=""
                        //   onChangeComplete={handleColorChange}
                        // />
                        <ColorPicker
                          color={color}
                          hideInput={["hsv"]}
                          onChange={(e) => {
                            console.log("onColorChange", e);
                            handleColorChange(e);
                            setColor(e);
                          }}
                        />
                      )}

                      <hr className="horizontal-break" />
                    </div>
                    {/* <div className="horizontal-break-div">
                    <hr className="horizontal-break" />
                  </div> */}
                  </div>
                </div>
              </div>

              {/* Options end */}
            </div>

            {/* Body Left Container  End */}

            {/* Body Right Container */}
            <div className="body-right-container">
              <div>
                <p>{t("manageCampaign.preview")}</p>
              </div>
              <div ref={jsxContainerRef}>
                <ReactJsxParser
                  key={key}
                  jsx={adTemplate}
                  components={{}}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              </div>
            </div>
            {/* Body Right Container  End */}
          </div>
          {/* Body Container  End */}
        </div>

        {/* Button Component  */}
        <div className="flex-center buttons-div">
          <div className="cancel-button-padding-right">
            <ButtonLight
              buttonText={t("common.cancelButton")}
              onClick={handleClose}
            />
          </div>
          <ButtonDark
            buttonText={t("common.saveButton")}
            onClick={() => {
              saveJsxFromDom();
            }}
          />
        </div>
        {/* Button Component End  */}
      </div>
    </DialogContent>
  );
}
