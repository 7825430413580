import { createSlice } from "@reduxjs/toolkit";
import { getComparePMSData, getPMSData, getPMSBasedHeatmap } from "../apiSlice";
import storage from "../../utils/storage";

const storedHeatmap = storage.getItem("heatmap");

const initialState = {
  // initial State
  allData: [],
  filteredData: [],
  totalSpendData: [],
  heatmapData: [],
  heatMapDateRange: null,
  filteredTotalSpendData: [],
  insights: {
    requests: 0,
    bookings: 0,
    revenue: 0,
    roomReq: 0,
    roomBooking: 0,
    conversionRate: 0,
    revenuePerBooking: 0
  },
  visitorsLineData: {},
  personaData: { labels: [], views: [], visitors: [] },
  roomsData: {},
  channelsData: {},
  daysOfStayData: {},
  adPartnerData: {},
  adMediumData: {},
  adCampaignData: {},
  monthlyRequestData: {},
  monthlyBookingData: {},
  countriesData: {},
  ageData: {},
  spendData: {},
  genderData: {},
  avgPriceData: {},
  typeData: {},
  isLoading: true,
  isHeatmapLoading: false,
  selectedHeatMap: storedHeatmap
};

const dataPMSSlice = createSlice({
  name: "PMSData",
  initialState,
  reducers: {
    updateGraphData: (state) => {
      return { ...state, graphData: [40, 30, 60, 43, 89, 20, 38] };
    },
    updateIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateIsHeatmapLoading: (state) => {
      return { ...state, isHeatmapLoading: !state.isHeatmapLoading };
    },
    updateHeatMapData: (state, actions) => {
      return { ...state, heatmapData: [...actions.payload] };
    },
    updateFilteredData: (state, actions) => {
      return { ...state, filteredData: [...actions.payload] };
    },
    updateFilteredTotalSpendData: (state, actions) => {
      return { ...state, filteredTotalSpendData: [...actions.payload] };
    },
    updateInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    updateVisitorsLineData: (state, actions) => {
      return { ...state, visitorsLineData: { ...actions.payload } };
    },
    updatePersonaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    updateRoomsData: (state, actions) => {
      return { ...state, roomsData: { ...actions.payload } };
    },
    updateChannelsData: (state, actions) => {
      return { ...state, channelsData: { ...actions.payload } };
    },
    updateDaysOfStayData: (state, actions) => {
      return { ...state, daysOfStayData: { ...actions.payload } };
    },
    updateAdMediumData: (state, actions) => {
      return { ...state, adMediumData: { ...actions.payload } };
    },
    updateAdPartnerData: (state, actions) => {
      return { ...state, adPartnerData: { ...actions.payload } };
    },
    updateAdCampaignData: (state, actions) => {
      return { ...state, adCampaignData: { ...actions.payload } };
    },
    updateMonthlyRequestData: (state, actions) => {
      return { ...state, monthlyRequestData: { ...actions.payload } };
    },
    updateMonthlyBookingData: (state, actions) => {
      return { ...state, monthlyBookingData: { ...actions.payload } };
    },
    updateCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    updateAgeData: (state, actions) => {
      return { ...state, ageData: { ...actions.payload } };
    },
    updateSpendData: (state, actions) => {
      return { ...state, spendData: { ...actions.payload } };
    },
    updateGenderData: (state, actions) => {
      return { ...state, genderData: { ...actions.payload } };
    },
    updateAvgPriceData: (state, actions) => {
      return { ...state, avgPriceData: { ...actions.payload } };
    },
    updateTypeData: (state, actions) => {
      return { ...state, typeData: { ...actions.payload } };
    },
    updateSelectedHeatMap: (state, actions) => {
      return { ...state, selectedHeatMap: actions.payload };
    }
  },
  extraReducers: {
    [getPMSBasedHeatmap.pending]: (state) => {
      return { ...state, isHeatmapLoading: true };
    },
    [getPMSBasedHeatmap.fulfilled]: (state, action) => {
      return {
        ...state,
        isHeatmapLoading: false,
        isLoading: false,
        allData: [...action.payload.pmsData],
        heatMapDateRange: action.payload.dateRange,
        filteredData: []
      };
    },
    [getPMSBasedHeatmap.rejected]: (state) => {
      return { ...state, isHeatmapLoading: false };
    },
    [getPMSData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getPMSData.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        heatMapDateRange: null,
        allData: [...action.payload]
      };
    },
    [getPMSData.rejected]: (state) => {
      return { ...state, isLoading: false };
    },
    [getComparePMSData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getComparePMSData.fulfilled]: (state, action) => {
      return {
        ...initialState,
        isLoading: false,
        allData: [...action.payload[0]]
      };
    },
    [getComparePMSData.rejected]: (state) => {
      return { ...state, isLoading: false };
    }
  }
});

const dataToComparePMSSlice = createSlice({
  name: "PMSdataToCompare",
  initialState,
  reducers: {
    updateCompareIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateCompareFilteredData: (state, actions) => {
      return { ...state, filteredData: [...actions.payload] };
    },
    updateCompareFilteredTotalSpendData: (state, actions) => {
      return { ...state, filteredTotalSpendData: [...actions.payload] };
    },
    updateCompareInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    updateCompareVisitorsLineData: (state, actions) => {
      return { ...state, visitorsLineData: { ...actions.payload } };
    },
    updateComparePersonaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    updateCompareRoomsData: (state, actions) => {
      return { ...state, roomsData: { ...actions.payload } };
    },
    updateCompareChannelsData: (state, actions) => {
      return { ...state, channelsData: { ...actions.payload } };
    },
    updateCompareDaysOfStayData: (state, actions) => {
      return { ...state, daysOfStayData: { ...actions.payload } };
    },
    updateCompareAdMediumData: (state, actions) => {
      return { ...state, adMediumData: { ...actions.payload } };
    },
    updateCompareAdPartnerData: (state, actions) => {
      return { ...state, adPartnerData: { ...actions.payload } };
    },
    updateCompareAdCampaignData: (state, actions) => {
      return { ...state, adCampaignData: { ...actions.payload } };
    },
    updateCompareMonthlyRequestData: (state, actions) => {
      return { ...state, monthlyRequestData: { ...actions.payload } };
    },
    updateCompareMonthlyBookingData: (state, actions) => {
      return { ...state, monthlyBookingData: { ...actions.payload } };
    },
    updateCompareCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    updateCompareAgeData: (state, actions) => {
      return { ...state, ageData: { ...actions.payload } };
    },
    updateCompareSpendData: (state, actions) => {
      return { ...state, spendData: { ...actions.payload } };
    },
    updateCompareGenderData: (state, actions) => {
      return { ...state, genderData: { ...actions.payload } };
    },
    updateCompareAvgPriceData: (state, actions) => {
      return { ...state, avgPriceData: { ...actions.payload } };
    },
    updateCompareTypeData: (state, actions) => {
      return { ...state, typeData: { ...actions.payload } };
    }
  },
  extraReducers: {
    [getComparePMSData.pending]: (state) => {
      return { ...state, isLoading: true };
    },
    [getComparePMSData.fulfilled]: (state, action) => {
      return {
        ...initialState,
        isLoading: false,
        allData: [...action.payload[1]]
      };
    },
    [getComparePMSData.rejected]: (state) => {
      return { ...state, isLoading: false };
    }
  }
});

export const {
  updateGraphData,
  updateIsLoading,
  updateIsHeatmapLoading,
  updateSelectedHeatMap,
  updateHeatMapData,
  updateFilteredData,
  updateFilteredTotalSpendData,
  updateInsights,
  updateVisitorsLineData,
  updatePersonaData,
  updateRoomsData,
  updateChannelsData,
  updateDaysOfStayData,
  updateAdCampaignData,
  updateAdMediumData,
  updateAdPartnerData,
  updateMonthlyRequestData,
  updateMonthlyBookingData,
  updateCountriesData,
  updateAgeData,
  updateSpendData,
  updateGenderData,
  updateAvgPriceData,
  updateTypeData
} = dataPMSSlice.actions;

export const {
  updateCompareIsLoading,
  updateCompareFilteredData,
  updateCompareInsights,
  updateCompareVisitorsLineData,
  updateComparePersonaData,
  updateCompareRoomsData,
  updateCompareChannelsData,
  updateCompareDaysOfStayData,
  updateCompareAdCampaignData,
  updateCompareAdMediumData,
  updateCompareAdPartnerData,
  updateCompareMonthlyRequestData,
  updateCompareMonthlyBookingData,
  updateCompareCountriesData,
  updateCompareAgeData,
  updateCompareSpendData,
  updateCompareGenderData,
  updateCompareAvgPriceData,
  updateCompareTypeData
} = dataToComparePMSSlice.actions;

export default { dataPMSSlice, dataToComparePMSSlice };
