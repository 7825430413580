import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-date-range";
import PropTypes from "prop-types";
import Moment from "moment";
import { Toastr } from "../../../Toastr/Toastr";
import CalendarButtonCombination from "../CalendarButtonCombination/CalendarButtonCombination";
import customCalendar from "../../api/customCalendar";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../../assets/stylesheets/editCustomRange.scss";
import LocalStorage from "../../../../utils/LocalStorgae";
import localLong from "../../../../data/calendarTransalation";

export default function EditCustomRange({
  labelData,
  fetchLabelsList,
  setActiveCalendarComponent
}) {
  const [customEditRangeName, setCustomEditNameRange] = useState(
    labelData.label
  );
  const language = LocalStorage.getItem("selectedLanguage");
  const [startDay, startMonth, startYear] = labelData.start_date.split("-");
  const [endDay, endMonth, endYear] = labelData.end_date.split("-");
  const [editStartDate, setEditStartDate] = useState(
    new Date(`${startYear}-${startMonth}-${startDay}`)
  );
  const [editEndDate, setEditEndDate] = useState(
    new Date(`${endYear}-${endMonth}-${endDay}`)
  );
  const [customEditSelectedRange, setCustomEditSelectedRange] = useState([
    {
      startDate: editStartDate,
      endDate: editEndDate,
      key: "selection"
    }
  ]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const customEditHandleSelect = (ranges) => {
    setEditStartDate(ranges.selection.startDate);
    setEditEndDate(ranges.selection.endDate);
    setCustomEditSelectedRange([ranges.selection]);
  };

  const handleEditCustomRange = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await customCalendar.editCustomCalendar({
        startDate: Moment(editStartDate).format("DD-MM-YYYY"),
        endDate: Moment(editEndDate).format("DD-MM-YYYY"),
        label: customEditRangeName,
        calendarId: labelData.calendar_id,
        website_id: labelData.website_id
      });
      if (response.data.success === true) {
        Toastr.success(
          t("analyticsPage.calendar.custom.editCustom.updateSuccess")
        );
        setLoading(false);
        fetchLabelsList();
        setActiveCalendarComponent(1);
      }
    } catch (error) {
      setLoading(false);
      Toastr.error(t("analyticsPage.calendar.custom.editCustom.updateError"));
    }
  };
  const handleCancel = () => {
    setActiveCalendarComponent(3);
  };

  return (
    <div className="edit-custom-calendar add-custom-calendar-range">
      <input
        className="custom-label-input"
        type="text"
        placeholder={t("analyticsPage.calendar.custom.editCustom.placeholder")}
        value={customEditRangeName}
        onChange={(e) => setCustomEditNameRange(e.target.value)}
      />
      <DateRangePicker
        ranges={customEditSelectedRange}
        onChange={customEditHandleSelect}
        color={themeColors.primaryColor4}
        rangeColors={[themeColors.primaryColor2]}
        showSelectionPreview
        direction="horizontal"
        editableDateInputs
        dragSelectionEnabled
        showMonthAndYearPickers
        showDateDisplay
        showPreview
        months={2}
        locale={localLong[language]}
      />
      <div className="button-combo">
        <CalendarButtonCombination
          firstButtonText={t("common.cancelButton")}
          secondButtonText={t("common.saveButton")}
          onClickFirstButton={handleCancel}
          onClickSecondButton={handleEditCustomRange}
          loading={loading}
        />
      </div>
    </div>
  );
}

EditCustomRange.propTypes = {
  fetchLabelsList: PropTypes.func.isRequired,
  setActiveCalendarComponent: PropTypes.func.isRequired,
  labelData: PropTypes.shape({
    calendar_id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    customer_id: PropTypes.number.isRequired,
    website_id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    shared_calendar: PropTypes.number.isRequired
  }).isRequired
};
