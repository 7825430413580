/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import "./input.scss";

export default function Input({
  type,
  placeholder,
  value,
  onChange,
  name,
  register,
  disabled,
  label,
  error,
  onClick,
  onBlur,
  readOnlyCondition
}) {
  if (register)
    return (
      <div className="input-wrapper">
        {" "}
        {label && <label htmlFor={label}>{label}</label>}
        <input
          type={type}
          onClick={onClick}
          id={label}
          className={`common-input add-tag-input-label ${error && "error"} ${
            disabled && "disabled"
          }`}
          placeholder={placeholder}
          name={name}
          {...register}
          disabled={disabled}
        />
        {error && <p className="error-message">{error}</p>}
      </div>
    );
  return (
    <div className="input-wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <input
        type={type}
        id={label}
        className={`common-input add-tag-input-label ${error && "error"}  ${
          disabled && "disabled"
        }`}
        placeholder={placeholder}
        value={value}
        name={name}
        onClick={onClick}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={!!readOnlyCondition}
        min="0"
      />
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  readOnlyCondition: PropTypes.bool
};
