import axios from "../../../api/axios";

export const getWebsites = () => axios.get("customer/getWebsites");

export const pinWebsite = (payload) =>
  axios.post("customer/pinWebsite", payload);

export const unPinWebsite = (payload) =>
  axios.post("customer/unPinWebsite", payload);

export const updateWebsiteDetails = (payload) =>
  axios.post("customer/updateWebsiteDetails", payload);

export const addWebsiteApi = (payload) =>
  axios.post("setup/addWebsite", payload);

export const trackTokenAPI = (payload) =>
  axios.post("trackToken/getTrackToken", payload);

export const getCompanyType = () => axios.get("setup/getCompanyType");

export const startCollectingData = (payload) =>
  axios.post("trackToken/getCollectingDataFlag", payload);

export const modifyDefaultWebsite = (payload) =>
  axios.post("customer/updateDefaultWebsite", payload);

export const removeWebsite = (payload) =>
  axios.post("customer/deleteWebsite", payload);
