/* eslint-disable */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import Toastr from "../../../../components/Toastr/Toastr";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";
import { enablePrivacy, getPrivacySettings } from "../api/privacy";
import "../assets/stylesheets/privacy.scss";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import CommonContent from "./CommonContent";
import Loader from "../../../../components/Loader/Loader";

export default function Privacy() {
  const { t } = useTranslation();
  const { websiteID } = useSelector((state) => state.generalData);
  const [privacySettings, setPrivacySettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fingerPrintLoading, setFingerPrintLoading] = useState(false);
  const [trackingLoading, setTrackingLoading] = useState(false);
  const [fingerPrintEnable, setFingerPrintEnable] = useState(false);
  const [trackingEnable, setTrackingEnable] = useState(false);

  const getPrivacySettingsHandler = async () => {
    setLoading(true);
    try {
      const res = await getPrivacySettings(websiteID);
      setPrivacySettings(res?.data[0]);
    } catch (error) {
      console.error("Error:", error);
      Toastr.error("Failed to fetch settings");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivacySettingsHandler();
  }, [websiteID]);

  const handleFingerPrintPrivacy = async () => {
    setFingerPrintEnable(!fingerPrintEnable);
    setFingerPrintLoading(true);
    const payload = {
      website_id: websiteID,
      enable_finger_print: !fingerPrintEnable ? 1 : 0
    };
    console.log("payload", payload);
    try {
      const res = await enablePrivacy(payload);
      if (res.success === true) {
        Toastr.success(t("common.settingsSuccess"));
      }
    } catch (error) {
      console.error("Error:", error);
      Toastr.error("Failed to change settings");
    } finally {
      setFingerPrintLoading(false);
    }
  };

  const handleTrackingPrivacy = async () => {
    setTrackingEnable(!trackingEnable);
    setTrackingLoading(true);
    const payload = {
      website_id: websiteID,
      enable_improve_track: !trackingEnable ? 1 : 0
    };
    try {
      const res = await enablePrivacy(payload);
      if (res.success === true) {
        Toastr.success(t("common.settingsSuccess"));
      }
    } catch (error) {
      console.error("Error:", error);
      Toastr.error("Failed to change settings");
    } finally {
      setTrackingLoading(false);
    }
  };

  useEffect(() => {
    if (privacySettings?.enable_finger_print === 1) {
      setFingerPrintEnable((state) => true);
    } else {
      setFingerPrintEnable((state) => false);
    }
    if (privacySettings?.enable_improve_track === 1) {
      setTrackingEnable((state) => true);
    } else {
      setTrackingEnable((state) => false);
    }
  }, [privacySettings]);

  return (
    <div>
      <a
        href="https://www.yodalytics.com/service/datenschutzbestimmungen"
        target="_blank"
        rel="noopener noreferrer"
        className="modules-heading"
      >
        {t("settings.privacy")}
      </a>
      <div
        style={{
          paddingTop: "20px"
        }}
      >
        <text className="sub-heading">{t("common.customisePrivacy")}</text>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <CommonContent content={t("common.fingerprint")} />

          <div className="flex-row-between">
            <div
              style={{
                paddingTop: "12px"
              }}
            >
              <text className="fingerprint-subheading">
                {t("common.enableFingerprintCapture")}
              </text>
            </div>
            <div
              style={{
                paddingTop: "12px"
              }}
            >
              {fingerPrintLoading ? (
                <TailSpin
                  height="30"
                  width="30"
                  color={themeColors.primaryColor}
                  ariaLabel="tail-spin-loading"
                  radius=".25"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible
                />
              ) : (
                <ToggleSwitch
                  isToggled={fingerPrintEnable}
                  setIsToggled={setFingerPrintEnable}
                  label={t("common.enable")}
                  onPress={handleFingerPrintPrivacy}
                />
              )}
            </div>
          </div>

          <CommonContent content={t("common.tracking")} />

          <div className="flex-row-between">
            <div
              style={{
                paddingTop: "12px"
              }}
            >
              <text className="fingerprint-subheading">
                {t("common.improveTracking")}
              </text>
            </div>
            <div
              style={{
                paddingTop: "12px"
              }}
            >
              {trackingLoading ? (
                <TailSpin
                  height="30"
                  width="30"
                  color={themeColors.primaryColor}
                  ariaLabel="tail-spin-loading"
                  radius=".25"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible
                />
              ) : (
                <ToggleSwitch
                  isToggled={trackingEnable}
                  setIsToggled={setTrackingEnable}
                  label={t("common.enable")}
                  onPress={handleTrackingPrivacy}
                />
              )}
            </div>
          </div>

          <div>
            <text className="site-tracking">* {t("common.siteTracking")}</text>
          </div>
        </>
      )}
    </div>
  );
}
