import { findMedianValue } from "./dataFilter";

export const getInsights = (data) => {
  const temp = {
    visitors: 0,
    sessions: 0,
    questions: 0,
    tokens: 0,
    average_time: 0
  };
  const avgTimeArray = [];

  data.map((ele) => {
    temp.visitors += ele.visitors;
    temp.sessions += ele.sessions;
    temp.questions += ele.questions;
    temp.tokens += ele.tokens;
    if (ele.totalSpentTime > 0) {
      avgTimeArray.push(ele.totalSpentTime);
    }
    return ele;
  });
  temp.average_time = findMedianValue(avgTimeArray);
  return temp;
};

export const getRecordsByDate = (responseData, startDate, endDate) => {
  const questions = [];
  const sessions = [];
  const visitors = [];
  const labels = [];

  const dataMap = new Map();

  responseData.forEach((record) => {
    const { dateName } = record;
    if (!dataMap.has(dateName)) {
      dataMap.set(dateName, { questions: 0, sessions: 0, visitors: 0 });
    }
    dataMap.get(dateName).questions += record.questions;
    dataMap.get(dateName).sessions += record.sessions;
    dataMap.get(dateName).visitors += record.visitors;
  });

  const end = new Date(endDate);
  const currentDate = new Date(startDate);
  while (currentDate <= end) {
    const dateString = `${currentDate.getDate()} ${currentDate.toLocaleDateString(
      "en-US",
      { month: "short" }
    )}`;
    const data = dataMap.get(dateString) || {
      questions: 0,
      sessions: 0,
      visitors: 0
    };
    questions.push(data.questions);
    sessions.push(data.sessions);
    visitors.push(data.visitors);
    labels.push(dateString);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return { labels, questions, sessions, visitors };
};

export const modifyData = (data) => {
  return data.map((ele) => {
    const dateUTC = new Date(ele.date);
    const tempDate = `${dateUTC.getDate()} ${dateUTC.toLocaleDateString(
      "en-US",
      { month: "short" }
    )}`;

    return {
      ...ele,
      dateName: tempDate,
      weekName: `Week ${ele.weekNumber}`,
      monthName: `${ele.month} ${ele.year}`
    };
  });
};

export const getAllQuestionsByDate = (data) => {
  const questions = data.map(({ user, bot, questionNumber }) => ({
    user,
    bot,
    questionNumber
  }));
  return questions;
};

export const getRecordsByWeekly = (responseData) => {
  const weeksData = new Map();

  for (let i = 1; i <= 52; i += 1) {
    weeksData.set(i, { questions: 0, sessions: 0, visitors: 0 });
  }

  responseData.forEach((record) => {
    const { weekNumber } = record;
    if (!weeksData.has(weekNumber)) {
      weeksData.set(weekNumber, { questions: 0, sessions: 0, visitors: 0 });
    }
    weeksData.get(weekNumber).questions += record.questions;
    weeksData.get(weekNumber).sessions += record.sessions;
    weeksData.get(weekNumber).visitors += record.visitors;
  });

  const sortedWeeks = Array.from(weeksData.keys()).sort((a, b) => a - b);

  const labels = sortedWeeks.map((weekNumber) => `Week ${weekNumber}`);
  const questions = [];
  const sessions = [];
  const visitors = [];

  sortedWeeks.forEach((weekNumber) => {
    const data = weeksData.get(weekNumber);
    questions.push(data.questions);
    sessions.push(data.sessions);
    visitors.push(data.visitors);
  });

  return { labels, questions, sessions, visitors };
};

export const getRecordsByMonthly = (responseData) => {
  const monthsData = new Map();

  responseData.forEach((record) => {
    const { month, year } = record;
    const monthYear = `${month}-${year}`;
    if (!monthsData.has(monthYear)) {
      monthsData.set(monthYear, { questions: 0, sessions: 0, visitors: 0 });
    }
    monthsData.get(monthYear).questions += record.questions;
    monthsData.get(monthYear).sessions += record.sessions;
    monthsData.get(monthYear).visitors += record.visitors;
  });

  const sortedMonths = Array.from(monthsData.keys()).sort();

  const labels = sortedMonths.map((monthYear) => {
    const [month, year] = monthYear.split("-");
    return `${month} ${year}`;
  });

  const questions = [];
  const sessions = [];
  const visitors = [];

  sortedMonths.forEach((monthYear) => {
    const data = monthsData.get(monthYear);
    questions.push(data.questions);
    sessions.push(data.sessions);
    visitors.push(data.visitors);
  });

  return { labels, questions, sessions, visitors };
};

export const getRecordsByYearly = (responseData) => {
  const yearsData = new Map();

  responseData.forEach((record) => {
    const { year } = record;
    if (!yearsData.has(year)) {
      yearsData.set(year, { questions: 0, sessions: 0, visitors: 0 });
    }
    yearsData.get(year).questions += record.questions;
    yearsData.get(year).sessions += record.sessions;
    yearsData.get(year).visitors += record.visitors;
  });

  const sortedYears = Array.from(yearsData.keys()).sort();

  const labels = sortedYears.map((year) => `${year}`);

  const questions = [];
  const sessions = [];
  const visitors = [];

  sortedYears.forEach((year) => {
    const data = yearsData.get(year);
    questions.push(data.questions);
    sessions.push(data.sessions);
    visitors.push(data.visitors);
  });

  return { labels, questions, sessions, visitors };
};

export const getPersonaData = (data) => {
  const labelMap = new Map();
  data.forEach((entry) => {
    const { personaLabel, visitors, sessions, questions } = entry;
    const totalVisitors = visitors || 0;
    const totalSessions = sessions || 0;
    const totalQuestions = questions || 0;
    if (!labelMap.has(personaLabel)) {
      labelMap.set(personaLabel, { visitors: 0, sessions: 0, questions: 0 });
    }
    const labelData = labelMap.get(personaLabel);
    labelData.visitors += totalVisitors;
    labelData.sessions += totalSessions;
    labelData.questions += totalQuestions;
  });
  const labels = Array.from(labelMap.keys());
  const values = Array.from(labelMap.values());

  const visitors = values.map((value) => value.visitors);
  const sessions = values.map((value) => value.sessions);
  const questions = values.map((value) => value.questions);

  return { labels, visitors, sessions, questions };
};

export const getCategoriesData = (data) => {
  const labelMap = new Map();
  data.forEach((entry) => {
    const { categories, categoryCount, visitors, sessions } = entry;
    const totalVisitors = visitors || 0;
    const totalSessions = sessions || 0;
    const totalCategoryCount = categoryCount || 0;
    if (!labelMap.has(categories)) {
      labelMap.set(categories, { visitors: 0, sessions: 0, categoryCount: 0 });
    }
    const labelData = labelMap.get(categories);
    labelData.visitors += totalVisitors;
    labelData.sessions += totalSessions;
    labelData.categoryCount += totalCategoryCount;
  });
  // for sorting based on categoryCount
  const sortedLabelMap = new Map(
    [...labelMap.entries()].sort(
      (a, b) => b[1].categoryCount - a[1].categoryCount
    )
  );

  const labels = Array.from(sortedLabelMap.keys());
  const values = Array.from(sortedLabelMap.values());

  const visitors = values.map((value) => value.visitors);
  const sessions = values.map((value) => value.sessions);
  const questions = values.map((value) => value.categoryCount);
  return { labels, visitors, sessions, questions };
};

export const compareMergeInsights = (data1, data2) => {
  return {
    labels: data1.labels,
    compareLabels: data2.labels,
    questions: data1.questions,
    sessions: data1.sessions,
    visitors: data1.visitors,
    secondQuestions: data2.questions,
    secondSessions: data2.sessions,
    secondVisitors: data2.visitors
  };
};

export const filterAccordingToSelected = (data, shiftLabels) => {
  const { labels, questions, sessions, visitors } = data;

  const indexMap = new Map();
  labels.forEach((label, index) => indexMap.set(label, index));

  const newLabels = [];
  const newQuestions = [];
  const newSessions = [];
  const newVisitors = [];

  shiftLabels.forEach((label) => {
    const index = indexMap.get(label);
    if (index !== undefined) {
      newLabels.push(labels[index]);
      newQuestions.push(questions[index]);
      newSessions.push(sessions[index]);
      newVisitors.push(visitors[index]);
    }
  });
  labels.forEach((label, index) => {
    if (!shiftLabels.includes(label)) {
      newLabels.push(label);
      newQuestions.push(questions[index]);
      newSessions.push(sessions[index]);
      newVisitors.push(visitors[index]);
    }
  });

  return {
    labels: newLabels,
    questions: newQuestions,
    sessions: newSessions,
    visitors: newVisitors
  };
};
