/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import close from "../../../../../assets/icons/close.png";
import AISettings from "../assets/icons/AISettingsBlack.svg";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import "../assets/stylesheets/editDesign.scss";
import { Dialog } from "@mui/material";
import AISettingsModel from "./AISettingsModel";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import ReactJsxParser from "react-jsx-parser";
import { generateTemplate } from "../../AddCampaign/api/addCampaignAPI";
import Toastr from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";
import DropdownComponent from "../../../../../components/inputElements/selectInput/DropdownComponent";
import { translateText, changeSentiment } from "../api/editCampaignAPI";
import { useSelector } from "react-redux";

export default function EditDesign() {
  const location = useLocation();
  const navigate = useNavigate();

  let {
    selectedImage,
    template,
    selectedRandomObject,
    selectedChannels,
    multiSelectTypes,
    selectedPersonas,
    navigationFrom,
    generatedHeading,
    generatedContent,
    generatedCta
  } = location.state || {};

  const { t } = useTranslation();
  const { websiteID } = useSelector((state) => state.generalData);

  const jsxContainerRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [aiSettingsPopUp, setAISettingsPopUp] = useState(false);

  const [field, setField] = useState("");

  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [adTemplate, setAdTemplate] = useState(template);

  const [templateTitle, setTemplateTitle] = useState({
    title: "",
    fontFamily: "",
    fontSize: "",
    fontColor: "",
    errorMessage: "",
    errorStatus: false,
    status: false
  });
  const [templateContent, setTemplateContent] = useState({
    content: "",
    fontFamily: "",
    fontSize: "",
    fontColor: "",
    errorMessage: "",
    errorStatus: false,
    status: false
  });
  const [templateButton, setTemplateButton] = useState({
    text: "",
    fontSize: "",
    fontFamily: "",
    fontColor: "",
    buttonColor: "",
    errorMessage: "",
    errorStatus: false,
    status: false
  });

  const [translatedLang, setTranslatedLang] = useState(
    t("manageCampaign.editDesignModel.Translate")
  );

  const translateOptions = [
    {
      id: 1,
      lang: "English",
      text: t("manageCampaign.editDesignModel.English"),
      label: t("manageCampaign.editDesignModel.English"),
      value: "English"
    },
    {
      id: 2,
      lang: "Italian",
      text: t("manageCampaign.editDesignModel.Italian"),
      label: t("manageCampaign.editDesignModel.Italian"),
      value: "Italian"
    },
    {
      id: 3,
      lang: "German",
      text: t("manageCampaign.editDesignModel.German"),
      label: t("manageCampaign.editDesignModel.German"),
      value: "German"
    }
  ];

  const [textSentiment, setTextSentiment] = useState(
    t("manageCampaign.editDesignModel.changeSentiment")
  );
  const sentimentOptions = [
    {
      id: 1,
      name: "Professional",
      text: t("manageCampaign.editDesignModel.Professional"),
      label: t("manageCampaign.editDesignModel.Professional"),
      value: "Professional",
      keywords: "Clear, concise, and authoritative."
    },
    {
      id: 2,
      name: "Friendly",
      text: t("manageCampaign.editDesignModel.Friendly"),
      label: t("manageCampaign.editDesignModel.Friendly"),
      value: "Friendly",
      keywords: "Warm, approachable, and conversational."
    },
    {
      id: 3,
      name: "Playful",
      text: t("manageCampaign.editDesignModel.Playful"),
      label: t("manageCampaign.editDesignModel.Playful"),
      value: "Playful",
      keywords: "Light-hearted, fun, and creative."
    },
    {
      id: 4,
      name: "Empathetic",
      text: t("manageCampaign.editDesignModel.Empathetic"),
      label: t("manageCampaign.editDesignModel.Empathetic"),
      value: "Empathetic",
      keywords: "Understanding, supportive, and compassionate."
    },
    {
      id: 5,
      name: "Confident",
      text: t("manageCampaign.editDesignModel.Confident"),
      label: t("manageCampaign.editDesignModel.Confident"),
      value: "Confident",
      keywords: "Assertive, strong, and self-assured"
    },
    {
      id: 6,
      name: "Persuasive",
      text: t("manageCampaign.editDesignModel.Persuasive"),
      label: t("manageCampaign.editDesignModel.Persuasive"),
      value: "Persuasive",
      keywords: "Assertive, strong, and self-assured"
    },
    {
      id: 7,
      name: "Excited",
      text: t("manageCampaign.editDesignModel.Excited"),
      label: t("manageCampaign.editDesignModel.Excited"),
      value: "Excited",
      keywords: "Enthusiastic, energetic, and positive"
    },
    {
      id: 8,
      name: "Urgent",
      text: t("manageCampaign.editDesignModel.Urgent"),
      label: t("manageCampaign.editDesignModel.Urgent"),
      value: "Urgent",
      keywords: "Time-sensitive, direct, and pressing"
    },
    {
      id: 9,
      name: "Informative",
      text: t("manageCampaign.editDesignModel.Informative"),
      label: t("manageCampaign.editDesignModel.Informative"),
      value: "Informative",
      keywords: "Detailed, factual, and educational"
    },
    {
      id: 10,
      name: "Inspirational",
      text: t("manageCampaign.editDesignModel.Inspirational"),
      label: t("manageCampaign.editDesignModel.Inspirational"),
      value: "Inspirational",
      keywords: "Motivational, encouraging, and optimistic."
    }
  ];

  const [textFontFamily, setTextFontFamily] = useState(
    t("manageCampaign.editDesignModel.chooseFontFamily")
  );
  const fontFamilyOptions = [
    {
      id: 0,
      label: "Arial",
      value: "Arial"
    },
    {
      id: 1,
      label: "Verdana",
      value: "Verdana"
    },
    {
      id: 2,
      label: "Helvetica",
      value: "Helvetica"
    },
    {
      id: 3,
      label: "Georgia",
      value: "Georgia"
    },
    {
      id: 4,
      label: "Times New Roman",
      value: "Times New Roman"
    },
    {
      id: 5,
      label: "Courier New",
      value: "Courier New"
    },
    {
      id: 6,
      label: "Trebuchet MS",
      value: "Trebuchet MS"
    },
    {
      id: 7,
      label: "Impact",
      value: "Impact"
    },
    {
      id: 8,
      label: "Comic Sans MS",
      value: "Comic Sans MS"
    },
    {
      id: 9,
      label: "Tahoma",
      value: "Tahoma"
    },
    {
      id: 10,
      label: "Lucida Sans",
      value: "Lucida Sans"
    },
    {
      id: 11,
      label: "Palatino Linotype",
      value: "Palatino Linotype"
    },
    {
      id: 12,
      label: "Book Antiqua",
      value: "Book Antiqua"
    },
    {
      id: 13,
      label: "Garamond",
      value: "Garamond"
    },
    {
      id: 14,
      label: "Arial Black",
      value: "Arial Black"
    },
    {
      id: 15,
      label: "Century Gothic",
      value: "Century Gothic"
    },
    {
      id: 16,
      label: "Candara",
      value: "Candara"
    },
    {
      id: 17,
      label: "Lucida Console",
      value: "Lucida Console"
    },
    {
      id: 18,
      label: "Franklin Gothic Medium",
      value: "Franklin Gothic Medium"
    },
    {
      id: 19,
      label: "Monaco",
      value: "Monaco"
    },
    {
      id: 20,
      label: "Gill Sans",
      value: "Gill Sans"
    },
    {
      id: 21,
      label: "Optima",
      value: "Optima"
    },
    {
      id: 22,
      label: "Futura",
      value: "Futura"
    },
    {
      id: 23,
      label: "Baskerville",
      value: "Baskerville"
    },
    {
      id: 24,
      label: "Didot",
      value: "Didot"
    },
    {
      id: 25,
      label: "Calibri",
      value: "Calibri"
    },
    {
      id: 26,
      label: "Cambria",
      value: "Cambria"
    },
    {
      id: 27,
      label: "Consolas",
      value: "Consolas"
    },
    {
      id: 28,
      label: "PT Sans",
      value: "PT Sans"
    },
    {
      id: 29,
      label: "PT Serif",
      value: "PT Serif"
    },
    {
      id: 30,
      label: "Roboto",
      value: "Roboto"
    },
    {
      id: 31,
      label: "Open Sans",
      value: "Open Sans"
    },
    {
      id: 32,
      label: "Lato",
      value: "Lato"
    },
    {
      id: 33,
      label: "Montserrat",
      value: "Montserrat"
    },
    {
      id: 34,
      label: "Poppins",
      value: "Poppins"
    },
    {
      id: 35,
      label: "Raleway",
      value: "Raleway"
    },
    {
      id: 36,
      label: "Nunito",
      value: "Nunito"
    },
    {
      id: 37,
      label: "Source Sans Pro",
      value: "Source Sans Pro"
    },
    {
      id: 38,
      label: "Muli",
      value: "Muli"
    },
    {
      id: 39,
      label: "Quicksand",
      value: "Quicksand"
    },
    {
      id: 40,
      label: "Inter",
      value: "Inter"
    },
    {
      id: 41,
      label: "Work Sans",
      value: "Work Sans"
    },
    {
      id: 42,
      label: "Titillium Web",
      value: "Titillium Web"
    },
    {
      id: 43,
      label: "Ubuntu",
      value: "Ubuntu"
    },
    {
      id: 44,
      label: "Oxygen",
      value: "Oxygen"
    },
    {
      id: 45,
      label: "Fira Sans",
      value: "Fira Sans"
    },
    {
      id: 46,
      label: "Noto Sans",
      value: "Noto Sans"
    },
    {
      id: 47,
      label: "Playfair Display",
      value: "Playfair Display"
    },
    {
      id: 48,
      label: "Merriweather",
      value: "Merriweather"
    },
    {
      id: 49,
      label: "Crimson Pro",
      value: "Crimson Pro"
    },
    {
      id: 50,
      label: "Roboto Slab",
      value: "Roboto Slab"
    },
    {
      id: 51,
      label: "Libre Baskerville",
      value: "Libre Baskerville"
    },
    {
      id: 52,
      label: "Noto Serif",
      value: "Noto Serif"
    },
    {
      id: 53,
      label: "Zilla Slab",
      value: "Zilla Slab"
    },
    {
      id: 54,
      label: "Courier Prime",
      value: "Courier Prime"
    },
    {
      id: 55,
      label: "Source Code Pro",
      value: "Source Code Pro"
    },
    {
      id: 56,
      label: "Inconsolata",
      value: "Inconsolata"
    },
    {
      id: 57,
      label: "Fira Code",
      value: "Fira Code"
    },
    {
      id: 58,
      label: "Space Mono",
      value: "Space Mono"
    },
    {
      id: 59,
      label: "Roboto Mono",
      value: "Roboto Mono"
    },
    {
      id: 60,
      label: "Pacifico",
      value: "Pacifico"
    },
    {
      id: 61,
      label: "Dancing Script",
      value: "Dancing Script"
    },
    {
      id: 62,
      label: "Lobster",
      value: "Lobster"
    },
    {
      id: 63,
      label: "Satisfy",
      value: "Satisfy"
    },
    {
      id: 64,
      label: "Great Vibes",
      value: "Great Vibes"
    },
    {
      id: 65,
      label: "Cinzel",
      value: "Cinzel"
    },
    {
      id: 66,
      label: "Bangers",
      value: "Bangers"
    },
    {
      id: 67,
      label: "Righteous",
      value: "Righteous"
    },
    {
      id: 68,
      label: "Fredericka the Great",
      value: "Fredericka the Great"
    },
    {
      id: 69,
      label: "Archivo Black",
      value: "Archivo Black"
    },
    {
      id: 70,
      label: "Bowlby One SC",
      value: "Bowlby One SC"
    },
    {
      id: 71,
      label: "Patrick Hand",
      value: "Patrick Hand"
    },
    {
      id: 72,
      label: "Shadows Into Light",
      value: "Shadows Into Light"
    },
    {
      id: 73,
      label: "Indie Flower",
      value: "Indie Flower"
    },
    {
      id: 74,
      label: "Caveat",
      value: "Caveat"
    },
    {
      id: 75,
      label: "Amatic SC",
      value: "Amatic SC"
    },
    {
      id: 76,
      label: "Baloo",
      value: "Baloo"
    },
    {
      id: 77,
      label: "Abril Fatface",
      value: "Abril Fatface"
    },
    {
      id: 78,
      label: "Josefin Sans",
      value: "Josefin Sans"
    },
    {
      id: 79,
      label: "Anton",
      value: "Anton"
    },
    {
      id: 80,
      label: "Bebas Neue",
      value: "Bebas Neue"
    },
    {
      id: 81,
      label: "Oswald",
      value: "Oswald"
    },
    {
      id: 82,
      label: "Rubik",
      value: "Rubik"
    },
    {
      id: 83,
      label: "Tangerine",
      value: "Tangerine"
    },
    {
      id: 84,
      label: "Merriweather Sans",
      value: "Merriweather Sans"
    },
    {
      id: 85,
      label: "Barlow",
      value: "Barlow"
    },
    {
      id: 86,
      label: "Arvo",
      value: "Arvo"
    },
    {
      id: 87,
      label: "Vollkorn",
      value: "Vollkorn"
    },
    {
      id: 88,
      label: "Dosis",
      value: "Dosis"
    },
    {
      id: 89,
      label: "Manrope",
      value: "Manrope"
    },
    {
      id: 90,
      label: "Saira",
      value: "Saira"
    },
    {
      id: 91,
      label: "Overpass",
      value: "Overpass"
    },
    {
      id: 92,
      label: "Assistant",
      value: "Assistant"
    },
    {
      id: 93,
      label: "Bitter",
      value: "Bitter"
    },
    {
      id: 94,
      label: "Karla",
      value: "Karla"
    },
    {
      id: 95,
      label: "Cormorant Garamond",
      value: "Cormorant Garamond"
    },
    {
      id: 96,
      label: "Nanum Gothic",
      value: "Nanum Gothic"
    },
    {
      id: 97,
      label: "PT Mono",
      value: "PT Mono"
    },
    {
      id: 98,
      label: "Space Grotesk",
      value: "Space Grotesk"
    }
  ];

  const handleAISettingsPopUpOpen = async (label) => {
    await handleUpdatedTemplate();
    setField(label);
    setAISettingsPopUp(true);
  };

  const handleAISettingsPopUpClose = () => {
    setAISettingsPopUp(false);
  };

  // useEffect(() => {
  //   if (selectedRandomObject) {
  //     setHeading(selectedRandomObject?.title);
  //     setContent(selectedRandomObject?.content);
  //     setCta(selectedRandomObject?.cta);
  //   } else if (navigationFrom === "designPage") {
  //     setHeading(generatedHeading);
  //     setContent(generatedContent);
  //     setCta(generatedCta);
  //   }
  // }, [selectedRandomObject, navigationFrom]);

  // console.log("selectedRandomObject", selectedRandomObject);

  // const handleGenerateTemplate = async () => {
  //   setLoading(true);
  //   const payload = [
  //     {
  //       content: content,
  //       cta: cta,
  //       imageUrl: selectedImage
  //         ? selectedImage?.src
  //         : selectedRandomObject?.imageUrl,
  //       title: heading
  //     }
  //   ];
  //   try {
  //     const templateResponse = await generateTemplate(payload);
  //     console.log("templateResponse", templateResponse);
  //     const originalTemplates = templateResponse?.data?.templates;
  //     const cleanTemplates = templateResponse?.data?.templates?.map(
  //       (template) =>
  //         template
  //           .replace(/\\n/g, "")
  //           .replace(/\\t/g, "")
  //           .replace(/\\"/g, '"')
  //           .replace(/\\'/g, "'")
  //     );
  //     if (templateResponse?.status === 200) {
  //       Toastr.success("Success");
  //       navigate("/manage/design-preview", {
  //         state: {
  //           selectedRandomObject,
  //           cleanTemplates,
  //           originalTemplates,
  //           selectedChannels,
  //           multiSelectTypes,
  //           selectedPersonas,
  //           template: templateResponse?.data?.templates[0],
  //           selectedImage,
  //           content,
  //           cta,
  //           heading
  //         }
  //       });
  //     } else {
  //       Toastr.error("Template generation failed.");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Toastr.error("Something went wrong.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  // console.log("navigationFrom", navigationFrom);

  // New logic for Edit

  // Function to handle input value change
  const handleInputChange = (type, field, data) => {
    if (type == "title") {
      setTemplateTitle((state) => {
        return { ...state, [field]: data, errorStatus: false };
      });
    }
    if (type == "content") {
      setTemplateContent((state) => {
        return { ...state, [field]: data, errorStatus: false };
      });
    }
    if (type == "button") {
      setTemplateButton((state) => {
        return { ...state, [field]: data, errorStatus: false };
      });
    }
  };

  // Function to translate text from one language to another

  const handleTranslateText = async (lang) => {
    try {
      setLoading(true);
      handleInputChange("title", templateTitle.title);
      const payload1 = {
        text: templateTitle.title,
        lang: lang,
        website_id: websiteID
      };
      const payload2 = {
        text: templateContent.content,
        lang: lang,
        website_id: websiteID
      };
      const payload3 = {
        text: templateButton.text,
        lang: lang,
        website_id: websiteID
      };

      const payload = [payload1, payload2, payload3];

      const TranslatedTextApi = payload.map(async (request) => {
        const updatedText = await translateText(request);
        return updatedText;
      });

      const TranslatedText = await Promise.all(TranslatedTextApi);
      console.log("TranslatedText", TranslatedText);

      TranslatedText.forEach((response, index) => {
        if (index == 0) {
          setTemplateTitle((state) => {
            return { ...state, title: response.data.translatedText };
          });
        } else if (index == 1) {
          setTemplateContent((state) => {
            return { ...state, content: response.data.translatedText };
          });
        } else {
          setTemplateButton((state) => {
            return { ...state, text: response.data.translatedText };
          });
        }
      });

      Toastr.success(t("manageCampaign.editDesignModel.translationSuccess"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toastr.error(error.message);
    }
  };

  // Function to Change the sentiment of the text
  const handleChangeSentiment = async (name) => {
    try {
      setLoading(true);
      handleInputChange("title", templateTitle.title);

      let sentiment = sentimentOptions.find((sent) => sent.name == name);
      const payload1 = {
        text: templateTitle.title,
        sentiment: sentiment.name,
        keywords: sentiment.keywords,
        website_id: websiteID,
        max_words: 15
      };
      const payload2 = {
        text: templateContent.content,
        sentiment: sentiment.name,
        keywords: sentiment.keywords,
        website_id: websiteID,
        max_words: 18
      };
      const payload3 = {
        text: templateButton.text,
        sentiment: sentiment.name,
        keywords: sentiment.keywords,
        website_id: websiteID,
        max_words: 3
      };

      const payload = [payload1, payload2, payload3];

      const ChangeSentimentApi = payload.map(async (request) => {
        const updatedText = await changeSentiment(request);
        return updatedText;
      });

      const updatedText = await Promise.all(ChangeSentimentApi);
      console.log("updatedText", updatedText);

      updatedText.forEach((response, index) => {
        if (index == 0) {
          setTemplateTitle((state) => {
            return { ...state, title: response.data.updatedText };
          });
        } else if (index == 1) {
          setTemplateContent((state) => {
            return { ...state, content: response.data.updatedText };
          });
        } else {
          setTemplateButton((state) => {
            return { ...state, text: response.data.updatedText };
          });
        }
      });

      Toastr.success(t("manageCampaign.editDesignModel.toneSucess"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toastr.error(error.message);
    }
  };

  // Function to update the font Family of the content
  const handleFontFamilyChange = (fontFamily) => {
    setTemplateTitle((state) => {
      return {
        ...state,

        fontFamily: fontFamily
      };
    });
    setTemplateContent((state) => {
      return {
        ...state,
        fontFamily: fontFamily
      };
    });
    setTemplateButton((state) => {
      return {
        ...state,
        fontFamily: fontFamily
      };
    });
  };

  // Function to validate the Form Data
  const validateFormData = () => {
    let valideStatus = true;
    if (!templateTitle.title.trim()) {
      valideStatus = false;
      setTemplateTitle((state) => {
        return {
          ...state,
          errorStatus: true,
          errorMessage: t("manageCampaign.headingRequired")
        };
      });
    }

    if (!templateContent.content.trim()) {
      valideStatus = false;
      setTemplateContent((state) => {
        return {
          ...state,
          errorStatus: true,
          errorMessage: t("manageCampaign.contentRequired")
        };
      });
    }

    if (!templateButton.text.trim()) {
      valideStatus = false;
      setTemplateButton((state) => {
        return {
          ...state,
          errorStatus: true,
          errorMessage: t("manageCampaign.ctaRequired")
        };
      });
    }

    return valideStatus;
  };

  // Function to save the rendered DOM as JSX-like string
  const saveJsxFromDom = () => {
    try {
      if (jsxContainerRef.current) {
        // Step 1: Extract the inner HTML
        let domHtml = jsxContainerRef.current.innerHTML;

        // Step 2: Convert style attributes to JSX-compatible format
        domHtml = domHtml.replace(/style="([^"]*)"/g, (_, styles) => {
          const jsxStyle = styles
            .split(";")
            .map((style) => {
              const [prop, value] = style.split(":");
              if (!prop) return "";
              console.log("prop", prop);
              const camelCasedProp = prop
                .trim()
                .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
              return `${camelCasedProp}: '${value.trim()}'`;
            })
            .filter(Boolean)
            .join(", ");
          return `style={{ ${jsxStyle} }}`;
        });

        // Step 3: Convert self-closing tags and boolean attributes to JSX format
        domHtml = domHtml
          .replace(/<([a-zA-Z]+)([^>]*)><\/\1>/g, "<$1$2 />") // Self-closing tags
          .replace(/(checked|disabled|selected)=""/g, "$1"); // Remove empty quotes for boolean attributes

        // Step 4: Display or save the converted JSX
        console.log("after editing ", domHtml); // You can store this in state or use it as needed
        const updatedTemplate = domHtml;
        let heading = templateTitle.title;
        let content = templateContent.content;
        let cta = templateButton.text;
        navigate("/manage/design-preview", {
          state: {
            selectedRandomObject,
            updatedTemplate,
            updatedTemplate,
            selectedChannels,
            multiSelectTypes,
            selectedPersonas,
            template: updatedTemplate,
            selectedImage,
            content,
            cta,
            heading
          }
        });
      }
    } catch (error) {
      let heading = templateTitle.title;
      let content = templateContent.content;
      let cta = templateButton.text;
      navigate("/manage/design-preview", {
        state: {
          selectedRandomObject,
          adTemplate,
          adTemplate,
          selectedChannels,
          multiSelectTypes,
          selectedPersonas,
          template: adTemplate,
          selectedImage,
          content,
          cta,
          heading
        }
      });
    }
  };

  // Function to updated Local template with updated Changes.
  const handleUpdatedTemplate = async () => {
    try {
      if (jsxContainerRef.current) {
        // Step 1: Extract the inner HTML
        let domHtml = jsxContainerRef.current.innerHTML;

        // Step 2: Convert style attributes to JSX-compatible format
        domHtml = domHtml.replace(/style="([^"]*)"/g, (_, styles) => {
          const jsxStyle = styles
            .split(";")
            .map((style) => {
              const [prop, value] = style.split(":");
              if (!prop) return "";

              const camelCasedProp = prop
                .trim()
                .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
              return `${camelCasedProp}: '${value.trim()}'`;
            })
            .filter(Boolean)
            .join(", ");
          return `style={{ ${jsxStyle} }}`;
        });

        // Step 3: Convert self-closing tags and boolean attributes to JSX format
        domHtml = domHtml
          .replace(/<([a-zA-Z]+)([^>]*)><\/\1>/g, "<$1$2 />") // Self-closing tags
          .replace(/(checked|disabled|selected)=""/g, "$1"); // Remove empty quotes for boolean attributes

        // Step 4: Display or save the converted JSX
        // console.log("after editing ", domHtml); // You can store this in state or use it as needed
        const updatedTemplate = domHtml;

        setAdTemplate(updatedTemplate);
        setParentKey((state) => state + 1);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Function to handle Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleInputChange("title", templateTitle.title);
    if (validateFormData()) {
      console.log("adTemplate", adTemplate);
      saveJsxFromDom();
    }
  };

  // UseEffect to update initial state of the input fields
  useEffect(() => {
    if (jsxContainerRef.current) {
      const title = jsxContainerRef.current.querySelector("#title");
      const content = jsxContainerRef.current.querySelector("#content");
      const button = jsxContainerRef.current.querySelector("#action-button");

      setTemplateTitle((state) => {
        return {
          ...state,
          title: title.textContent.trim(),
          fontFamily: title.style.fontFamily
        };
      });
      setTemplateContent((state) => {
        return {
          ...state,
          content: content.textContent.trim(),
          fontFamily: content.style.fontFamily
        };
      });
      setTemplateButton((state) => {
        return {
          ...state,
          text: button.textContent.trim(),
          fontFamily: content.style.fontFamily
        };
      });
    }
  }, [jsxContainerRef.current, adTemplate, key]);

  useEffect(() => {
    if (jsxContainerRef.current) {
      const title = jsxContainerRef.current.querySelector("#title");
      const content = jsxContainerRef.current.querySelector("#content");
      const button = jsxContainerRef.current.querySelector("#action-button");

      title.textContent = templateTitle.title;
      title.style.fontFamily = templateTitle.fontFamily;
      content.textContent = templateContent.content;
      content.style.fontFamily = templateContent.fontFamily;
      button.textContent = templateButton.text;
      button.style.fontFamily = templateButton.fontFamily;
    }
  }, [templateTitle, templateContent, templateButton, adTemplate]);

  useEffect(() => {
    // console.log(
    //   "templateTitle",
    //   templateTitle,
    //   templateContent,
    //   templateButton
    // );
  }, [templateTitle, templateButton]);

  return (
    <>
      <AuthorizedHeader />

      <div className="edit-design container">
        <div className="generated-designs background-box">
          <div className="generated-designs-margins">
            {/*  Heading and close icon container */}
            <div className="flex-between">
              <div className="modules-heading">
                <p className="modules-heading">
                  {t("manageCampaign.textImages")}
                </p>
              </div>
              <div className="close-icon">
                <img
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    // Handling keyboard events
                    if (e.key === "Enter" || e.key === " ") {
                      navigate(-1);
                    }
                  }}
                  src={close}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="close-icon-img"
                />
              </div>
            </div>

            {/*  Edit container  */}
            <div className="setting-dropdown-container">
              <div className="setting-dropdown-div">
                <DropdownComponent
                  value={translatedLang}
                  name={"translate"}
                  options={translateOptions}
                  onChange={(e) => {
                    console.log("e", e.target, e.target.value);
                    const value = e.target.value;
                    setTranslatedLang(value);
                    handleTranslateText(value);
                  }}
                  marginTop="0px"
                  minWidth="200px"
                  defaultValue={t("manageCampaign.editDesignModel.Translate")}
                />
              </div>
              <div className="setting-dropdown-div">
                <DropdownComponent
                  value={textSentiment}
                  name={"textSentiment"}
                  options={sentimentOptions}
                  onChange={(e) => {
                    console.log("e", e.target);
                    const value = e.target.value;
                    setTextSentiment(value);
                    handleChangeSentiment(value);
                  }}
                  marginTop="0px"
                  minWidth="200px"
                  defaultValue={t(
                    "manageCampaign.editDesignModel.changeSentiment"
                  )}
                />
              </div>
              <div className="setting-dropdown-div">
                <DropdownComponent
                  value={textFontFamily}
                  name={"fontFamily"}
                  options={fontFamilyOptions}
                  onChange={(e) => {
                    console.log("e", e.target);
                    const value = e.target.value;
                    setTextFontFamily(value);
                    handleFontFamilyChange(value);
                  }}
                  marginTop="0px"
                  minWidth="200px"
                  defaultValue={t(
                    "manageCampaign.editDesignModel.chooseFontFamily"
                  )}
                />
              </div>
            </div>

            <form onSubmit={handleFormSubmit}>
              <div className="flex-space">
                {/* Input fields */}
                <div className="inputs-width">
                  <div className={"input-margin"}>
                    {/* Heading */}
                    <div className="generated-designs-campaign-name">
                      {t("manageCampaign.heading")}
                    </div>
                    <div className="flex">
                      <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            templateTitle.errorStatus
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={templateTitle.title}
                          onChange={(e) => {
                            let value = e.target.value;
                            handleInputChange("title", "title", value);
                          }}
                          // disabled
                        />
                        {templateTitle.errorStatus ? (
                          <p className="input-error-text">
                            {templateTitle.errorMessage}
                          </p>
                        ) : null}
                      </div>

                      <div className="ai-settings-icon-container">
                        <img
                          src={AISettings}
                          onClick={() => {
                            // (e) =>
                            // handleIconClick(e, `AI settings for ${label}`)
                            handleAISettingsPopUpOpen(0);
                          }}
                          className="ai-settings-icon"
                        />
                      </div>
                    </div>
                    {/* Heading ends */}

                    {/* Content */}
                    <div className="generated-designs-campaign-name">
                      {t("manageCampaign.content")}
                    </div>
                    <div className="flex">
                      <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            templateContent.errorStatus
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={templateContent.content}
                          onChange={(e) => {
                            let value = e.target.value;
                            handleInputChange("content", "content", value);
                          }}
                          // disabled
                        />

                        {templateContent.errorStatus ? (
                          <p className="input-error-text">
                            {templateContent.errorMessage}
                          </p>
                        ) : null}
                      </div>
                      <div className="ai-settings-icon-container">
                        <img
                          src={AISettings}
                          onClick={() => {
                            // (e) =>
                            // handleIconClick(e, `AI settings for ${label}`)
                            handleAISettingsPopUpOpen(1);
                          }}
                          className="ai-settings-icon"
                        />
                      </div>
                    </div>
                    {/* Content ends */}

                    {/* Optional text */}
                    {/* <div className="generated-designs-campaign-name">
                      {t("manageCampaign.opText")}
                    </div> */}
                    <div className="flex">
                      {/* <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            errors.opText
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={opText}
                          onChange={handleInputChange(setOpText, "opText")}
                        />
                        {errors.opText}
                      </div> */}
                      {/* <img
                        src={AISettings}
                        onClick={() => {
                          // (e) =>
                          // handleIconClick(e, `AI settings for ${label}`)
                          handleAISettingsPopUpOpen("Optional text");
                        }}
                        className="ai-settings-icon"
                      /> */}
                    </div>
                    {/* Optional text ends */}

                    {/* Call to action button */}
                    <div className="generated-designs-campaign-name">
                      {t("manageCampaign.ctaButton")}
                    </div>
                    <div className="flex">
                      <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            templateButton.errorStatus
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={templateButton.text}
                          onChange={(e) => {
                            let value = e.target.value;
                            handleInputChange("button", "text", value);
                          }}
                          // disabled
                        />

                        {templateButton.errorStatus ? (
                          <p className="input-error-text">
                            {templateButton.errorMessage}
                          </p>
                        ) : null}
                      </div>
                      <div className="ai-settings-icon-container">
                        <img
                          src={AISettings}
                          onClick={() => {
                            // (e) =>
                            // handleIconClick(e, `AI settings for ${label}`)
                            handleAISettingsPopUpOpen(2);
                          }}
                          className="ai-settings-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Input fields ends */}

                {/* Previewed image */}
                <div className="preview-campaign-background">
                  <div>
                    <div className="preview-campaign-text">
                      {t("manageCampaign.preview")}
                    </div>
                  </div>
                  <div>
                    {/* Ref wrapper to capture the DOM structure */}
                    {(navigationFrom === "designPage" && (
                      <div ref={jsxContainerRef}>
                        <ReactJsxParser
                          key={key}
                          jsx={jsxCode}
                          components={{}}
                          renderInWrapper={false}
                          autoCloseVoidElements
                        />
                      </div>
                    )) ||
                      (selectedImage ? (
                        <img
                          src={selectedImage.src}
                          style={{
                            width: "320px",
                            height: "390px",
                            objectFit: "cover"
                          }}
                        />
                      ) : (
                        template && null
                      ))}

                    <div ref={jsxContainerRef}>
                      <ReactJsxParser
                        key={key}
                        jsx={adTemplate}
                        components={{}}
                        renderInWrapper={false}
                        autoCloseVoidElements
                      />
                    </div>
                  </div>
                </div>
                {/* Previewed image ends */}
              </div>

              {/* Button Container  */}
              <div className="flex-center buttons-padding">
                {loading ? (
                  <Loader text={t("manageCampaign.generatingImages")} />
                ) : (
                  <>
                    <div className="cancel-button-padding-right">
                      <ButtonLight
                        buttonText={t("common.confirmation.cancel")}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    <ButtonDark
                      isSubmit
                      buttonText={t("common.confirmation.next")}
                    />
                  </>
                )}
              </div>
            </form>
          </div>
        </div>

        <Dialog
          open={aiSettingsPopUp}
          onClose={handleAISettingsPopUpClose}
          PaperProps={{
            style: {
              minHeight: "450px",
              minWidth: "400px",
              maxWidth: "1200px"
            }
          }}
        >
          <AISettingsModel
            close={close}
            field={field}
            adTemplate={adTemplate}
            setAdTemplate={setAdTemplate}
            handleClose={handleAISettingsPopUpClose}
            setParentKey={setKey}
          />
        </Dialog>
        {/* Popover for AI Settings ends */}
      </div>
    </>
  );
}
