const getNotificationInfo = (notificationId, mappedNotifications) => {
  return (
    mappedNotifications[notificationId] || {
      text: "Unknown Notification",
      body: "Details not available.",
      link: "/unknown-notification"
    }
  );
};

export default getNotificationInfo;
