import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import RestrictedPage from "../../features/Placeholders/RestrictedPage";

export default function RestrictedAccess() {
  return (
    <>
      <AuthorizedHeader />
      <RestrictedPage />
    </>
  );
}
