/* eslint-disable no-continue */
/* eslint-disable */
import { countryISO } from "../data/countryData";
import {
  dataFilterFunction,
  getDatesInRange,
  getMonths
  // getMonthsInRange,
  // getYearsInRange
} from "./dataFilter";

const ageRange = (age, t) => {
  if (age < 20) {
    return `${t("heatmap.below")} 20`;
  }
  if (age >= 20 && age < 30) {
    return "20 - 30";
  }
  if (age >= 30 && age < 40) {
    return "30 - 40";
  }
  if (age >= 40 && age < 50) {
    return "40 - 50";
  }
  if (age >= 50 && age < 60) {
    return "50 - 60";
  }
  return `${t("heatmap.above")} 60`;
};

export const spendRange = (amount, t) => {
  if (amount === 0) return "";
  if (amount < 2000) {
    return `${t("heatmap.below")} 2000`;
  }
  if (amount >= 2000 && amount < 3000) {
    return "2000 - 3000";
  }
  if (amount >= 3000 && amount < 4000) {
    return "3000 - 4000";
  }
  if (amount >= 4000 && amount < 5000) {
    return "4000 - 5000";
  }
  if (amount >= 5000 && amount < 6000) {
    return "5000 - 6000";
  }
  return `${t("heatmap.above")} 6000`;
};

export const filterAccordingToSelected = (data, selected) => {
  const { labels, roomReq, requests, roomBook, bookings } = data;

  const tempLabels = labels ? [...labels] : [];
  const tempRoomReq = roomReq ? [...roomReq] : [];
  const tempRequests = requests ? [...requests] : [];

  const tempBookings = bookings ? [...bookings] : [];
  const tempRoomBook = roomBook ? [...roomBook] : [];

  selected.map((ele) => {
    const index = labels?.indexOf(ele);

    if (index > -1) {
      tempLabels.unshift(...new Set(tempLabels.splice(index, 1)));
      tempRequests.unshift(...new Set(tempRequests.splice(index, 1)));
      tempRoomReq.unshift(...new Set(tempRoomReq.splice(index, 1)));
      tempBookings.unshift(...new Set(tempBookings.splice(index, 1)));
      tempRoomBook.unshift(...new Set(tempRoomBook.splice(index, 1)));
    } else {
      tempLabels.unshift(ele);
      tempRequests.unshift(0);
      tempRoomReq.unshift(0);
      tempBookings.unshift(0);
      tempRoomBook.unshift(0);
    }
    return ele;
  });

  return {
    labels: tempLabels,
    roomReq: tempRoomReq,
    requests: tempRequests,
    roomBook: tempRoomBook,
    bookings: tempBookings
  };
};

// KPI values calculation for all data
export const getInsights = (data) => {
  const temp = {
    requests: 0,
    bookings: 0,
    revenue: 0,
    roomReq: 0,
    roomBooking: 0,
    conversionRate: 0,
    revenuePerBooking: 0
  };

  const type = dataFilterFunction(data, "type");

  temp.revenue = (data || [])?.reduce((a, b) => a + b.revenue, 0);

  temp.bookings = Object.keys(
    dataFilterFunction(type.reservation || [], "reservation_id")
  ).length;

  temp.requests = Object.keys(
    dataFilterFunction(data, "reservation_id")
  ).length;

  temp.roomReq = data.reduce((a, b) => a + Number(b.roomReq), 0);

  temp.revenuePerBooking =
    temp.revenue && temp.bookings ? temp.revenue / temp.bookings : 0;

  temp.conversionRate =
    temp.bookings && temp.requests ? (temp.bookings * 100) / temp.requests : 0;

  return temp;
};

export const getGraphDataSorted = (
  data,
  reservation = false,
  bookings = false
) => {
  const graphData = [];
  Object.keys(data || {}).forEach((key) => {
    if (key) {
      let tempRoomReq = 0;
      let tempRequests = 0;
      if (bookings) {
        tempRequests = Math.ceil(
          data[key].reduce((a, b) => a + b.split_request, 0)
        );

        tempRoomReq = data[key].reduce((a, b) => a + Number(b.roomBook), 0);
      } else {
        tempRequests = reservation
          ? Object.keys(dataFilterFunction(data[key], "reservation_id")).length
          : data[key].reduce((a, b) => a + b.split_request, 0).toFixed(2);

        tempRoomReq = data[key].reduce((a, b) => a + Number(b.roomReq), 0);
      }
      const dataToAdd = {
        label: key,
        requests: tempRequests,
        roomRequests: tempRoomReq
      };

      graphData.push(dataToAdd);
    }
  });

  graphData.sort((a, b) => {
    return b.requests - a.requests;
  });
  return graphData;
};

export class GraphData {
  constructor() {
    this.wholeGraphData = {};
  }

  compute(data, allProps) {
    // create a hashmap for each prop in all_props

    for (const key in allProps) {
      if (Object.hasOwnProperty.call(allProps, key)) {
        this.wholeGraphData[allProps[key]] = {};
      }
    }

    for (const key in data) {
      if (!Object.hasOwnProperty.call(data, key)) continue;

      // iterate over all elements in whis.wholeGraphData
      for (const prop in this.wholeGraphData) {
        if (!Object.hasOwnProperty.call(this.wholeGraphData, prop)) continue;

        if (this.wholeGraphData[prop][data[key][prop]]) {
          // incremet the requests and roomRequests
          this.wholeGraphData[prop][data[key][prop]].roomRequests += 1;
          this.wholeGraphData[prop][data[key][prop]].requests +=
            data[key].split_request;
        } else {
          this.wholeGraphData[prop][data[key][prop]] = {
            roomRequests: 1,
            requests: data[key].split_request
          };
        }
      }
    }
    // convert the hashmap to an array
    for (const prop in this.wholeGraphData) {
      if (!Object.hasOwnProperty.call(this.wholeGraphData, prop)) continue;
      const tempArray = [];
      for (const key in this.wholeGraphData[prop]) {
        if (!Object.hasOwnProperty.call(this.wholeGraphData[prop], key))
          continue;
        tempArray.push({
          label: key,
          requests: this.wholeGraphData[prop][key]?.requests?.toFixed(2),
          roomRequests: this.wholeGraphData[prop][key].roomRequests
        });
      }
      tempArray.sort((a, b) => {
        return b.requests - a.requests;
      });
      this.wholeGraphData[prop] = tempArray;
    }
  }

  getGraphData(prop) {
    return this.wholeGraphData[prop];
  }
}

export const getGenderDataSorted = (data, t) => {
  const graphData = [];
  [t("PMSPage.male"), t("PMSPage.female"), t("PMSPage.others")].forEach(
    (key) => {
      if (data[key]) {
        let tempRequests = 0;
        tempRequests = Object.keys(
          dataFilterFunction(data[key], "reservation_id")
        ).length;

        const dataToAdd = {
          label: key,
          requests: tempRequests
        };

        graphData.push(dataToAdd);
      }
    }
  );

  return graphData;
};

export const compareAndMergeData = (data1, data2) => {
  const {
    labels: labels1,
    requests: requests1,
    roomReq: roomReq1,
    bookings: bookings1,
    roomBook: roomBook1
  } = data1;
  const {
    labels: labels2,
    requests: requests2,
    roomReq: roomReq2,
    bookings: bookings2,
    roomBook: roomBook2
  } = data2;

  const tempLabels = [];

  const tempRequests1 = [];
  const tempRequests2 = [];

  const tempBookings1 = [];
  const tempBookings2 = [];

  const tempRoomReq1 = [];
  const tempRoomReq2 = [];

  const tempRoomBook1 = [];
  const tempRoomBook2 = [];

  const tempRequests = requests1 && requests2 ? [] : null;
  const tempBookings = bookings1 && bookings2 ? [] : null;
  const tempRoomReq = roomReq1 && roomReq2 ? [] : null;
  const tempRoomBook = roomBook1 && roomBook2 ? [] : null;
  labels1.map((ele, i) => {
    tempLabels.push(ele);

    if (labels2.includes(ele)) {
      const tempIndex = labels2.indexOf(ele);

      if (requests1 && requests2) {
        tempRequests1.push(requests1[i]);
        tempRequests2.push(requests2[tempIndex]);
        tempRequests.push(
          Math.abs(requests1[i] - requests2[tempIndex]).toFixed(2)
        );
      }
      if (bookings1 && bookings2) {
        tempBookings1.push(bookings1[i]);
        tempBookings2.push(bookings2[tempIndex]);
        tempBookings.push(
          Math.abs(bookings1[i] - bookings2[tempIndex]).toFixed(2)
        );
      }
      if (roomReq1 && roomReq2) {
        tempRoomReq1.push(roomReq1[i]);
        tempRoomReq2.push(roomReq2[tempIndex]);
        tempRoomReq.push(Math.abs(roomReq1[i] - roomReq2[tempIndex]));
      }
      if (roomBook1 && roomBook2) {
        tempRoomBook1.push(roomBook1[i]);
        tempRoomBook2.push(roomBook2[tempIndex]);
        tempRoomBook.push(Math.abs(roomBook1[i] - roomBook2[tempIndex]));
      }
    } else {
      if (labels1) {
        tempRequests1.push(requests1?.[i]);
        tempBookings1.push(bookings1?.[i]);
        tempRoomBook1.push(roomBook1?.[i]);
        tempRoomReq1.push(roomReq1?.[i]);
      }
      tempRequests2.push(0);
      tempBookings2.push(0);
      tempRoomBook2.push(0);
      tempRoomReq2.push(0);

      if (requests1 && requests2) tempRequests.push(requests1[i]);
      if (bookings1 && bookings2) tempBookings.push(bookings1[i]);
      if (roomReq1 && roomReq2) tempRoomReq.push(roomReq1[i]);
      if (roomBook1 && roomBook2) tempRoomBook.push(roomBook1[i]);
    }

    return ele;
  });

  labels2.map((ele, i) => {
    if (!tempLabels.includes(ele)) {
      tempLabels.push(ele);
      tempRequests1.push(0);
      tempBookings1.push(0);
      tempRoomBook1.push(0);
      tempRoomReq1.push(0);
      if (labels2) {
        tempRequests2.push(requests2?.[i]);
        tempBookings2.push(bookings2?.[i]);
        tempRoomBook2.push(roomBook2?.[i]);
        tempRoomReq2.push(roomReq2?.[i]);
      }
      if (requests1 && requests2) tempRequests.push(requests2[i]);
      if (bookings1 && bookings2) tempBookings.push(bookings2[i]);
      if (roomReq1 && roomReq2) tempRoomReq.push(roomReq2[i]);
      if (roomBook1 && roomBook2) tempRoomBook.push(roomBook2[i]);
    }

    return ele;
  });

  return {
    labels: tempLabels,
    requests: tempRequests,
    requests1: tempRequests1,
    requests2: tempRequests2,
    bookings: tempBookings,
    bookings1: tempBookings1,
    bookings2: tempBookings2,
    roomReq: tempRoomReq,
    roomReq1: tempRoomReq1,
    roomReq2: tempRoomReq2,
    roomBook: tempRoomBook,
    roomBook1: tempRoomBook1,
    roomBook2: tempRoomBook2
  };
};

const customComparator = (a, b) => {
  const [dayA, monthA] = a.split(" ");
  const [dayB, monthB] = b.split(" ");

  const monthOrder = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };
  const monthComparison = monthOrder[monthA] - monthOrder[monthB];
  if (monthComparison !== 0) {
    return monthComparison;
  }

  // If months are the same, compare days
  return parseInt(dayA, 10) - parseInt(dayB, 10);
};

const getDaysBasedOnYear = (year) => {
  const values = getDatesInRange({
    startDate: `01-01-${year}`,
    endDate: `31-12-${year}`
  });
  return values;
};

export const getLineChartData = (
  data,
  dateRange,
  timeType,
  heatMapDateRange
) => {
  // populate the label, requests, bookings, revenue
  const graphData = [];

  let dateArray = [];
  if (heatMapDateRange !== null) {
    dateArray = getDatesInRange(heatMapDateRange);
  } else {
    dateArray = getDatesInRange(dateRange);
  }

  dateArray.forEach((key) => {
    let tempBookings = 0;
    let tempRequests = 0;
    let tempRevenue = 0;
    if (data[key]) {
      const type = dataFilterFunction(data[key], "type");

      if (type.reservation) {
        tempRevenue = (data[key] || []).reduce((a, b) => a + b.revenue, 0);

        tempBookings = Object.keys(
          dataFilterFunction(type.reservation || [], "reservation_id")
        ).length;
      }

      tempRequests = Object.keys(
        dataFilterFunction(data[key], "reservation_id")
      ).length;

      graphData.push({
        label: key,
        bookings: tempBookings,
        requests: tempRequests,
        revenue: tempRevenue
      });
    } else graphData.push({ label: key, bookings: 0, requests: 0, revenue: 0 });
  });
  return graphData;
};

function genderUpdate(gender, t) {
  const translation = {
    male: t("PMSPage.male"),
    female: t("PMSPage.female"),
    unknown: t("PMSPage.others")
  };

  let genderChange = translation.unknown;

  if (gender?.toLowerCase() === "male") genderChange = translation.male;
  if (gender?.toLowerCase() === "female") genderChange = translation.female;

  return genderChange;
}

export const updateSpendRange = (incomingData) => {
  const graphData = [];
  Object.keys(incomingData).forEach((key) => {
    if (key) {
      const tempSpend = incomingData[key] || 0;
      const dataToAdd = {
        reservation_id: key,
        amount: tempSpend,
        spend: spendRange(tempSpend)
      };
      graphData.push(dataToAdd);
    }
  });

  return graphData;
};

// Date with formatted value based on the date range)
export const modifyData = (data, t) => {
  const defaultName = "Others";

  return data.map((ele) => {
    const {
      country_code: countryCode,
      booking_channel: bc,
      advertising_campaign: ac,
      advertising_medium: am,
      advertising_partner: ap,
      gender,
      age,
      revenue
    } = ele;

    const dateUTC = new Date(ele.date);
    const tempDate = `${dateUTC.getDate()}/${dateUTC.getMonth() + 1}/${dateUTC
      .getFullYear()
      .toString()
      .slice(-2)}`;

    return {
      ...ele,
      country_code: countryCode
        ? countryISO[countryCode]?.name ?? defaultName
        : defaultName,
      oldDate: ele.date,
      date: tempDate,
      age: age === "Unknown" ? age : ageRange(age, t),
      gender: genderUpdate(gender, t),
      booking_channel: bc !== "" ? bc : defaultName,
      advertising_campaign: ac !== "" ? ac : defaultName,
      advertising_medium: am !== "" ? am : defaultName,
      advertising_partner: ap !== "" ? ap : defaultName,
      spendRange: spendRange(revenue, t)
    };
  });
};

export const compareMergeReqBook = (data1, data2) => {
  return {
    labels: data1.labels,
    compareLabels: data2.labels,
    requests: data1.requests,
    bookings: data1.bookings,
    revenue: data1.revenue,
    secondRequests: data2.requests,
    secondBookings: data2.bookings,
    secondRevenue: data2.revenue
  };
};
