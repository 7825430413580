/* eslint-disable */
import React from "react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import managecampaign from "../../assets/icons/managecampaign.png";
import YellowIcon from "../../assets/icons/status (2).svg";
import GreenIcon from "../../assets/icons/status (3).svg";
import sort from "../../assets/icons/sort.svg";
import "../../features/ManageCampaign/assets/stylesheets/manageCampaign.scss";
import themeColors from "../../assets/stylesheets/_var.scss";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import { ButtonDark } from "../../components/inputElements/buttons/MainButton";
import DataTable from "../../components/Table/DataTable";
import { deleteCampaigns } from "../../features/ManageCampaign/components/AddCampaign/api/addCampaignAPI";
import Toastr from "../../components/Toastr/Toastr";
import DeleteComponent from "../../components/DeletePopUp/DeleteComponent";
import { handleGetAllCampaigns } from "../../features/ManageCampaign/services/addCampaignServices";

export default function ManageCampaign() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { websiteID } = useSelector((state) => state.generalData);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [allCampaigns, setAllCampaigns] = useState([]);

  const [deleteCampaignsList, setDeleteCampaignsList] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  function CustomButton() {
    return (
      <IconButton>
        <img src={sort} alt="sort" />
      </IconButton>
    );
  }

  const columns = [
    {
      field: "campaign_name",
      headerName: t("manageCampaign.campaignName"),
      flex: 1
    },
    { field: "channel_name", headerName: t("manageCampaign.channel"), flex: 1 },
    { field: "type", headerName: t("PMSPage.type"), flex: 1 },
    {
      field: "created_date",
      headerName: t("tagManager.Created Date"),
      flex: 1
    },
    {
      field: "campaign_status",
      headerName: t("userManagement.dataTable.status"),
      flex: 0,
      renderCell: (params) => (
        <div className="button-group">
          {params.value === 1 ? (
            <img src={GreenIcon} alt="Verified" />
          ) : (
            <img src={YellowIcon} alt="Not Verified" />
          )}
        </div>
      )
    }
  ];

  const getAllCampaigns = async () => {
    await handleGetAllCampaigns({
      setLoading,
      websiteID,
      setAllCampaigns
    });
  };

  const handleAddCampaign = () => {
    navigate("/manage/add-campaign");
  };

  const handleRowsSelect = (selectedData) => {
    const campaignInfo = selectedData?.map((campaign) => ({
      campaign_name: campaign?.campaign_name,
      channel_id: campaign?.channel_id
    }));
    setDeleteCampaignsList(campaignInfo);
  };

  const handleRowClick = (rowData) => {
    navigate("/manage/saved-campaign", {
      state: {
        selectedCampaign: rowData
      }
    });
  };

  const handleDeleteCampaigns = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        website_id: websiteID,
        campaignData: deleteCampaignsList
      };
      const res = await deleteCampaigns(payload);
      if (res?.data?.success) {
        const updatedCampaigns = allCampaigns?.filter(
          (campaign) =>
            !payload?.campaignData?.some(
              (payloadItem) =>
                payloadItem?.campaign_name === campaign?.campaign_name &&
                payloadItem?.channel_id === campaign?.channel_id
            )
        );
        Toastr.success(res?.data?.message);
        setShowDeletePopup(false);
        setAllCampaigns(updatedCampaigns);
        await getAllCampaigns();
      }
    } catch (error) {
      setDeleteLoading(false);
      Toastr.error("Failed to delete");
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    getAllCampaigns();
  }, []);

  const payload = {
    campaignData: deleteCampaignsList
  };

  let filteredIds = allCampaigns?.filter(
    (campaign) =>
      campaign?.channel_id === payload?.campaignData?.channel_id &&
      campaign?.channel_name === payload?.campaignData?.campaign_name
  );

  console.log("filteredIds", filteredIds);

  console.log(
    "payload",
    JSON.stringify(payload),
    "allCampaigns",
    JSON.stringify(allCampaigns)
  );

  const renderContent = () => {
    return (
      <div className="container">
        <div
          className="flex-center"
          style={{
            marginTop: "1%"
          }}
        >
          <div
            style={!isMobile ? { width: "100%" } : {}}
            className="flex-between"
          >
            <div className="manage-campaign-heading">
              {t("manageCampaign.campaignManagement")}
            </div>
            <div className="flex">
              <button
                aria-label="Delete multiple campaigns" // This line adds an accessible name
                style={
                  !isMobile
                    ? { backgroundSize: "50px" }
                    : { backgroundSize: "40px" }
                }
                className="delete-multiple"
                type="button"
                onClick={() => {
                  if (deleteCampaignsList?.length === 0) {
                    Toastr.error(t("manageCampaign.notSelected"));
                  } else {
                    setShowDeletePopup(true);
                  }
                }}
              />

              {showDeletePopup && (
                <DeleteComponent
                  onCancel={() => setShowDeletePopup(false)}
                  loading={deleteLoading}
                  onConfirmDelete={() => {
                    handleDeleteCampaigns();
                  }}
                />
              )}
              <div
                style={{
                  paddingBottom: "20px"
                }}
              >
                <ButtonDark
                  buttonText={"+ " + t("manageCampaign.addCampaign")}
                  onClick={handleAddCampaign}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {loading ? (
          <div className="flex-center">
            <TailSpin
              height="50"
              width="50"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible
            />
          </div>
        ) : (
          <div
            style={{
              paddingTop: "20px"
            }}
            className="background-box"
          >
            <div>
              <p className="all-campaigns-heading">
                {t("manageCampaign.allCampaigns")}
              </p>
              <DataTable
                getRowId={(row) => row?.id}
                rows={allCampaigns || []}
                pageSize={7}
                CustomButton={CustomButton}
                onRowsSelect={handleRowsSelect}
                onRowClick={handleRowClick}
                tableType={3}
                columns={columns}
              />
            </div>
          </div>
        )} */}

        {loading ? (
          <div className="flex-center">
            <TailSpin
              height="50"
              width="50"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible
            />
          </div>
        ) : allCampaigns.length === 0 ? (
          <div className="flex-center">
            <img
              src={managecampaign}
              className="manage-campaign-image"
              alt="manage-campaign"
            />
          </div>
        ) : (
          <div
            style={{
              paddingTop: "20px"
            }}
            className="background-box"
          >
            <div>
              <p className="all-campaigns-heading">
                {t("manageCampaign.allCampaigns")}
              </p>
              <DataTable
                getRowId={(row) => row?.id}
                rows={allCampaigns}
                pageSize={7}
                CustomButton={CustomButton}
                onRowsSelect={handleRowsSelect}
                onRowClick={handleRowClick}
                tableType={3}
                columns={columns}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <AuthorizedHeader />
      <div className="">{renderContent()}</div>
    </>
  );
}
