/* eslint-disable */
import React from "react";
import ReactJsxParser from "react-jsx-parser";

export default function GoogleImages({
  googleImages,
  navigationFrom,
  templateRefs,
  handleTemplateClick
}) {
  return (
    <div
      style={{
        cursor: "pointer"
      }}
    >
      {/* 1st div */}
      {/* 580x400, 336x280 */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {/* 580 x 400 */}
        <div
          onClick={() => handleTemplateClick(0)}
          style={{
            width: "580px",
            height: "400px",
            overflow: "hidden"
          }}
        >
          {navigationFrom === "createFlow" ? (
            <div>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.google?.[0]?.template}
                renderInWrapper={false}
                autoCloseVoidElements
              />

              <></>
            </div>
          ) : (
            <div ref={(el) => (templateRefs.current[0] = el)}>
              <ReactJsxParser
                renderInWrapper={false}
                autoCloseVoidElements
                components={{}}
                jsx={googleImages?.[0]}
              />
            </div>
          )}
        </div>
        {/* 580 x 400 ends */}

        {/* 336x280 */}
        <div
          style={{
            width: "336px",
            height: "280px",
            marginRight: "10px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(1)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[1]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[1] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[1]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 336 x 280 ends */}
      </div>
      {/* 1st div ends */}
      {/* 580x400, 336x280 ends */}

      {/* 2nd div */}
      <div
        style={{
          marginTop: "10px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          {/* 300 x 250 */}
          <div
            onClick={() => handleTemplateClick(2)}
            style={{
              width: "300px",
              height: "250px",
              marginRight: "10px",
              overflow: "hidden"
            }}
          >
            {navigationFrom === "createFlow" ? (
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.google?.[2]?.template}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            ) : (
              <div ref={(el) => (templateRefs.current[2] = el)}>
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.[2]}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              </div>
            )}
          </div>
          {/* 300 x 250 ends */}

          <div>
            {/* 300 x 50 */}
            <div
              style={{
                paddingBottom: "20px",
                overflow: "hidden"
              }}
              onClick={() => handleTemplateClick(3)}
            >
              {navigationFrom === "createFlow" ? (
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.google?.[3]?.template}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              ) : (
                <div ref={(el) => (templateRefs.current[3] = el)}>
                  <ReactJsxParser
                    components={{}}
                    jsx={googleImages?.[3]}
                    renderInWrapper={false}
                    autoCloseVoidElements
                  />
                </div>
              )}
            </div>
            {/* 300 x 50 ends */}

            {/* 320x50 */}
            <div
              style={{
                paddingBottom: "20px",
                overflow: "hidden"
              }}
              onClick={() => handleTemplateClick(4)}
            >
              {navigationFrom === "createFlow" ? (
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.google?.[4]?.template}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              ) : (
                <div ref={(el) => (templateRefs.current[4] = el)}>
                  <ReactJsxParser
                    components={{}}
                    jsx={googleImages?.[4]}
                    renderInWrapper={false}
                    autoCloseVoidElements
                  />
                </div>
              )}
            </div>
            {/* 320x50 ends */}

            {/* 320x100 */}
            <div
              style={{
                marginBottom: "12px",
                marginRight: "12px",
                width: "320px",
                height: "100px",
                overflow: "hidden"
              }}
              onClick={() => handleTemplateClick(5)}
            >
              {navigationFrom === "createFlow" ? (
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.google?.[5]?.template}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              ) : (
                <div ref={(el) => (templateRefs.current[5] = el)}>
                  <ReactJsxParser
                    components={{}}
                    jsx={googleImages?.[5]}
                    renderInWrapper={false}
                    autoCloseVoidElements
                  />
                </div>
              )}
            </div>
            {/* 320x100 ends */}
          </div>

          {/* 200x200 */}
          <div>
            <div
              style={{
                marginRight: "10px",
                width: "200px",
                height: "200px",
                overflow: "hidden"
              }}
              onClick={() => handleTemplateClick(9)}
            >
              {navigationFrom === "createFlow" ? (
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.google?.[9]?.template}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              ) : (
                <div ref={(el) => (templateRefs.current[9] = el)}>
                  <ReactJsxParser
                    components={{}}
                    jsx={googleImages?.[9]}
                    renderInWrapper={false}
                    autoCloseVoidElements
                  />
                </div>
              )}
            </div>
          </div>
          {/* 200x200 ends */}
        </div>
      </div>
      {/* 2nd div ends */}

      {/* 3rd div */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px"
        }}
      >
        {/* 468x60 */}
        <div
          style={{
            paddingBottom: "20px",
            width: "468px",
            height: "60px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(8)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[8]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[8] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[8]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 468x60 ends */}

        {/* Responsive 200x200 */}
        <div
          onClick={() => handleTemplateClick(19)}
          style={{
            marginRight: "10px",
            width: "200px",
            height: "200px",
            overflow: "hidden"
          }}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[19]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[19] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[19]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* Responsive 200x200 ends */}
      </div>
      {/* 3rd div ends */}

      {/* 4th div */}
      <div>
        <div>
          {/* 728x90 */}
          <div
            style={{
              marginRight: "14px",
              width: "728px",
              height: "90px",
              overflow: "hidden"
            }}
            onClick={() => handleTemplateClick(10)}
          >
            {navigationFrom === "createFlow" ? (
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.google?.[10]?.template}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            ) : (
              <div ref={(el) => (templateRefs.current[10] = el)}>
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.[10]}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              </div>
            )}
          </div>
          {/* 728x90 ends */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px"
          }}
        >
          {/* 120x600 */}
          <div
            onClick={() => handleTemplateClick(15)}
            style={{
              marginRight: "10px",
              width: "120px",
              height: "600px",
              overflow: "hidden"
            }}
          >
            {navigationFrom === "createFlow" ? (
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.google?.[15]?.template}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            ) : (
              <div ref={(el) => (templateRefs.current[15] = el)}>
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.[15]}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              </div>
            )}
          </div>
          {/* 120x600 ends */}

          {/* 160x600 */}
          <div
            onClick={() => handleTemplateClick(16)}
            style={{ width: "160px", height: "600px", overflow: "hidden" }}
          >
            {navigationFrom === "createFlow" ? (
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.google?.[16]?.template}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            ) : (
              <div ref={(el) => (templateRefs.current[16] = el)}>
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.[16]}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              </div>
            )}
          </div>
          {/* 160x600 ends */}

          {/* 240x400 */}
          <div
            style={{
              marginRight: "12px",
              width: "240px",
              height: "400px",
              overflow: "hidden"
            }}
            onClick={() => handleTemplateClick(6)}
          >
            {navigationFrom === "createFlow" ? (
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.google?.[6]?.template}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            ) : (
              <div ref={(el) => (templateRefs.current[6] = el)}>
                <ReactJsxParser
                  components={{}}
                  jsx={googleImages?.[6]}
                  renderInWrapper={false}
                  autoCloseVoidElements
                />
              </div>
            )}
          </div>
          {/* 240x400 ends */}
        </div>
      </div>
      {/* 4th div ends */}

      {/* 5th div */}
      <div
        style={{
          marginTop: "10px"
        }}
      >
        {/* 970x250 */}
        <div
          style={{
            marginBottom: "12px",
            width: "970px",
            height: "250px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(13)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[13]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[13] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[13]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 970x250 ends */}
      </div>
      {/* 5th div ends */}

      {/* 6th div */}
      <div
        style={{
          marginTop: "10px"
        }}
      >
        {/* 930x180 */}
        <div
          style={{
            marginBottom: "12px",
            width: "930px",
            height: "180px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(11)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[11]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[11] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[11]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 930x180 ends */}
      </div>
      {/* 6th div ends */}

      {/* 7th div */}
      <div
        style={{
          marginTop: "10px"
        }}
      >
        {/* 980x120 */}
        <div
          style={{
            marginTop: "12px",
            marginRight: "24px",
            width: "980px",
            height: "120px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(14)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[14]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[14] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[14]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 980x120 ends */}
      </div>
      {/* 7th div ends */}

      {/* 8th div */}
      <div
        style={{
          marginTop: "10px"
        }}
      >
        {/* 970x90 */}
        <div
          style={{
            marginBottom: "12px",
            width: "970px",
            height: "90px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(12)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[12]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[12] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[12]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 970x90 ends */}
      </div>
      {/* 8th div ends */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {/* 300x1050 */}
        <div
          style={{
            marginTop: "12px",
            marginBottom: "12px",
            width: "300px",
            height: "1050px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(18)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[18]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[18] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[18]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 300x1050 ends */}

        {/* 300x600 */}
        <div
          style={{
            marginRight: "12px",
            width: "300px",
            height: "600px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(7)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[7]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[7] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[7]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 300x600 ends */}

        {/* 250x250 */}
        <div
          style={{
            width: "250px",
            height: "250px",
            marginRight: "10px",
            overflow: "hidden"
          }}
          onClick={() => handleTemplateClick(17)}
        >
          {navigationFrom === "createFlow" ? (
            <ReactJsxParser
              components={{}}
              jsx={googleImages?.google?.[17]?.template}
              renderInWrapper={false}
              autoCloseVoidElements
            />
          ) : (
            <div ref={(el) => (templateRefs.current[17] = el)}>
              <ReactJsxParser
                components={{}}
                jsx={googleImages?.[17]}
                renderInWrapper={false}
                autoCloseVoidElements
              />
            </div>
          )}
        </div>
        {/* 250x250 ends */}
      </div>
    </div>
  );
}
