/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SingupImage from "../../../../assets/images/auth1.svg";
import Input from "../../../../components/inputElements/Input/Input";
import PasswordInput from "../../../../components/inputElements/Input/Password";
import ConfirmPasswordInput from "../../../../components/inputElements/Input/ConfirmPassword";
import terms from "../../../../data/terms";
import LanguageDropdown from "../../../../components/layouts/LanguageDropdown";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/signupForm.scss";

export default function SignUpForm({
  email,
  token,
  firstNameChnageHandler,
  setLastName,
  emailChangeHandler,
  passwordChangeHandler,
  confirmPasswordChangeHandler,
  handleSubmit,
  loading,
  nameError,
  emailError,
  passwordError,
  confirmPasswordError,
  textareaRef,
  acceptTerms,
  handleAcceptTermsChange,
  scrollToEnd,
  acceptPrivacy,
  handleAcceptPrivacyChange,
  acceptTermsError,
  acceptPrivacyError
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="signup-section">
      <div
        style={isMobile ? { width: "1296px" } : { maxWidth: "1296px" }}
        className="container-2"
      >
        <div className="flex">
          <div className="signup-image">
            <img
              style={isMobile ? { height: "72rem" } : {}}
              src={SingupImage}
              alt="svg"
            />
          </div>
          <div
            className="signup-form"
            style={isMobile ? { height: "72rem" } : {}}
          >
            <h2 className="modules-heading heading-bottom">
              {t("profileSettings.generalInformation.labels.registerNow")}
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="name-input seperator">
                <div className="flex-between">
                  <Input
                    type="text"
                    label={t(
                      "profileSettings.generalInformation.labels.firstName"
                    )}
                    placeholder="Bruce"
                    onChange={firstNameChnageHandler}
                  />
                  <Input
                    type="text"
                    label={t(
                      "profileSettings.generalInformation.labels.lastName"
                    )}
                    placeholder="Wayne"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                {nameError && <p className="error-message">{nameError}</p>}
              </div>
              <div className="email-input seperator">
                <Input
                  type="text"
                  label={t("common.emailField")}
                  placeholder="Brucewayne.batman@email.com"
                  onChange={emailChangeHandler}
                  error={emailError}
                  value={email}
                  readOnlyCondition={!!token}
                />
              </div>
              <div className="password-input seperator">
                <PasswordInput
                  placeholder="**************"
                  label={t(
                    "profileSettings.generalInformation.labels.createPassword"
                  )}
                  onChange={passwordChangeHandler}
                  error={passwordError}
                />
                <p className="note">
                  {t(
                    "profileSettings.generalInformation.labels.passwordValidation"
                  )}
                </p>
              </div>
              <div className="confirm-password-input seperator">
                <ConfirmPasswordInput
                  placeholder="**************"
                  label={t(
                    "profileSettings.generalInformation.labels.reenterPassword"
                  )}
                  onChange={confirmPasswordChangeHandler}
                  error={confirmPasswordError}
                />
              </div>
              <div className="terms-and-condition">
                <label htmlFor="terms">
                  {t(
                    "profileSettings.generalInformation.labels.termsAndConditions"
                  )}
                </label>
                <textarea id="terms" ref={textareaRef} value={terms} readOnly />
              </div>

              <div className="accept-terms-and-condition seperator">
                <div className="flex">
                  <input
                    type="checkbox"
                    id="accept-terms"
                    checked={acceptTerms}
                    onChange={handleAcceptTermsChange}
                    disabled={!scrollToEnd}
                  />
                  <p className="title">
                    {t("profileSettings.generalInformation.labels.acceptTerms")}
                  </p>
                </div>
                <p className="note">
                  {t("profileSettings.generalInformation.labels.readDocument")}
                </p>
                {acceptTermsError && (
                  <p className="error-message">{acceptTermsError}</p>
                )}
              </div>

              <div className="accept-privacy-policy">
                <div className="flex">
                  <input
                    type="checkbox"
                    id="accept-privacy"
                    checked={acceptPrivacy}
                    onChange={handleAcceptPrivacyChange}
                  />
                  <p className="title">
                    {t(
                      "profileSettings.generalInformation.labels.acceptPrivacy"
                    )}
                    {/* <span>
                      <a
                        href="https://yodalytics.com/service/datenschutzbestimmungen"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy policies.
                      </a>
                    </span> */}
                  </p>
                </div>
                {acceptPrivacyError && (
                  <p className="error-message">{acceptPrivacyError}</p>
                )}
              </div>
              <button
                type="submit"
                className={loading ? "register-loader" : ""}
              >
                {loading ? (
                  <div className="flex-center">
                    <div className="login-text-padding">
                      {t(
                        "profileSettings.generalInformation.labels.registering"
                      )}
                    </div>
                    <div className="flex-start">
                      <div className="dotted-progress-container-register">
                        <div className="dotted-progress-register" />
                      </div>
                    </div>
                  </div>
                ) : (
                  t("profileSettings.generalInformation.labels.register")
                )}
              </button>
            </form>
            <div className="login-redirect">
              <p>
                {t(
                  "profileSettings.generalInformation.labels.alreadyHaveAnAccount"
                )}{" "}
                <span onClick={() => navigate("/")}>
                  {t("profileSettings.generalInformation.labels.clickToLogin")}
                </span>
              </p>
            </div>

            <div className="signup-bottom">
              <div className="flex-between">
                <div className="bottom-details">
                  <p>Yodalytics™- A Limendo GmbH Software product</p>
                  <div className="flex policies">
                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        cursor: "pointer",
                        color: themeColors.primaryColorShade2
                      }}
                      onClick={() =>
                        window.open(
                          "https://www.yodalytics.com/service/nutzungsbedingungen",
                          "_blank"
                        )
                      }
                    >
                      {t("landingPage.termsConditions")}
                    </p>
                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        cursor: "pointer",
                        color: themeColors.primaryColorShade2
                      }}
                      onClick={() =>
                        window.open(
                          "https://www.yodalytics.com/service/datenschutzbestimmungen",
                          "_blank"
                        )
                      }
                    >
                      {t("landingPage.privacyPolicy")}
                    </p>
                  </div>
                </div>
                <div className="language-change-signup">
                  <LanguageDropdown showLabel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SignUpForm.propTypes = {
  email: PropTypes.string,
  token: PropTypes.string,
  firstNameChnageHandler: PropTypes.func,
  setLastName: PropTypes.func,
  emailChangeHandler: PropTypes.func,
  passwordChangeHandler: PropTypes.func,
  confirmPasswordChangeHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  nameError: PropTypes.string,
  emailError: PropTypes.string,
  passwordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  textareaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  acceptTerms: PropTypes.bool,
  handleAcceptTermsChange: PropTypes.func,
  scrollToEnd: PropTypes.bool,
  acceptPrivacy: PropTypes.bool,
  handleAcceptPrivacyChange: PropTypes.func,
  acceptTermsError: PropTypes.string,
  acceptPrivacyError: PropTypes.string
};
