/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import authImage from "../../../../assets/images/auth2.svg";
import PasswordInput from "../../../../components/inputElements/Input/Password";
import ConfirmPasswordInput from "../../../../components/inputElements/Input/ConfirmPassword";
import LanguageDropdown from "../../../../components/layouts/LanguageDropdown";
import "../assets/resetPassword.scss";

export default function ResetPasswordForm({
  passwordChangeHandler,
  confirmPasswordChangeHandler,
  loading,
  handleSubmit,
  passwordError,
  confirmPasswordError
}) {
  const navigate = useNavigate();
  return (
    <div className="reset-password-section">
      <div className="container-2">
        <div className="flex">
          <div className="auth-image">
            <img src={authImage} alt="svg" />
          </div>
          <div className="reset-password-form">
            <div className="flex-column">
              <div className="reset-top">
                <h2 className="modules-heading heading-bottom">
                  Reset Password?
                </h2>
                <p className="sub-text">Reset your password</p>
                <form onSubmit={handleSubmit}>
                  <div className="password-input">
                    <PasswordInput
                      placeholder="**************"
                      label="New password"
                      onChange={passwordChangeHandler}
                      error={passwordError}
                    />
                    <p className="note">
                      Note:- Password must contain uppercase, numbers and
                      specail character.
                    </p>
                  </div>
                  <div className="confirm-password-input">
                    <ConfirmPasswordInput
                      placeholder="**************"
                      label="Re-enter new password"
                      onChange={confirmPasswordChangeHandler}
                      error={confirmPasswordError}
                    />
                  </div>
                  <button type="submit">
                    {loading ? "Loading..." : "Reset"}
                  </button>
                </form>
                <div className="signup-redirect">
                  <p>
                    Don’t have an account yet?{" "}
                    <span onClick={() => navigate("/signup")}>
                      Click here to Register!
                    </span>
                  </p>
                </div>
              </div>
              <div className="reset-bottom">
                <div className="flex-between">
                  <div className="bottom-details">
                    <p>Yodalytics™- A Limendo GmbH Software product</p>
                    <div className="flex policies">
                      <p>Terms & Condition</p>
                      <p>Privacy policy</p>
                    </div>
                  </div>
                  <div className="language-change-reset">
                    <LanguageDropdown showLabel />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ResetPasswordForm.propTypes = {
  passwordChangeHandler: PropTypes.func,
  confirmPasswordChangeHandler: PropTypes.func,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  passwordError: PropTypes.string,
  confirmPasswordError: PropTypes.string
};
