/* eslint-disable */
import Toastr from "../../../components/Toastr/Toastr";
import {
  addCampaignImages,
  generateMultipleDimensions,
  generateTemplate,
  getAllCampaigns,
  getCampaignDetails,
  getCategorisedImage
} from "../components/AddCampaign/api/addCampaignAPI";

export const handleSaveCampaign = async (
  campaignInfo,
  setLoading,
  navigate
) => {
  setLoading(true);
  try {
    const response = await addCampaignImages(campaignInfo);
    if (response.data?.success) {
      Toastr.success(response.data?.message);
      navigate("/manage/campaign");
    }
  } catch (error) {
    Toastr.error("Error");
  } finally {
    setLoading(false);
  }
};

export const handleGetCategorisedImage = async ({
  payload,
  navigate,
  setLoading,
  setNoImageFound,
  navigateState
}) => {
  setLoading(true);

  try {
    // First API call to getCategorisedImage
    const res = await getCategorisedImage(payload);
    console.log("res", res);

    if (res.data.success) {
      const randomObjects = res.data.randomObjects;

      // Second API call to generateTemplate with the response from the first API
      const templateResponse = await generateTemplate(randomObjects);
      console.log("templateResponse", templateResponse);

      // If second API is successful, navigate with success message
      if (templateResponse?.status === 200) {
        const originalTemplates = templateResponse.data.templates;
        const cleanTemplates = originalTemplates.map((template) =>
          template
            .replace(/\\n/g, "")
            .replace(/\\t/g, "")
            .replace(/\\"/g, '"')
            .replace(/\\'/g, "'")
        );

        Toastr.success("Success");
        navigate("/manage/generate-campaign", {
          state: {
            ...navigateState,
            randomObjects,
            originalTemplates,
            cleanTemplates
          }
        });
      } else {
        Toastr.error("Template generation failed.");
      }
    } else {
      setNoImageFound(true);
    }
  } catch (error) {
    console.error("Error:", error); // Log the error for debugging
    Toastr.error("Something went wrong.");
  } finally {
    setLoading(false);
  }
};

export const handleGenerateMultipleDimensions = async ({
  setLoading,
  payload,
  navigate,
  cleanTemplates,
  template,
  selectedRandomObject,
  selectedChannels,
  campaignName,
  adRedirectUrl,
  ctaButtonUrl,
  multiSelectTypes,
  selectedPersonas,
  selectedImage,
  content,
  cta,
  heading,
  setErrors
}) => {
  setLoading(true);
  try {
    const response = await generateMultipleDimensions(payload);
    if (response?.status === 200) {
      navigate("/manage/generated-campaign-preview", {
        state: {
          cleanTemplates: cleanTemplates,
          template: template,
          selectedRandomObject: selectedRandomObject,
          selectedChannels: selectedChannels,
          campaignName: campaignName,
          adRedirectUrl: adRedirectUrl,
          ctaButtonUrl: ctaButtonUrl,
          multiDimensionAds: response.data.ads,
          multiSelectTypes,
          selectedPersonas,
          selectedImageNew: selectedImage,
          content,
          cta,
          heading
        }
      });
      Toastr.success("Success");
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.error || "An error required";
    setErrors((prevErrors) => ({
      ...prevErrors,
      campaignName: <p className="input-error-text">{errorMessage}</p>
    }));
  } finally {
    setLoading(false);
  }
};

export const handleGetAllCampaigns = async ({
  setLoading,
  websiteID,
  setAllCampaigns
}) => {
  setLoading(true);
  try {
    const res = await getAllCampaigns(websiteID);
    console.log("res", res);
    if (res.data.success) {
      setAllCampaigns(res?.data?.campaignsList || []);
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const handleGetCampaignDetails = async ({
  setLoading,
  setCampaignDetails
}) => {
  setLoading(true);
  try {
    const res = await getCampaignDetails();
    const order = [6, 1, 2, 3];

    const sortedCampaignDetails = res?.data?.campaignsData.sort(
      (a, b) => order.indexOf(a.channel_id) - order.indexOf(b.channel_id)
    );

    setCampaignDetails(sortedCampaignDetails);
  } catch (error) {
    setLoading(false);
    console.log(error);
  } finally {
    setLoading(false);
  }
};
