/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  cancelSubscription,
  getBillingAddresses,
  getCategories,
  getMonthlyUsage,
  getPageUsage,
  getPlansList,
  payVivaWallet
} from "./api/subscriptionAPI";
import storage from "../../../utils/storage";
import "./assets/subscription.scss";
import Subscription from "./components/Subscription";

const newPlansList = [
  {
    id: 1,
    localization: [
      {
        lang: "en",
        name: "Analytics"
      },
      {
        lang: "it",
        name: "Analitica"
      },
      {
        lang: "de",
        name: "Analytik"
      }
    ]
  },
  {
    id: 3,
    localization: [
      {
        lang: "en",
        name: "API integration"
      },
      {
        lang: "it",
        name: "Integrazione API"
      },
      {
        lang: "de",
        name: "API-Integration"
      }
    ]
  },
  {
    id: 2,
    localization: [
      {
        lang: "en",
        name: "Marketing campaign"
      },
      {
        lang: "it",
        name: "Campagna marketing"
      },
      {
        lang: "de",
        name: "Marketingkampagne"
      }
    ]
  },
  {
    id: 5,
    localization: [
      {
        lang: "en",
        name: "Real time call to action"
      },
      {
        lang: "it",
        name: "Call To Action in tempo reale"
      },
      {
        lang: "de",
        name: "Call to Action in Echtzeit"
      }
    ]
  }
];

export default function Index() {
  const {
    websitesList,
    websiteID: web,
    userEmail,
    countryISO,
    createdBy: cId
  } = useSelector((state) => state.generalData);

  const adminOrOwnerWebsites = websitesList?.filter(
    (website) =>
      website.role_id === 1 ||
      website.role_id === 3 ||
      website.role_id === 5 ||
      website.role_id === 6 ||
      website.role_id === 7
  );

  const [loading, setLoading] = useState(false);
  const [plansLoading, setPlansLoading] = useState(true);
  const [usageLoading, setUsageLoading] = useState(false);
  const [pageUsage, setPageUsage] = useState(null);
  const [monthlyUsage, setMonthlyUsage] = useState(null);
  const [billingAddresses, setBillingAddresses] = useState([]);
  /* eslint-disable */
  const [pills, setCategories] = useState([]);
  const [plansList, setPlansList] = useState(null);
  const [topUpPlanList, setTopUpPlanList] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [downGradedPlan, setDownGradedPlan] = useState(null);
  const [currentTopup, setCurrentTopup] = useState(null);
  const [pillSelect, setPillSelect] = useState(null);
  const [addressSelect, setAddressSelect] = useState(null);
  const [planSelect, setPlanSelect] = useState(null);
  const [websiteID, setWebsiteID] = useState(web);
  const [customerId, setCustomerId] = useState(cId);

  const [modalContent, setModalContent] = useState({
    open: false,
    children: null,
    address: null
  });

  const handlePillSelect = (pill) => {
    setPillSelect(pill);
  };
  const handleAddressSelect = (address) => {
    setAddressSelect(address);
  };
  const handlePlanSelect = (plan) => {
    setPlanSelect(plan);
  };

  const handleChangeModal = (children, value) => {
    setModalContent({
      open: true,
      children,
      ...value
    });
  };

  const handleCloseModal = () => {
    setModalContent({ open: false, children: null });
  };

  const handlePlanClick = (inList, plan) => {
    if (inList) {
      handlePlanSelect(plan);
      if (addressSelect) handleChangeModal("paymentDetails", null);
      else {
        const scrollEle =
          document.getElementsByClassName("billing-accordian")[0];
        scrollEle.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });
      }
    }
  };

  const handleWebsiteChange = (id) => {
    const website = websitesList.find((ele) => ele.id === id);
    setCustomerId(website.created_by);
    setWebsiteID(id);
  };

  const fetchAddressList = async () => {
    setLoading(true);
    try {
      const response = await getBillingAddresses({ customer_id: customerId });
      setBillingAddresses(response.data.result);
      if (!addressSelect) setAddressSelect(response.data.result[0]);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchPageUsage = async () => {
    setUsageLoading(true);
    try {
      const res = await getPageUsage({
        websiteId: websiteID,
        categoryId: pillSelect.id,
        customer_id: customerId
      });

      setPageUsage(res.data.result);
      setUsageLoading(false);
    } catch (error) {
      setPageUsage(null);
      setUsageLoading(false);
    }
  };

  const fetchPageViews = async () => {
    setUsageLoading(true);
    try {
      const res = await getMonthlyUsage({
        websiteId: websiteID,
        categoryId: pillSelect.id,
        customer_id: customerId
      });
      setMonthlyUsage(res.data.pageViews);
      setUsageLoading(false);
    } catch (error) {
      setMonthlyUsage(null);
      setUsageLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        websiteId: websiteID,
        categoryId: pillSelect.id,
        customer_id: customerId
      });
      window.location.reload();
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await getCategories();
      console.log("res====>", res);
      setCategories(res.data.categoryList);
      // setPillSelect(res.data.categoryList[0]);
      setPillSelect(newPlansList[0]);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchVivaWallet = async (payload) => {
    try {
      const res = await payVivaWallet({ ...payload, customer_id: customerId });
      storage.setItem("order", res.data.result.OrderCode);
      window.open(res.data.paymentUrl, "_self");
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchPlanDetails = async () => {
    try {
      setPlansLoading(true);
      const response = await getPlansList({
        websiteId: websiteID,
        categoryId: pillSelect.id,
        customer_id: customerId
      });
      console.log("response====>", response);
      setCurrentPlan(response?.data?.result?.currentPlan);
      setCurrentPlanId(response?.data?.result?.currentPlan);
      setExpiryDate(response?.data?.result?.expireDate);
      setDownGradedPlan(response?.data?.result?.downGradedPlan);
      setCurrentTopup(response.data.result.currentTopup);
      setPlansList(response.data.result.plans);
      setTopUpPlanList(response.data.result.topupPlans);
      setPlansLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const website = websitesList.find((ele) => ele.id === web);
    setCustomerId(website?.created_by);
    setWebsiteID(web);
  }, [web]);

  useEffect(() => {
    fetchCategories();
    fetchAddressList();
  }, []);

  useEffect(() => {
    fetchAddressList();
  }, [websiteID]);

  useEffect(() => {
    if (
      modalContent.open &&
      (modalContent.children === "changeAddress" || !modalContent.address)
    ) {
      fetchAddressList();
    }
  }, [modalContent]);

  useEffect(() => {
    if (billingAddresses.length < 1 && modalContent.open) {
      setAddressSelect(null);
      handleCloseModal();
    }
  }, [billingAddresses]);

  useEffect(() => {
    if (pillSelect) {
      fetchPlanDetails();
      fetchPageUsage();
      fetchPageViews();
    }
  }, [pillSelect, websiteID]);

  console.log("websiteID====>", websiteID);

  return (
    <Subscription
      websitesList={adminOrOwnerWebsites}
      websiteID={websiteID}
      handleWebsiteChange={handleWebsiteChange}
      userEmail={userEmail}
      countryISO={countryISO}
      loading={loading}
      plansLoading={plansLoading}
      usageLoading={usageLoading}
      pageUsage={pageUsage}
      monthlyUsage={monthlyUsage}
      billingAddresses={billingAddresses}
      // categoryPills={pills}
      categoryPills={newPlansList}
      plansList={plansList}
      topUpPlanList={topUpPlanList}
      currentPlan={currentPlan}
      currentPlanId={currentPlanId}
      expiryDate={expiryDate}
      downGradedPlan={downGradedPlan}
      currentTopup={currentTopup}
      planSelect={planSelect}
      pillSelect={pillSelect}
      addressSelect={addressSelect}
      modalContent={modalContent}
      handlePillSelect={handlePillSelect}
      handlePlanClick={handlePlanClick}
      handleChangeModal={handleChangeModal}
      handleCloseModal={handleCloseModal}
      handleAddressSelect={handleAddressSelect}
      fetchVivaWallet={fetchVivaWallet}
      handleCancelSubscription={handleCancelSubscription}
    />
  );
}
