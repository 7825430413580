import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Select } from "@mui/material";
import themeColors from "../../../../assets/stylesheets/_var.scss";

export default function MultiSelectDashboardComponent({
  userWebsitesInfo,
  options,
  handleDashboardChange,
  dashboardMapping,
  website
}) {
  const selectedDashboard = userWebsitesInfo?.filter(
    (dashboard) => dashboard?.website === website
  );

  return (
    <Select
      multiple
      className="select-options"
      disabled={selectedDashboard[0]?.role_id === 3}
      value={selectedDashboard[0]?.dashboard_id || []}
      onChange={handleDashboardChange}
      renderValue={(selected) => {
        if (Array.isArray(selected)) {
          return selected.length > 2 ? (
            <div>{selected.length} Dashboards Selected</div>
          ) : (
            selected.map((dashboard) => dashboardMapping[dashboard]).join(", ")
          );
        }
        return dashboardMapping[selected];
      }}
    >
      {options.map((option) => (
        <MenuItem
          style={{
            fontWeight: "500",
            fontSize: "16px",
            color: themeColors.primaryText,
            fontFamily: themeColors.fontQuickSand
          }}
          key={option}
          value={option}
        >
          {dashboardMapping[option]}
        </MenuItem>
      ))}
    </Select>
  );
}

MultiSelectDashboardComponent.propTypes = {
  userWebsitesInfo: PropTypes.oneOfType([PropTypes.array]),
  options: PropTypes.oneOfType([PropTypes.array]),
  handleDashboardChange: PropTypes.func,
  dashboardMapping: PropTypes.oneOfType([PropTypes.array]),
  website: PropTypes.oneOfType([PropTypes.array])
};

// export default MultiSelectDashboardComponent;
