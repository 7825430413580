import QuestionsComponent from "./components/QuestionsComponent";

export default function index({
  title,
  isCompare,
  questions,
  compareQuestions,
  pageCountOfQuestions,
  comparePageCountOfQuestions,
  loadingQuestions,
  handlePagination,
  pageNumber,
  comparePageNumber,
  handlePagination2
}) {
  return (
    <QuestionsComponent
      title={title}
      isCompare={isCompare}
      questions={questions}
      compareQuestions={compareQuestions}
      pageCountOfQuestions={pageCountOfQuestions}
      comparePageCountOfQuestions={comparePageCountOfQuestions}
      loadingQuestions={loadingQuestions}
      handlePagination={handlePagination}
      pageNumber={pageNumber}
      comparePageNumber={comparePageNumber}
      handlePagination2={handlePagination2}
    />
  );
}
